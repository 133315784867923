import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import styles from './material-details-modal.styles';
import imgCloseModal from '../../../assets/images/close-modal.png';
import { modalStyles } from '../../../constants/styles';

export default function MaterialDetailsModal({ closeModal, data, title }) {
  const generateContentComponent = () => Object.keys(data).map((item) => (
    <div style={styles.detailsView}>
      <p style={styles.labelText}>{item}</p>
      <p style={styles.valueText}>{data[item]}</p>
    </div>
  ));
  return (
    <Modal
      isOpen
      style={modalStyles}
    >
      <div style={styles.modalContainer}>
        <div style={styles.modalView}>
          <div style={styles.titleView}>
            <p style={styles.titleText}>{title}</p>
            <div role="button" tabIndex={0} style={styles.closeButton} onClick={closeModal} onKeyDown={closeModal}>
              <img src={imgCloseModal} alt="close-modal" style={styles.imgClose} />
            </div>
          </div>
          <div style={styles.contentView}>
            {generateContentComponent()}
          </div>
        </div>
      </div>
    </Modal>
  );
}

MaterialDetailsModal.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.shape({}),
  title: PropTypes.string,
};

MaterialDetailsModal.defaultProps = {
  closeModal: () => { },
  data: {},
  title: '',
};
