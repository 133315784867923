import { connect } from 'react-redux';

import Home from './home.component';
import { showLoader, hideLoader } from '../../../redux/actions/global.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
