import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LabeledField from '../labeled-field/index';
import CONFIRMATION from '../../pages/single-confirmation/single-confirmation.strings';
import SubHeader from '../sub-header/sub-header.component';
import imgHeaderInfoIcon from '../../../assets/images/header-info-icon.png';
// import CustomModal from '../custom-modal/index';
// import CustomButton from '../custom-button';
import styles from './order-detail.module.scss';
import { orderDetailsStrings } from '../../../constants/strings';

export default function OrderDetails({ order, userData }) {
  const [showMore, setShowMore] = useState(false);

  // const buttonWidth = { width: '48%' };
  return (
    <div className="row gx-0 no-gutters gx-0 gy-0 mt-2">
      <div>
        <SubHeader icon={imgHeaderInfoIcon} headerContent={orderDetailsStrings.HEADER_DETAILS}>
          <div className={`${styles.woinfosWrapper} border-bottom col pl-4 ml-3 mr-3`}>
            <div className="row gx-0">
              <LabeledField fieldClass={`${styles.woinfo} col-3`} label={orderDetailsStrings.ENTERED_BY}>
                <h5>{userData.displayName}</h5>
              </LabeledField>
              {CONFIRMATION.operationFields.map(({ label, size, key }) => (
                <LabeledField
                  key={key}
                  fieldClass={`${styles.woinfo} col-${size}`}
                  label={label}
                >
                  <h5>{order[key]}</h5>
                </LabeledField>
              ))}
            </div>
            <div className="no-gutters gx-0 gy-0 d-flex mt-2 mb-2">
              <span className={styles.moreButton} onClick={() => setShowMore(true)} role="presentation">
                {
                  !showMore && orderDetailsStrings.SHOW_MORE_HEADER_DETAILS
                }
              </span>
            </div>
            {showMore && (
              <div className="row gx-0">
                {CONFIRMATION.moreFields.map(({ label, size, key }) => (
                  <LabeledField
                    key={key}
                    fieldClass={`woinfo col-${size}`}
                    label={label}
                  >
                    <h5 style={{ whiteSpace: 'pre-wrap' }}>{order[key]}</h5>
                  </LabeledField>
                ))}
                <div className="no-gutters gx-0 gy-0 d-flex mt-2 mb-2">
                  <span className={`${styles.moreButton}`} onClick={() => setShowMore(false)} role="presentation">
                    {
                      showMore && orderDetailsStrings.SHOW_LESS_HEADER_DETAILS
                    }
                  </span>
                </div>
              </div>
            )}
          </div>
        </SubHeader>
      </div>
    </div>
  );
}

OrderDetails.propTypes = {
  order: PropTypes.shape({}),
  userData: PropTypes.shape({
    displayName: PropTypes.string,
  }),
};

OrderDetails.defaultProps = {
  order: {},
  userData: {},
};
