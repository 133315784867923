import _ from 'lodash';
import { createWorkOrdersTypes } from '../../../redux/actions/action-types';
import { createWorkOrderStrings } from '../../../constants/strings';
import staticItemsData from './work-order.data';
import staticFUItemsData from './work-order-fu.data';
import { checkNotNull } from '../../../Helpers/common-helpers';
import { priorityCWItems, specialityWOItems, impactWOItems } from '../../../constants/enums';
import staticEOItemsData from './work-order-eo.data';

const initialReserveMaterial = {
  [createWorkOrderStrings.MATERIAL]: '',
  [createWorkOrderStrings.MATERIAL_MEMO]: '',
  [createWorkOrderStrings.QTY_RQD]: '',
  [createWorkOrderStrings.UNLOAD_POINT]: '',
  [createWorkOrderStrings.REQ_DATE]: {
    value: null,
  },
  [createWorkOrderStrings.RECIPIENT]: '',
  PO_TEXT: 'NO MATERIAL SELECTED',
  isMaterialFetched: false,
};

const initialState = {
  orderData: null,
  isPostingFailed: false,
  errorMessage: '',
  postSuccesfull: false,
  isFailTextShow: false,
  canBeSaved: false,
  reserveMaterialCanBeSaved: true,
  funcLocationData: staticItemsData[createWorkOrderStrings.FUNC_LOC_LEV_2],
  priority: null,
  newWorkOrderValue: null,
  funcLocValue: {
    name: createWorkOrderStrings.FLOC_VALUE,
    value: '',
  },
  initialLocation: null,
  type: 'standalone',
  parentOrderId: null,
  [createWorkOrderStrings.USER]: {
    name: createWorkOrderStrings.USER,
    value: '',
    items: null,
    mandatory: true,
  },
  [createWorkOrderStrings.FUNC_LOC_LEV_1]: {
    name: createWorkOrderStrings.FUNC_LOC_LEV_1,
    value: '',
    items: null,
    mandatory: true,
  },
  [createWorkOrderStrings.ORDER_TYPE]: {
    name: createWorkOrderStrings.ORDER_TYPE,
    value: null,
    selectedObject: null,
    items: staticItemsData[createWorkOrderStrings.ORDER_TYPE].map((item) => ({
      name: item.name,
      value: item.value,
    })),
    isPickerOpen: false,
    mandatory: true,
  },
  [createWorkOrderStrings.PRIORITY]: {
    name: createWorkOrderStrings.PRIORITY,
    value: null,
    selectedObject: null,
    items: priorityCWItems,
    isPickerOpen: false,
    mandatory: true,
  },
  [createWorkOrderStrings.SYS_CONDITION]: {
    name: createWorkOrderStrings.SYS_CONDITION,
    value: null,
    selectedObject: null,
    items: [
      {
        name: '5 - RUNTIME',
        value: '5',
      },
      {
        name: '6 - DOWNDAY',
        value: '6',
      },
      {
        name: '7 - OUTAGE',
        value: '7',
      },
      {
        name: '8 - INTERRUPTION',
        value: '8',
      },
    ],
    isPickerOpen: false,
    mandatory: true,
  },
  [createWorkOrderStrings.ACTIVITY_TYPE]: {
    name: createWorkOrderStrings.ACTIVITY_TYPE,
    value: createWorkOrderStrings.SELECT_ORDER_TYPE,
    selectedObject: null,
    mandatory: true,
    items: null,
    isPickerOpen: false,
  },
  [createWorkOrderStrings.MAIN_WORK_CENTER]: {
    name: createWorkOrderStrings.MAIN_WORK_CENTER,
    value: null,
    mandatory: true,
    selectedObject: null,
    items: [],
    isPickerOpen: false,
  },
  [createWorkOrderStrings.SHORT_TEXT]: {
    name: createWorkOrderStrings.SHORT_TEXT,
    value: '',
    mandatory: true,
  },
  [createWorkOrderStrings.FUNC_LOC_LEV_2]: {
    name: createWorkOrderStrings.FUNC_LOC_LEV_2,
    value: null,
    // mandatory: true,
    selectedObject: null,
    items: staticItemsData[createWorkOrderStrings.FUNC_LOC_LEV_2].map((item) => ({
      name: item.name,
      value: item.value,
    })),
    isPickerOpen: false,
  },
  [createWorkOrderStrings.FUNC_LOC_LEV_3]: {
    name: createWorkOrderStrings.FUNC_LOC_LEV_3,
    value: createWorkOrderStrings.SELECT_FLOC_2,
    selectedObject: null,
    // mandatory: true,
    items: null,
    isPickerOpen: false,
  },
  [createWorkOrderStrings.FUNC_LOC_LEV_4]: {
    name: createWorkOrderStrings.FUNC_LOC_LEV_4,
    value: null,
    selectedObject: null,
    items: null,
    isPickerOpen: false,
  },
  [createWorkOrderStrings.FUNC_LOC_LEV_5]: {
    name: createWorkOrderStrings.FUNC_LOC_LEV_5,
    value: null,
    selectedObject: null,
    items: null,
    isPickerOpen: false,
  },
  [createWorkOrderStrings.FUNC_LOC_LEV_6]: {
    name: createWorkOrderStrings.FUNC_LOC_LEV_6,
    value: null,
    selectedObject: null,
    items: null,
    isPickerOpen: false,
  },
  [createWorkOrderStrings.EQUIPMENT]: {
    name: createWorkOrderStrings.EQUIPMENT,
    value: null,
    mandatory: false,
    selectedObject: null,
    items: null,
    isPickerOpen: false,
  },
  [createWorkOrderStrings.REVISION]: {
    name: createWorkOrderStrings.REVISION,
    value: '',
  },
  [createWorkOrderStrings.OP_DESC]: {
    name: createWorkOrderStrings.OP_DESC,
    value: '',
  },
  [createWorkOrderStrings.OP_WK_CNTR]: {
    name: createWorkOrderStrings.OP_WK_CNTR,
    value: null,
    selectedObject: null,
    items: [],
    isPickerOpen: false,
  },
  [createWorkOrderStrings.KITTING]: {
    name: createWorkOrderStrings.KITTING,
    value: false,
  },
  [createWorkOrderStrings.OP_DURATION]: {
    name: createWorkOrderStrings.OP_DURATION,
    value: '',
  },
  [createWorkOrderStrings.NO_OF_RQD_RES]: {
    name: createWorkOrderStrings.NO_OF_RQD_RES,
    value: '',
  },
  [createWorkOrderStrings.BASIC_START_DATE]: {
    name: createWorkOrderStrings.BASIC_START_DATE,
    value: '',
  },
  [createWorkOrderStrings.SPECIALITY]: {
    name: createWorkOrderStrings.SPECIALITY,
    value: 'Not Selected',
    selectedObject: null,
    items: _.cloneDeep(specialityWOItems),
    isPickerOpen: false,
  },
  [createWorkOrderStrings.IMPACT]: {
    name: createWorkOrderStrings.IMPACT,
    value: 'Not Selected',
    selectedObject: null,
    items: _.cloneDeep(impactWOItems),
    isPickerOpen: false,
  },
  [createWorkOrderStrings.IMMEDIATE_EXECUTION]: {
    name: createWorkOrderStrings.IMMEDIATE_EXECUTION,
    value: false,
  },
  reserveMaterials: [],
};

const checkReserveMaterials = (reserveMaterials) => {
  let canSave = true;
  for (let index = 0; index < reserveMaterials.length; index += 1) {
    const item = reserveMaterials[index];
    if (item.isMaterialFetched) {
      if (
        item[createWorkOrderStrings.QTY_RQD] === ''
        || item[createWorkOrderStrings.UNLOAD_POINT] === ''
        || item[createWorkOrderStrings.RECIPIENT] === ''
        || item[createWorkOrderStrings.REQ_DATE.value === null]
      ) {
        canSave = false;
        break;
      }
    }
  }
  return canSave;
};

const checkCanBeSaved = (state) => {
  let canBeSaved = true;
  Object.keys(state).forEach((element) => {
    let isKeyCanSave = true;
    if (state[element] && typeof state[element] === 'object') {
      if (element === 'funcLocValue') {
        if (state[element].value !== '' && state[element]?.value?.split('-').length > 2) {
          isKeyCanSave = true;
        } else {
          isKeyCanSave = false;
        }
      } else if (state[element].mandatory) {
        if (state[element].name === createWorkOrderStrings.ACTIVITY_TYPE) {
          isKeyCanSave = checkNotNull(state[element].selectedObject);
        } else if (checkNotNull(state[element].items)) {
          isKeyCanSave = checkNotNull(state[element].selectedObject);
        } else {
          isKeyCanSave = checkNotNull(state[element].value);
        }
      }
      canBeSaved = canBeSaved && isKeyCanSave;
    }
  });
  return canBeSaved;
};
const checkReserveMaterialsCanBeSaved = (state) => {
  let canBeSaved = true;
  Object.keys(state).forEach((element) => {
    let isKeyCanSave = true;
    if (state[element] && typeof state[element] === 'object') {
      if (element === 'reserveMaterials') {
        isKeyCanSave = checkReserveMaterials(state[element]);
      }
      canBeSaved = canBeSaved && isKeyCanSave;
    }
  });
  return canBeSaved;
};

// const checkEquipment = (items, value) => {
//   if (items instanceof Array && items.length > 0) {
//     const equipObj = items.find((item) => item.value === value);
//     if (equipObj !== undefined) {
//       return equipObj;
//     }
//     let equipObject;
//     items.forEach((equip) => {
//       if (equipObject === undefined) {
//         if (equip.items) {
//           equipObject = checkEquipment(equip.items, value);
//         }
//       }
//     });
//     return equipObject;
//   }
//   return null;
// };
const createWorkOrderReducer = (state = initialState, action = {}) => {
  let unsavedState = {};
  switch (action.type) {
    case createWorkOrdersTypes.UPDATE_VALUE: {
      const updatedObj = { ...state[action.payload.key] };
      updatedObj.value = action.payload.value;
      const updatedStateForUpdateValue = {
        ...state,
        [action.payload.key]: updatedObj,
        isFailTextShow: false,
      };
      return {
        ...updatedStateForUpdateValue,
        canBeSaved: checkCanBeSaved(updatedStateForUpdateValue),
      };
    }
    case createWorkOrdersTypes.UPDATE_DROP_DOWN_ITEM: {
      try {
        let newState = {};
        const updatedDropDownItem = { ...state[action.payload.key] };
        if (action.payload.name === null && action.payload.value === null) {
          updatedDropDownItem.selectedObject = null;
        } else {
          updatedDropDownItem.selectedObject = {
            name: action.payload.name,
            value: action.payload.value,
          };
        }
        updatedDropDownItem.value = action.payload.name;
        newState = {
          ...state,
          [action.payload.key]: updatedDropDownItem,
        };
        if (action.payload.key.includes('Functional')) {
          newState = {
            ...newState,
            funcLocValue: {
              name: action.payload.name,
              value: action.payload.value,
            },
          };
        }
        if (
          action.payload.key === createWorkOrderStrings.ORDER_TYPE
          || action.payload.key === createWorkOrderStrings.FUNC_LOC_LEV_2
        ) {
          const dependedObject = action.payload.key === createWorkOrderStrings.ORDER_TYPE
            ? createWorkOrderStrings.ACTIVITY_TYPE
            : createWorkOrderStrings.FUNC_LOC_LEV_3;
          let typedObj = staticItemsData;
          if (state.type === createWorkOrderStrings.FOLLOW_UP_TYPE) {
            typedObj = staticFUItemsData;
          } else if (state.type === createWorkOrderStrings.EDIT_TYPE) {
            typedObj = staticEOItemsData;
          } else {
            typedObj = staticItemsData;
          }
          const dataObj = action.payload.key === createWorkOrderStrings.ORDER_TYPE
            ? typedObj[action.payload.key]
            : state.funcLocationData;
          const staticObj = dataObj.find(
            (item) => item.value === action.payload.value,
          );
          const updatedActivityType = { ...state[dependedObject] };
          updatedActivityType.items = staticObj.items;
          let equipmentItems = null;
          if (action.payload.key === createWorkOrderStrings.FUNC_LOC_LEV_2) {
            let nonEquipmentItems = staticObj.items?.filter(
              (item) => item.category !== 'FE' && item.category !== 'EE',
            );
            nonEquipmentItems = nonEquipmentItems?.length > 0 ? nonEquipmentItems : null;
            equipmentItems = staticObj.items?.filter(
              (item) => item.category === 'FE' || item.category === 'EE',
            );
            updatedActivityType.items = nonEquipmentItems;
          }
          if ((action.payload.value === 'ZM01') || (action.payload.value === 'ZM07' && state[createWorkOrderStrings.IMMEDIATE_EXECUTION].value)) {
            if (
              action.payload.key === createWorkOrderStrings.ORDER_TYPE
            ) {
              newState = {
                ...newState,
                [createWorkOrderStrings.PRIORITY]: {
                  ...newState[createWorkOrderStrings.PRIORITY],
                  selectedObject: priorityCWItems.find((item) => item.value === '1'),
                  value: '1',
                },
              };
            }
          } else {
            // if (action.payload.key === createWorkOrderStrings.ORDER_TYPE) {
            newState = {
              ...newState,
              [createWorkOrderStrings.PRIORITY]: {
                ...newState[createWorkOrderStrings.PRIORITY],
                selectedObject: priorityCWItems.find((item) => item.value === '3'),
                value: '3',
              },
            };
            // }
          }
          if (action.payload.value === 'ZM07') {
            if (
              action.payload.key === createWorkOrderStrings.ORDER_TYPE
              && action.payload.isFollowUp
            ) {
              updatedActivityType.items = staticObj.items[action.payload.parentOrderType];
            }
          }

          if (updatedActivityType.items.length === 1) {
            updatedActivityType.selectedObject = {
              name: updatedActivityType.items[0].name,
              value: updatedActivityType.items[0].value,
            };
          } else {
            updatedActivityType.selectedObject = null;
          }
          if (action.payload.value === 'ZM01' && action.payload.key === createWorkOrderStrings.ORDER_TYPE) {
            updatedActivityType.selectedObject = updatedActivityType.items.find((item) => item.value === '130');
            updatedActivityType.value = '130';
            newState = {
              ...newState,
              [createWorkOrderStrings.SYS_CONDITION]: {
                ...newState[createWorkOrderStrings.SYS_CONDITION],
                selectedObject: newState[createWorkOrderStrings.SYS_CONDITION].items.find((item) => item.value === '8'),
                value: '8',
              },
            };
          } else {
            newState = {
              ...newState,
              [createWorkOrderStrings.SYS_CONDITION]: {
                ...newState[createWorkOrderStrings.SYS_CONDITION],
                selectedObject: null,
                value: null,
              },
            };
          }
          newState = {
            ...newState,
            [dependedObject]: updatedActivityType,
            [createWorkOrderStrings.EQUIPMENT]: {
              ...initialState[createWorkOrderStrings.EQUIPMENT],
              items: equipmentItems,
            },
          };
        }
        const funcLocNumber = Number(
          action.payload.key.charAt(action.payload.key.length - 1),
        );

        if (funcLocNumber >= 2 && funcLocNumber < 6) {
          const dependentText = `Functional Location - Level ${funcLocNumber + 1}`;
          let i = funcLocNumber;
          for (i = funcLocNumber; i < 6; i += 1) {
            if (i !== 2) {
              const flocLoopLevel = `Functional Location - Level ${i + 1}`;
              newState = {
                ...newState,
                [flocLoopLevel]: {
                  ...initialState[flocLoopLevel],
                },
              };
            }
          }

          const dropDownItems = state[action.payload.key].items.find(
            (item) => item.value === action.payload.value,
          ).items;
          let nonEquipmentItems = dropDownItems?.filter(
            (item) => item.category !== 'FE' && item.category !== 'EE',
          );
          nonEquipmentItems = nonEquipmentItems?.length > 0 ? nonEquipmentItems : null;
          const equipmentItems = dropDownItems?.filter(
            (item) => item.category === 'FE' || item.category === 'EE',
          );
          if (funcLocNumber !== 2) {
            newState = {
              ...newState,
              [dependentText]: {
                ...initialState[dependentText],
                items: nonEquipmentItems,
              },
              [createWorkOrderStrings.EQUIPMENT]: {
                ...initialState[createWorkOrderStrings.EQUIPMENT],
                items: equipmentItems,
              },
            };
          }
        }
        newState = {
          ...newState,
          canBeSaved: checkCanBeSaved(newState),
        };
        return newState;
      } catch (e) {
        console.log(e);
      }
      return state;
    }
    case createWorkOrdersTypes.UPDATE_PICKER_OPEN_STATUS: {
      const updatedState = { ...state };
      Object.keys(updatedState).forEach((key) => {
        if (
          typeof updatedState[key] === 'object'
          && checkNotNull(updatedState[key])
        ) {
          if (action.payload === updatedState[key].name) {
            updatedState[key].isPickerOpen = !updatedState[key].isPickerOpen;
          } else {
            updatedState[key].isPickerOpen = false;
          }
        }
      });
      return {
        ...updatedState,
      };
    }
    case createWorkOrdersTypes.UPDATE_TYPE: {
      let typeObj = null;
      if (action.payload === createWorkOrderStrings.FOLLOW_UP_TYPE) {
        typeObj = staticFUItemsData;
      } else {
        typeObj = staticItemsData;
      }
      return {
        ...state,
        type: action.payload,
        [createWorkOrderStrings.ORDER_TYPE]: {
          name: createWorkOrderStrings.ORDER_TYPE,
          value: null,
          selectedObject: null,
          items: typeObj[createWorkOrderStrings.ORDER_TYPE].map((item) => ({
            name: item.name,
            value: item.value,
          })),
          isPickerOpen: false,
        },
      };
    }
    case createWorkOrdersTypes.RESET_CREATE_WORK_ORDER_STATE:
      return {
        ...initialState,
        [createWorkOrderStrings.USER]: state[createWorkOrderStrings.USER],
        [createWorkOrderStrings.FUNC_LOC_LEV_1]:
          state[createWorkOrderStrings.FUNC_LOC_LEV_1],
      };
    case createWorkOrdersTypes.UPDATE_WO_SUCCESS_STATUS:
      return {
        ...state,
        postSuccesfull: action.payload,
      };
    case createWorkOrdersTypes.UPDATE_PARENT_ORDER_ID:
      return {
        ...state,
        parentOrderId: action.payload,
      };
    case createWorkOrdersTypes.UPDATE_WO_FAILURE_STATUS:
      return {
        ...state,
        isPostingFailed: action.payload.value,
        errorMessage: typeof action.payload.errorMessage === 'string' ? action.payload.errorMessage : action.payload?.errorMessage?.confirmStatus?.[0]?.message,
        newWorkOrderValue: action.payload?.errorMessage?.orderStatus?.message?.split(' ')[action.payload.errorMessage.orderStatus.message.split(' ').length - 1],
      };
    case createWorkOrdersTypes.UPDATE_WO_VALUE:
      return {
        ...state,
        newWorkOrderValue: action.payload.orderId,
        personalNumber: action.payload?.personalNumber,
        orderData: action.payload,
      };
    case createWorkOrdersTypes.UPDATE_FUNC_LOC_2: {
      const funcLoc2Obj = { ...state[createWorkOrderStrings.FUNC_LOC_LEV_2] };
      funcLoc2Obj.items = action.payload.map((item) => ({
        name: item.name,
        value: item.value,
      }));
      return {
        ...state,
        [createWorkOrderStrings.FUNC_LOC_LEV_2]: funcLoc2Obj,
        funcLocationData: action.payload,
      };
    }
    case createWorkOrdersTypes.CHECKBOX_STATUS: {
      let priorityObj = state[createWorkOrderStrings.PRIORITY];
      if (action.payload.key === createWorkOrderStrings.IMMEDIATE_EXECUTION && (state[createWorkOrderStrings.ORDER_TYPE].selectedObject?.value === 'ZM07')) {
        priorityObj = {
          [createWorkOrderStrings.PRIORITY]: {
            ...priorityObj,
            selectedObject: priorityCWItems.find((item) => item.value === (action.payload.value ? '1' : '3')),
            value: action.payload.value ? '1' : '3',
          },
        };
      }
      return {
        ...state,
        ...priorityObj,
        [action.payload.key]: {
          ...state[action.payload.key],
          value: action.payload.value,
        },
      };
    }
    case createWorkOrdersTypes.UPDATE_MULTI_SELECT:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          ...action.payload,
        },
      };
    case createWorkOrdersTypes.UPDATE_RESERVE_MATERIALS: {
      const updatedReserveLineItems = [...state.reserveMaterials];
      if (action.payload.key === createWorkOrderStrings.MATERIAL) {
        updatedReserveLineItems[action.payload.index] = {
          ...updatedReserveLineItems[action.payload.index],
          ...initialReserveMaterial,
        };
      }
      updatedReserveLineItems[action.payload.index][action.payload.key] = action.payload.value;
      unsavedState = {
        ...state,
        reserveMaterials: updatedReserveLineItems,
      };
      return {
        ...unsavedState,
        reserveMaterialCanBeSaved: checkReserveMaterialsCanBeSaved(unsavedState),
      };
    }
    case createWorkOrdersTypes.ADD_RESERVE_LINE_ITEM: {
      const addReserveLineItems = [...state.reserveMaterials];
      addReserveLineItems.push({ ...initialReserveMaterial, isMaterial: action.payload });
      unsavedState = {
        ...state,
        reserveMaterials: addReserveLineItems,
      };
      return {
        ...unsavedState,
        reserveMaterialCanBeSaved: checkReserveMaterialsCanBeSaved(unsavedState),
      };
    }
    case createWorkOrdersTypes.DELETE_RESERVE_LINE_ITEM: {
      const deleteReserveLineItems = [...state.reserveMaterials];
      if (deleteReserveLineItems[action.payload].isMaterial === 'I') {
        deleteReserveLineItems.splice(action.payload, 1);
      } else {
        deleteReserveLineItems[action.payload].isMaterial = 'D';
      }
      unsavedState = {
        ...state,
        reserveMaterials: deleteReserveLineItems,
      };
      return {
        ...unsavedState,
        reserveMaterialCanBeSaved: checkReserveMaterialsCanBeSaved(unsavedState),
      };
    }
    case createWorkOrdersTypes.RESET_RESERVE_LINE_ITEM: {
      const resetReserveLineItems = [...state.reserveMaterials];
      resetReserveLineItems[action.payload] = initialReserveMaterial;
      unsavedState = {
        ...state,
        reserveMaterials: resetReserveLineItems,
      };
      return {
        ...unsavedState,
        reserveMaterialCanBeSaved: checkReserveMaterialsCanBeSaved(unsavedState),
      };
    }
    case createWorkOrdersTypes.UPDATE_MATERIAL: {
      const updateMaterialReserveLineItems = [...state.reserveMaterials];
      let updatedItem = {
        ...updateMaterialReserveLineItems[action.payload.index],
      };
      updatedItem = {
        ...state.reserveMaterials[action.payload.index],
        ...action.payload,
      };
      updateMaterialReserveLineItems[action.payload.index] = updatedItem;
      unsavedState = {
        ...state,
        reserveMaterials: updateMaterialReserveLineItems,
      };
      return {
        ...unsavedState,
        reserveMaterialCanBeSaved: checkReserveMaterialsCanBeSaved(unsavedState),
      };
    }
    case createWorkOrdersTypes.UPDATE_SECTIONAL_MULTI_SELECT:
      return {
        ...state,
        [createWorkOrderStrings.EQUIPMENT]: {
          ...state[createWorkOrderStrings.EQUIPMENT],
          selectedObject: action.payload,
        },
      };
    case createWorkOrdersTypes.UPDATE_WRK_CTR_ITEMS:
      return {
        ...state,
        [createWorkOrderStrings.MAIN_WORK_CENTER]: {
          ...state[createWorkOrderStrings.MAIN_WORK_CENTER],
          items: action.payload,
        },
        [createWorkOrderStrings.OP_WK_CNTR]: {
          ...state[createWorkOrderStrings.OP_WK_CNTR],
          items: action.payload,
        },
      };
    case createWorkOrdersTypes.UPDATE_EQUIPMENT:

      return {
        ...state,
        [createWorkOrderStrings.EQUIPMENT]: {
          ...state[createWorkOrderStrings.EQUIPMENT],
          items: action.payload.items,
          selectedObject: action.payload.name,
          value: action.payload.value,
        },
      };
    case createWorkOrdersTypes.UPDATE_FLOC_VALUE: {
      const flocUpdatedState = {
        ...state,
        funcLocValue: {
          ...state[createWorkOrderStrings.FLOC_VALUE],
          name: action.payload.name,
          value: action.payload.value,
        },
      };
      return {
        ...flocUpdatedState,
        canBeSaved: checkCanBeSaved(flocUpdatedState),
      };
    }
    default:
      return state;
  }
};

// const checkEquipment = (items, value) => {

//   if (items instanceof Array && items.length > 0) {
//     let equipObj = items.find((item) => item.value === value);
//     if (equipObj !== undefined) {
//       return equipObj;
//     } else {
//       let equipObj;
//       items.forEach(equip => {
//         if (equipObj === undefined)
//           if (equip.items) {
//             equipObj = checkEquipment(equip.items, value);
//           }
//       })
//       return equipObj;
//     }
//   }
// }
export default createWorkOrderReducer;
