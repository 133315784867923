export const colorDefs = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  VERY_LIGHT_GRAY: '#E5E5E5',
  DESATURATED_BLUE: '#192D38',
  DARK_BLUE: '#003366',
  STRONG_BLUE: '#0578BE',
  LIGHT_GRAYISH_BLUE: '#ECF2F6',
  VERY_LIGHT_GRAYISH_BLUE: '#E0E4E6',
  DARK_GRAYISH_BLUE: '#53626A',
  SEMI_TRANSPARENT: 'rgba(0, 0, 0, 0.85)',
  LIGHT_GRAY_TRANSPARENT: '#D0D0D099',
  MODAL_TRANSPARENT: '#CCCCCC90',
  TRANSPARENT: '#FFFFFF10',
  STRONG_RED: '#CC0000',
  BALI_HAI: '#8C969B',
  GRAYISH_BLUE: '#C1C8CD',
  MODERATE_RED: '#C74834',
  VERY_PALE_RED: '#FFE2DC',
  PALE_LIGHTISH_BLUE: '#F2F8FC',
  DESATURATED_GREEN: '#31AF19',
  VIVID_ORANGE: '#E5A812',
};

export const appColors = {
  PAGE_BG: colorDefs.VERY_LIGHT_GRAY,
  WHITE_BG: colorDefs.WHITE,
  TEXT_WHITE: colorDefs.WHITE,
  TEXT_BLACK: colorDefs.BLACK,
  TEXT_DESATURATED_BLUE: colorDefs.DESATURATED_BLUE,
  TEXT_DARK_BLUE: colorDefs.DARK_BLUE,
  TEXT_STRONG_BLUE: colorDefs.STRONG_BLUE,
  LOADER_COLOR: colorDefs.STRONG_BLUE,
  TEXT_LIGHT_GRAYISH_BLUE: colorDefs.LIGHT_GRAYISH_BLUE,
  TEXT_DARK_GRAYISH_BLUE: colorDefs.DARK_GRAYISH_BLUE,
  TEXT_ERROR: colorDefs.STRONG_RED,
  SEMI_TRANSPARENT: colorDefs.SEMI_TRANSPARENT,
  LOADER_BACKGROUND: colorDefs.SEMI_TRANSPARENT,
  MODAL_TRANSPARENT: colorDefs.SEMI_TRANSPARENT,
  STATUS_BAR_TRANSPARENT: colorDefs.TRANSPARENT,
  BUTTON_BACKGROUND: colorDefs.STRONG_BLUE,
  TAB_BAR_LABEL_TEXT: colorDefs.BALI_HAI,
  DISABLE_LABEL_TEXT: colorDefs.BALI_HAI,
  SEARCH_BAR_BG: colorDefs.LIGHT_GRAYISH_BLUE,
  BORDER_COLOR: colorDefs.GRAYISH_BLUE,
  DISABLE_BUTON_BG: colorDefs.VERY_LIGHT_GRAYISH_BLUE,
  TEXT_ERROR_MODERATE: colorDefs.MODERATE_RED,
  ERROR_SEARCH_BAR_BG: colorDefs.VERY_PALE_RED,
  DROP_DOWN_ITEM_BG: colorDefs.PALE_LIGHTISH_BLUE,
  SUCCESS: colorDefs.DESATURATED_GREEN,
  WARNING: colorDefs.VIVID_ORANGE,
};
