export const appFontSizes = {
  TITLE: 38,
  HEADER_TEXT: 24,
  INSTRUCTIONS_TEXT: 16,
  SIDE_HEADING: 14,
  TEXT: 12,
  TAB_BAR_TEXT: 10,
};
export const appFontFamilies = {
  BARLOW: 'Barlow-Black',
  BARLOW_BOLD: 'Barlow-Bold',
  BARLOW_REGULAR: 'Barlow-Regular',
  BARLOW_MEDIUM: 'Barlow-Medium',
  BARLOW_SEMI_BOLD: 'Barlow-SemiBold',
  BARLOW_THIN: 'Barlow-Thin',
  OPENSANS: 'OpenSans-Black',
  OPENSANS_BOLD: 'OpenSans-Bold',
  OPENSANS_REGULAR: 'OpenSans-Regular',
  OPENSANS_MEDIUM: 'OpenSans-Medium',
  OPENSANS_SEMI_BOLD: 'OpenSans-SemiBold',
  OPENSANS_THIN: 'OpenSans-Thin',
  FUTURA_MEDIUM: 'Futura-Medium',
  OPENSANS_ITALIC: 'OpenSans-Italic',
};
export const DEVICE_WIDTH = window.innerWidth;
export const DEVICE_HEIGHT = window.innerHeight;

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
    zIndex: 5000,
  },
  content: {
    backgroundColor: 'rgba(255, 255, 255, 0.0001)',
    borderWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export const customLoaderStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
    zIndex: 5001,
  },
  content: {
    backgroundColor: 'rgba(255, 255, 255, 0.0001)',
    borderWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
