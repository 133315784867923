import React from 'react';
import { HttpGet, HttpPost, HttpPut } from '../services/api-services';
import { getInspSchURL } from './common-helpers';
import apiConfig from '../utilities/config';
import CustomModal from '../components/controls/custom-modal';
import { notificationStrings } from '../constants/strings';


export const askForPushNotificationPermission = (plantId, userMail) => {
    Notification.requestPermission().then(permission => {
        if (permission === "granted") {
            console.log("Notification permission granted.");
            subscribeToPushNotifications(plantId, userMail);
        } else {
            console.log("Notification permission denied.");
        }
        });
};
function subscribeToPushNotifications(plantId, userMail) {
    if ("serviceWorker" in navigator && "PushManager" in window) {
        navigator.serviceWorker.register("./sw.js");
        navigator.serviceWorker.ready
            .then(registration => {
                return registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey:
                        "BN6b1zoU_CuUqj2HWogGGRBqOkCBSCLUkGGRRzRWH7_uqbhXCBe8zilPK7L6yZfaqoD9iB5LJou3HHeNHNUjcNI"
                });
            })
            .then(subscription => {
                let subscriptionObject = {
                    ...subscription.toJSON(),
                    environment: apiConfig.ENVIRONMENT,
                    plantId: plantId,
                };
                let obj = {
                    useremail: userMail,
                    subscription: subscriptionObject,
                };
                sendSubscriptionToBackend(obj);
            })
            .catch(error => {
                console.error("Error subscribing to push notifications:", error);
            });
    }
}
async function sendSubscriptionToBackend(subscription) {
    try {
        let pushSubscriptionUrl = getInspSchURL("/push-subscription");
        const params = {
            user_email: subscription.useremail,
        };
        let response = await HttpGet(pushSubscriptionUrl, params);
        if (response.data.body.length === 0) {
            let postData = await HttpPost(pushSubscriptionUrl, subscription);
            if (postData.data.statusCode === 200) {
                console.log("Subscription Posted Successfully");
            } else {
                console.log("Failed to post subscription");
            }
        }
        else {
            let obj = JSON.stringify(subscription.subscription);
            const parsedObj = JSON.parse(obj);
            const filterEmail = response.data.body.filter(item => {
                const parsedItem = JSON.parse(item.subscription_object);
                if (!_.isEqual(parsedItem.endpoint, parsedObj.endpoint)) {
                    if (
                        parsedItem.endpoint.includes("fcm.googleapis.com") &&
                        parsedObj.endpoint.includes("fcm.googleapis.com")
                    ) {
                        return item.user_subscription_id;
                    } else if (
                        parsedItem.endpoint.includes("updates.push.services.mozilla.com") && parsedObj.endpoint.includes("updates.push.services.mozilla.com")
                    ) {
                        return item.user_subscription_id;
                    }
                }
            });
            const hasGoogle = response.data.body.some(item => {
                const parsedItem = JSON.parse(item.subscription_object);
                return parsedItem.endpoint.includes("fcm.googleapis.com");
            });
            const hasMozilla = response.data.body.some(item => {
                const parsedItem = JSON.parse(item.subscription_object);
                return parsedItem.endpoint.includes(
                    "updates.push.services.mozilla.com"
                );
            });
            if (
                (!hasMozilla &&
                    parsedObj.endpoint.includes("updates.push.services.mozilla.com")) ||
                (!hasGoogle && parsedObj.endpoint.includes("fcm.googleapis.com"))
            ) {
                let postData = await HttpPost(pushSubscriptionUrl, subscription);
                if (postData.data.statusCode === 200) {
                    console.log("Subscription Posted Successfully");
                } else {
                    console.log("Failed to post subscription");
                }
            } else if (filterEmail.length !== 0)
            {
                let putBody = {
                    user_id: filterEmail[0].user_subscription_id,
                    subscription: subscription.subscription
                };
                const putResponse = await HttpPut(pushSubscriptionUrl, putBody);
                console.log(putResponse, "putResponse");
            } else {
                console.log("Already Subscription present in database");
            }
        }
    } catch (error) {
        console.error("Error sending subscription to server:", error);
    }
}

export function NotificationModal() {
    return (
        <CustomModal
            customWidth="500px"
            header={{
                title: notificationStrings.CONFIGURE_NOTIFICATIONS_FOR_IN_APP_ACCESS,
                color: "#192D38",
                bgColor: "#F2F8FC",
                height: "33px"
            }}
        >
            <div
                className="p-2 m-2"
                style ={{
                fontFamily: 'Open-sans Regular',
                fontSize: '20px',
                fontStyle: 'normal',
                }}
            >
                <b>{notificationStrings.STEPS_TO_ENABLE_NOTIFICATIONS}</b>
                <ul>
                    <li>{notificationStrings.CLICK_ON_INFO_ICON}</li>
                    <li>{notificationStrings.NAVIGATE_TO_SITE_SETTINGS}</li>
                    <li>{notificationStrings.SELECT_ALLOW_NOTIFICATIONS}</li>
                    <li>{notificationStrings.RELOAD_THE_PAGE}</li>
                </ul>
            </div>
        </CustomModal>
    );
}
