import { getURL, checkNotNull } from '../../../Helpers/common-helpers';
import { GET_BOM, GET_DOC } from '../../../constants/endpoints';
import { HttpGet } from '../../../services/api-services';

export const getBom = async (params) => {
  try {
    const results = await HttpGet(getURL(GET_BOM), params);
    return results.data.message.data;
  } catch (e) {
    throw e.message.data.error[0].description;
  }
};

export const getDoc = async (params) => {
  const results = await HttpGet(getURL(GET_DOC), params);
  if (results.data.message.data?.error?.message) {
    // eslint-disable-next-line
    throw 'No Document found for selected Asset';
  } else {
    return results.data.message.data.sapDocuments.filter((item) => checkNotNull(item.documentUrl));
  }
};

export default () => {};
