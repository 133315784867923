import { connect } from 'react-redux';

import InspectionSchedule from './inspection-schedule.component';
import {
  showLoader, hideLoader, updateSelectedPage, addBreadCrumb,
} from '../../../redux/actions/global.actions';
import {
  updateDeleteBaSch, updateBasicSchedule, updateInspectionSchedule,
  updateDailySchedule, updateBasicSchDB, updateDailySchDB,
  updateDeletedElements, updateDeleteDailyScheduleData,
} from './inspection-schedule.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
  insSchedState: state.inspectionScheduleReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  updateDeletedElements: (value) => dispatch(updateDeletedElements(value)),
  updateDeleteBaSch: (value) => dispatch(updateDeleteBaSch(value)),
  updateDeleteDailyScheduleData: (value) => dispatch(updateDeleteDailyScheduleData(value)),
  updateBasicSchedule: (value) => dispatch(updateBasicSchedule(value)),
  updateDailySchedule: (value) => dispatch(updateDailySchedule(value)),
  updateInspectionSchedule: (value) => dispatch(updateInspectionSchedule(value)),
  updateBasicSchDB: (value) => dispatch(updateBasicSchDB(value)),
  updateDailySchDB: (value) => dispatch(updateDailySchDB(value)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InspectionSchedule);
