import { adminSetupTypes } from '../../../redux/actions/action-types';

export const updateWCAssignmentsList = (value) => ({
  type: adminSetupTypes.UPDATE_WC_ASSIGNMENTS_LIST,
  payload: value,
});

export const addWCAssignment = (value) => ({
  type: adminSetupTypes.ADD_WC_ASSIGNMENT,
  payload: value,
});

export const editWCAssignment = (value) => ({
  type: adminSetupTypes.EDIT_WC_ASSIGNMENT,
  payload: value,
});

export const addWCAssignments = (value) => ({
  type: adminSetupTypes.ADD_WC_ASSIGNMENTS,
  payload: value,
});

export const copyWCAssignment = (value) => ({
  type: adminSetupTypes.COPY_ASSIGNMENT,
  payload: value,
});

export const addWorkCenters = (value) => ({
  type: adminSetupTypes.ADD_WORK_CENTERS,
  payload: value,
});

export const deleteWCAssignment = (index) => ({
  type: adminSetupTypes.DELETE_WC_ASSIGNMENT,
  payload: index,
});

export const updateWCAssignment = (value) => ({
  type: adminSetupTypes.UPDATE_WC_ASSIGNMENT,
  payload: value,
});

export const resetWCAssignment = (value) => ({
  type: adminSetupTypes.RESET_WC_ASSIGNMENT,
  payload: value,
});
