import React from 'react';

export default function IconDoc(props) {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 12 16"
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      <path
        d="M12 4.5V14a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h5.5L12 4.5zm-3 0A1.5 1.5 0 017.5 3V1H2a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V4.5H9z"
        fill="#192D38"
      />
    </svg>
  );
}
