export default function mountAttachmentList(list, mail, orderId) {
  return list.map((item) => {
    let name;
    let type;
    let url;

    const username = mail.substring(0, mail.indexOf('@'));
    if (item.docFile) {
      if (!item.docFile.includes('einspections')) {
        url = item.docFile;
      } else if (item.docFile.includes('einspections') && !orderId) {
        url = item.docFile;
      } else {
        url = `${item.docFile}${username}/${orderId.replace('0000', '')}`;
      }
    }

    if (item?.url) {
      name = item.url;
      type = 'doc';
      url = item.url;
    } else {
      name = url;
      type = 'url';
      // url = url;
    }

    return {
      name,
      type,
      url,
    };
  });
}
