import axios from 'axios';
import config from '../utilities/config';
import { apiConstants } from '../constants/strings';

const api = axios.create({
  timeout: 200000,
  headers: {
    // [apiConstants.CLIENT_ID]: process.env.REACT_APP_CLIENT_ID,
    // [apiConstants.CLIENT_SECRET]: process.env.REACT_APP_CLIENT_SECRET,
    [apiConstants.CLIENT_ID]: config.CLIENT_ID,
    [apiConstants.CLIENT_SECRET]: config.CLIENT_SECRET,
  },
});

export const HttpGet = async (endPoint, queryParams = {}, headers = {}) => {
  try {
    if (typeof endPoint === 'string') {
      const result = await api.get(endPoint, {
        params: queryParams,
        headers,
      });
      return result;
    }
    throw new Error('Incorrect request');
  } catch (error) {
    console.log(error.response);
    throw (error ? error?.response?.data : new Error('Something went wrong'));
  }
};

export const HttpPost = async (endPoint, body = {}, headers = {}) => {
  try {
    if (typeof endPoint === 'string') {
      const result = await api.post(endPoint, body, {
        headers,
      });
      return result;
    }
    throw new Error('Incorrect Request');
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const HttpPut = async (endPoint, body = {}, headers = {}) => {
  try {
    if (typeof endPoint === 'string') {
      const result = await api.put(endPoint, body, {
        headers,
      });
      return result;
    }
    throw new Error('Incorrect request');
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const HttpDelete = async (endPoint, body = {}, headers = {}) => {
  try {
    if (typeof endPoint === 'string') {
      const result = await api.delete(endPoint, {
        params: { ...body },
        headers,
      });
      return result;
    }
    throw new Error('Incorrect request');
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};
