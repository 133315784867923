import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './constants/global-styles.scss';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import pca from './Helpers/auth-config';
import './datadog-rum';

// eslint-disable-next-line
import bootstrap from 'bootstrap';

ReactDOM.render(
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

