import React, { useState } from 'react';
// import { appColors } from '../../../constants/colors';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
// import './custom-dropdown.styles.css';
import styles from './custom-dropdown.module.scss';
import imgDownArrow from '../../../assets/images/new-dropdown-arrow.png';
import imgUpArrow from '../../../assets/images/new-dropdown-up-arrow.png';
import { checkArrayNotNull, checkNotNull } from '../../../Helpers/common-helpers';
import CustomTextInput from '../custom-textinput';
// import { checkNotNull } from '../../../Helpers/common-helpers';
import { customDropdownStrings } from '../../../constants/strings';
// import { propTypes } from 'react-bootstrap/esm/Image';

export default function CustomDropdown({
  data, editable, showSelect, style, updateDropDownItem, required = false, childName = 'name', childValue = 'value',
}) {
  const [smartTypingValue, setSmartTypingValue] = useState('');
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const toggleDropdown = () => {
    setDropdownStatus(!dropdownStatus);
  };
  function generateDropdownItems() {
    return data.items.filter(
      (item) => item[childName]?.includes(smartTypingValue.toUpperCase()),
    ).map((item) => {
      const onItemPress = () => {
        const keyName = data.name;
        const obj = {
          key: keyName,
          value: item[childValue],
          // name: item.name,
          name: checkNotNull(item[childName]) ? item[childName] : item.label,
        };
        // console.log(obj);
        setDropdownStatus(false);
        updateDropDownItem(obj);
      };
      let styleObj = {};
      if (
        data.selectedObject
        && data.selectedObject.value === item[childValue]
      ) {
        styleObj = {
          backgroundColor: '#FFF5CC',
          fontFamily: 'OpenSans-SemiBold',
        };
      }
      return (
        <div
          className={`${styles.listItem}`}
          onClick={onItemPress}
          role="presentation"
          style={{ ...styleObj }}
        >
          {checkNotNull(item[childName]) ? item[childName] : item[childValue]}
        </div>
      );
    });
  }

  const onSelectPress = () => {
    const keyName = data.name;
    const obj = {
      key: keyName,
      value: null,
      name: null,
    };
    setDropdownStatus(false);
    updateDropDownItem(obj);
  };

  const onValueChange = (newObj) => {
    setSmartTypingValue(newObj.value);
  };
  const renderView = () => {
    if (data?.selectedObject) {
      if (checkNotNull(data?.selectedObject?.name)) {
        return (data?.selectedObject?.name);
      }
      return data?.selectedObject?.label;
    }
    return '';
  };

  return (
    <OutsideClickHandler onOutsideClick={() => { setDropdownStatus(false); }}>
      <div className={`${styles.dropDownContainer}`}>
        {data.name && (
          <p className={`${styles.labelText} p-0 m-0`}>
            {data.name}
            {editable && required && <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>}
          </p>
        )}
        {editable && checkArrayNotNull(data.items) ? (
          <div
            className={`${styles.dropdownView} justify-content-between non-selectable ${dropdownStatus ? styles.dropdownPickerViewWidth : styles.dropdownViewWidth}`}
            style={{ position: dropdownStatus ? 'absolute' : 'relative', ...style, boxShadow: `inset 0px 0px 5px ${dropdownStatus ? '#0578BE' : 'rgba(0, 0, 0, 0.25)'}` }}
            role="presentation"
          >
            <div className="d-flex justify-content-between" onClick={toggleDropdown} role="presentation">
              <p className={`${styles.ddvalueText} m-0`}>
                {/* {data.selectedObject ? data.selectedObject.name : ''} */}
                {renderView()}
              </p>
              <div className="flex-col pr-2">
                <img src={dropdownStatus ? imgUpArrow : imgDownArrow} alt="dropdown-arrow" height={7} width={11} style={{ marginBottom: 7 }} />
              </div>
            </div>
            {dropdownStatus && (
              <>
                <div className="dropdown-divider ml-2 mr-2" />
                {
                  data.items.length > 10
                  && (
                    <div className="ml-2 mr-2 mb-1">
                      <CustomTextInput
                        value={smartTypingValue}
                        editable
                        onValueChange={onValueChange}
                        autoCapitalize="characters"
                        rows={1}
                        placeholder={`Search ${data.name} ...`}
                      />
                    </div>
                  )
                }
                <div className={`${styles.dropdownList}`}>
                  {
                    showSelect
                    && (
                      <div onClick={onSelectPress} role="presentation">
                        <p className={`${styles.listItem} ${styles.italicClearText}`}>{customDropdownStrings.CLEAR_SELECTION}</p>
                      </div>
                    )
                  }
                  {generateDropdownItems()}
                </div>
              </>
            )}

          </div>
        ) : (
          <p className={`${styles.nameText}`} style={{ marginTop: -6 }}>{data.value}</p>
        )}

      </div>
    </OutsideClickHandler>
  );
}

CustomDropdown.propTypes = {
  data: PropTypes.shape({
    isPickerOpen: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    selectedObject: PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    value: PropTypes.string,
  }),
  editable: PropTypes.bool,
  required: PropTypes.bool,
  showSelect: PropTypes.bool,
  style: PropTypes.shape({}),
  updateDropDownItem: PropTypes.func, // updatePickerOpenStatus: PropTypes.func,
  childName: PropTypes.string,
  childValue: PropTypes.string,
};

CustomDropdown.defaultProps = {
  data: {},
  editable: false,
  showSelect: false,
  required: false,
  style: {},
  childName: 'name',
  childValue: 'value',
  updateDropDownItem: () => { },
  // updatePickerOpenStatus: () => { },
  // withSmartTyping: false,
};
