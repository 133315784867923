import { appColors } from '../../../constants/colors';
import { appFontFamilies, appFontSizes } from '../../../constants/styles';

const styles = {
  lineItemTileContainer: {
    padding: 5,
    borderBottomWidth: 1,
    borderColor: appColors.BORDER_COLOR,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  imgIcon: {
    resizeMode: 'contain',
    width: 19,
    height: 19,
    marginLeft: 10,
    marginRight: 5,
  },
  imgClear: {
    height: 19,
    width: 19,
  },
  searchView: {
    padding: 10,
    backgroundColor: appColors.SEARCH_BAR_BG,
    borderRadius: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    display: 'flex',
  },
  titleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    marginLeft: 0,
  },
  imgDelete: {
    width: 22,
    height: 26,
  },
  index: {
    flexDirection: 'row',
    display: 'flex',
    marginHorizontal: 10,
    alignItems: 'center',
    width: '90%',
    paddingLeft: 0,
  },
  poView: {
    marginLeft: 10,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    marginRight: 0,
    paddingRight: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: 1,
  },
  indexText: {
    color: appColors.TEXT_DARK_GRAYISH_BLUE,
    fontSize: appFontSizes.SIDE_HEADING,
    fontFamily: appFontFamilies.BARLOW_BOLD,
    margin: 0,
    backgroundColor: '#F1F5F8',
    borderWidth: 1,
    borderColor: '#CFD7DC',
    padding: 5,
    paddingLeft: 7,
    paddingRight: 7,
    borderStyle: 'solid',
    borderRadius: 3,
  },
  poText: {
    color: appColors.TEXT_DARK_GRAYISH_BLUE,
    fontSize: appFontSizes.SIDE_HEADING,
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
  },
  data: {
    flex: 1,
    flexWrap: 'wrap',
  },
  textInput: {
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    color: appColors.TEXT_DARK_GRAYISH_BLUE,
    paddingVertical: 0,
    fontSize: 14,
    border: 'none',
    backgroundColor: appColors.SEARCH_BAR_BG,
    width: '100%',
  },
  quantityText: {
    fontSize: appFontSizes.SIDE_HEADING,
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    color: appColors.TEXT_DARK_GRAYISH_BLUE,
    padding: 10,
    borderRadius: 5,
    border: 'none',
    width: '100%',
  },
  memoText: {
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    color: appColors.TEXT_DARK_GRAYISH_BLUE,
    marginRight: 0,
    // maxWidth:  (2 * (DEVICE_WIDTH / 3)) - 130 ,
    fontSize: appFontSizes.SIDE_HEADING,
    marginLeft: 5,
    paddingLeft: 10,
    paddingTop: 10,
  },
  valueText: {
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    color: appColors.TEXT_DARK_GRAYISH_BLUE,
    padding: 10,
    paddingRight: 0,
    fontSize: appFontSizes.SIDE_HEADING,
  },
  delete: {
    padding: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
  firstRow: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  secondRow: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  detailsContainer: {
    padding: 5,
    // width:DEVICE_WIDTH / 3 + 20 ,
    // marginRight: 15,
  },
  memoView: {
    padding: 5,
    // width:(2 * (DEVICE_WIDTH / 3)) - 60,
  },
  quantityContainer: {
    padding: 5,
  },
  UOMContainer: {
    flex: 1,
    padding: 5,
  },
  BINContainer: {
    flex: 1.4,
    padding: 5,
  },
  unResQtyContainer: {
    flex: 2,
    padding: 5,
  },
  priceContainer: {
    flex: 1.5,
    padding: 5,
  },
  commentsContainer: {
    padding: 5,
    flex: 6.5,
  },
  labelText: {
    color: appColors.TEXT_DESATURATED_BLUE,
    fontSize: appFontSizes.TEXT,
    fontFamily: appFontFamilies.OPENSANS_SEMI_BOLD,
    marginBottom: 0,
  },
  moreButton: {
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  moreButtonText: {
    color: appColors.TEXT_STRONG_BLUE,
    fontSize: appFontSizes.SIDE_HEADING,
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    margin: 0,
  },
  memoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
    width: '90%',
  },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  goButton: {
    height: 45,
    width: 55,
    alignItems: 'end',
    justifyContent: 'center',
    display: 'flex',
  },
  clearButton: {
    height: 20,
    width: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  barcodeSmallButton: {
    height: 45,
    width: 55,
    alignItems: 'end',
    justifyContent: 'center',
    marginHorizontal: 2,
    display: 'flex',
    cursor: 'pointer',
  },
  optionalText: {
    color: appColors.TEXT_BLACK,
    fontSize: 12,
    fontFamily: appFontFamilies.OPENSANS_ITALIC,
    margin: 0,
  },
};
export default styles;
