import { createWorkOrderStrings } from '../../../constants/strings';

const staticFUItemsData = {
  [createWorkOrderStrings.ORDER_TYPE]: [
    {
      name: 'ZM07 - CORRECTIVE ORDER',
      value: 'ZM07',
      items: {
        // {
        //     name: "152 - CONSUMABLES",
        //     value: "152",
        // },
        ZM01: [{
          name: '100 - BREAKDOWN MAINT FOLLOW UP',
          value: '100',
        }],
        ZM02: [
          {
            name: '128 - PREDICTIVE FOLLOW UP',
            value: '128',
          },
        ],
        ZM03: [
          {
            name: '129 - PREVENTIVE FOLLOW UP',
            value: '129',
          },
        ],
        ZM04: [
          {
            name: '113 - MAINT INSPEC FOLLOW UP',
            value: '113',
          },
        ],
        ZM05: [
          {
            name: '121 - FOLLOW UP FROM OPS PAPER INSP FOR MAINT ACTION',
            value: '121',
          },
        ],
        ZM06: [
          {
            name: '116 - FOLLOW UP FROM CALIBRATION',
            value: '116',
          },
        ],
        ZM07: [
          {
            name: '106 - IMPROMPTU FROM MAINT',
            value: '106',
          },
          {
            name: '107 - PMT IMPROMPTU FROM OPS',
            value: '107',
          },
        ],
        ZM08: [
          {
            name: '149 - IMPROVEMENT FOLLOW UP',
            value: '149',
          },
        ],
        ZM09: [
          {
            name: '150 - REFURBISHMENT FOLLOW UP',
            value: '150',
          },
        ],
        ZM11: [
          {
            name: '116 - FOLLOW UP FROM CALIBRATION',
            value: '116',
          },
        ],
      },
    },
    {
      name: 'ZM08 - IMPROVEMENT ORDER',
      value: 'ZM08',
      items: [
        {
          name: '151 - ENERGY IMPROVEMENT',
          value: '151',
        },
        {
          name: '102 - ENGINEERING IMPROVEMENT',
          value: '102',
        },
        {
          name: '103 - ENVIRONMENTAL IMPROVEMENT',
          value: '103',
        },
        {
          name: '114 - MAINTENANCE IMPROVEMENT',
          value: '114',
        },
        {
          name: '115 - MANAGEMENT OF PROCESS RISK IMPROVEMENT',
          value: '115',
        },
        {
          name: '123 - OPERATIONAL IMPROVEMENT',
          value: '123',
        },
        {
          name: '140 - SAFETY IMPROVEMENT',
          value: '140',
        },
      ],
    },
    {
      name: 'ZM09 - REFURBISHMENT ORDER',
      value: 'ZM09',
      items: [
        // {
        //     name: "152 - CONSUMABLES - LOW COST/BUILD MATERIAL REQ'TS",
        //     value: "152",
        // },
        {
          name: '136 - REPAIR BY EXTERNAL RESOURCES',
          value: '136',
        },
        {
          name: '137 - REPAIR BY INTERNAL RESOURCES (GERDAU)',
          value: '137',
        },
        {
          name: '138 - REWORK BY EXTERNAL RESOURCES',
          value: '138',
        },
        {
          name: '139 - REWORK BY INTERNAL RESOURCES (GERDAU)',
          value: '139',
        },
      ],
    },
  ],
  [createWorkOrderStrings.FUNC_LOC_LEV_2]: [
    {
      name: 'SY1 - SCRAP YARD',
      value: 'SY1 - SCRAP YARD',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'MS1 - MELT SHOP',
      value: 'MS1 - MELT SHOP',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'MM1 - MEDIUM SECTION MILL',
      value: 'MM1 - MEDIUM SECTION MILL',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'QA1 - QUALITY CONTROL',
      value: 'QA1 - QUALITY CONTROL',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'LG1 - LOGISTICS',
      value: 'LG1 - LOGISTICS',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'GP1 - GENERAL PLANT',
      value: 'GP1 - GENERAL PLANT',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
  ],
};

export default staticFUItemsData;
