import { singleConfirmationTypes } from '../../../redux/actions/action-types';

const initialState = {
  order: {},
  onlyFinal: false,
  personnel: [],
};

const singleConfirmationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case singleConfirmationTypes.UPDATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case singleConfirmationTypes.RESET_ORDER:
      return {
        initialState,
      };
    case singleConfirmationTypes.UPDATE_PERSONNEL:
      return {
        ...state,
        personnel: action.payload,
      };
    case singleConfirmationTypes.SET_FINAL_CONFIRMATION:
      return {
        ...state,
        onlyFinal: true,
      };
    case singleConfirmationTypes.RESET_FINAL_CONFIRMATION:
      return {
        ...state,
        onlyFinal: false,
      };
    default:
      return state;
  }
};

export default singleConfirmationReducer;
