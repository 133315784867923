export const hostname = window && window.location && window.location.hostname;
const apiConfig = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  INS_SCH_BASE_URL: process.env.REACT_APP_INS_SCH_BASE_URL,
  JOB_AID_FORMS_URL: process.env.REACT_APP_JOB_AID_FORMS_URL,
  FLOC_BUCKET_NAME: process.env.REACT_APP_FLOC_BUCKET_NAME,
};
export default apiConfig;
