import { connect } from 'react-redux';

import CustomDropdown from './custom-dropdown.component';
import { showLoader, hideLoader } from '../../../redux/actions/global.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
  selectedPage: state.globalReducer.selectedPage,
  isSideNavOpen: state.globalReducer.isSideNavOpen,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomDropdown);
