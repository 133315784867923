import { connect } from 'react-redux';

import AdminSetup from './admin-setup.component';
import { showLoader, hideLoader } from '../../../redux/actions/global.actions';
import {
  addWCAssignment,
  addWCAssignments,
  updateWCAssignmentsList,
  updateWCAssignment,
  editWCAssignment,
  deleteWCAssignment,
  resetWCAssignment,
  addWorkCenters,
  copyWCAssignment,
} from './admin-setup.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
  adminSetupState: state.adminSetupReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  updateWCAssignmentsList: (value) => dispatch(updateWCAssignmentsList(value)),
  updateWCAssignment: (value) => dispatch(updateWCAssignment(value)),
  editWCAssignment: (value) => dispatch(editWCAssignment(value)),
  deleteWCAssignment: (value) => dispatch(deleteWCAssignment(value)),
  resetWCAssignment: (value) => dispatch(resetWCAssignment(value)),
  addWCAssignment: () => dispatch(addWCAssignment()),
  addWCAssignments: (value) => dispatch(addWCAssignments(value)),
  copyWCAssignment: (value) => dispatch(copyWCAssignment(value)),
  addWorkCenters: (value) => dispatch(addWorkCenters(value)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSetup);
