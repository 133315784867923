import { connect } from 'react-redux';
import WorkOrder from './work-order.component';
import {
  showLoader,
  hideLoader,
  updateSelectedPage,
  addBreadCrumb,
  resetBreadCrumbs,
  deleteBreadCrumb,
  updateBreadCrumb,
  updateParentFollowupOrder,
} from '../../../redux/actions/global.actions';
import {
  updateDropDownItem,
  updateIsPickerOpenStatus,
  updateValue,
  resetCWOState,
  updateSuccessValue,
  updateWOValue,
  updateFuncLocData,
  updateFailureValue,
  updateCheckBoxStatus,
  updateMultiSelect,
  addReserveLineItem,
  deleteReserveLineItem,
  updateReserveMaterials,
  resetLineItem,
  updateMaterial,
  updateSectionalMultiSelect,
  updateFlocValue,
  updateEquipment,

} from './work-order.actions';
import { initialiseData } from './work-order.helper';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
  createWorkOrdersState: state.createWorkOrderReducer,
  personnel: state.singleConfirmationReducer.personnel,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  hideLoader: () => dispatch(hideLoader()),
  updateDropDownItem: (data) => dispatch(updateDropDownItem(data)),
  resetBreadCrumbs: (data) => dispatch(resetBreadCrumbs(data)),
  updateBreadCrumb: (data) => dispatch(updateBreadCrumb(data)),
  updateValue: (value) => dispatch(updateValue(value)),
  updateSuccessValue: (value) => dispatch(updateSuccessValue(value)),
  updateFailureValue: (value) => dispatch(updateFailureValue(value)),
  updateWOValue: (data) => dispatch(updateWOValue(data)),
  updateFuncLocData: (data) => dispatch(updateFuncLocData(data)),
  updateMultiSelect: (data) => dispatch(updateMultiSelect(data)),
  updateParentFollowupOrder: (data) => dispatch(updateParentFollowupOrder(data)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  deleteBreadCrumb: (value) => dispatch(deleteBreadCrumb(value)),
  resetState: () => dispatch(resetCWOState()),
  updateCheckBoxStatus: (data) => dispatch(updateCheckBoxStatus(data)),
  updateIsPickerOpenStatus: (value) => dispatch(updateIsPickerOpenStatus(value)),
  initialiseData: (userData, plantID, followUpOrderDetails, funcData, isEditOrder, dataFromSpares) => (
    initialiseData(dispatch, userData, plantID, followUpOrderDetails, funcData, isEditOrder, dataFromSpares)),
  deleteReserveLineItem: (index) => dispatch(deleteReserveLineItem(index)),
  resetLineItem: (index) => dispatch(resetLineItem(index)),
  updateMaterial: (value) => dispatch(updateMaterial(value)),
  updateReserveMaterials: (data) => dispatch(updateReserveMaterials(data)),
  addReserveLineItem: () => dispatch(addReserveLineItem('I')),
  updateSectionalMultiSelect: (data) => dispatch(updateSectionalMultiSelect(data)),
  updateFlocValue: (data) => dispatch(updateFlocValue(data)),
  updateEquipment: (data) => dispatch(updateEquipment(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);
