import { globalActionTypes } from '../actions/action-types';
import { pages } from '../../constants/strings';

const initialState = {
  loaderEnabled: false,
  version: 'v.3.7.3',
  loaderMessage: '',
  userData: null,
  plantId: null,
  facilPlannerData: [],
  userPersonalData: {
    name: '',
    personalNo: '',
    sapUserID: '',
    workCenter: [null],
  },
  selectedPage: pages.HOME,
  imageURL: null,
  followUpParentOrder: null,
  dataFromSpares: null,
  breadCrumbs: [
    {
      name: 'Home',
      action: null,
    },
  ],
  activeTab: 'My Schedule',
  globalSearchOrder: null,
  lastUpdatedDate: 0,
};

const globalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case globalActionTypes.SHOW_LOADER: {
      return {
        ...state,
        loaderEnabled: true,
        loaderMessage: action.payload,
      };
    }
    case globalActionTypes.HIDE_LOADER: {
      return {
        ...state,
        loaderEnabled: false,
      };
    }
    case globalActionTypes.UPDATE_USER_DATA: {
      return { ...state, userData: action.payload };
    }
    case globalActionTypes.UPDATE_PLANT_ID:
      return { ...state, plantId: action.payload };
    case globalActionTypes.UPDATE_SELECTED_PAGE: {
      let breadCrumbs = [...state.breadCrumbs];
      if (pages.HOME === action.payload) {
        breadCrumbs = [
          {
            name: 'Home',
            action: null,
          },
        ];
      }
      return { ...state, selectedPage: action.payload, breadCrumbs };
    }
    case globalActionTypes.ADD_BREAD_CRUMB: {
      // console.log(action.payload);
      const oldData = [...state.breadCrumbs];
      oldData.push({
        name: action.payload.name,
        action: action.payload.action,
      });
      return { ...state, breadCrumbs: oldData };
    }
    case globalActionTypes.UPDATE_BREAD_CRUMB: {
      const oldBcs = [...state.breadCrumbs];
      const updateIndex = oldBcs.findIndex((item) => item.name === action.payload.name);
      oldBcs[updateIndex].action = action.payload.action;
      return { ...state, breadCrumbs: oldBcs };
    }
    case globalActionTypes.RESET_BREAD_CRUMB: {
      const initialBreadCrumb = [
        {
          name: 'Home',
          action: action.payload,
        },
      ];
      return { ...state, breadCrumbs: initialBreadCrumb };
    }
    case globalActionTypes.DELETE_BREAD_CRUMB: {
      const deletedBreadCrumbs = [...state.breadCrumbs];
      if (action.payload !== -1) {
        deletedBreadCrumbs.splice(
          action.payload + 1,
          deletedBreadCrumbs.length - action.payload - 1,
        );
      } else {
        deletedBreadCrumbs.splice(
          deletedBreadCrumbs.length - 1,
          1,
        );
      }
      return { ...state, breadCrumbs: deletedBreadCrumbs };
    }
    case globalActionTypes.UPDATE_PROFILE_PIC:
      return { ...state, imageURL: action.payload };
    case globalActionTypes.SET_PARENT_FOLLOW_UP_ORDER:
      return { ...state, followUpParentOrder: action.payload };
    case globalActionTypes.UPDATE_PERSONNAL_DATA:
      return {
        ...state,
        userPersonalData: action.payload,
      };
    case globalActionTypes.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case globalActionTypes.UPDATE_GLOBAL_SEARCH_ORDER: {
      return {
        ...state,
        globalSearchOrder: action.payload,
      };
    }
    case globalActionTypes.UPDATE_LAST_UPDATED_TIME: {
      return {
        ...state,
        lastUpdatedDate: action.payload,
      };
    }
    case globalActionTypes.UPDATE_FACIL_PLANNER_DATA: {
      return {
        ...state,
        facilPlannerData: action.payload,
      };
    }
    case globalActionTypes.DATA_FROM_SPARES:{
      return {
        ...state,
        dataFromSpares: action.payload,
      }
    }
    default:
      return state;
  }
};

export default globalReducer;
