import GraphManager from '../../../Helpers/GraphManager';
import { checkNotNull, getPlantIDByName } from '../../../Helpers/common-helpers';
import {
  updatePlantID,
  updateUserData,
  updateProfilePic,
} from '../../../redux/actions/global.actions';
import { setDataDogUser } from '../../../datadog-rum';

export const getUserData = async (token) => {
  const userData = await GraphManager.getUserAsync(token);
  return userData;
};

const getPlantID = async (officeLocation) => {
  try {
    return await getPlantIDByName(officeLocation);
  } catch (err) {
    return null;
  }
};

const getProfilePic = async (token) => {
  const blob = await GraphManager.getPhotoAsync(token);
  return window.URL.createObjectURL(blob);
};

export const initialiseData = async (dispatch, token) => {
  try {
    const userData = await getUserData(token);
    // userData.displayName = 'Ken Hicks';
    // userData.mail = 'Ken.Hicks@gerdau.com';
    // userData.givenName='Kenneth';
    // userData.surname='Hicks';
    // userData.userPrincipalName= 'ken.hicks@gerdau.com'
    // userData.officeLocation = 'US10 Cartersville Steel Mill';
    userData.mail= userData.mail.toLowerCase();
    // userData.displayName = 'Hemasundar Koneru';
    // userData.mail = 'hemasundar.konaru@gerdau.com';
    // userData.officeLocation = 'Wilton';
    // userData.displayName = 'Brenda Wallace';
    // userData.mail = 'brenda.wallace@gerdau.com';
    // userData.officeLocation = 'US12 Petersburg Steel Mill';
    dispatch(updateUserData(userData));
    setDataDogUser(userData);
    let plantId = '1327'
    if (checkNotNull(userData.officeLocation)) {
      if(userData.officeLocation === 'US11 Midlothian GMSP') {
        plantId='2860';
      } else {
        const result = await getPlantID(userData.officeLocation);
        if (checkNotNull(result) && result === '7320' ) {
          plantId = '1327';
        } else {
          plantId = result;
        }
      }
    }
    dispatch(updatePlantID(plantId));
    const imageURL = await getProfilePic(token);
    dispatch(updateProfilePic(imageURL));
  } catch (err) {
    console.log(err);
    throw err;
  }
};
