import { format } from 'date-fns';
import { HttpPost, HttpGet, HttpDelete } from '../../../services/api-services';
import {
  BASIC_SCHEDULE,
  DAILY_SCHEDULE,
  FORM_LAST_UPDATE_TIME,
} from '../../../constants/endpoints';
// import { analytics } from '../../../firebase-config';
import { weekDays, insSchStrings } from '../../../constants/strings';
import { getInspSchURL, getJobAidURL } from '../../../Helpers/common-helpers';

export const getLastUpdatedTimes = async (formIds, token) => {
  const LAST_UPDATED_URL = getInspSchURL(FORM_LAST_UPDATE_TIME);
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return HttpGet(
    LAST_UPDATED_URL,
    {
      ids: formIds,
    },
    header,
  );
};

export const getForms = async (userId, token, plantId) => {
  try {
    // userId = "ken.hicks"
    const apiURL = getJobAidURL();
    const params = {
      loginId: userId,
    };
    const searchResults = await HttpGet(apiURL, params);
    let forms = searchResults.data.Forms;
    if (
      userId.toLowerCase() !== 'brenda.wallace'
      && userId.toLowerCase() !== 'ken.hicks'
    ) {
      forms = forms.filter((item) => item.plantId === plantId);
    }
    const finalRecords = [];
    if (forms.length > 0) {
      const formIds = forms.map((item) => item.formId);
      const lastUpdatedTimes = await getLastUpdatedTimes(formIds, token);

      const records = forms.map((record) => {
        const lastUpdatedObj = lastUpdatedTimes.data.body.find(
          (item) => item.form_id === record.formId.toString(),
        );
        return {
          ...record,
          last_updated_time: lastUpdatedObj
            ? format(new Date(lastUpdatedObj.last_change_date), 'MM/dd/yyyy')
            : '-',
        };
      });
      records.forEach((record) => {
        const matchedIndex = finalRecords.findIndex(
          (item) => item.plantId === record.plantId,
        );
        if (matchedIndex === -1) {
          finalRecords.push({
            plantId: record.plantId,
            areas: [
              {
                areaName: record.area,
                isAreaOpen: false,
                forms: [record],
              },
            ],
            isPlantOpened: false,
          });
        } else {
          const matchedAreaIndex = finalRecords[matchedIndex].areas.findIndex(
            (item) => item.areaName === record.area,
          );
          if (matchedAreaIndex === -1) {
            finalRecords[matchedIndex].areas.push({
              areaName: record.area,
              isAreaOpen: false,
              forms: [record],
            });
          } else {
            finalRecords[matchedIndex].areas[matchedAreaIndex].forms.push(record);
          }
        }
      });
    }
    return finalRecords;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const prepareBasicScheduleObj = async (basicScheduleData, formId) => {
  const basicSchedule = [{}, {}, {}, {}];
  const keys = Object.keys(weekDays);
  const lastUpdateTimes = [
    ...new Set(
      basicScheduleData.map((item) => item.last_change_date),
    ),
  ].filter((item) => item !== undefined);

  let mxDate = new Date();
  mxDate = lastUpdateTimes?.reduce((a, b) => (a > b ? a : b));

  basicScheduleData.forEach((item) => {
    basicSchedule[item.week_of_month - 1].endDate = {
      value: format(new Date(item.repeat_end), insSchStrings.DATE_FORMAT),
      actualValue: format(new Date(item.repeat_end), insSchStrings.DATE_FORMAT),
    };
    basicSchedule[item.week_of_month - 1].basicSetDate = format(new Date(mxDate), insSchStrings.DATE_FORMAT);
    basicSchedule[item.week_of_month - 1].week_of_month = item.week_of_month;
    basicSchedule[item.week_of_month - 1].form_id = formId;
    basicSchedule[item.week_of_month - 1][weekDays[keys[item.day_of_week - 1]]] = {
      basic_schedule_id: item.basic_schedule_id,
      day_shift: {
        value: item.day_shift,
        actualValue: item.day_shift,
      },
      night_shift: {
        value: item.night_shift,
        actualValue: item.night_shift,
      },
      swing_shift: {
        value: item.swing_shift,
        actualValue: item.swing_shift,
      },
    };
  });
  return basicSchedule;
};

export const fetchBasicSchedule = async (formId, token) => {
  try {
    const apiURL = getInspSchURL(BASIC_SCHEDULE);
    const params = {
      formId,
      date: format(new Date(), insSchStrings.DATE_FORMAT),
    };
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const searchResults = await HttpGet(apiURL, params, header);
    if (searchResults.data.body.length > 0) {
      const basicSchedule = await prepareBasicScheduleObj(
        searchResults.data.body,
        formId,
      );
      return basicSchedule;
    }

    return [];
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchDailySchedule = async (formId, startDate, endDate, token) => {
  try {
    const apiURL = getInspSchURL(DAILY_SCHEDULE);
    const params = {
      formId,
      fromDate: format(new Date(startDate.value), insSchStrings.API_DATE_FORMAT),
      toDate: format(new Date(endDate.value), insSchStrings.API_DATE_FORMAT),
    };
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const searchResults = await HttpGet(apiURL, params, header);
    return searchResults.data.body;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteBasicScheduleRecords = async (ids, token) => {
  try {
    const apiURL = getInspSchURL(BASIC_SCHEDULE);
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const searchResults = await HttpDelete(apiURL, { ids }, header);

    return searchResults.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteDailyScheduleRecords = async (ids, token) => {
  try {
    const apiURL = getInspSchURL(DAILY_SCHEDULE);
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const searchResults = await HttpDelete(apiURL, { ids }, header);

    return searchResults.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const postBasicSchedule = async (postObj, token) => {
  try {
    const apiURL = getInspSchURL(BASIC_SCHEDULE);
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const searchResults = await HttpPost(apiURL, postObj, header);
    return searchResults.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const postDailySchedule = async (postObj, token) => {
  try {
    const apiURL = getInspSchURL(DAILY_SCHEDULE);
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const searchResults = await HttpPost(apiURL, postObj, header);
    return searchResults.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const prepareDailyScheduleObj = async (
  dailyScheduleData,
  calendarDates,
  basicSchedule,
  formId,
) => {
  try {
    const keys = Object.keys(weekDays);
    const bsDate = new Date(basicSchedule[0].basicSetDate);
    calendarDates = calendarDates.map((calDate) => {
      let day = calDate.date.getDay();

      day = day === 0 ? 7 : day;
      let obj = {
        form_id: formId,
        [weekDays[keys[[day - 1]]]]: {
          day_shift: {
            actualValue: 0,
            value: 0,
          },
          swing_shift: {
            actualValue: 0,
            value: 0,
          },
          night_shift: {
            actualValue: 0,
            value: 0,
          },
        },
      };
      if (bsDate <= calDate.date && calDate.date <= new Date(basicSchedule[0].endDate.value)) {
        const diffTime = calDate.date - bsDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffTime >= 0) {
          const basicScheduleRecord = basicSchedule[Math.floor((diffDays / 7) % 4)];
          const record = { ...basicScheduleRecord };
          const weekDetail = { ...record[weekDays[keys[[day - 1]]]] };
          obj = {
            ...obj,
            [weekDays[keys[[day - 1]]]]: {
              day_shift: {
                ...weekDetail.day_shift,
              },
              swing_shift: {
                ...weekDetail.swing_shift,
              },
              night_shift: {
                ...weekDetail.night_shift,
              },
            },
          };
        }
      }

      dailyScheduleData.forEach((record) => {
        if (
          format(new Date(record.shift_date).setDate((new Date(record.shift_date)).getDate() + 1), 'MMM, dd yyyy') === calDate.value
        ) {
          obj = {
            ...obj,
            [weekDays[keys[[day - 1]]]]: {
              day_shift: {
                actualValue: record.day_shift,
                value: record.day_shift,
              },
              swing_shift: {
                actualValue: record.swing_shift,
                value: record.swing_shift,
              },
              night_shift: {
                actualValue: record.night_shift,
                value: record.night_shift,
              },
            },
            daily_schedule_id: record.daily_schedule_id,
            form_id: record.form_id,
            shift_date: record.shift_date,
          };
        }
      });
      return {
        ...calDate,
        ...obj,
      };
    });
    return calendarDates;
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const onBasicScheduleApplyToDaily = async (
  basicSchedule,
  dailySchedule,
) => {
  try {
    const keys = Object.keys(weekDays);
    const deletedDailySch = [];
    const dailyScheduleData = dailySchedule.map((record) => {
      let day = record.date.getDay();
      day = day === 0 ? 6 : day - 1;

      const bsDate = new Date(basicSchedule[0].basicSetDate);

      const endDate = basicSchedule[0].endDate.value === null
        ? insSchStrings.MAX_DATE
        : basicSchedule[0].endDate.value;
      if (bsDate <= record.date && record.date <= new Date(basicSchedule[0].endDate.value)) {
        const todayDate = format(new Date(), insSchStrings.UI_DATE_FORMAT);
        const diffTime = record.date - new Date(basicSchedule[0].basicSetDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffTime >= 0) {
          const actualValueObj = basicSchedule[Math.floor((diffDays / 7) % 4)][weekDays[keys[day]]];
          if (
            (new Date(record.value) <= new Date(endDate)) && (new Date(record.value) >= new Date(todayDate))
          ) {
            // if (
            //   bsRecord.startDate.value !== bsRecord.startDate.actualValue ||
            //   bsRecord.endDate.value !== bsRecord.endDate.actualValue
            // ) {
            //   actualValueObj = record[weekDays[keys[day]]];
            // } else {
            // }

            if (record.daily_schedule_id) {
              deletedDailySch.push(record.daily_schedule_id);
            } else {
              record.isDailyScheduleChange = false;
            }
            record[weekDays[keys[day]]] = {
              day_shift: {
                value: actualValueObj.day_shift.value,
                actualValue: record[weekDays[keys[day]]].day_shift.actualValue,
              },
              swing_shift: {
                value: actualValueObj.swing_shift.value,
                actualValue: record[weekDays[keys[day]]].swing_shift.actualValue,
              },
              night_shift: {
                value: actualValueObj.night_shift.value,
                actualValue: record[weekDays[keys[day]]].night_shift.actualValue,
              },
            };
          } else {
            record[weekDays[keys[day]]] = {
              day_shift: {
                value: 0,
                actualValue: actualValueObj.day_shift.actualValue,
              },
              swing_shift: {
                value: 0,
                actualValue: actualValueObj.swing_shift.actualValue,
              },
              night_shift: {
                value: 0,
                actualValue: actualValueObj.night_shift.actualValue,
              },
            };
          }
        }
      }
      return record;
    });
    return {
      dailySchedule: dailyScheduleData,
      deletedDailySchIds: deletedDailySch,
    };
  } catch (e) {
    console.log(e);
  }
  return null;
};
