import PropTypes from 'prop-types';
import React from 'react';
import styles from './section-header.module.scss';
import imgUp from '../../../assets/images/section-header-up.png';
import imgDown from '../../../assets/images/section-header-down.png';

export default function SectionHeader({
  text,
  isOpen,
  onClick = null,
  subHeading = false,
  hasCollapse = false,
}) {
  return (
    <div
      className={`${styles.sectionHeader}`}
      onClick={onClick}
      onKeyDown={(e) => { if (e.keyCode === 13) onClick(); }}
      tabIndex={0}
      role="button"
      style={{ backgroundColor: subHeading ? '#83929B' : '#003366', cursor: onClick == null ? 'default' : 'pointer' }}
    >
      <p className={`${styles.sectionText}`}>{text}</p>
      {
        hasCollapse
        && (
          <img
            src={isOpen ? imgUp : imgDown}
            className={styles.imageStyle}
            alt="collapse-icon"
          />
        )
      }
    </div>
  );
}

SectionHeader.propTypes = {
  hasCollapse: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  subHeading: PropTypes.bool,
  text: PropTypes.string,
};

SectionHeader.defaultProps = {
  hasCollapse: false,
  isOpen: false,
  onClick: () => { },
  subHeading: false,
  text: '',
};
