import PropTypes from 'prop-types';
import React from 'react';

import styles from './info-pill.module.scss';

function InfoPill({ label, info }) {
  return (
    <div className={styles.pill}>
      <span className={styles.pill__label}>{label}</span>
      <span className={styles.pill__text}>{info}</span>
    </div>
  );
}

InfoPill.propTypes = {
  info: PropTypes.string,
  label: PropTypes.string,
};

InfoPill.defaultProps = {
  info: '',
  label: '',
};

export default InfoPill;
