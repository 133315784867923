import PropTypes from 'prop-types';
import React from 'react';

export default function IconPaperClip(props) {
  const { fill } = props;
  return (
    <svg
      fill="none"
      height={21}
      viewBox="0 0 19 21"
      width={19}
      xmlns="http://www.w3.org/2000/svg"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M0 0h19v21H0z" fill="#fff" />
        <path
          d="M1.834 19.119c-2.478-2.473-2.432-6.46.059-8.925l8.896-8.8a4.86 4.86 0 016.814 0 4.663 4.663 0 010 6.656l-7.755 7.664a3.24 3.24 0 01-4.58-.041c-1.198-1.23-1.16-3.178.062-4.387l6.097-6.022a.695.695 0 01.96-.01l.969.918a.641.641 0 01.01.928L7.27 13.12c-.209.207-.222.551-.027.75.185.19.477.194.665.008l7.755-7.664a2.098 2.098 0 000-2.986 2.082 2.082 0 00-2.933 0l-8.897 8.8c-1.474 1.459-1.497 3.82-.05 5.263a3.703 3.703 0 005.219.012l7.297-7.219a.695.695 0 01.96-.01l.97.917a.641.641 0 01.01.928l-7.296 7.219c-2.527 2.499-6.614 2.47-9.109-.02z"
          fill={fill || '#0578BE'}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path d="M0 0h19v21H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconPaperClip.propTypes = {
  fill: PropTypes.string,
};

IconPaperClip.defaultProps = {
  fill: '',
};
