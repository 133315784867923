import { format } from 'date-fns';
import _ from 'lodash';
import { inspSchedulingTypes } from '../../../redux/actions/action-types';
import { weekDays } from '../../../constants/strings';

function generateCalendar() {
  const currentDate = new Date();
  let currentDay = currentDate.getDay();
  if (currentDay === 0) currentDay = 7;
  const datesArray = [];
  for (let i = currentDay - 1; i >= 1; i--) {
    const calDate = new Date();
    calDate.setDate(new Date(currentDate.getDate() - i));
    datesArray.push({
      isPast: true,
      date: calDate,
      value: format(calDate, 'MMM, dd yyyy'),
    });
  }
  datesArray.push({
    isPast: false,
    date: currentDate,
    value: format(currentDate, 'MMM, dd yyyy'),
  });
  const addDays = 182 + 7 - currentDay;
  for (let i = 1; i <= addDays; i++) {
    const calDate = new Date();
    calDate.setDate(new Date(currentDate.getDate() + i));
    datesArray.push({
      isPast: false,
      date: calDate,
      value: format(calDate, 'MMM, dd yyyy'),
    });
  }
  return datesArray;
}

const initialState = {
  inspectionList: [],
  basicSchedule: [],
  basicScheduleToPost: [],
  deletedIds: [],
  dailySchedule: [],
  calendarDates: generateCalendar(),
  dailyScheduleToPost: [],
  dailyScheduleDB: [],
  basicScheduleDB: [],
  deletedElements: [],
  deletedDailyIds: [],
};

const prepareBasicSchedulePostObj = (basicSchedule) => {
  const postObj = [];
  const keys = Object.keys(weekDays);
  basicSchedule.map((record) => {
    if ((record.endDate.value !== record.endDate.actualValue) || record.isAdded) {
      const endDate = record.endDate.value === null ? '12/31/9999' : record.endDate.value;
      postObj.push(...keys.map((key, index) => ({
        repeat_end: format(new Date(endDate), 'yyyy-MM-dd'),
        basic_schedule_id: record[weekDays[key]].basic_schedule_id,
        day_of_week: index + 1,
        day_shift: Number(record[weekDays[key]].day_shift.value),
        swing_shift: Number(record[weekDays[key]].swing_shift.value),
        night_shift: Number(record[weekDays[key]].night_shift.value),
        form_id: record.form_id,
        week_of_month: record.week_of_month,
      })));
    } else {
      postObj.push(...keys.map((key, index) => {
        const recordObj = record[weekDays[key]];
        if ((recordObj.day_shift.value !== recordObj.day_shift.actualValue)
          || (recordObj.swing_shift.value !== recordObj.swing_shift.actualValue)
          || (recordObj.night_shift.value !== recordObj.night_shift.actualValue)) {
          return {
            repeat_end: format(new Date(record.endDate.value), 'yyyy-MM-dd'),
            basic_schedule_id: record[weekDays[key]].basic_schedule_id,
            day_of_week: index + 1,
            day_shift: Number(record[weekDays[key]].day_shift.value),
            swing_shift: Number(record[weekDays[key]].swing_shift.value),
            night_shift: Number(record[weekDays[key]].night_shift.value),
            form_id: record.form_id,
            week_of_month: record.week_of_month,
          };
        }
        return undefined;
      }).filter((item) => item !== undefined));
    }
    return null;
  });
  return postObj.filter((item) => item !== undefined);
};

const prepareDailySchedulePostObj = (dailySchedule) => {
  try {
    const keys = Object.keys(weekDays);
    return dailySchedule.map((record) => {
      const day = new Date(record.value).getDay();
      const weekDay = weekDays[keys[day % 7 === 0 ? 6 : ((day % 7) - 1)]];
      // if ((record[weekDay].day_shift.value !== record[weekDay].day_shift.actualValue) ||
      //     (record[weekDay].swing_shift.value !== record[weekDay].swing_shift.actualValue) ||
      //     (record[weekDay].night_shift.value !== record[weekDay].night_shift.actualValue))
      if (record.isDailyScheduleChange) {
        return {
          daily_schedule_id: record.daily_schedule_id,
          form_id: record.form_id,
          shift_date: format(new Date(record.value), 'yyyy-MM-dd'),
          day_shift: record[weekDay].day_shift.value,
          swing_shift: record[weekDay].swing_shift.value,
          night_shift: record[weekDay].night_shift.value,
        };
      }
      return undefined;
    }).filter((item) => item !== undefined);
  } catch (e) {
    console.log(e);
  }
  return [];
};

const inspSchedulingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case inspSchedulingTypes.UPDATE_INSPECTION_LIST:
      return {
        ...state,
        inspectionList: action.payload,
      };
    case inspSchedulingTypes.UPDATE_BASIC_SCHEDULE: {
      const basicSchedulePostObj = prepareBasicSchedulePostObj(action.payload);
      return {
        ...state,
        basicSchedule: _.cloneDeep(action.payload),
        basicScheduleToPost: basicSchedulePostObj,
      };
    }
    case inspSchedulingTypes.UPDATE_DAILY_SCHEDULE: {
      const dailySchedulePostObj = prepareDailySchedulePostObj(action.payload);
      return {
        ...state,
        dailySchedule: action.payload,
        dailyScheduleToPost: dailySchedulePostObj,
      };
    }
    case inspSchedulingTypes.DELETE_BASIC_SCHEDULE:
      return {
        ...state,
        deletedIds: action.payload,
      };
    case inspSchedulingTypes.UPDATED_DELETED_DAILY_SCH:
      return {
        ...state,
        deletedDailyIds: action.payload,
      };
    case inspSchedulingTypes.UPDATE_BASIC_SCH_DB:
      return {
        ...state,
        dailyScheduleDB: action.payload,
      };
    case inspSchedulingTypes.UPDATE_DAILY_SCH_DB:
      return {
        ...state,
        basicScheduleDB: action.payload,
      };
    case inspSchedulingTypes.UPDATED_DELETED_ELEMENTS:
      return {
        ...state,
        deletedElements: action.payload,
      };
    default: return state;
  }
};

export default inspSchedulingReducer;
