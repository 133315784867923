import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import InfoPill from '../info-pill';
import styles from './confirmation-content.componet.module.scss';
import { confirmationContentStrings } from '../../../constants/strings';

function ConfirmationContent({ data }) {
  const {
    title, operation, createdDate, finishDate, longText,
  } = data;
  const popover = (
    <Popover id="popover-basic" arrowProps={{ style: { backgroundColor: '#FFF5CC' } }}>
      <Popover.Body style={{
        backgroundColor: '#FFF5CC',
        color: '#53626A',
        fontFamily: 'OpenSans-SemiBold',
        fontSize: 14,
        borderRadius: 3,
      }}
      >
        {longText}
      </Popover.Body>
    </Popover>
  );
  return (
    <div className="row gx-0 operation-content">
      <div className="row gx-0">
        <div className="col-auto">
          <h5 className={`${styles.operationContent__title}`}>{title}</h5>
        </div>
      </div>
      <div className="row gx-0">
        <div className="col-auto d-flex">
          <InfoPill label={confirmationContentStrings.LONG_TEXT} info={longText.substring(0, 40) || '-'} />
          {
            longText.length > 40
            && (
              <div className="ml-2">
                <OverlayTrigger placement="bottom-start" overlay={popover} arrowProps={{ style: { backgroundColor: '#FFF5CC' } }}>
                  <div className={`${styles.ellipseDiv}`}>
                    <div className={`${styles.ellipseView}`}>...</div>
                  </div>
                </OverlayTrigger>
              </div>
            )
          }
        </div>
      </div>
      <div className="operation-content__row">
        <InfoPill info={operation} label={confirmationContentStrings.OPERATION} />
      </div>
      <div className="operation-content__row">
        <InfoPill
          customStyle={{ flexGrow: 0, marginRight: 10 }}
          info={createdDate}
          label={confirmationContentStrings.START_DATE_AND_TIME}
        />
        <InfoPill
          customStyle={{ flexGrow: 1 }}
          info={finishDate}
          label={confirmationContentStrings.END_DATE_AND_TIME}
        />
      </div>
    </div>
  );
}

ConfirmationContent.propTypes = {
  data: PropTypes.shape({
    createdDate: PropTypes.string,
    finishDate: PropTypes.string,
    longText: PropTypes.string,
    operation: PropTypes.string,
    title: PropTypes.string,
  }),
};

ConfirmationContent.defaultProps = {
  data: PropTypes.shape({
    createdDate: '',
    finishDate: '',
    operation: '',
    title: '',
  }),
};

export default ConfirmationContent;
