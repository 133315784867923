import { connect } from 'react-redux';

import WorkConfirmation from './work-confirm.component';
import {
  showLoader, hideLoader, addBreadCrumb, deleteBreadCrumb,
} from '../../../redux/actions/global.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  deleteBreadCrumb: (value) => dispatch(deleteBreadCrumb(value)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkConfirmation);
