import { createWorkOrderStrings } from '../../../constants/strings';
import { eStoresTypes } from '../../../redux/actions/action-types';
import { checkNotNull } from '../../../Helpers/common-helpers';

const initialState = {
  orderData: null,
  attOrderData: null,
  reserveMaterials: [],
  canBeSaved: false,
  wbsData: null,
  wbsSelectedValue: '',
};

const initialReserveMaterial = {
  [createWorkOrderStrings.MATERIAL]: '',
  [createWorkOrderStrings.MATERIAL_MEMO]: '',
  [createWorkOrderStrings.QTY_RQD]: '',
  [createWorkOrderStrings.UNLOAD_POINT]: '',
  [createWorkOrderStrings.REQ_DATE]: {
    value: null,
  },
  [createWorkOrderStrings.RECIPIENT]: '',
  PO_TEXT: 'NO MATERIAL SELECTED',
  isMaterialFetched: false,
  isMaterialFetchFail: false,
};
export const checkReserveMaterials = (reserveMaterials, orderData) => {
  let canSave = reserveMaterials.length > 0 && checkNotNull(orderData);
  for (let index = 0; index < reserveMaterials.length; index++) {
    const item = reserveMaterials[index];
    if (item.isMaterialFetched && !item.isMaterialFetchFail) {
      if (
        item[createWorkOrderStrings.QTY_RQD] === ''
        || item[createWorkOrderStrings.UNLOAD_POINT] === ''
        || item[createWorkOrderStrings.RECIPIENT] === ''
      ) {
        canSave = false;
        break;
      }
    } else {
      canSave = false;
      break;
    }
  }
  return canSave;
};

const eStoresReducer = (state = initialState, action = {}) => {
  let unsavedState = {};
  switch (action.type) {
    case eStoresTypes.UPDATE_MATERIAL: {
      const updateMaterialReserveLineItems = [...state.reserveMaterials];
      let updatedItem = {
        ...updateMaterialReserveLineItems[action.payload.index],
      };
      updatedItem = {
        ...state.reserveMaterials[action.payload.index],
        ...action.payload,
      };
      updateMaterialReserveLineItems[action.payload.index] = updatedItem;
      unsavedState = {
        ...state,
        reserveMaterials: updateMaterialReserveLineItems,
      };
      return {
        ...unsavedState,
        canBeSaved: checkReserveMaterials(
          unsavedState.reserveMaterials,
          state.orderData,
        ),
      };
    }
    case eStoresTypes.RESET_RESERVE_LINE_ITEM: {
      const resetReserveLineItems = [...state.reserveMaterials];
      resetReserveLineItems[action.payload] = initialReserveMaterial;
      unsavedState = {
        ...state,
        reserveMaterials: resetReserveLineItems,
      };
      return {
        ...unsavedState,
        canBeSaved: checkReserveMaterials(
          unsavedState.reserveMaterials,
          state.orderData,
        ),
      };
    }
    case eStoresTypes.DELETE_RESERVE_LINE_ITEM: {
      const deleteReserveLineItems = [...state.reserveMaterials];
      deleteReserveLineItems.splice(action.payload, 1);
      unsavedState = {
        ...state,
        reserveMaterials: deleteReserveLineItems,
      };
      return {
        ...unsavedState,
        canBeSaved: checkReserveMaterials(
          unsavedState.reserveMaterials,
          state.orderData,
        ),
      };
    }
    case eStoresTypes.ADD_RESERVE_LINE_ITEM: {
      const addReserveLineItems = [...state.reserveMaterials];
      addReserveLineItems.push({
        ...initialReserveMaterial,
        isMaterial: action.payload,
      });
      unsavedState = {
        ...state,
        reserveMaterials: addReserveLineItems,
      };
      return {
        ...unsavedState,
        canBeSaved: checkReserveMaterials(
          unsavedState.reserveMaterials,
          state.orderData,
        ),
      };
    }
    case eStoresTypes.UPDATE_RESERVE_MATERIALS: {
      const updatedReserveLineItems = [...state.reserveMaterials];
      if (action.payload.key === createWorkOrderStrings.MATERIAL) {
        updatedReserveLineItems[action.payload.index] = {
          ...updatedReserveLineItems[action.payload.index],
          ...initialReserveMaterial,
        };
      }
      updatedReserveLineItems[action.payload.index][action.payload.key] = action.payload.value;
      unsavedState = {
        ...state,
        reserveMaterials: updatedReserveLineItems,
      };
      return {
        ...unsavedState,
        canBeSaved: checkReserveMaterials(
          unsavedState.reserveMaterials,
          state.orderData,
        ),
      };
    }
    case eStoresTypes.E_STORES_UPDATE_ORDER:
      return {
        ...state,
        orderData: action.payload,
        canBeSaved: checkReserveMaterials(
          state.reserveMaterials,
          action.payload,
        ),
      };
    case eStoresTypes.E_STORES_UPDATE_ATT_ORDER:
      return {
        ...state,
        attOrderData: action.payload,
      };
    case eStoresTypes.RESET_E_STORES_STATE:
      return {
        ...initialState,
      };
    case eStoresTypes.GET_WBS_DATA: {
      return {
        ...state,
        wbsData: action.payload,
      };
    }
    case eStoresTypes.WBS_VALUE: {
      return {
        ...state,
        wbsSelectedValue: action.payload,
      };
    }
    default:
      return state;
  }
};

export default eStoresReducer;
