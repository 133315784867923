import PropTypes from 'prop-types';
import React from 'react';
// import './critical-inspection.styles.css';
import IframeResizer from 'iframe-resizer-react';
import config from '../../../utilities/config';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import { pages } from '../../../constants/strings';
import { criticalInspectionUrls } from '../../../constants/enums';
import styles from './critical-inspection.module.scss';

export default function CriticalInspection({ addBreadCrumb, global }) {
  React.useEffect(() => {
    addBreadCrumb({
      name: pages.CRITICAL_INSPECTION,
      action: null,
    });
    // eslint-disable-next-line
  }, []);
  // let url = `https://codepen.io/JGallardo/pen/lJoyk`
  const displayName = global.userData.mail.substring(
    0,
    global.userData.mail.indexOf('@'),
  );
  let url = criticalInspectionUrls.dev(displayName);
  if (config.ENVIRONMENT === 'QA') {
    url = criticalInspectionUrls.qa(displayName);
  } else {
    url = criticalInspectionUrls.prod(displayName);
  }
  // let url = `https://einspections.gerdaugln.tech/JobAid/external-login/jobaid/MID/ken.hicks`
  return (
    <ContentWithBreadcrumb title={pages.CRITICAL_INSPECTION}>
      <div
        className={`${styles.eIContainer} d-flex justify-content-center align-items-center`}
      >
        <IframeResizer
          heightCalculationMethod="taggedElement"
          src={url}
          style={{ width: '1px', minWidth: '100%', height: '86vh' }}
          scrolling
        />
      </div>
    </ContentWithBreadcrumb>
  );
}

CriticalInspection.propTypes = {
  addBreadCrumb: PropTypes.func,
  global: PropTypes.shape({
    userData: PropTypes.shape({
      mail: PropTypes.string,
    }),
  }),
};

CriticalInspection.defaultProps = {
  addBreadCrumb: () => { },
  global: {},
};
