import { getURL } from '../../../Helpers/common-helpers';
import { ADD_NEW_OPERATION } from '../../../constants/endpoints';
import { HttpPost } from '../../../services/api-services';

export const createOperation = async (opData, orderData, plantId) => {
  try {
    const acNo = orderData.operations[orderData.operations.length - 1].activity || 0;
    const activityNo = Number(acNo) + 10;
    const reqObj = {
      orderId: orderData.orderId,
      activity: String(activityNo).padStart(4, '0'),
      plantId,
      operation: {
        mainWorkCenter: opData.opWkCntr.toUpperCase(),
        shortText: opData.opDesc.slice(0, 40).toUpperCase(),
        duration: opData.duration,
        numberCapacities: opData.noOfCapacities,
        longText: opData.opDesc.toUpperCase(),
      },
      longText: orderData.longText.toUpperCase(),
      shortText: orderData.shortDescription.toUpperCase(),
    };
    const apiURL = getURL(ADD_NEW_OPERATION);
    await HttpPost(apiURL, reqObj);
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export default () => { };
