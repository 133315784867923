import { connect } from 'react-redux';
import CustomNavBar from './custom-nav-bar.component';
import {
  showLoader, hideLoader, updateSelectedPage, addBreadCrumb, resetBreadCrumbs, deleteBreadCrumb, updateGlobalSearchOrder,
} from '../../../redux/actions/global.actions';
import { initialiseData } from './custom-nav-bar.helper';
import { resetWorkConfirmValue } from '../../pages/work-confirm/work-confirm.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
  selectedPage: state.globalReducer.selectedPage,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  hideLoader: () => dispatch(hideLoader()),
  initialiseData: (token) => initialiseData(dispatch, token),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  resetBreadCrumbs: (value) => dispatch(resetBreadCrumbs(value)),
  deleteBreadCrumb: (value) => dispatch(deleteBreadCrumb(value)),
  updateGlobalSearchOrder: (value) => dispatch(updateGlobalSearchOrder(value)),
  resetWorkConfirmValue: () => dispatch(resetWorkConfirmValue()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomNavBar);
