import PropTypes from 'prop-types';
import React from 'react';
// import cx from 'classnames';
import styles from './custom-textinput.module.scss';
import { appColors } from '../../../constants/colors';
import imgClearInput from '../../../assets/images/clear-input.png';
import { customDropdownStrings } from '../../../constants/strings';

export default function CustomTextInput(props) {
  const {
    label, value, onValueChange, isFailed, description, required, editable, rows, placeholder, type, autoCapitalize, step, onKeyDown, textStyle, onClearText, maxLength = 100, isFilled,disable = false,
  } = props;
  const [focusStatus, setFocusStatus] = React.useState(false);
  const onChangeText = (event) => {
    const input = event.target;
    const obj = {
      key: label,
      value: input.value,
    };
    // console.log(obj);
    onValueChange(obj);
  };

  const clearText = () => {
    onValueChange({
      key: label,
      value: '',
    });
    if (onClearText) {
      onClearText();
    }
  };

  return (
    <div>
      {
        label
        && (
        <p
          className={`${styles.labelTextStyle}`}
          style={isFailed ? { color: appColors.TEXT_ERROR_MODERATE } : {}}
        >
          {label}
          <span className={`${styles.italicText}`}>
            {' '}
            {description && description}
          </span>
          {editable && required && <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>}
        </p>
        )
      }
      {
        editable
          ? (
            <div
              className={`${autoCapitalize === 'characters' && 'capitaliseText'
              } ${styles.textInputContainer} d-flex justify-content-between align-items-center`}
              style={{
                boxShadow: focusStatus ? 'inset 0px 0px 5px #0578BE' : 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)',
                backgroundColor: isFailed ? appColors.ERROR_SEARCH_BAR_BG : isFilled ? '#E0F0D1' : disable ? '#F1F5F8':'white',
              }}
            >
              {
                rows === 1
                  ? (
                    <input
                      value={value}
                      rows={rows}
                      placeholder={placeholder}
                      type={type || 'text'}
                      step={step}
                      onFocus={() => setFocusStatus(true)}
                      onBlur={() => setFocusStatus(false)}
                      className={`${styles.textInput} ${autoCapitalize} ${styles.upperCaseTextArea}`}
                      onChange={onChangeText}
                      onKeyDown={onKeyDown}
                      maxLength={maxLength}
                      readOnly={disable}
                      style={
                        { resize: rows > 1 ? 'vertical' : 'none', padding: 2, backgroundColor: isFailed ? appColors.ERROR_SEARCH_BAR_BG : isFilled ? '#E0F0D1' : disable ? '#F1F5F8':'white' }
                      }
                    />
                  )
                  : (
                    <textarea
                      value={value}
                      rows={rows}
                      placeholder={placeholder}
                      type={type || 'text'}
                      onFocus={() => setFocusStatus(true)}
                      onBlur={() => setFocusStatus(false)}
                      className={`${styles.textInput} ${styles.upperCaseTextArea}`}
                      onChange={onChangeText}
                      style={
                        { resize: rows > 1 ? 'vertical' : 'none', backgroundColor: isFailed ? appColors.ERROR_SEARCH_BAR_BG : 'white' }
                      }
                    />
                  )
              }
              <div className="mr-2 ml-2 " style={{ cursor: 'pointer', width: 15 }} onClick={clearText} role="presentation">
                {
                  value
                  && (
                    disable ? '' : <img src={imgClearInput} alt="clear" height={13} width={13} />
                  )
                }
              </div>
            </div>
          )
          : (
            <pre className={`${styles.nameText}`} style={{ ...textStyle }}>{value || '  -'}</pre>
          )
      }
    </div>
  );
}

CustomTextInput.propTypes = {
  autoCapitalize: PropTypes.string,
  description: PropTypes.string,
  editable: PropTypes.bool,
  isFailed: PropTypes.bool,
  label: PropTypes.string,
  onKeyDown: PropTypes.func,
  onValueChange: PropTypes.func,
  onClearText: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  step: PropTypes.string,
  textStyle: PropTypes.shape({}),
  type: PropTypes.string,
  value: PropTypes.string,
};

CustomTextInput.defaultProps = {
  autoCapitalize: '',
  description: '',
  editable: false,
  isFailed: false,
  label: '',
  onKeyDown: () => { },
  onValueChange: () => { },
  placeholder: '',
  rows: 0,
  step: '',
  textStyle: {},
  type: '',
  value: '',
  required: false,
  onClearText: () => { },
};
