import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal, useAccount } from '@azure/msal-react';
import { useQueryClient } from 'react-query';
import styles from './custom-nav-bar.module.scss';
import imgGerdauNavLogo from '../../../assets/images/gerdau-nav-logo.png';
import imgDDArrow from '../../../assets/images/dropdown-arrow-yellow.png';
import imgRedirect from '../../../assets/images/redirect-icon.png';
import imgProfileEmpty from '../../../assets/images/profile-empty@2x.png';
import UserFeedBack from '../user-feedback-modal';
import {
  pages, loaderMessages, navBarStrings, mainPortalStrings, generalStrings, queryKeyStrings,
} from '../../../constants/strings';
import hydrostatusUrl from '../../../constants/externalRefs';
import 'bootstrap/dist/js/bootstrap.bundle';
import { loginSilentRequest } from '../../../Helpers/auth-config';
import { setActiveTab, updatePlantID, updateUserData } from '../../../redux/actions/global.actions';
import imgSearchGray from '../../../assets/images/search-gray.png';
import imgSearchBlue from '../../../assets/images/search-icon@2x.png';
import { getWorkOrderResults } from '../../pages/work-confirm/work-confirm.helper';
import ButtonModal from '../button-modal';
import apiConfig from '../../../utilities/config';
import CustomModal from '../custom-modal';
import CustomButton from '../custom-button';
import CustomDropdown from '../custom-dropdown';
import { plantIdDetails } from '../../../constants/enums';
import CustomTextInput from '../custom-textinput/custom-textinput.component';
import blueClose from '../../../assets/images/blue-close.svg';
import imgEmptyBucket from '../../../assets/images/img-empty-bucket.png';
import notificationIcon from '../../../assets/images/notification-icon.svg';
import { HttpGet, HttpPost } from '../../../services/api-services';
import { getInspSchURL } from '../../../Helpers/common-helpers';
import { askForPushNotificationPermission } from '../../../Helpers/push-notifications';
import CustomMultiSelect from '../custom-multi-select';
import { plantIdMultiSelectData } from '../../../constants/enums';
import { getWCAsssignments } from '../../pages/admin-setup/admin-setup.helper';
// import { sendNotification } from '../../../serviceWorker';


function WorkOrderSearch({ onSearch }) {
  const [workOrder, setWorkOrder] = React.useState('');
  const updateWorkOrder = (e) => setWorkOrder(e.target.value);
  const onSearchClick = () => onSearch(workOrder);
  const onKeyDown = (e) => {
    if (workOrder.length >= 8 && e.keyCode === 13) {
      onSearchClick();
    }
  };


  return (
    <div className={`${styles.searchWorkOrder}`}>
      <div>
        <input
          type="number"
          placeholder={navBarStrings.WO_PLACEHOLDER}
          className={`${styles.search_order}`}
          onChange={updateWorkOrder}
          value={workOrder}
          onKeyDown={onKeyDown}
        />
      </div>
      <div
        className={`${
          workOrder.length >= 8 ? styles.enableWoButton : styles.disableWoButton
          } d-flex justify-content-center align-items-center`}
        onClick={onSearchClick}
        onKeyDown={(e) => { if (e.keyCode === 13 && workOrder.length >= 8) onSearchClick(); }}
        tabIndex={0}
        role="button"
      >
        <img
          src={workOrder.length >= 8 ? imgSearchBlue : imgSearchGray}
          height={12}
          width={12}
          alt="search"
        />
      </div>
    </div>
  );
}

WorkOrderSearch.propTypes = {
  onSearch: PropTypes.func,
};

WorkOrderSearch.defaultProps = {
  onSearch: () => { },
};

export default function CustomNavBar({
  deleteBreadCrumb, global, hideLoader, initialiseData, resetBreadCrumbs, addBreadCrumb, resetWorkConfirmValue, selectedPage, showLoader, updateSelectedPage, updateGlobalSearchOrder,
}) {
  const [feedBackStatus, setFeedbackStatus] = React.useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const queryClient = useQueryClient();
  const [showOrderNotFoundModal, setShowOrderNotFoundModal] = React.useState(false);
  const [openImpersonateModal, setOpenImpersonateModal] = React.useState(false);
  const [userValue, setUserValue] = React.useState('');
  const [plantIdValue, setPlantIdValue] = React.useState('');
  const [broadCastMessage, setBroadCastMessage] = React.useState('');
  const [title, setTitle] = React.useState('');
  const dispatch = useDispatch();
  const [notificationsData, setNotificationsData] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [notificationModal, setNotificationModal] = React.useState(false);
  const [plantIdData, setPlantIdData] = React.useState(plantIdMultiSelectData);
  const [multiSelectPlantValue, setMultiSelectPlantValue] = React.useState('');
  const [notificationSenderOrNot, setNotificationSendorOrNot] = React.useState(false);

  const updateMultiSelect = (val) => {
    setMultiSelectPlantValue(val?.value);
    setPlantIdData(val.items);
  };
  React.useEffect(() => {
    let notificationSender = async () => {
      if (global.userData?.mail?.includes('ken.hicks') || global.userData?.mail?.includes('sainag.chunduru') || global.userData?.mail?.includes('brenda.wallace')) {
        setNotificationSendorOrNot(true);
      }
      else {
        let data = await getWcData();
        let facilatorData = data.find((item) => item.email_id.toLowerCase() == global.userData.mail.toLowerCase() && item.plant_id == global.plantId);
        if (facilatorData?.person_role == navBarStrings.FACILITATOR || facilatorData?.person_role == navBarStrings.PLANNER) {
          setNotificationSendorOrNot(true);
        }
        else {
          setNotificationSendorOrNot(false);
        }
      }
    }
    if (global.userData) {
      notificationSender();
    }
  }, [global.userData, global.plantId]);
  
  const getWcData = async () => {
    let wcData = await getWCAsssignments();
    return wcData;
  }

  React.useEffect(() => {
    if (global.plantId != null) {
      askForPushNotificationPermission(global.plantId, account.username);
    }
  }, [global.plantId])

  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('notifications_db', 1);

      request.onerror = (event) => {
        reject('Error opening database');
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const objectStore = db.createObjectStore('notifications', { keyPath: 'id' });
        objectStore.createIndex('title', 'title', { unique: false });
        objectStore.createIndex('body', 'body', { unique: false });
        objectStore.createIndex('timestamp', 'timestamp', { unique: false });
      };
    });
  };
  React.useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const db = await openDatabase();
        const transaction = db.transaction('notifications', 'readonly');
        const store = transaction.objectStore('notifications');
        const notificationId = 'notifications';
        const getRequest = store.get(notificationId);
        getRequest.onsuccess = () => {
          const notificationData = getRequest.result;
          if (notificationData) {
            setNotificationsData(notificationData.notifications.reverse());
          }
        };
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [isOpen]);

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  const onPersonateClick = () => {
    setOpenImpersonateModal(true);
  };
  const userDetails = useSelector((state) => state.globalReducer.userData);
  const changeUserDetails = () => {
    const plantId = plantIdValue.value;
    if (plantId === '1323') {
      userDetails.mail = 'ken.hicks@gerdau.com';
    }
    if (plantId === '1327') {
      userDetails.mail = 'brenda.wallace@gerdau.com';
    }
    userDetails.displayName = userValue.name;
    userDetails.officeLocation = plantIdValue.name;
    dispatch(updateUserData(userDetails));
    dispatch(updatePlantID(plantId));
    setOpenImpersonateModal(false);
    return null;
  };
  const userData = (user) => {
    setUserValue(user);
    if (user.name === 'Brenda Wallace') {
      setPlantIdValue({
        name: '1327 - Midlothian',
        value: '1327',
      });
    } else {
      setPlantIdValue({
        name: '1323 - Cartersville',
        value: '1323',
      });
    }
  };
  const onSearch = async (workOrderValue) => {
    try {
      showLoader(loaderMessages.FINDING_ORDER);
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SEARCH_ORDER} - ${workOrderValue}`,
        queryFn: async () => {
          const response = await getWorkOrderResults(global.plantId, workOrderValue);
          return response;
        },
        select: (results) => results,
        retry: false,
        staleTime: 1000,
      });
      updateGlobalSearchOrder(data);
      resetBreadCrumbs(() => {
        updateSelectedPage(pages.HOME);
      });
      addBreadCrumb({
        name: pages.CHANGE_WORK_ORDER,
        action: null,
      });
      addBreadCrumb({
        name: navBarStrings.SELECT_OPERATION,
        action: () => {
          updateSelectedPage(pages.CHANGE_WORK_ORDER);
        },
      });
      updateSelectedPage(pages.CHANGE_WORK_ORDER);
      // }
      hideLoader();
    } catch (e) {
      setShowOrderNotFoundModal(true);
      console.log(new Error(e));
      hideLoader();
    }
  };
  const sendNotification = async () => {
    setNotificationModal(false);
    showLoader(navBarStrings.SENDING_BROADCAST_MESSAGE)
    try {
      let pushNotificationUrl = getInspSchURL("/push-subscription");
      let sendNotificationUrl = getInspSchURL("/send-notification");
      const response = await HttpGet(pushNotificationUrl);
      if (response.data.body) {
        const selectedPlantIdData = plantIdData.map(item => item.selected && item.value).filter(Boolean);
        const resData = selectedPlantIdData.length > 0 ? response.data.body.filter(item => {
          const subscriptionObj = JSON.parse(item.subscription_object);
          return !subscriptionObj.endpoint.includes('expired') && selectedPlantIdData.includes(subscriptionObj.plantId) && apiConfig.ENVIRONMENT == subscriptionObj.environment;
        }) : response.data.body.filter(item => {
          const subscriptionObj = JSON.parse(item.subscription_object);
          return !subscriptionObj.endpoint.includes('expired') && global.plantId == subscriptionObj.plantId && apiConfig.ENVIRONMENT == subscriptionObj.environment;
        });
        console.log(resData);
        let payload = {
          response: resData,
          title: title,
          broadCastMessage: broadCastMessage
        }
        let postResponse = await HttpPost(sendNotificationUrl, payload);
        if (!postResponse.data.statusCode == 200) {
          throw new Error("Failed to send subscription to server.");
        }
        else {
          console.log("Subscription sent to server successfully.");
          setBroadCastMessage('');
          setTitle('');
          setMultiSelectPlantValue('');
          setPlantIdData(plantIdMultiSelectData);
          hideLoader();
        }
      }
    }
    catch (e) {
      console.log(e);
      hideLoader();
    }
  }
  React.useEffect(() => {
    const initialise = async () => {
      try {
        showLoader(navBarStrings.INITIALISING_DATA);
        let response;
        try {
          if (account) {
            response = await instance.acquireTokenSilent({
              ...loginSilentRequest,
              account,
            });
          }
        } catch (e) {
          console.log(e);
          sessionStorage.clear();
          window.location.reload();
        }
        await initialiseData(response.accessToken);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
      }
    };
    initialise();
    // eslint-disable-next-line
  }, []);
  const closeModal = () => {
    setFeedbackStatus(false);
  };
  const onFeedbackClick = () => {
    setFeedbackStatus(true);
  };
  const onNavItemClick = (target) => {
    if (target !== selectedPage) {
      resetBreadCrumbs(() => {
        updateSelectedPage(pages.HOME);
      });
      updateSelectedPage(target);
    }
  };
  // const displayNotifications = () => {
  //   return notificationsData.map((notification) => (
  //     <p key={notification.id}>{notification.title}</p>
  //   ));
  // };
  const displayNot = () => {
    setIsOpen(!isOpen);
  }

  const generateBreadCrumbs = () => {
    const { length } = global.breadCrumbs;
    if (length > 1) {
      return (
        <div className={`${styles.breadcrumb__container}`}>
          <p>
            {global.breadCrumbs.map((item, index) => {
              const onBreadCrumbClick = () => {
                if (index === 0) {
                  updateSelectedPage(pages.HOME);
                } else {
                  deleteBreadCrumb(index);
                  item.action();
                }
              };
              return (
                <span key={item.name}>
                  {index !== length - 1 && item.action !== null ? (
                    <button
                      className={`${styles.breadcrumb__link} ${styles.remove_button_styling}`}
                      onClick={onBreadCrumbClick}
                      type="button"
                    >
                      {item.name}
                    </button>
                  ) : (
                      <span className={`${styles.breadcrumb__last}`}>{item.name}</span>
                    )}
                  {index !== length - 1 && (
                    <span style={{ paddingRight: 5, color: '#A2ADB4' }}>/</span>
                  )}
                </span>
              );
            })}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="fixed-top">
      <nav
        className={`navbar navbar-expand-lg navbar-light ${styles.navbar_bg}`}
      >
        {/* <div className="container-fluid p-0"> */}
        <img src={imgGerdauNavLogo} alt="gerdau-logo" height={30} width={30} />
        <button
          className={`${styles.appName} ${styles.homeLink} ${styles.remove_button_styling}`}
          type="button"
          onClick={() => onNavItemClick(pages.HOME)}
        >
          {mainPortalStrings.HEADER_TEXT}
        </button>
        <p
          className={`${styles.appName} d-none d-lg-block d-xl-block`}
          style={{ fontSize: 16, marginRight: 20 }}
        >
          |
        </p>
        <button
          className={`navbar-toggler ${styles.toggler_icon} ${styles.remove_button_styling}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <button
                className={`nav-link ${styles.remove_button_styling}`}
                style={{ padding: 0, display: "inline-block" }}
                id="navbarDropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className={`${styles.pages_dd}`}>{selectedPage}</span>
                <img src={imgDDArrow} alt="dd_arrow" height={6} width={10} />
              </button>
              <div
                className={`${styles.menuWidth} dropdown-menu`}
                style={{ maxHeight: 500 }}
                id="dd"
                aria-labelledby="navbarDropdown"
              >
                <span className={`${styles.dd_sub_heading}`}>
                  {mainPortalStrings.MAIN_FEATURES}
                </span>
                <div className="dropdown-divider" />
                <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => {
                    setActiveTab("My Schedule");
                    onNavItemClick(pages.HOME);
                  }}
                  type="button"
                >
                  {pages.WEEK_SCHEDULE}
                </button>
                <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => onNavItemClick(pages.E_STORES)}
                  type="button"
                >
                  {pages.E_STORES}
                </button>
                <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => onNavItemClick(pages.WORK_ORDER)}
                  type="button"
                >
                  {pages.NEW_WO}
                </button>
                {(global.plantId == "1330" ||
                  global.userData?.mail?.includes("sainag.chunduru") ||
                  global.userData?.mail?.includes("brenda.wallace") ||
                  global.userData?.mail?.includes("ken.hicks")) && (
                    <button
                      type="button"
                      className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                      onClick={() => onNavItemClick(pages.SPARES_MANAGEMENT)}
                    >
                      {pages.SPARES_MANAGEMENT}
                    </button>
                  )}
                {/* <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => onNavItemClick(pages.SPARES_MANAGEMENT_EXTERNAL_REPAIRS)}
                  type="button"
                >
                  {pages.SPARES_MANAGEMENT_EXTERNAL_REPAIRS}
                </button> */}
                <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => {
                    resetWorkConfirmValue();
                    onNavItemClick(pages.CONFIRM);
                  }}
                  type="button"
                >
                  {pages.CONFIRM}
                </button>
                <button
                  type="button"
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => onNavItemClick(pages.EINSPECTION)}
                >
                  {pages.EINSPECTION}
                </button>
                {notificationSenderOrNot && (
                  <button
                    className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                    onClick={() => onNavItemClick(pages.INSPECTION_SCHEDULE)}
                    type="button"
                  >
                    {pages.INSPECTION_SCHEDULE}
                  </button>
                )}
                <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => window.open(hydrostatusUrl(), "_blank")}
                  type="button"
                >
                  {pages.HYDROSTATS}
                  <img
                    src={imgRedirect}
                    alt="redirect_icon"
                    height={10}
                    width={10}
                    style={{ marginLeft: 10 }}
                  />
                </button>
                <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => onNavItemClick(pages.ASSET_VIEW)}
                  type="button"
                >
                  {pages.ASSET_VIEW}
                </button>
                <button
                  className={`dropdown-item ${styles.dd_page} ${styles.remove_button_styling}`}
                  onClick={() => onNavItemClick(pages.CRITICAL_INSPECTION)}
                  type="button"
                >
                  {pages.CRITICAL_INSPECTION}
                </button>

                <div className="dropdown-divider" />
                <span className={`${styles.dd_sub_heading}`}>
                  {pages.EXTERNAL_RESOURCES}
                </span>
                <div className="dropdown-divider" />
                <a
                  className={`dropdown-item ${styles.dd_page}`}
                  href="https://appv.gerdau.net/vaees/vaeesCatOnPremise/rootUI/requisitioner.html?requisitioner=GERDAU&u=VAEESREQ&p=vaeES2014&vsCatalogWindow=_top"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pages.PRODUCT_CATALOG}
                  {pages.EXTRA_SPACE}
                  <img
                    src={imgRedirect}
                    alt="redirect_icon"
                    height={10}
                    width={10}
                    style={{marginLeft: 5}}
                  />
                </a>
                <a
                  className={`dropdown-item ${styles.dd_page}`}
                  href="https://mysemeq13.semeq.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pages.MYSEMEQ}
                  {pages.EXTRA_SPACE}
                  <img
                    src={imgRedirect}
                    alt="redirect_icon"
                    height={10}
                    width={10}
                    style={{ marginLeft: 5 }}
                  />
                </a>
                {/* <a
                  className="dropdown-item dd_page"
                  href="https://hcm19.sapsf.com/sf/home?bplte_company=gerdauacos#Shell-home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pages.GERDAU_PEOPLE_PORTAL}
                  {pages.EXTRA_SPACE}
                  <img
                    src={imgRedirect}
                    alt="redirect_icon"
                    height={10}
                    width={10}
                    style={{ marginLeft: 5 }}></img>
                </a> */}
                <a
                  className={`dropdown-item ${styles.dd_page}`}
                  href="https://kronosna.gerdau.com/wfc/logon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pages.KRONOS}
                  {pages.EXTRA_SPACE}
                  <img
                    src={imgRedirect}
                    alt="redirect_icon"
                    height={10}
                    width={10}
                    style={{ marginLeft: 5 }}
                  />
                </a>
                <a
                  className={`dropdown-item ${styles.dd_page}`}
                  href="https://clients.intelex.com/Login3/Gerdau/Application/Home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pages.INTELEX}
                  {pages.EXTRA_SPACE}
                  <img
                    src={imgRedirect}
                    alt="redirect_icon"
                    height={10}
                    width={10}
                    style={{ marginLeft: 5 }}
                  />
                </a>
                <a
                  className={`dropdown-item ${styles.dd_page}`}
                  href="https://apexmi.gerdau.com/QmosApexMvc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pages.QUAD_APEX}
                  {pages.EXTRA_SPACE}
                  <img
                    src={imgRedirect}
                    alt="redirect_icon"
                    height={10}
                    width={10}
                    style={{ marginLeft: 5 }}
                  />
                </a>
              </div>
            </li>
          </ul>
          {notificationSenderOrNot && (
            <div
              onClick={() => {
                setNotificationModal(true)
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) setNotificationModal(true);
              }}
              tabIndex={0}
              role="button"
            >
              <p className={`${styles.rightMenu} mr-3 ${styles.feedback}`}>
                {navBarStrings.SEND_NOTIFICATIONS}
              </p>
            </div>
          )}
          <WorkOrderSearch onSearch={onSearch} />
          <div
            onClick={displayNot}
            onKeyDown={e => {
              if (e.keyCode === 13) displayNot();
            }}
            tabIndex={0}
            role="button"
          >
            <p className={`${styles.rightMenu} mr-3 ${styles.feedback}`}>
              <img src={notificationIcon} alt="notification-icon" width='16px' height='16px' />
            </p>
          </div>
          {isOpen && (
            <div className={styles.filterListDiv}>
              <div className={styles.notificationHeader}>
                {navBarStrings.NOTIFICATIONS}
              </div>
              <div style={{ height: '82%', overflow: 'scroll' }}>
                {notificationsData.length ? notificationsData.map(notification => (
                  <>
                    <div className={`p-2 m-2`}>
                      <div
                        className="d-flex justify-content-between mb-2"
                        style={{ margin: "0px" }}
                      >
                        <b
                          key={notification.id}
                          className={styles.notificationTitle}
                        >
                          {notification.title}
                        </b>
                        <div className={styles.notificationTime}>
                          {new Date(notification.timestamp).toLocaleString()}
                        </div>
                      </div>
                      <div className={styles.notificationBody}>{notification.body}</div>
                    </div>
                    <div className={`${styles.dropdownDivider} ml-2 mr-2 mt-0`} />
                  </>
                )) : (
                    <div
                      className='d-flex flex-column justify-content-center align-items-center gap-3 h-100'
                    >
                      <div>
                        <img src={imgEmptyBucket} alt="V2" width="48px" height="32px" />
                      </div>
                      <div className={`${styles.noNotificationText}`}>
                        You Have No Notifications
                    </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {notificationModal && (
            <CustomModal
              customWidth="320px"
              actions={{
                cancel: () => {
                  setNotificationModal(false);
                  setBroadCastMessage("");
                  setMultiSelectPlantValue("");
                  setTitle("");
                }
              }}
              header={{
                title: navBarStrings.BROADCAST_NOTIFICATION,
                color: "#192D38",
                bgColor: "#F2F8FC",
                exit: true,
                height: "33px"
              }}
              icon={blueClose}
              buttons={
                <div className="d-flex flex-row justify-content-between m-3">
                  <CustomButton
                    value={navBarStrings.CANCEL}
                    onPress={() => {
                      setNotificationModal(false);
                      setBroadCastMessage("");
                      setTitle("");
                    }}
                  />
                  <CustomButton
                    value={navBarStrings.SEND_MESSAGE}
                    onPress={sendNotification}
                  />
                </div>
              }
            >
              <div className="m-3">
                <div className='pt-2'>
                  <CustomTextInput
                    label={navBarStrings.TITLE}
                    value={title}
                    editable
                    onValueChange={value => setTitle(value.value)}
                    rows={1}
                  />
                </div>
                <div className='pt-2'>
                  <CustomTextInput
                    label={navBarStrings.BROADCAST_MESSAGE}
                    value={broadCastMessage}
                    editable
                    onValueChange={value => setBroadCastMessage(value.value)}
                    rows={3}
                  />
                </div>
                <div style={{ height: '60px' }} className='pt-2'>
                  <CustomMultiSelect
                    data={{
                      name: navBarStrings.SELECT_PLANT_ID,
                      items: plantIdData,
                      value: global.userData.mail === 'sainag.chunduru@gerdau.com' || global.userData.mail === 'ken.hicks@gerdau.com' || global.userData.mail === 'brenda.wallace@gerdau.com' ? multiSelectPlantValue : global.plantId,
                      selectedObject: '',
                    }}
                    editable={global.userData.mail === 'sainag.chunduru@gerdau.com' || global.userData.mail === 'ken.hicks@gerdau.com' || global.userData.mail === 'brenda.wallace@gerdau.com'}
                    isEditOrder={false}
                    updateMultiSelect={updateMultiSelect}
                    style={{ zIndex: 800 }}
                    selectAllData={global.userData.mail === 'sainag.chunduru@gerdau.com' || global.userData.mail === 'ken.hicks@gerdau.com' || global.userData.mail === 'brenda.wallace@gerdau.com'}
                  />
                </div>
              </div>
            </CustomModal>
          )}
          <div
            onClick={onFeedbackClick}
            onKeyDown={e => {
              if (e.keyCode === 13) onFeedbackClick();
            }}
            tabIndex={0}
            role="button"
          >
            <p className={`${styles.rightMenu} mr-3 ${styles.feedback}`}>
              {pages.FEEDBACK}
            </p>
          </div>
          {apiConfig.ENVIRONMENT === "QA" && (
            <div
              onClick={onPersonateClick}
              onKeyDown={e => {
                if (e.keyCode === 13) onPersonateClick();
              }}
              tabIndex={0}
              role="button"
            >
              <p className={`${styles.rightMenu} mr-3 ${styles.feedback}`}>
                {pages.IMPERSONATE}
              </p>
            </div>
          )}
          {openImpersonateModal && (
            <CustomModal
              customWidth="350px"
              customHeight="300px"
              actions={{ cancel: () => setOpenImpersonateModal(false) }}
              header={{
                title: navBarStrings.IMPERSONATE_USER_DETAILS,
                bgColor: "#0578BE",
                exit: true
              }}
              buttons={
                <div className="modal__footer submitModal__footer">
                  <CustomButton
                    customStyle={{ width: "100%", marginTop: 10 }}
                    onPress={changeUserDetails}
                    value={navBarStrings.IMPERSONATE_DETAILS}
                  />
                </div>
              }
            >
              <div className="d-flex flex-column align-items-center gap-4 h-50 justify-content-center">
                <div className="col-lg-8 pb-2 h-25" style={{ zIndex: 1000 }}>
                  <CustomDropdown
                    data={{
                      name: navBarStrings.SELECT_USER,
                      value: userValue.name,
                      selectedObject: userValue,
                      items: [
                        {
                          name: "Brenda Wallace",
                          value: "BWALLACE"
                        },
                        {
                          name: "Ken Hicks",
                          value: "KHICKS"
                        }
                      ]
                    }}
                    updateDropDownItem={data => userData(data)}
                    editable
                  />
                </div>
                <div className="col-lg-8 pb-2 h-25" style={{ zIndex: 980 }}>
                  <CustomDropdown
                    data={{
                      name: navBarStrings.SELECT_PLANT_ID,
                      value: plantIdValue.value,
                      selectedObject: plantIdValue,
                      items: plantIdDetails
                    }}
                    editable
                    updateDropDownItem={data => setPlantIdValue(data)}
                  />
                </div>
              </div>
            </CustomModal>
          )}
          <p className={`${styles.rightMenu} mr-4`}>{global.version}</p>
          <button
            className={`nav-link  ${styles.remove_button_styling}`}
            id="navbarDropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            type="button"
          >
            <img
              className="rounded-circle"
              alt="profile-pic"
              src={global.imageURL || imgProfileEmpty}
              height={30}
              width={30}
            />
          </button>
          <div
            className="dropdown-menu "
            style={{ left: "auto", right: 20 }}
            aria-labelledby="navbarDropdownMenuLink"
          >
            <p className={`${styles.dd_sub_heading}`}>
              {pages.EXTRA_SPACE}
              {global.userData && (global.userData.displayName || " ")}
            </p>
            <p
              className={`${styles.dd_sub_heading}`}
              style={{ color: "#53626A" }}
            >
              {pages.EXTRA_SPACE}
              {global.userData &&
                (global.userData.officeLocation || generalStrings.US10_TAMPA)}
              {pages.EXTRA_SPACE}-{pages.EXTRA_SPACE}
              {global.plantId}
            </p>
            <div className="dropdown-divider" />
            <button
              className={`dropdown-item  ${styles.dd_page} pt-1 pb-1 ${styles.remove_button_styling}`}
              onClick={onLogout}
              type="button"
            >
              {generalStrings.LOGOUT}
            </button>
          </div>
        </div>
        {/* </div> */}
      </nav>
      {/* <div style={{}}>
        Welcome {global.userData?.displayName} <span>Not you? Click here to logout.</span>
      </div> */}
      {generateBreadCrumbs()}
      {feedBackStatus && <UserFeedBack closeModal={closeModal} />}
      {showOrderNotFoundModal && (
        <ButtonModal
          noOfButtons={1}
          isError
          data={{
            instructionsText1: mainPortalStrings.WO_NOT_EXIST,
            buttonText: generalStrings.OK
          }}
          onButtonPress={() => setShowOrderNotFoundModal(false)}
        />
      )}
    </div>
  );
}

CustomNavBar.propTypes = {
  addBreadCrumb: PropTypes.func,
  deleteBreadCrumb: PropTypes.func,
  global: PropTypes.shape({
    breadCrumbs: PropTypes.arrayOf(PropTypes.shape({})),
    imageURL: PropTypes.string,
    plantId: PropTypes.string,
    userData: PropTypes.shape({
      displayName: PropTypes.string,
      mail: PropTypes.shape({
        includes: PropTypes.func,
      }),
      officeLocation: PropTypes.string,
    }),
    version: PropTypes.string,
  }),
  hideLoader: PropTypes.func,
  initialiseData: PropTypes.func,
  resetBreadCrumbs: PropTypes.func,
  resetWorkConfirmValue: PropTypes.func,
  selectedPage: PropTypes.string,
  showLoader: PropTypes.func,
  updateGlobalSearchOrder: PropTypes.func,
  updateSelectedPage: PropTypes.func,
};

CustomNavBar.defaultProps = {
  deleteBreadCrumb: () => { },
  global: PropTypes.shape({
    breadCrumbs: [],
    imageURL: '',
    plantId: '',
    userData: PropTypes.shape({
      displayName: '',
      officeLocation: '',
    }),
    version: '',
  }),
  hideLoader: () => { },
  initialiseData: () => { },
  resetBreadCrumbs: () => { },
  resetWorkConfirmValue: () => { },
  selectedPage: '',
  showLoader: () => { },
  updateGlobalSearchOrder: () => { },
  updateSelectedPage: () => { },
  addBreadCrumb: () => { },
};
