import { appColors } from '../../../constants/colors';
import { appFontFamilies, appFontSizes } from '../../../constants/styles';

const styles = {
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: appColors.MODAL_TRANSPARENT,
    display: 'flex',
  },
  titleView: {
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
    backgroundColor: appColors.BUTTON_BACKGROUND,
    paddingVertical: 12,
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    flex: 1,
    alignItems: 'flex-end',
    marginRight: 20,
    justifyContent: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
  imgClose: {
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  modalView: {
    width: '95%',
    backgroundColor: appColors.WHITE_BG,
    elevation: 7,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderRadius: 5,
  },

  titleText: {
    fontFamily: appFontFamilies.BARLOW_BOLD,
    fontSize: 18,
    color: appColors.TEXT_WHITE,
    width: '100%',
    textAlign: 'center',
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
  },
  labelText: {
    fontFamily: appFontFamilies.OPENSANS_BOLD,
    fontSize: appFontSizes.TEXT,
    color: appColors.TEXT_BLACK,
  },
  valueText: {
    color: appColors.TEXT_DARK_GRAYISH_BLUE,
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    fontSize: appFontSizes.SIDE_HEADING,
    marginLeft: 20,
    marginVertical: 22,
  },
  detailsView: {
    marginBottom: 10,
    //  backgroundColor: "green",
    marginHorizontal: 10,
  },
  contentView: {
    padding: 10,
  },

};

export default styles;
