import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
// import './admin-setup.styles.css';
import { adminSetupHeaders, plantDetails } from '../../../constants/enums';
import CustomButton from '../../controls/custom-button';
import imgSuccess from '../../../assets/images/success.png';
import imgSaveDisabled from '../../../assets/images/save-disabled.png';
import imgError from '../../../assets/images/error-red.png';
import imgPencil from '../../../assets/images/pencil.png';
import imgPlus from '../../../assets/images/plus.png';
import imgCopyRow from '../../../assets/images/copy-row.png';
import imgTrash from '../../../assets/images/delete-red@2x.png';
import { adminSetupStrings, loaderMessages } from '../../../constants/strings';
import Dropdown from '../../controls/dropdown/dropdown.component';
import {
  getPersonsData, getWorkCenters, getWCAsssignments, putWCAssignment, prepareWCAssignmentsObject, postWCAssignment, updateBiUrl, deleteWCAssignment,
} from './admin-setup.helper';
import ToolTip from '../../controls/tooltip';
import DeleteLineModal from '../../controls/delete-line-modal';
import FormDropdown from '../../controls/form-dropdown';
import FormInput from '../../controls/form-input';
import FormLabelField from '../../controls/form-label-field/form-label-field.component';
import styles from './admin-setup.module.scss';

export default function AdminSetup({
  showLoader, addWorkCenters, addWCAssignments, hideLoader, addWCAssignment, adminSetupState, editWCAssignment, resetWCAssignment, copyWCAssignment, updateWCAssignment,
}) {
  const [persons, setPersons] = React.useState([]);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const [plantSelection, setPlantSelection] = React.useState('');
  const [quickFilter, setQuickFilter] = React.useState('');

  const onInputTextChange = (e) => setQuickFilter(e.target.value.toUpperCase());

  const [deleteId, setDeleteId] = React.useState(null);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const initialiseData = async () => {
    try {
      showLoader(loaderMessages.LOADING_WC_ASSIGNMENTS);
      const personsData = await getPersonsData();
      setPersons(personsData);
      const wcData = await getWorkCenters();
      addWorkCenters(wcData);
      const wcAssignments = await getWCAsssignments();
      addWCAssignments(prepareWCAssignmentsObject(wcAssignments, wcData));
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const tableCellView = (row, key, bgColor, updateField) => {
    const onDbUrlChange = (e) => {
      updateField({ name: e.target.value });
    };
    switch (key) {
      case adminSetupStrings.PLANT:
        return (
          <div>
            <Dropdown value={row[key].value} onItemClick={updateField} items={Object.keys(plantDetails).map((item) => ({ name: item }))} style={{ inputBgColor: bgColor }} />
          </div>
        );
      case adminSetupStrings.WC_ID:
        return (
          <div>
            <Dropdown value={row[key].value} onItemClick={updateField} items={row[key].items} style={{ inputBgColor: bgColor }} withSmartTyping={true}/>
          </div>
        );
      case adminSetupStrings.WC_DESCRIPTION:
        return (
          <div>
            <p className={`${styles.tableText} ${styles.italicDisabled}`}>{row[key]}</p>
          </div>
        );
      case adminSetupStrings.PERSONELL:
        return (
          <div>
            <Dropdown allowNewValues value={row[key].value} onItemClick={updateField} items={persons.map((item) => ({ id: item.id, name: item.email_id }))} style={{ inputBgColor: bgColor }} withSmartTyping={true}/>
          </div>
        );
      case adminSetupStrings.EINSP_DB_URL:
        return (
          <div style={{ width: '--web-kit-available' }}>
            <input value={row[key]?.value} onChange={onDbUrlChange} className={`${styles.dataDropDownInput}`} style={{ backgroundColor: bgColor }} />
          </div>
        );
      default: return null;
    }
  };

  useEffect(() => {
    initialiseData();
    // eslint-disable-next-line
  }, []);

  const saveWCAssignment = async (row) => {
    try {
      showLoader(adminSetupStrings.SAVING_DATA);
      if (row.newLine) {
        await postWCAssignment({
          person_id: row.person_id,
          work_center_id: row.work_center_id,
          email_id: row[adminSetupStrings.PERSONELL].value,
          user_id: row[adminSetupStrings.PERSONELL].value.split('@')[0],
          user_loc: row[adminSetupStrings.PLANT].value,
        });
      } else if ((row[adminSetupStrings.PERSONELL].value !== row[adminSetupStrings.PERSONELL].previousValue)
        || (row[adminSetupStrings.PLANT].value !== row[adminSetupStrings.PLANT].previousValue)
        || (row[adminSetupStrings.WC_ID].value !== row[adminSetupStrings.WC_ID].previousValue)) {
        if (row.person_id) {
          await putWCAssignment({
            id: row.id,
            person_id: row.person_id,
            work_center_id: row.work_center_id,
          });
        } else {
          await putWCAssignment({
            id: row.id,
            work_center_id: row.work_center_id,
            email_id: row[adminSetupStrings.PERSONELL].value,
            user_id: row[adminSetupStrings.PERSONELL].value.split('@')[0],
          });
        }
      }
      if (row[adminSetupStrings.EINSP_DB_URL].previousValue !== row[adminSetupStrings.EINSP_DB_URL].value) {
        await updateBiUrl({
          id: row.work_center_id,
          bi_url: row[adminSetupStrings.EINSP_DB_URL].value,
        });
      }
      await initialiseData();
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteAssignment = async (id) => {
    try {
      setDeleteId(null);
      showLoader(adminSetupStrings.DELETING_ASSIGNMENT);
      await deleteWCAssignment(id);
      await initialiseData();
      hideLoader();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="row gx-0">
      <div className="col d-flex flex-column no-gutters p-0">
        <div className="row gx-0 mx-4">
          <div className="mt-3 d-flex justify-content-between m-0">
            <div className="d-flex">
              <div>
                <FormLabelField label={adminSetupStrings.PLANT}>
                  <FormDropdown
                    items={[{ name: adminSetupStrings.NO_SELECTION }, ...Object.keys(plantDetails).map((item) => ({ name: item }))]}
                    placeholder={adminSetupStrings.FILTER_BY_PLANT}
                    onItemClick={(item) => (item.name === adminSetupStrings.NO_SELECTION ? setPlantSelection('') : setPlantSelection(item.name))}
                    value={plantSelection}
                    style={{ width: 150 }}
                  />
                </FormLabelField>
              </div>
              <div className="ml-3">
                <FormLabelField label={adminSetupStrings.QUICK_FILTER}>
                  <FormInput
                    onChange={onInputTextChange}
                    placeholder={adminSetupStrings.FILTER_BY_PARAMETERS}
                    value={quickFilter}
                    inputWidth={300}
                    textTransform="uppercase"
                  />
                </FormLabelField>
              </div>
            </div>
            <CustomButton
              value={adminSetupStrings.NEW_ENTRY}
              icon={imgPlus}
              onPress={addWCAssignment}
              outline
              customStyle={{ borderRadius: 5, alignSelf: 'flex-end', height: 'fit-content' }}
            />
          </div>
        </div>
        <div className="row gx-0 m-4">
          <table className={`${styles.workCenterTable}`} style={{ width: windowWidth }}>
            <tr>
              {
                adminSetupHeaders.map((heading) => (
                  <th className={`${!heading.customWidth && `col-${heading.flexValue}`} ${styles.tableHeader} border`} style={{ width: heading.customWidth, paddingLeft: 10 }}>
                    {heading.name}
                  </th>
                ))
              }
            </tr>
            <tbody>
              {
                adminSetupState.workCenterAssignments.filter((item) => {
                  if (plantSelection === '') {
                    return true;
                  }
                  return item.newLine || item[adminSetupStrings.PLANT].value === plantSelection;
                }).filter((item) => {
                  if (quickFilter === '') {
                    return true;
                  }
                  return `${item[adminSetupStrings.PERSONELL].value.toUpperCase()}-${item[adminSetupStrings.WC_ID].value}`.includes(quickFilter);
                }).map((row, index) => {
                  let bgColor = { backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F2F8FC' };
                  const editRow = () => {
                    editWCAssignment({
                      index: row.id,
                      value: true,
                    });
                  };
                  const deleteRow = () => {
                    if (!row.newLine) {
                      setDeleteId(row);
                    } else {
                      deleteWCAssignment(adminSetupState.workCenterAssignments.findIndex((item) => item.id === row.id));
                    }
                  };
                  const saveRow = async () => {
                    await saveWCAssignment(row);
                  };
                  const resetRow = () => {
                    resetWCAssignment(row.id);
                  };
                  const copyRow = () => { copyWCAssignment(row); };
                  return (
                    <tr style={{ ...bgColor }}>
                      {
                        adminSetupHeaders.filter((item) => item.name !== '').map((col) => {
                          if (typeof row[col.name] === 'object' && row[col.name].previousValue !== row[col.name].value) {
                            bgColor = { backgroundColor: '#FFF5CC' };
                          } else {
                            bgColor = { backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F2F8FC' };
                          }
                          const updateField = (itemData) => {
                            updateWCAssignment({
                              index: row.id,
                              key: col.name,
                              value: itemData.name,
                              id: itemData.id,
                            });
                          };
                          return (
                            <td className={`${!col.customWidth && `col-${col.flexValue} p-0`} border`} style={{ width: col.customWidth }}>
                              {
                                row.editable
                                  ? (
                                    <div className="w-100 p-0">
                                      {tableCellView(row, col.name, bgColor.backgroundColor, updateField)}
                                    </div>
                                  )
                                  : (
                                    <div>
                                      {
                                        col.name === adminSetupStrings.EINSP_DB_URL
                                          ? (
                                            <div className="">
                                              <a href={row[col.name]?.value || ''} rel="noopener noreferrer" target="_blank" className={`${styles.eInspUrlText}`}>{row[col.name]?.value}</a>
                                            </div>
                                          )
                                          : (
                                            <p className={`${styles.tableText} ${col.name === adminSetupStrings.WC_DESCRIPTION && styles.italicDisabled}`}>{typeof row[col.name] === 'object' ? row[col.name]?.value : row[col.name]}</p>
                                          )
                                      }
                                    </div>
                                  )
                              }
                            </td>
                          );
                        }).filter((item) => item !== undefined)
                      }
                      {
                        !row.editable
                          ? (
                            <>
                              <td className=" p-0 border" style={{ width: '5%' }}>
                                <ToolTip toolTipText={adminSetupStrings.EDIT}>
                                  <div className="d-flex justify-content-center" onClick={editRow} onKeyDown={(e) => { if (e.keyCode === 13) editRow(); }} role="presentation">
                                    <img className={`${styles.pointer}`} src={imgPencil} alt="pencil" height={20} width={20} />
                                  </div>
                                </ToolTip>
                              </td>
                              <td className="p-0 border" style={{ width: '5%' }}>
                                <ToolTip toolTipText={adminSetupStrings.COPY_LINE}>
                                  <div className="d-flex justify-content-center" onClick={copyRow} onKeyDown={(e) => { if (e.keyCode === 13) copyRow(); }} role="presentation">
                                    <img className={`${styles.pointer}`} src={imgCopyRow} alt="pencil" height={20} width={20} />
                                  </div>
                                </ToolTip>
                              </td>
                              <td className="p-0 border" style={{ width: '5%' }}>
                                <ToolTip toolTipText={adminSetupStrings.DELETE}>
                                  <div className="d-flex justify-content-center" onClick={deleteRow} onKeyDown={(e) => { if (e.keyCode === 13) deleteRow(); }} role="presentation">
                                    <img className={`${styles.pointer}`} src={imgTrash} alt="pencil" height={20} width={20} />
                                  </div>
                                </ToolTip>
                              </td>
                            </>
                          )
                          : (
                            <>
                              <td className="p-0 border" style={{ width: '5%' }}>
                                <ToolTip toolTipText={`${row.saveErrorMessage || adminSetupStrings.SAVE}`}>
                                  <div className="d-flex justify-content-center">
                                    {
                                      row.saveErrorMessage
                                        ? (
                                          <img className={`${styles.pointer}`} src={imgSaveDisabled} alt="pencil" height={22} width={22} />
                                        )
                                        : (
                                          <div onClick={saveRow} onKeyDown={(e) => { if (e.keyCode === 13) saveRow(); }} role="presentation">
                                            <img className={`${styles.pointer}`} src={imgSuccess} alt="pencil" height={22} width={22} />
                                          </div>
                                        )
                                    }
                                  </div>
                                </ToolTip>
                              </td>
                              <td className="p-0 border" style={{ width: '5%' }}>
                                <ToolTip toolTipText={`${row.resetErrorMessage || adminSetupStrings.REVERT_ALL_CHANGES}`}>
                                  <div className="d-flex justify-content-center" onClick={row.resetErrorMessage ? () => { } : resetRow} role="presentation">
                                    <img className={`${styles.pointer}`} src={imgError} alt="pencil" height={20} width={20} />
                                  </div>
                                </ToolTip>
                              </td>
                              <td className="p-0 border" style={{ width: '5%' }}>
                                <ToolTip toolTipText={adminSetupStrings.DELETE}>
                                  <div className="d-flex justify-content-center" onClick={deleteRow} role="presentation">
                                    <img className={`${styles.pointer}`} src={imgTrash} alt="pencil" height={20} width={20} />
                                  </div>
                                </ToolTip>
                              </td>
                            </>
                          )
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{ height: 100 }} />
      </div>
      {
        deleteId && (
          // {true && (
          <DeleteLineModal
            onCancelClick={() => setDeleteId(null)}
            onDeleteClick={() => onDeleteAssignment(deleteId.id, deleteId.work_center_id)}
          />
        )
      }
    </div>
  );
}

AdminSetup.propTypes = {
  addWCAssignment: PropTypes.func,
  addWCAssignments: PropTypes.func,
  addWorkCenters: PropTypes.func,
  adminSetupState: PropTypes.shape({
    workCenterAssignments: PropTypes.shape({
      filter: PropTypes.func,
      findIndex: PropTypes.func,
    }),
  }),
  copyWCAssignment: PropTypes.func,
  editWCAssignment: PropTypes.func,
  hideLoader: PropTypes.func,
  resetWCAssignment: PropTypes.func,
  showLoader: PropTypes.func,
  updateWCAssignment: PropTypes.func,
};

AdminSetup.defaultProps = {
  addWCAssignment: () => {},
  addWCAssignments: () => {},
  addWorkCenters: () => {},
  adminSetupState: PropTypes.shape({
    workCenterAssignments: PropTypes.shape({
      filter: () => {},
      findIndex: () => {},
    }),
  }),
  copyWCAssignment: () => {},
  editWCAssignment: () => {},
  hideLoader: () => {},
  resetWCAssignment: () => {},
  showLoader: () => {},
  updateWCAssignment: () => {},
};
