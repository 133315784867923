import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import config from '../../../utilities/config';
// import './hydrostats.css';
import { addBreadCrumb } from '../../../redux/actions/global.actions';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import { pages } from '../../../constants/strings';
import { hydrostatsUrls } from '../../../constants/enums';
import styles from './hydrostats.module.scss';

export default function Hydrostats() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      addBreadCrumb({
        name: pages.HYDROSTATS,
        action: null,
      }),
    );
    /* eslint-disable-next-line */
  }, []);
  let url = '';
  if (config.ENVIRONMENT === 'DEV') {
    url = hydrostatsUrls.DEV;
  } else if (config.ENVIRONMENT === 'QA') {
    url = hydrostatsUrls.QA;
  } else if (config.ENVIRONMENT === 'PROD') {
    url = hydrostatsUrls.PROD;
  }
  url = useMemo(() => {
    const fullPath = window?.location?.pathname ?? '';
    const urlParams = fullPath.split('/hydrostats/')?.[1] ?? '';
    return `${url}${urlParams}`;
  }, [url]);
  return (
    <div className={`${styles.hydrostatsContainer} h-100`}>
      <ContentWithBreadcrumb title={pages.HYDROSTATS} customHeight="inherit">
        <iframe
          title={pages.HYDROSTATS}
          src={url}
          style={{ minWidth: '100%', height: 'inherit' }}
        />
      </ContentWithBreadcrumb>
    </div>
  );
}
