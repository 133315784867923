import PropTypes from 'prop-types';
import React from 'react';
import styles from './add-attachment.module.scss';
import { checkArrayNotNull } from '../../../Helpers/common-helpers';
import imgAddAttachment from '../../../assets/images/add-attachment@2x.png';
import pdf from '../../../assets/images/Gerdau@2x.png';
import imgTrash from '../../../assets/images/icon_trash@2x.png';
import { generalStrings, addAttachmentStrings } from '../../../constants/strings';
// import { addAttachmentStrings } from '../../../constants/strings';

export default function AddAttachment({
  onDelete,
  onFileUpload,
  imgList,
  customStyle,
  id,
  editable = true,
}) {
  const generatePreviewImages = () => {
    if (checkArrayNotNull(imgList)) {
      return imgList.map((element, index) => (
        <div
          style={{
            width: 150,
            height: '100%',
            marginLeft: '10px',
            marginTop: '5px',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'inline-flex',
              height: '65%',
              width: 150,
              // height: 200,
              marginLeft: '10px',
              marginTop: '5px',
            }}
            id={`${index}`}
          >
            {element.name.includes('pdf') ? <img src={pdf} width="100%" alt="pdf" /> : <img src={element.dataURI} width="100%" alt="attachment" />}
            {
              !element.isUploaded
              && (
                <div
                  onClick={onDelete}
                  onKeyDown={(e) => { if (e.keyCode === 13) onDelete(); }}
                  tabIndex={0}
                  role="button"
                >
                  <img
                    alt="trash_icon"
                    id={`${index}`}
                    src={imgTrash}
                    height={22}
                    width={22}
                    style={{ position: 'relative', right: 30, top: 10 }}
                  />
                </div>
              )
            }
          </div>
          <pre
            className={`${styles.newAttachmentText} ${styles.attachmentName}`}
          >
            {element.name}
          </pre>
          {
            element.size
            && (
              <p className={`${styles.sizeText} mt-2`}>
                {element.size}
                {addAttachmentStrings.EMPTY_STRING}
                {addAttachmentStrings.MB}
              </p>
            )
          }
        </div>
      ));
    }
    return null;
  };
  const handleFocusBack = () => {
    window.removeEventListener('focus', handleFocusBack);
  };

  const onFileUploadChange = (e) => {
    onFileUpload(e);
    window.removeEventListener('focus', handleFocusBack);
  };

  const clickedFileInput = () => {
    window.addEventListener('focus', handleFocusBack);
  };

  return (
    <div
      style={{
        ...customStyle,
      }}
      className={styles.attachmentContainer}
    >
      {
        editable
        && (
          <div
            // align="center"
            className={styles.addAttachment}
          >
            <label
              htmlFor={id}
              className={`shadow d-flex align-items-center ${styles.labelStyle}`}
            >
              <input
                type="file"
                style={{ width: 0 }}
                onClick={clickedFileInput}
                onKeyDown={(e) => { if (e.keyCode === 13) clickedFileInput(); }}
                id={id}
                accept="image/*,application/pdf"
                onChange={onFileUploadChange}
              />
              <img
                src={imgAddAttachment}
                alt="add-attachment"
                className={styles.imageStyle}
                height={40}
                width={40}
              />
            </label>
            <p className={styles.newAttachmentText}>{generalStrings.NEW_ATTACHMENT}</p>
          </div>
        )
      }
      {generatePreviewImages()}
    </div>
  );
}

AddAttachment.propTypes = {
  customStyle: PropTypes.shape({}),
  editable: PropTypes.bool,
  id: PropTypes.string,
  imgList: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func,
  onFileUpload: PropTypes.func,
};

AddAttachment.defaultProps = {
  customStyle: {},
  editable: false,
  id: '',
  imgList: [],
  onDelete: () => { },
  onFileUpload: () => { },
};
