import PropTypes from 'prop-types';
import React from 'react';

export default function ArrowRight({ fill }) {
  return (
    <svg
      fill="none"
      height="11"
      viewBox="0 0 16 11"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2.85161 0.246223L7.07202 4.43401C7.31173 4.68523 7.61991 4.81095 7.99592 4.81095C8.366 4.81095 8.67749 4.68523 8.93036 4.43401L13.167 0.230076C13.386 0.0765062 13.6353 0 13.915 0C14.2779 0 14.5893 0.128687 14.8495 0.387277L15.6199 1.15151C15.8728 1.41626 15.9999 1.72233 15.9999 2.06878C15.9999 2.42161 15.8728 2.73089 15.6199 2.99613L8.93405 9.63032C8.68118 9.88154 8.36969 10.0073 7.99961 10.0073C7.6236 10.0073 7.31542 9.88154 7.07571 9.63032L0.389842 2.99613C0.130397 2.73779 0 2.42875 0 2.06878C0 1.71521 0.130397 1.40961 0.389842 1.15151L1.16031 0.387277C1.40659 0.128687 1.71477 0 2.08485 0C2.37952 0 2.63521 0.0818688 2.85161 0.246223Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

ArrowRight.propTypes = {
  fill: PropTypes.bool,
};

ArrowRight.defaultProps = {
  fill: false,
};
