import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './calendar-tile.module.scss';

import iconBlock from '../../../assets/images/icon_block.png';
import iconCopyButton from '../../../assets/images/copy-button.png';
import iconCalendarToday from '../../../assets/images/calendar-today.png';
import { calendarStrings } from '../../../constants/strings';

export default function CalendarTile({
  showBackgroundWhenContent = false, value, disabled, customStyle, shiftDetails, onChange, onResetClick, isToday = false, onCopyClick = null,
}) {
  const hasContent = Number(shiftDetails.day_shift.value) !== 0 || Number(shiftDetails.swing_shift.value) !== 0 || Number(shiftDetails.night_shift.value) !== 0;
  const calendarContainerBg = cx({
    [styles.disabledBgColor]: disabled,
    [styles.contentBgColor]: showBackgroundWhenContent && hasContent,
  });
  const onInputChange = (event) => {
    onChange(event.target.id, event.target.value);
  };
  return (
    <div className={`${styles.calendarTileContainer} border ${calendarContainerBg}`} style={{ ...customStyle }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-row">
          {
            isToday
            && (
              <div className={styles.iconDiv}>
                <img alt="calendar-today" src={iconCalendarToday} height={14} width={14} />
              </div>
            )
          }

          <p className={styles.weekText}>{value}</p>
        </div>
        {
          showBackgroundWhenContent && hasContent && !disabled
          && (
            <div role="button" tabIndex={0} className={styles.resetIconDiv} onClick={onResetClick} onKeyDown={(e) => { if (e.keyCode === 13) onResetClick(); }}>
              <img alt="imgBlock" src={iconBlock} height={14} width={14} />
            </div>
          )
        }
        {
          onCopyClick
          && (
            <div role="button" tabIndex={0} className={styles.iconDiv} onClick={onCopyClick} onKeyDown={(e) => { if (e.keyCode === 13) onCopyClick(); }}>
              <img alt="calendar-today" src={iconCopyButton} height={14} width={14} />
            </div>
          )
        }
      </div>
      <div className="d-flex mt-3">
        <div className={`${styles.shiftDiv} ${styles.shiftRightDiv}`}>
          <p className={styles.inputHeading}>{calendarStrings.DAY}</p>
          <input
            id="day_shift"
            onChange={onInputChange}
            min={0}
            className={`${styles.shiftInput} ${shiftDetails.day_shift.value.toString() !== shiftDetails.day_shift.actualValue.toString() && styles.shiftInputChanged}`}
            type="number"
            defaultValue={0}
            disabled={disabled}
            value={shiftDetails.day_shift.value}
          />
        </div>
        <div className={`${styles.shiftDiv} ${styles.shiftRightDiv}`}>
          <p className={styles.inputHeading}>{calendarStrings.SWING}</p>
          <input id="swing_shift" onChange={onInputChange} min={0} className={`${styles.shiftInput} ${shiftDetails.swing_shift.value.toString() !== shiftDetails.swing_shift.actualValue.toString() && styles.shiftInputChanged}`} type="number" defaultValue={0} disabled={disabled} value={shiftDetails.swing_shift.value} />
        </div>
        <div className={`${styles.shiftDiv}`}>
          <p className={styles.inputHeading}>{calendarStrings.NIGHT}</p>
          <input id="night_shift" onChange={onInputChange} min={0} className={`${styles.shiftInput} ${shiftDetails.night_shift.value.toString() !== shiftDetails.night_shift.actualValue.toString() && styles.shiftInputChanged}`} type="number" defaultValue={0} disabled={disabled} value={shiftDetails.night_shift.value} />
        </div>
      </div>
    </div>
  );
}

CalendarTile.propTypes = {
  customStyle: PropTypes.shape({}),
  disabled: PropTypes.bool,
  isToday: PropTypes.bool,
  onChange: PropTypes.func,
  onCopyClick: PropTypes.func,
  onResetClick: PropTypes.func,
  shiftDetails: PropTypes.shape({
    day_shift: PropTypes.shape({
      actualValue: PropTypes.string,
      value: PropTypes.string,
    }),
    night_shift: PropTypes.shape({
      actualValue: PropTypes.string,
      value: PropTypes.string,
    }),
    swing_shift: PropTypes.shape({
      actualValue: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  showBackgroundWhenContent: PropTypes.bool,
  value: PropTypes.string,
};

CalendarTile.defaultProps = {
  customStyle: null,
  disabled: false,
  isToday: false,
  onChange: () => { },
  onCopyClick: () => { },
  onResetClick: () => { },
  shiftDetails: {},
  showBackgroundWhenContent: false,
  value: '',
};
