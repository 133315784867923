import PropTypes from 'prop-types';
import React from 'react';
import { format } from 'date-fns';
import _ from 'lodash';
import { useMsal, useAccount } from '@azure/msal-react';
import styles from './inspection-schedule.module.scss';
import InspectionLineItem from '../../controls/inspection-line-item';
import SectionHeader from '../../controls/section-header';
import DatePicker from '../../controls/date-picker';
import CustomButton from '../../controls/custom-button';

import LabelValueField from '../../controls/label-value-field/label-value-field.component';
import imgCollapse from '../../../assets/images/collapse.png';
import imgEmptyBucket from '../../../assets/images/empty-bucket.png';
import CalendarTile from '../../controls/calendar-tile';
import TitleHeader from '../../controls/title-header';
import {
  weekDays, loaderMessages, pages, insSchStrings,
} from '../../../constants/strings';
import {
  getForms,
  fetchBasicSchedule,
  postBasicSchedule,
  deleteBasicScheduleRecords,
  fetchDailySchedule,
  prepareDailyScheduleObj,
  postDailySchedule,
  onBasicScheduleApplyToDaily,
  deleteDailyScheduleRecords,
} from './inspection-schedule.helper';
import { checkArrayNotNull } from '../../../Helpers/common-helpers';
import ButtonModal from '../../controls/button-modal';
import {
  insSchModal,
  activityTypes,
  invalidInsSchModal,
  plantDetails,
} from '../../../constants/enums';
import { loginSilentRequest } from '../../../Helpers/auth-config';

export default function InspectionSchedule({
  addBreadCrumb, global, hideLoader, insSchedState, showLoader, updateBasicSchDB, updateBasicSchedule, updateDailySchedule, updateDailySchDB, updateDeleteBaSch, updateDeleteDailyScheduleData, updateInspectionSchedule, updateSelectedPage,
}) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isBasicScheduleOpen, setIsBasicScheduleOpen] = React.useState(true);
  const [selectedInspection, setSelectedInspection] = React.useState(null);
  const [postSuccessful, setPostSuccessful] = React.useState(false);
  const [isPostingFailed, setPostFailStatus] = React.useState(false);

  const hasChanges = insSchedState.basicScheduleToPost.length > 0
    || insSchedState.dailyScheduleToPost.length > 0
    || insSchedState.deletedIds.length > 0;

  const resetState = async () => {
    setIsBasicScheduleOpen(true);
    updateDeleteBaSch([]);
    updateBasicSchedule([]);
    updateBasicSchDB([]);
    updateDailySchDB([]);
    updateDeleteDailyScheduleData([]);
    setSelectedInspection(null);
    setPostSuccessful(false);
    setPostFailStatus(false);
  };

  const initialise = async () => {
    try {
      showLoader(loaderMessages.FETCHING_FORM);
      addBreadCrumb({
        name: pages.INSPECTION_SCHEDULE,
        action: resetState,
      });
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      const results = await getForms(
        global.userData.mail.substring(
          0,
          global.userData.mail.indexOf('@'),
        ),
        response.idToken,
        global.plantId,
      );
      updateInspectionSchedule(results);
      hideLoader();
    } catch (e) {
      if (e?.details === insSchStrings.API_MAINTENANCE) {
        setErrorMessage(insSchStrings.API_MAINTENANCE);
      }
      else {
        setErrorMessage('Something Went Wrong');
      }
        hideLoader();
      console.log(e);
    }
  };

  React.useEffect(() => {
    initialise();
    // eslint-disable-next-line
  }, []);

  const getBasicScheduleInitialState = (formId, weekOfMonth) => ({
    isAdded: true,
    basicSetDate: format(new Date(), insSchStrings.DATE_FORMAT),
    form_id: formId,
    week_of_month: weekOfMonth,
    endDate: {
      value: insSchStrings.MAX_DATE,
      actualValue: insSchStrings.MAX_DATE,
    },
    [weekDays.MONDAY]: {
      day_shift: {
        value: 0,
        actualValue: 0,
      },
      swing_shift: {
        value: 0,
        actualValue: 0,
      },
      night_shift: {
        value: 0,
        actualValue: 0,
      },
    },
    [weekDays.TUESDAY]: {
      day_shift: {
        value: 0,
        actualValue: 0,
      },
      swing_shift: {
        value: 0,
        actualValue: 0,
      },
      night_shift: {
        value: 0,
        actualValue: 0,
      },
    },
    [weekDays.WEDNESDAY]: {
      day_shift: {
        value: 0,
        actualValue: 0,
      },
      swing_shift: {
        value: 0,
        actualValue: 0,
      },
      night_shift: {
        value: 0,
        actualValue: 0,
      },
    },
    [weekDays.THURSDAY]: {
      day_shift: {
        value: 0,
        actualValue: 0,
      },
      swing_shift: {
        value: 0,
        actualValue: 0,
      },
      night_shift: {
        value: 0,
        actualValue: 0,
      },
    },
    [weekDays.FRIDAY]: {
      day_shift: {
        value: 0,
        actualValue: 0,
      },
      swing_shift: {
        value: 0,
        actualValue: 0,
      },
      night_shift: {
        value: 0,
        actualValue: 0,
      },
    },
    [weekDays.SATURDAY]: {
      day_shift: {
        value: 0,
        actualValue: 0,
      },
      swing_shift: {
        value: 0,
        actualValue: 0,
      },
      night_shift: {
        value: 0,
        actualValue: 0,
      },
    },
    [weekDays.SUNDAY]: {
      day_shift: {
        value: 0,
        actualValue: 0,
      },
      swing_shift: {
        value: 0,
        actualValue: 0,
      },
      night_shift: {
        value: 0,
        actualValue: 0,
      },
    },
  });

  const onFormClick = async (item, isReloading = false) => {
    try {
      await resetState();
      setSelectedInspection(item);
      addBreadCrumb({
        name: item.formName,
        action: null,
      });
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      if (isReloading) showLoader(loaderMessages.RELOADING_CHANGES);
      else showLoader(loaderMessages.FETCHING_BASIC_SCHEDULE);
      let basicSchedule = [];
      basicSchedule = await fetchBasicSchedule(
        item.formId,
        response.idToken,
      );
      if (basicSchedule.length === 0) {
        basicSchedule.push(
          getBasicScheduleInitialState(item.formId, 1),
          getBasicScheduleInitialState(item.formId, 2),
          getBasicScheduleInitialState(item.formId, 3),
          getBasicScheduleInitialState(item.formId, 4),
        );
      }
      if (!isReloading) {
        hideLoader();
        showLoader(loaderMessages.FETCHING_DAILY_SCHEDULE);
      }
      const dailyScheduleData = await fetchDailySchedule(
        item.formId,
        insSchedState.calendarDates[0],
        insSchedState.calendarDates[
          insSchedState.calendarDates.length - 1
        ],
        response.idToken,
      );
      const dailySchedule = await prepareDailyScheduleObj(
        dailyScheduleData,
        insSchedState.calendarDates,
        basicSchedule,
        item.formId,
      );
      updateBasicSchedule(basicSchedule);
      updateDailySchedule(dailySchedule);
      updateDailySchDB(dailyScheduleData);
      updateBasicSchDB(_.cloneDeep(basicSchedule));
      hideLoader();
    } catch (e) {
      hideLoader();
      console.log(e);
    }
  };

  const generateList = () => insSchedState.inspectionList.map((plantRecord, plantIndex) => {
    const onPlantClick = () => {
      const plantList = [...insSchedState.inspectionList];
      plantList[plantIndex].isPlantOpened = !plantList[plantIndex].isPlantOpened;
      updateInspectionSchedule(plantList);
    };
    const plantHeader = (
      <SectionHeader
        text={
          plantRecord.plantId !== undefined
            ? plantDetails[plantRecord.plantId]
            : 'Other'
        }
        isOpen={plantRecord.isPlantOpened}
        onClick={onPlantClick}
        hasCollapse
      />
    );
    const areaElements = plantRecord.areas.map((element, index) => {
      const onAreaClick = () => {
        const plantList = [...insSchedState.inspectionList];

        plantList[plantIndex].areas[index].isAreaOpen = !plantList[plantIndex].areas[index].isAreaOpen;
        updateInspectionSchedule(plantList);
      };
      const sectionHeader = (
        <SectionHeader
          text={element.areaName}
          isOpen={element.isAreaOpen}
          onClick={onAreaClick}
          subHeading={insSchedState.inspectionList.length > 1}
          hasCollapse
        />
      );
      const inspectionsInArea = element.forms.map((item) => {
        const onItemClick = async () => {
          await onFormClick(item);
        };
        const activityType = activityTypes[Number(item.activityType)]
          ? activityTypes[Number(item.activityType)]
          : Number(item.activityType);
        return (
          <InspectionLineItem
            title={item.formName}
            onClick={onItemClick}
            details={{
              detail_1: item.mainWorkCenter,
              detail_2: activityType,
              detail_3: `LAST UPDATE: ${item.last_updated_time}`,
            }}
          />
        );
      });
      return (
        <>
          {sectionHeader}
          {element.isAreaOpen && inspectionsInArea}
        </>
      );
    });
    return (
      <>
        {insSchedState.inspectionList.length > 1 && plantHeader}
        {(insSchedState.inspectionList.length === 1
          || plantRecord.isPlantOpened)
          && areaElements}
      </>
    );
  });

  const onCollapseClick = () => setIsBasicScheduleOpen(!isBasicScheduleOpen);

  const undoUnsavedChangesClick = async () => {
    if (hasChanges) {
      const dailySchedule = await prepareDailyScheduleObj(
        insSchedState.dailyScheduleDB,
        insSchedState.calendarDates,
        insSchedState.basicScheduleDB,
      );
      updateDailySchedule(dailySchedule);
      updateBasicSchedule(insSchedState.basicScheduleDB);
    }
  };

  const onBasicScheduleApply = async () => {
    try {
      showLoader(loaderMessages.APPLYING_BASIC_SCHEDULE);
      const dailyScheduleData = await onBasicScheduleApplyToDaily(
        insSchedState.basicSchedule,
        insSchedState.dailySchedule,
        insSchedState.deletedElements,
      );
      updateDailySchedule(dailyScheduleData.dailySchedule);
      updateDeleteDailyScheduleData(dailyScheduleData.deletedDailySchIds);
      hideLoader();
    } catch (e) {
      hideLoader();
    }
  };

  const onSubmitSchedule = async () => {
    try {
      showLoader(loaderMessages.POST_SCHEDULE);
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      if (insSchedState.basicScheduleToPost.length !== 0) {
        await postBasicSchedule(
          insSchedState.basicScheduleToPost,
          response.idToken,
        );
      }
      if (insSchedState.deletedIds.length !== 0) {
        await deleteBasicScheduleRecords(
          insSchedState.deletedIds,
          response.idToken,
        );
      }
      if (insSchedState.dailyScheduleToPost.length !== 0) {
        await postDailySchedule(
          insSchedState.dailyScheduleToPost,
          response.idToken,
        );
      }
      if (insSchedState.deletedDailyIds.length !== 0) {
        await deleteDailyScheduleRecords(
          insSchedState.deletedDailyIds,
          response.idToken,
        );
      }
      setPostSuccessful(true);
      hideLoader();
    } catch (e) {
      hideLoader();
      setPostFailStatus(true);
    }
  };

  const generateBasicSchedule = () => {
    const onEndDateChange = (date) => {
      const basicScheduleArray = [...insSchedState.basicSchedule];
      basicScheduleArray.forEach((item) => {
        item.endDate.value = format(date, insSchStrings.DATE_FORMAT);
      });
      updateBasicSchedule(basicScheduleArray);
    };
    const basicSchRows = insSchedState.basicSchedule.map((record, index) => {
      const onCopyClick = (key) => {
        const basicScheduleArray = _.cloneDeep(insSchedState.basicSchedule);
        basicScheduleArray.forEach((bsRecord) => {
          bsRecord[weekDays[key]].day_shift.value = basicScheduleArray[0][weekDays[key]].day_shift.value;
          bsRecord[weekDays[key]].swing_shift.value = basicScheduleArray[0][weekDays[key]].swing_shift.value;
          bsRecord[weekDays[key]].night_shift.value = basicScheduleArray[0][weekDays[key]].night_shift.value;
        });
        updateBasicSchedule(basicScheduleArray);
      };
      return (
        <div className="d-flex pr-4 mt-4">
          {Object.keys(weekDays).map((key) => {
            const onChange = (shiftKey, value) => {
              const basicScheduleArray = [...insSchedState.basicSchedule];
              basicScheduleArray[index][weekDays[key]][shiftKey].value = value;
              updateBasicSchedule(basicScheduleArray);
            };
            return (
              <CalendarTile
                onChange={onChange}
                value={weekDays[key]}
                shiftDetails={record[weekDays[key]]}
                onCopyClick={index === 0 ? () => onCopyClick(key) : null}
              />
            );
          })}
        </div>
      );
    });
    return (
      <div className={`border-bottom pb-3 pt-2 ${styles.paddingLeft30} mt-3`}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <div>
              <p className={styles.dateText}>End Date</p>
              {
                insSchedState.basicSchedule.length > 0
                && (
                  <DatePicker
                    value={
                      insSchedState.basicSchedule?.[0].endDate.value === insSchStrings.MAX_DATE
                        ? null
                        : new Date(insSchedState.basicSchedule?.[0].endDate.value)
                    }
                    onChange={onEndDateChange}
                    isChanged={
                      insSchedState.basicSchedule?.[0].endDate.value !== insSchedState.basicSchedule[0].endDate.actualValue
                    }
                  />
                )
              }
            </div>
          </div>
        </div>
        {basicSchRows}
      </div>
    );
  };

  const generateCalHeader = () => Object.keys(weekDays).map((weekDay) => (
    <div style={{ flex: 1 }} className={styles.calendarHeader}>
      {weekDays[weekDay]}
    </div>
  ));
  const goToHome = () => {
    setPostSuccessful(false);
    updateSelectedPage(pages.HOME);
  };

  const onOkClick = () => {
    setPostFailStatus(false);
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate()
      && someDate.getMonth() === today.getMonth()
      && someDate.getFullYear() === today.getFullYear()
    );
  };

  const goToInspectionList = () => {
    setPostSuccessful(false);
    setSelectedInspection(null);
  };

  const goBack = async () => {
    setPostSuccessful(false);
    await onFormClick(selectedInspection, true);
  };

  const generateCalendar = () => {
    const keys = Object.keys(weekDays);
    if (checkArrayNotNull(insSchedState.dailySchedule)) {
      const dailySchedule = [];
      const calDates = [...insSchedState.dailySchedule];

      for (let i = 0; i <= 26; i++) {
        dailySchedule[i] = calDates.splice(0, 7);
      }
      return dailySchedule.map((row, rowIndex) => {
        const calRow = row.map((dateObj, index) => {
          const weekDetail = dateObj[weekDays[keys[index]]];
          const onInputChange = (shiftId, value) => {
            const dailyScheduleArray = [...insSchedState.dailySchedule];
            dailyScheduleArray[
              rowIndex * 7 + index
            ].isDailyScheduleChange = true;
            dailyScheduleArray[rowIndex * 7 + index][weekDays[keys[index]]][
              shiftId
            ].value = value;
            updateDailySchedule(dailyScheduleArray);
          };
          const onResetClick = () => {
            const dailyScheduleArray = [...insSchedState.dailySchedule];
            dailyScheduleArray[
              rowIndex * 7 + index
            ].isDailyScheduleChange = true;
            dailyScheduleArray[rowIndex * 7 + index][
              weekDays[keys[index]]
            ].day_shift.value = 0;
            dailyScheduleArray[rowIndex * 7 + index][
              weekDays[keys[index]]
            ].swing_shift.value = 0;
            dailyScheduleArray[rowIndex * 7 + index][
              weekDays[keys[index]]
            ].night_shift.value = 0;
            updateDailySchedule(dailyScheduleArray);
          };
          return (
            <CalendarTile
              isToday={isToday(new Date(dateObj.value))}
              onResetClick={onResetClick}
              showBackgroundWhenContent
              onChange={onInputChange}
              shiftDetails={weekDetail}
              value={dateObj.value}
              disabled={dateObj.isPast}
            />
          );
        });
        return <div className="d-flex mt-1 mb-2">{calRow}</div>;
      });
    }
    return null;
  };

  return (
    <div
      style={{
        paddingTop: '100px',
        minWidth: selectedInspection !== null ? 1320 : null,
      }}
    >
      <TitleHeader value={pages.INSPECTION_SCHEDULE} />
      {selectedInspection === null ? (
        <div className={styles.insSchedContainer}>
          {insSchedState.inspectionList.length > 0 ? (
            generateList()
          ) : (
            <div>
              <div className={`d-flex justify-content-center align-items-center ${styles.emptyBucket}`}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img
                    alt="imgEmptyBucket"
                    src={imgEmptyBucket}
                    className="img-fluid"
                    height={150}
                    width={150}
                  />
                  {
                      errorMessage === '' ? (
                        <>
                          <p className={`${styles.introText} p-0 mt-3`}>
                            {insSchStrings.NO_INSPECTION_FOUND}
                          </p>
                          <p className={`${styles.introText} p-0 mt-3`}>
                            {insSchStrings.CREATE_INSPECTION}
                          </p>
                        </>
                      )
                        : <p className={`${styles.introText} p-0 mt-3`}>{errorMessage}</p>
                    }
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className={`row ml-0 mr-0 mt-1 border-bottom ${styles.paddingLeft30}`}>
            <LabelValueField
              bootStrapClass="col-5"
              label="Form Name"
              value={selectedInspection.formName}
            />
            <LabelValueField
              bootStrapClass="col-3"
              label="Area"
              value={selectedInspection.area}
            />
            <LabelValueField
              bootStrapClass="col-2"
              label="Work Center"
              value={selectedInspection.mainWorkCenter}
            />
            <LabelValueField
              bootStrapClass="col-2"
              label="Inspection Type"
              value={
                  activityTypes[Number(selectedInspection.activityType)]
                    ? activityTypes[Number(selectedInspection.activityType)]
                    : Number(selectedInspection.activityType)
                }
            />
          </div>
          <div className="border-bottom">
            <div className={`d-flex justify-content-between mt-3 ${styles.paddingLeft30}`}>
              <h4 className={styles.heading}>{insSchStrings.BASIC_SCHEDULE_HDR}</h4>
              <div
                className={styles.iconDiv}
                style={{ marginRight: 30 }}
                onClick={onCollapseClick}
                onKeyDown={(e) => { if (e.keyCode === 13) onCollapseClick(); }}
                tabIndex={0}
                role="button"
              >
                <img
                  alt="imgCollapse"
                  src={imgCollapse}
                  className={styles.collapseIcon}
                />
              </div>
            </div>
            {isBasicScheduleOpen && (
            <div className="mt-2">
              {generateBasicSchedule()}
              <div
                className="d-flex align-items-center pt-3 pb-3 justify-content-end mr-4"
              >
                <CustomButton
                  onPress={onBasicScheduleApply}
                  value="Apply Changes To Daily Schedule"
                  customStyle={{ padding: 12, fontSize: 15, marginRight: 20 }}
                  disabled={
                        !(
                          insSchedState.basicScheduleToPost.length !== 0
                          || insSchedState.deletedIds.length !== 0
                        )
                      }
                />
              </div>
            </div>
            )}
          </div>
          <div>
            <div className={`d-flex mt-3 ${styles.paddingLeft30}`}>
              <h4 className={styles.heading}>{insSchStrings.DAILY_SCHEDULE_HDR}</h4>
            </div>
            <div
              className={`d-flex ${styles.calHeader}`}
            >
              {generateCalHeader()}
            </div>
            <div className={styles.calendarContainer}>
              {generateCalendar()}
            </div>
          </div>
        </div>
      )}
      {selectedInspection !== null && (
        <div
          className={`border-top ${styles.footerContainer} d-flex justify-content-end align-items-end`}
        >
          <div
            className={`${styles.undoTextButton} ${
              !hasChanges && styles.undoTextButton__disabled
            }`}
            onClick={undoUnsavedChangesClick}
            onKeyDown={(e) => { if (e.keyCode === 13) undoUnsavedChangesClick(); }}
            tabIndex={0}
            role="button"
          >
            {insSchStrings.UNDO}
          </div>
          <CustomButton
            value="Save Schedule"
            disabled={!hasChanges}
            customStyle={{
              padding: 12,
              fontSize: 15,
              marginRight: 20,
              float: 'right',
            }}
            onPress={onSubmitSchedule}
          />
        </div>
      )}
      {postSuccessful && (
        <ButtonModal
          noOfButtons={3}
          isSuccess
          onButtonPress={{
            firstButtonPress: goToHome,
            secondButtonPress: goBack,
            thirdButtonPress: goToInspectionList,
          }}
          data={{
            ...insSchModal,
            instructionsText1:
              insSchStrings.SAVE_SUCCESS,
          }}
        />
      )}

      {isPostingFailed && (
        <ButtonModal
          noOfButtons={1}
          isError
          data={invalidInsSchModal}
          onButtonPress={onOkClick}
        />
      )}
    </div>
  );
}

InspectionSchedule.propTypes = {
  addBreadCrumb: PropTypes.func,
  global: PropTypes.shape({
    plantId: PropTypes.number,
    userData: PropTypes.shape({
      mail: PropTypes.string,
    }),
  }),
  hideLoader: PropTypes.func,
  insSchedState: PropTypes.shape({
    basicSchedule: PropTypes.arrayOf(PropTypes.shape({
      endDate: {
        value: PropTypes.string,
        actualValue: PropTypes.string,
      },
    })),
    basicScheduleDB: PropTypes.arrayOf(PropTypes.shape({})),
    basicScheduleToPost: PropTypes.arrayOf(PropTypes.shape({})),
    calendarDates: PropTypes.arrayOf(PropTypes.shape({})),
    dailySchedule: PropTypes.arrayOf(PropTypes.shape({})),
    dailyScheduleDB: PropTypes.arrayOf(PropTypes.shape({})),
    dailyScheduleToPost: PropTypes.arrayOf(PropTypes.shape({})),
    deletedDailyIds: PropTypes.arrayOf(PropTypes.shape({})),
    deletedElements: PropTypes.arrayOf(PropTypes.shape({})),
    deletedIds: PropTypes.arrayOf(PropTypes.shape({})),
    inspectionList: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showLoader: PropTypes.func,
  updateBasicSchDB: PropTypes.func,
  updateBasicSchedule: PropTypes.func,
  updateDailySchDB: PropTypes.func,
  updateDailySchedule: PropTypes.func,
  updateDeleteBaSch: PropTypes.func,
  updateDeleteDailyScheduleData: PropTypes.func,
  updateInspectionSchedule: PropTypes.func,
  updateSelectedPage: PropTypes.func,
};

InspectionSchedule.defaultProps = {
  addBreadCrumb: () => { },
  global: {},
  hideLoader: () => { },
  insSchedState: {},
  showLoader: () => { },
  updateBasicSchDB: () => { },
  updateBasicSchedule: () => { },
  updateDailySchDB: () => { },
  updateDailySchedule: () => { },
  updateDeleteBaSch: () => { },
  updateDeleteDailyScheduleData: () => { },
  updateInspectionSchedule: () => { },
  updateSelectedPage: () => { },
};