import PropTypes from 'prop-types';
import React from 'react';

export default function Drawer({ fill }) {
  return (
    <svg
      fill="none"
      height="101"
      viewBox="0 0 150 101"
      width="150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M147.901 47.5177L120.378 6.23282C119.236 4.52063 117.689 3.11674 115.875 2.14575C114.061 1.17476 112.035 0.666704 109.977 0.666672H40.0232C37.9654 0.666678 35.9394 1.17473 34.125 2.14572C32.3107 3.11671 30.7641 4.52062 29.6227 6.23282L2.09948 47.5177C0.73049 49.5709 -3.01386e-05 51.9835 9.32578e-10 54.4513L9.3258e-10 88.1666C9.3258e-10 95.0703 5.59635 100.667 12.5 100.667H137.5C144.404 100.667 150 95.0703 150 88.1666V54.4513C150 51.9835 149.27 49.5709 147.901 47.5177ZM42.2531 17.3333H107.747L129.969 50.6667H97.9167L89.5833 67.3333H60.4167L52.0833 50.6667H20.0307L42.2531 17.3333Z"
        fill={fill || '#ecf2f6'}
      />
    </svg>
  );
}

Drawer.propTypes = {
  fill: PropTypes.string,
};

Drawer.defaultProps = {
  fill: '',
};
