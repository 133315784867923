import {
  updateSelectedPage,
  addBreadCrumb,
  resetBreadCrumbs,
} from '../../../redux/actions/global.actions';
import { pages } from '../../../constants/strings';
import { setFinalConfirmation, updateOrder } from './single-confirmation.actions';

export const handleSingleConfirmation = (
  dispatch,
  order,
  finalConfirmation,
) => {
  if (finalConfirmation) {
    dispatch(resetBreadCrumbs(() => {
      updateSelectedPage(pages.HOME);
    }));
  }
  dispatch(
    addBreadCrumb({
      name: 'Create Confirmation',
      action: null,
    }),
  );
  dispatch(updateOrder(order));
  if (finalConfirmation) dispatch(setFinalConfirmation(true));
  dispatch(updateSelectedPage(pages.SINGLE_CONFIRMATION));
};

export default () => { };
