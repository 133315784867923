/* eslint-disable */
import { Client } from '@microsoft/microsoft-graph-client';

class GraphManager {
  static getUserAsync = async (token) => {
    class GraphAuthProvider {
      getAccessToken = async () => token;
    }
    const clientOptions = {
      authProvider: new GraphAuthProvider(),
    };
    const graphClient = Client.initWithMiddleware(clientOptions);
    try {
      return graphClient.api('/me').get();
    } catch (err) {
      console.log('err', err);
      throw err;
    }
    // GET /me
  };

  static getPhotoAsync = async (token) => {
    class GraphAuthProvider {
      getAccessToken = async () => token;
    }
    const clientOptions = {
      authProvider: new GraphAuthProvider(),
    };
    const graphClient = Client.initWithMiddleware(clientOptions);
    try {
      return graphClient.api('/me/photo/$value').get();
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  static sendFeedbackMail = async (token, content, version) => {
    class GraphAuthProvider {
      getAccessToken = async () => token;
    }
    const clientOptions = {
      authProvider: new GraphAuthProvider(),
    };
    const graphClient = Client.initWithMiddleware(clientOptions);
    try {
      const sendMail = {
        message: {
          subject: `Feedback for Maintenance Portal - ${version}`,
          body: {
            contentType: 'Text',
            content: `${content.text}\n\nBrowser Details: ${content.browserDetails}\n\n`,
          },
          toRecipients: [
            {
              emailAddress: {
                address: 'ce502e84.gerdaucld.onmicrosoft.com@amer.teams.ms',
              },
            },
          ],
          attachments: [...content.attachments],
        },
        saveToSentItems: 'false',
      };

      await graphClient.api('/me/sendMail')
        .version('beta')
        .post(sendMail);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export default GraphManager;

/* eslint-enable */
