import PropTypes from 'prop-types';
import React from 'react';
// import './form-label-field.styles.css';
import styles from './form-label-field.module.scss';

export default function FormLabelField({ children, label }) {
  return (
    <div>
      <p className={`${styles.formLabel} m-0 mb-1`}>{label}</p>
      {children}
    </div>
  );
}

FormLabelField.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
};

FormLabelField.defaultProps = {
  children: '',
  label: '',
};
