import PropTypes from 'prop-types';
import React from 'react';

export default function DoubleArrowDown({ fill }) {
  return (
    <svg
      fill="none"
      height="27"
      viewBox="0 0 21 27"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.38374 13.754L0.45875 4.86383C-0.158125 4.24937 -0.158125 3.25576 0.45875 2.64784L1.94187 1.1705C2.55875 0.556039 3.55625 0.556039 4.16656 1.1705L10.4928 7.47204L16.819 1.1705C17.4359 0.556039 18.4334 0.556039 19.0437 1.1705L20.54 2.6413C21.1569 3.25576 21.1569 4.24937 20.54 4.8573L11.615 13.7474C10.9981 14.3684 10.0006 14.3684 9.38374 13.754ZM11.615 26.3048L20.54 17.4146C21.1569 16.8001 21.1569 15.8065 20.54 15.1986L19.0569 13.7213C18.44 13.1068 17.4425 13.1068 16.8322 13.7213L10.4994 20.0163L4.17312 13.7147C3.55625 13.1003 2.55875 13.1003 1.94844 13.7147L0.45875 15.1921C-0.158125 15.8065 -0.158125 16.8001 0.45875 17.4081L9.38374 26.2982C10.0006 26.9192 10.9981 26.9192 11.615 26.3048Z"
        fill={fill || '#0578BE'}
      />
    </svg>
  );
}

DoubleArrowDown.propTypes = {
  fill: PropTypes.string,
};

DoubleArrowDown.defaultProps = {
  fill: '',
};
