import * as msal from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '4f9806da-c194-4a5f-825d-8a966edb8210',
    clientSecret: 'PtD.2gJpdR64z25uHLTZmK7.2b_~IVki~4',
    authority: 'https://login.microsoftonline.com/461fd7ef-0eb3-4420-b044-310dc2914d64',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read', 'Mail.Send', 'Tasks.ReadWrite', 'Tasks.ReadWrite.Shared'],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  prompt: 'select_account',
};

export const loginSilentRequest = {
  scopes: ['openid', 'profile', 'User.Read', 'Mail.Send', 'Tasks.ReadWrite', 'Tasks.ReadWrite.Shared'],
  forceRefresh: true,
  prompt: 'none',
};

// // Add here scopes for id token to be used at MS Identity Platform endpoints.
// export const apiRequest = {
//     scopes: ["API-SCOPE"],
//     forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
// };

const msalInstance = new msal.PublicClientApplication(msalConfig);
export default msalInstance;
