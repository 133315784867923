import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import './order-attachment.styles.css';
import CustomModal from '../../controls/custom-modal';
import IconImg from '../../../assets/svgs/icon_img';
import IconDoc from '../../../assets/svgs/icon_doc';
import IconUrl from '../../../assets/svgs/icon_url';
import IconNewTab from '../../../assets/svgs/icon_newtab';
import { generalStrings } from '../../../constants/strings';
// import IconViewAttachment from '../../../assets/svgs/icon_view-attachment';

const Icons = {
  jpg: IconImg,
  doc: IconDoc,
  url: IconUrl,
};

export default function OrderAttachment({
  attachments,
  visible,
  onClose,
  orderId,
  // onViewImage,
}) {
  return (
    <>
      {visible && (
        <CustomModal
          customWidth="550px"
          actions={{ cancel: onClose }}
          header={{ title: generalStrings.ATTACHMENTS, bgColor: '#0578BE', exit: true }}
        >
          <div className="modal__content container d-flex flex-column">
            {attachments.map((attachment) => (
              <AttachmentItem
                key={attachment.name}
                data={attachment}
                orderId={orderId}
              />
            ))}
          </div>
        </CustomModal>
      )}
    </>
  );
}

OrderAttachment.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func,
  orderId: PropTypes.string,
  visible: PropTypes.bool,
};

OrderAttachment.defaultProps = {
  attachments: [],
  onClose: () => { },
  orderId: '',
  visible: false,
};

function AttachmentItem({
  data,
  orderId,
  // onViewImage
}) {
  const globalReducer = useSelector((state) => state.globalReducer);
  const { userData } = globalReducer;
  const { name, url, type } = data;
  const DynamicIcon = Icons[type];

  const generateAuthUrl = (eInspUrl) => {
    const username = userData.mail.substring(0, userData.mail.indexOf('@'));
    if (!eInspUrl.includes(generalStrings.E_INSP_KEY)) {
      return eInspUrl;
    } if (eInspUrl.includes(generalStrings.E_INSP_KEY) && !orderId) return eInspUrl;
    return `${eInspUrl}${username}/${orderId.replace('0000', '')}`;
  };

  return (
    <div className="row-col attachment-item">
      <DynamicIcon />
      <span className="attachment-text">{name}</span>
      <div className="pointer">
        <IconNewTab onClick={() => window.open(generateAuthUrl(url), '_blank')} />
      </div>
    </div>
  );
}

AttachmentItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
  orderId: PropTypes.string,
};

AttachmentItem.defaultProps = {
  data: {},
  orderId: '',
};
