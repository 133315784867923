import { getURL } from '../../../Helpers/common-helpers';
import {
  GET_PERSONS_DATA,
  GET_WORK_CENTERS,
  GET_WORK_CENTER_ASSIGNMENTS,
} from '../../../constants/endpoints';
import {
  HttpGet,
  HttpPut,
  HttpPost,
  HttpDelete,
} from '../../../services/api-services';
import { adminSetupStrings } from '../../../constants/strings';

export const getPersonsData = async () => {
  try {
    const results = await HttpGet(getURL(GET_PERSONS_DATA));
    return results.data.message.data;
  } catch (e) {
    throw e.message.data.error[0].description;
  }
};
const prepareWorkCentersObject = (apiData) => (
  apiData.map((item) => ({
    [adminSetupStrings.PLANT]: item.plant_id,
    [adminSetupStrings.WC_DESCRIPTION]: item.work_center_description,
    [adminSetupStrings.WC_ID]: item.work_center_id,
    [adminSetupStrings.EINSP_DB_URL]: item.bi_url,
    id: item.id,
  }))
);

export const getWorkCenters = async () => {
  try {
    const results = await HttpGet(getURL(GET_WORK_CENTERS));
    return prepareWorkCentersObject(results.data.message.data);
  } catch (e) {
    throw e.message.data.error[0].description;
  }
};

export const getWCAsssignments = async () => {
  try {
    const results = await HttpGet(getURL(GET_WORK_CENTER_ASSIGNMENTS));
    return results.data.message.data;
  } catch (e) {
    throw e.message.data.error[0].description;
  }
};

export const putWCAssignment = async (data) => {
  try {
    // const apiUR = `${getURL(GET_WORK_CENTER_ASSIGNMENTS)} / ${data.id}`;
    const apiURL = `${getURL(GET_WORK_CENTER_ASSIGNMENTS)}/${data.id}`;
    await HttpPut(apiURL, {
      ...data,
      id: undefined,
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateBiUrl = async (data) => {
  try {
    const apiURL = `${getURL(GET_WORK_CENTERS)}/${data.id}`;
    await HttpPut(apiURL, {
      bi_url: data.bi_url,
    });
  } catch (e) {
    console.error(e);
  }
};

export const postWCAssignment = async (data) => {
  try {
    const apiURL = getURL(GET_WORK_CENTER_ASSIGNMENTS);
    await HttpPost(apiURL, data);
  } catch (e) {
    console.error(e);
  }
};

export const deleteWCAssignment = async (id) => {
  try {
    const apiURL = (`${getURL(GET_WORK_CENTER_ASSIGNMENTS)}/${id}`);
    await HttpDelete(apiURL);
  } catch (e) {
    console.error(e);
  }
};

export const prepareWCAssignmentsObject = (apiData, wcData) => (
  apiData.map((item) => (
    {
      [adminSetupStrings.PLANT]: {
        previousValue: item.plant_id,
        value: item.plant_id,
      },
      [adminSetupStrings.WC_DESCRIPTION]: item.work_center_description,
      [adminSetupStrings.WC_ID]: {
        previousValue: item.work_center_id,
        value: item.work_center_id,
        items: wcData
          .filter(
            (workCenterData) => workCenterData[adminSetupStrings.PLANT] === item.plant_id,
          )
          .map((wcInfo) => (
            { ...wcInfo, name: wcInfo[adminSetupStrings.WC_ID] }
          )),
      },
      [adminSetupStrings.EINSP_DB_URL]: {
        previousValue: item.bi_url,
        value: item.bi_url,
      },
      [adminSetupStrings.PERSONELL]: {
        previousValue: item.email_id,
        value: item.email_id,
      },
      id: item.id,
      person_id: item.person_id,
      editable: false,
      work_center_id: wcData.find(
        (wcObj) => wcObj[adminSetupStrings.WC_ID] === item.work_center_id,
      ).id,
    }
  ))
);
