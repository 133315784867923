import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
// import './custom-multi-select.styles.css';
import PropTypes from 'prop-types';

import styles from './custom-multi-select.module.scss';
import imgDownArrow from '../../../assets/images/new-dropdown-arrow.png';
import imgUpArrow from '../../../assets/images/new-dropdown-up-arrow.png';
import { appColors } from '../../../constants/colors';
import { checkArrayNotNull } from '../../../Helpers/common-helpers';
import CustomRadioButton from '../custom-radio-button';

export default function CustomMultiSelect({
  data, editable, isEditOrder, style, updateMultiSelect, selectAllData = false
}) {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const toggleDropdown = () => {
    setDropdownStatus(!dropdownStatus);
  };
  const onApplyClick = (clonedItems) => {
    const selectedValues = clonedItems
      .map((item) => {
        if (item.selected) {
          return item;
        }
        return undefined;
      })
      .filter((item) => item !== undefined);
    const value = selectedValues.length !== 0
    && (selectedValues.length > 1
      ? 'Multiple Selected'
      : selectedValues[0].name);
    toggleDropdown();
    updateMultiSelect({
      key: data.name,
      value,
      items: clonedItems,
    });
  };
  const selectAllItems = () => {
    setSelectAll(!selectAll);
    const clonedItems = [...data.items];
    let selectAllData = clonedItems.map(item => ({
      ...item,
      selected : !selectAll,
    }))
    onApplyClick(selectAllData);
  }

  function generateDropdownItems() {
    return (
      <>
        {selectAllData && <div
          className={`${styles.listItem}`}
          onClick={selectAllItems}
          role="presentation"
        >
          <CustomRadioButton
            height={18}
            width={18}
            value={selectAll}
            editable= {true}
          />
          <span style={{ marginLeft: 10 }}>{!selectAll ? 'Select' : 'Unselect'} All</span>
        </div>}
        {
          data.items.map((item, index) => {
            const onItemPress = () => {
              const clonedItems = [...data.items];
              const clonedObj = { ...clonedItems[index] };
              clonedObj.selected = !clonedObj.selected;
              clonedItems[index] = clonedObj;
              onApplyClick(clonedItems);
            };
            let styleObj = {};
            if (item.selected) {
              styleObj = {
                backgroundColor: '#FFF5CC',
                fontFamily: 'OpenSans-SemiBold',
              };
            }
            if (item.saved) {
              styleObj = {
                cursor: 'default',
                backgroundColor: appColors.DROP_DOWN_ITEM_BG,
              };
            }
            return (
              <div
                className={`${styles.listItem}`}
                onClick={item.saved ? () => { } : onItemPress}
                style={{ ...styleObj }}
                role="presentation"
                // key={`${index}`}
              >
                <CustomRadioButton
                  height={18}
                  width={18}
                  value={item.selected}
                  editable={!item.saved}
                />
                <span style={{ marginLeft: 10 }}>{item.name}</span>
              </div>
            )
          })
        }
      </>
    )
  }
  return (
    <OutsideClickHandler onOutsideClick={() => setDropdownStatus(false)}>
      <div className={`${styles.dropDownContainer}`}>
        {data.name && (
          <p className={`${styles.labelText} p-0 m-0`}>{data.name}</p>
        )}
        {!editable || !checkArrayNotNull(data.items) ? (
          <p>{data.value}</p>
        ) : (
          <div
            className={`${styles.dropdownView} justify-content-between non-selectable ${dropdownStatus ? styles.dropdownPickerViewWidth : styles.dropdownViewWidth}`}
            style={{
              position: dropdownStatus ? 'absolute' : 'relative', ...style, boxShadow: `inset 0px 0px 5px ${dropdownStatus ? '#0578BE' : 'rgba(0, 0, 0, 0.25)'}`,
            }}
            onClick={toggleDropdown}
            role="presentation"
          >
            <div className="d-flex justify-content-between">
              <p
                className={`${styles.ddvalueText} m-0`}
              >
                {data.value}
              </p>
              <div className="flex-col pr-2">
                <img src={dropdownStatus ? imgUpArrow : imgDownArrow} alt="dropdown-arrow" height={7} width={11} style={{ marginBottom: 7 }} />
              </div>
            </div>
            {dropdownStatus && (
              <>
                <div className="dropdown-divider ml-2 mr-2" />
                <div className={`${styles.dropdownList}`}>
                  {generateDropdownItems(isEditOrder)}
                </div>
              </>
            )}
          </div>
        )}

      </div>
    </OutsideClickHandler>
  );
}

CustomMultiSelect.propTypes = {
  data: PropTypes.shape({
    isPickerOpen: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  editable: PropTypes.bool,
  isEditOrder: PropTypes.bool,
  style: PropTypes.shape({}),
  updateMultiSelect: PropTypes.func,
  selectAllData: PropTypes.bool,
  // updatePickerOpenStatus: PropTypes.func,
};

CustomMultiSelect.defaultProps = {
  data: {},
  editable: false,
  isEditOrder: false,
  style: {},
  updateMultiSelect: () => { },
  selectAllData: false,
  // updatePickerOpenStatus: () => { },
};
