import PropTypes from 'prop-types';
import React from 'react';
import styles from './title-header.module.scss';

export default function TitleHeader({ value }) {
  return (
    <h1 className={`${styles.headerTitle} ${styles.headerStyle} `} style={{ paddingLeft: 5 }}>{value}</h1>
  );
}

TitleHeader.propTypes = {
  value: PropTypes.string,
};

TitleHeader.defaultProps = {
  value: '',
};
