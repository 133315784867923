export const BASE_URI = 'https://qa-exp-maint-stores-v1.us-e1.cloudhub.io/api/v1';
export const GET_ORDERS = '/orders';
export const GET_ORDER_BY_WEEK_SCHEDULE = '/orders/byScheduleDate';
export const GET_ORDER_BY_WC = '/scheduleByFacilWorkcenter';
export const GET_WORK_CENTERS = '/workCenter';
export const GET_WORK_CENTER_ASSIGNMENTS = '/workCenterAssignements';
export const GET_FACIL_WC = '/ordersByFacilitatorPlanner';
export const GET_FOLLOW_UP_BACKLOG = '/backlogWorkorders';
export const COST_CENTER_RESERVATION = '/reservationByCostcenter';
export const GET_ORDERS_CREATED_BY_ME = '/ordersCreatedByUser';
export const GET_WBS_DATA = '/wbsElement';
export const GET_PERSONS_DATA = '/getAllPersonsData';
export const GET_SPARES_TRANSACTIONS = '/sparesManagement';
export const GET_CONFIRM_RECENT = '/confirmRecent';
export const POST_GOODS_MOVEMENT = '/goods-movement';
export const POST_ORDERS = '/orders?svcName=standAlone';
export const FUNCTION_LOCATIONS = '/function-locations';
export const MATERIAL_SEARCH = '/materials';
export const COST_CENTER = '/costcenter';
export const PERSONNEL_DATA = '/personalData';
export const POST_WORK_CONFIRMATION = '/orders/confirmOrder';
export const JOBAID_INSP_URL = 'https://einspections.gerdaugln.tech/JobAid_TEST/Api/GetJobAidFormsAndParameters';
export const INSP_SCHEDULE_URL = 'http://localhost:8001';
export const BASIC_SCHEDULE = '/basic-schedule';
export const DAILY_SCHEDULE = '/daily-schedule';
export const FORM_LAST_UPDATE_TIME = '/form/lastUpdatedTime';
export const CWO_IMAGES = '/images/';
export const GET_AWS_CREDS = '/aws-creds';
export const ADD_NEW_OPERATION = '/orders/addNewDetails';
export const GET_WORK_CTR_BY_PLANT = '/workCentreByPlant';
export const GET_BOM = '/bom';
export const GET_DOC = '/sapDocuments';
export const SPARES = '/spares';
export const SPARES_MATERIAL_ID = '/sparesMaterialId';
export const AREA_GROUPS = '/areaGroups';
export const SPARES_INFO = '/sparesInfo';
export const SPARES_MGT_WITH_MATERIAL_ID = '/sparesMgtWithMaterialId';
export const FUNC_LOC_OPEN_ORDERS = '/funLocOpenOrders';
