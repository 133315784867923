import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import styles from './change-order.module.scss';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import OrderDetails from '../../controls/order-detail';
import Operations from '../../controls/operations';
import {
  pages, createWorkOrderStrings, changeOrderStrings, queryKeyStrings,
} from '../../../constants/strings';
import CustomButton from '../../controls/custom-button';
import CustomTextInput from '../../controls/custom-textinput/custom-textinput.component';
import imgInfoIcon from '../../../assets/images/info-icon@2x.png';
import imgDisAtt from '../../../assets/images/disabled-attachment.png';
import imgDisAsset from '../../../assets/images/disabled-asset-view-new.png';
import imgDisConf from '../../../assets/images/disabled-confirmation.png';
import imgDisEdit from '../../../assets/images/disabled-edit-wo.png';
import { createOperation } from './change-order.helper';
import { opFailureModalData, opSuccessModalData } from '../../../constants/enums';
import ButtonModal from '../../controls/button-modal';
import { getWorkOrderResults } from '../work-confirm/work-confirm.helper';
import mountAttachmentList from '../../modals/OrderAttachment/order-attachment.helper';
import ContentField from '../../controls/content-field';
import OrderAttachment from '../../modals/OrderAttachment';
import imgAcAtt from '../../../assets/images/active-attachment.png';

export default function ChangeOrder({
  addBreadCrumb, deleteBreadCrumb, global, hideLoader, showLoader, updateBreadCrumb, updateGlobalSearchOrder, updateSelectedPage,
}) {
  const [showOrderDetails, setShowOrderDetails] = React.useState(true);
  const [opDesc, setOpDesc] = React.useState('');
  const [duration, setDuration] = React.useState('');
  const [opWkCntr, setOpWkCntr] = React.useState('');
  const [noOfCapacities, setNoOfCapacities] = React.useState('');
  const [successModal, setSuccessModal] = React.useState(false);
  const [failureModal, setFailureModal] = React.useState(false);

  const queryClient = useQueryClient();
  const [attachments, setAttachments] = useState([]);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const updateValue = (data) => {
    switch (data.key) {
      case createWorkOrderStrings.OP_DESC:
        setOpDesc(data.value);
        break;
      case createWorkOrderStrings.NO_OF_RQD_RES:
        setNoOfCapacities(data.value);
        break;
      case createWorkOrderStrings.OP_DURATION:
        setDuration(data.value);
        break;
      case createWorkOrderStrings.OP_WK_CNTR:
        setOpWkCntr(data.value);
        break;
      default:
        break;
    }
  };
  // React.useEffect(() => {
  //   resetBreadCrumbs(() => {
  //     updateSelectedPage(pages.HOME);
  //   });
  //   addBreadCrumb({
  //     name: `Work Order Search`
  //     // action: onBreadCrumbClick,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [global.globalSearchOrder.orderId]);
  const resetState = () => {
    setOpDesc('');
    setDuration('');
    setOpWkCntr('');
    setNoOfCapacities('');
  };
  const onBreadCrumbClick = () => {
    // updateGlobalSearchOrder(global.globalSearchOrder)
    setShowOrderDetails(true);
    updateSelectedPage(pages.CHANGE_WORK_ORDER);
  };

  const onOperationClick = (operation) => {
    updateGlobalSearchOrder({ ...global.globalSearchOrder, selectedOperation: operation });
    updateSelectedPage(pages.EDIT_ORDER);
  };

  const updateDuration = (obj) => {
    const index = obj.value.indexOf('.');
    if (index >= 0) {
      obj.value = obj.value.substring(0, index + 2);
    }
    updateValue(obj);
  };

  const addNewOperation = () => {
    resetState();
    setShowOrderDetails(false);
    addBreadCrumb({
      name: changeOrderStrings.ADD_NEW_OP,
      action: null,
    });
    updateBreadCrumb({
      name: changeOrderStrings.SELECT_OPERATION,
      action: () => {
        setShowOrderDetails(true);
        updateBreadCrumb({
          name: changeOrderStrings.SELECT_OPERATION,
          action: () => {
            updateSelectedPage(pages.CHANGE_WORK_ORDER);
          },
        });
      },
    });
  };

  const onCancelClick = () => {
    deleteBreadCrumb(1);
    onBreadCrumbClick();
  };
  const backToWorkOrder = async () => {
    deleteBreadCrumb(1);
    setSuccessModal(false);
    // await searchWo();
    setShowOrderDetails(true);
  };

  React.useEffect(() => {
    if (global.globalSearchOrder.operations) {
      const operationAttachments = [];
      global.globalSearchOrder.operations.forEach((item) => item.prtDocuments.length > 0 && operationAttachments.push(...item.prtDocuments));
      setAttachments(operationAttachments);
    }
  }, [global.globalSearchOrder]);

  const goToOperation = async () => {
    setSuccessModal(false);
    // let data = await searchWo();
    const operation = global.globalSearchOrder.operations[global.globalSearchOrder.operations.length - 1];
    deleteBreadCrumb(1);
    await onOperationClick(operation);
  };
  const searchWo = async () => {
    try {
      showLoader(changeOrderStrings.RELOADING_ORDER);
      const searchWoData = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SEARCH_ORDER} - ${global.globalSearchOrder.orderId}`,
        queryFn: async () => {
          const response = await getWorkOrderResults(global.plantId, global.globalSearchOrder.orderId);
          return response;
        },
        select: (data) => data,
        retry: false,
        staleTime: 1000,
      });
      updateGlobalSearchOrder(searchWoData);
      hideLoader();
      return searchWoData;
    } catch (e) {
      console.log(e);
      hideLoader();
      throw e;
    }
  };

  const saveNewOperation = async () => {
    try {
      showLoader(changeOrderStrings.ADDING_NEW_OPERATION);
      await createOperation({
        opDesc,
        duration,
        opWkCntr,
        noOfCapacities,
      }, global.globalSearchOrder, global.plantId);
      await searchWo();
      hideLoader();
      setSuccessModal(true);
    } catch (e) {
      hideLoader();
      setFailureModal(true);
      console.log(JSON.stringify(e));
    }
  };

  const onOkClick = () => setFailureModal(false);
  return (
    <>
      {
        showOrderDetails
          ? (
            <>
              <ContentWithBreadcrumb>
                <div className={`${styles.headerSticky}`}>
                  <div className="d-flex align-items-center">
                    <h1 className="headerTitle d-flex align-items-center" style={{ width: 'fit-content', paddingLeft: 5 }}>
                      {`Work Order # ${global.globalSearchOrder?.orderId}`}
                    </h1>
                    <div className="ml-3">
                      <ContentField>
                        <p className={`${styles.contentFieldText} p-0 m-0 `}>{global.globalSearchOrder.shortDescription}</p>
                      </ContentField>
                    </div>
                  </div>
                  <div style={{ paddingLeft: 25, display: 'flex', flexDirection: 'row' }}>
                    <div className="d-flex flex-direction-row justify-content-between mr-2 mt-2">
                      <CustomButton
                        disabled={attachments.length === 0}
                        outline
                        icon={attachments.length === 0 ? imgDisAtt : imgAcAtt}
                        onPress={() => setShowAttachmentModal(true)}
                        customStyle={{ marginLeft: 5, marginRight: 5 }}
                        value={(
                          <p className="m-0">
                            {changeOrderStrings.ATTACHMENTS}
                            <span className={`${attachments.length > 0 ? styles.attachmentLength : styles.attachmentLengthDisabled}`}>
                              {attachments.length}
                            </span>
                          </p>
                        )}
                      />
                      <CustomButton
                        disabled
                        customStyle={{ marginLeft: 5, marginRight: 5 }}
                        icon={imgDisConf}
                        value={changeOrderStrings.CREATE_CONFIRMATION}
                      />
                      <CustomButton
                        disabled
                        customStyle={{ marginLeft: 5, marginRight: 5 }}
                        icon={imgDisConf}
                        value={changeOrderStrings.CREATE_FOLLOW_UP}
                      />
                      <CustomButton
                        disabled
                        customStyle={{ marginLeft: 5, marginRight: 5 }}
                        icon={imgDisAsset}
                        value={changeOrderStrings.ASSET_VIEW}
                      />
                      <CustomButton
                        customStyle={{ marginLeft: 5, marginRight: 5 }}
                        disabled
                        icon={imgDisEdit}
                        value={changeOrderStrings.MODIFY}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <OrderDetails
                    order={{
                      ...global.globalSearchOrder,
                      longText: global.globalSearchOrder.longText || global.globalSearchOrder.shortDescription,
                    }}
                    userData={global.userData}
                  />
                  <Operations
                    mnWrkCtrId={global.globalSearchOrder.mnWrkCtrId}
                    operations={global.globalSearchOrder.operations}
                    onOperationClick={onOperationClick}
                    addOperation={addNewOperation}
                  />
                </div>
              </ContentWithBreadcrumb>
              <OrderAttachment
                attachments={mountAttachmentList(attachments, global.userData.mail, global.globalSearchOrder?.orderId)}
                visible={showAttachmentModal}
                onClose={() => setShowAttachmentModal(false)}
              />
            </>
          )
          : (
            <>
              <ContentWithBreadcrumb>
                <h1 className={`${styles.headerTitle} d-flex  align-items-center`} style={{ width: 'fit-content', paddingLeft: 5 }}>
                  {changeOrderStrings.ADD_NEW_OP}
                  <div
                    style={{ display: 'inline-flex', marginLeft: 20 }}
                  >
                    <CustomButton
                      value={changeOrderStrings.SAVE}
                      customStyle={{
                        padding: 5,
                        fontSize: 12,
                        borderRadius: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      onPress={saveNewOperation}
                    />
                  </div>
                  <div
                    style={{ display: 'inline-flex', marginLeft: 20 }}
                  >
                    <CustomButton
                      value={changeOrderStrings.CANCEL}
                      customStyle={{
                        padding: 5,
                        fontSize: 12,
                        background: '#7F99B2',
                        border: 0,
                        borderRadius: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      onPress={onCancelClick}
                    />
                  </div>
                </h1>
                <div className="px-3 mt-3">
                  <div className="row px-2">
                    <div className="col-lg-4 pb-3">
                      <CustomTextInput
                        label={createWorkOrderStrings.OP_WK_CNTR}
                        value={opWkCntr}
                        rows={1}
                        style={{ zIndex: 600 }}
                        onValueChange={updateValue}
                        autoCapitalize="characters"
                        editable
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <CustomTextInput
                        label={createWorkOrderStrings.NO_OF_RQD_RES}
                        value={noOfCapacities}
                        type="number"
                        rows={1}
                        style={{ zIndex: 600 }}
                        onValueChange={updateValue}
                        autoCapitalize="characters"
                        editable
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <CustomTextInput
                        label={createWorkOrderStrings.OP_DURATION}
                        value={duration}
                        type="number"
                        rows={1}
                        step="0.1"
                        style={{ zIndex: 600 }}
                        onValueChange={updateDuration}
                        editable
                      />
                    </div>
                  </div>
                  <div className="row px-2">
                    <div className="col-12 pb-3">
                      <CustomTextInput
                        label={createWorkOrderStrings.OP_DESC}
                        description={changeOrderStrings.FIRST_40_CHARACTERS}
                        value={opDesc}
                        onValueChange={updateValue}
                        style={{ zIndex: 200 }}
                        autoCapitalize="characters"
                        editable
                      />
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          marginTop: 10,
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={imgInfoIcon}
                          alt="info-icon"
                          className={`${styles.infoIcon}`}
                        />
                        <p className={`${styles.labelText} ml-1 mb-0`}>
                          {changeOrderStrings.SHORT_TEXT}
                          {' '}
                          <span className={`${styles.nameText} ml-0 pl-0`}>
                            {opDesc.slice(0, 40).toUpperCase()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ContentWithBreadcrumb>
              {
                successModal
                  && (
                    <ButtonModal
                      noOfButtons={2}
                      isSuccess
                      onButtonPress={{
                        firstButtonPress: backToWorkOrder,
                        secondButtonPress: goToOperation,
                      }}
                      data={opSuccessModalData}
                    />
                  )
              }
              {
                failureModal
                  && (
                    <ButtonModal
                      noOfButtons={1}
                      isError
                      onButtonPress={onOkClick}
                      data={opFailureModalData}
                    />
                  )
              }
            </>
          )

      }

    </>
  );
}

ChangeOrder.propTypes = {
  addBreadCrumb: PropTypes.func,
  deleteBreadCrumb: PropTypes.func,
  global: PropTypes.shape({
    globalSearchOrder: PropTypes.shape({
      mnWrkCtrId: PropTypes.string,
      operations: PropTypes.arrayOf(PropTypes.shape({})),
      orderId: PropTypes.string,
      shortDescription: PropTypes.string,
      longText: PropTypes.string,
    }),
    plantId: PropTypes.number,
    userData: PropTypes.shape({
      mail: PropTypes.string,
    }),
  }),
  hideLoader: PropTypes.func,
  // resetBreadCrumbs: PropTypes.func,
  showLoader: PropTypes.func,
  updateBreadCrumb: PropTypes.func,
  updateGlobalSearchOrder: PropTypes.func,
  updateSelectedPage: PropTypes.func,
};

ChangeOrder.defaultProps = {
  addBreadCrumb: () => { },
  deleteBreadCrumb: () => { },
  global: {},
  updateBreadCrumb: () => { },
  hideLoader: () => { },
  // resetBreadCrumbs: () => { },
  showLoader: () => { },
  updateGlobalSearchOrder: () => { },
  updateSelectedPage: () => { },
};
