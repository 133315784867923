import { HttpGet } from '../../../services/api-services';
import { getURL } from '../../../Helpers/common-helpers';
import {
  GET_ORDER_BY_WEEK_SCHEDULE,
  GET_FOLLOW_UP_BACKLOG,
  GET_ORDERS_CREATED_BY_ME,
  GET_ORDER_BY_WC,
  GET_FACIL_WC,
} from '../../../constants/endpoints';

export const mountLocalWork = (userData, wo, operation, orderData) => {
  let attachments = [...operation?.prtDocuments];
  if (orderData?.imageUrl) attachments = [...attachments, ...orderData?.imageUrl];
  return {
    displayName: userData.displayName,
    orderId: wo.orderId,
    orderType: wo.orderType,
    activityType: wo.activityType,
    mnWrkCtrId: wo.mnWrkCtrId,
    basicStartDate: wo.basicStartDate,
    basicFinishDate: wo.basicFinishDate,
    priority: wo.priority,
    peopleAssigned: wo.personnelInfo?.length,
    sysStatus: wo.systemStatus,
    userStatus: wo.userStatus,
    funLoc: wo.funLoc,
    wlongText:
      wo.shortDescription.length > 40 ? wo.longText : wo.shortDescription,
    funLocDescription: wo.funLocDescription,
    ...operation,
    searchOrder: orderData,
    attachments,
  };
};

export const sortArrayByKey = (array, arrayKeys) => {
  let sorted = array;

  arrayKeys.forEach((key) => {
    sorted = sorted.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    });
  });

  return sorted;
};

export const getRevisions = (array) => {
  const revisions = array.reduce(
    (result, item) => ({
      ...result,
      [item.revision]: [...(result[item.revision] || []), item],
    }),
    {},
  );
  return revisions;
};

export const getCreatedBy = (array) => {
  const revisions = array.reduce(
    (result, item) => ({
      ...result,
      [item.createdBy]: [...(result[item.createdBy] || []), item],
    }),
    {},
  );
  return revisions;
};
export const getOrdersByWc = async (params) => {
  const apiURL = getURL(GET_ORDER_BY_WC);
  try {
    const response = await HttpGet(apiURL, params);

    let safeData = [];
    if (Array.isArray(response.data.message.data)) {
      safeData = response.data.message.data;
    }
    return safeData;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const getFacilWorkOrders = async (params, workCenters) => {
  try {
    const ordersByWc = [];
    for (let i = 0; i < workCenters.length; i++) {
      try {
        const orders = getOrdersByWc({
          plant: params.plant,
          wrkCenter: workCenters[i].workCenter,
        });
        ordersByWc.push(orders);
      } catch (e) {
        console.log(e);
      }
    }
    return await Promise.all(ordersByWc).filter((item) => item !== undefined);
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const getWeekScheduleResults = async (params) => {
  try {
    const apiURL = getURL(GET_ORDER_BY_WEEK_SCHEDULE);
    const response = await HttpGet(apiURL, params);
    let safeData = [];
    if (Array.isArray(response.data.message.data)) {
      safeData = response.data.message.data;
    }
    return safeData;
    // }
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const getFacilWrkCntr = async (params) => {
  const apiURL = getURL(GET_FACIL_WC);
  try {
    const response = await HttpGet(apiURL, params);
    let safeData = [];
    if (Array.isArray(response.data.message.data)) {
      safeData = response.data.message.data;
    }
    return safeData;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const getBacklogOrders = async (params) => {
  const apiURL = getURL(GET_FOLLOW_UP_BACKLOG);
  try {
    const response = await HttpGet(apiURL, params);

    let safeData = [];
    if (Array.isArray(response.data.message.data)) {
      safeData = response.data.message.data;
    }
    return safeData;
  } catch (err) {
    throw new Error(err);
  }
};

export const getOrdersCreatedByMe = async (params) => {
  const apiURL = getURL(GET_ORDERS_CREATED_BY_ME);
  try {
    const response = await HttpGet(apiURL, params);

    let safeData = [];
    if (Array.isArray(response.data.message.data)) {
      safeData = response.data.message.data;
    }
    return safeData;
  } catch (err) {
    throw new Error(err);
  }
};
