import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import './sub-header.styles.css';
import open from '../../../assets/images/blue-plus.png';
import close from '../../../assets/images/subtract.png';
import styles from './sub-header-with-border.module.scss';

export default function SubHeaderBorder({
  icon, children, headerContent, headerDescription, index, toggleSubHeader,
}) {
  const [headerOpen, setHeaderOpen] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const onSubHeaderClick = () => {
    setIsMounted(!isMounted);
    setHeaderOpen(!headerOpen);
    toggleSubHeader(index);
  };

  const mountedStyle = {
    animation: 'inAnimation 250ms ease-in',
  };
  const unmountedStyle = {
    animation: 'outAnimation 270ms ease-out',
    animationDelay: 1,
  };

  return (
    <>
      <div
        className={`${headerOpen ? styles.subheaderContainer : styles.subheaderContainer1} shadow-sm`}
        role="presentation"
      >
        <div className={`${styles.headerCol}`} style={{ width: '70%' }}>
          {icon
            ? (
              <div className={`${styles.headerIconDiv}`}>
                <img alt="header-icon" src={icon} height={14} width={14} />
              </div>
            )
            : (
              <div className="ml-2" />
            )}
          <div className={`${styles.subHeaderContent} `}>{headerContent}</div>
          <div>
            {headerDescription}
          </div>
        </div>
        <div className={`${styles.arrowDiv}`} style={{ width: '30%' }} onClick={onSubHeaderClick} onKeyDown={(e) => { if (e.keyCode === 13) onSubHeaderClick(); }} role="presentation">
          {headerOpen ? (
            <img alt="up-arrow" src={close} className={`${styles.arrowIcon}`} />
          ) : (
            <img alt="down-arrow" src={open} className={`${styles.arrowIcon}`} />
          )}
        </div>
      </div>
      {headerOpen && (
        <div
          className={`${styles.childrenStyle}`}
          style={isMounted ? mountedStyle : unmountedStyle}
          onAnimationEnd={() => {
            if (!isMounted) setHeaderOpen(false);
          }}
        >
          {children}
        </div>
      )}
    </>
  );
}
SubHeaderBorder.propTypes = {
  children: PropTypes.element,
  headerContent: PropTypes.string,
  icon: PropTypes.element,
  index: PropTypes.number,
  headerDescription: PropTypes.element,
  toggleSubHeader: PropTypes.func,
};
SubHeaderBorder.defaultProps = {
  children: '',
  headerContent: '',
  icon: '',
  index: null,
  headerDescription: null,
  toggleSubHeader: () => { },
};
