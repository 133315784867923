import { connect } from 'react-redux';

import CriticalInspection from './critical-inspection.component';
import {
  showLoader,
  hideLoader,
  addBreadCrumb,
} from '../../../redux/actions/global.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CriticalInspection);
