import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import styles from './reserve-material-tile.styles';
import {
  reserveMaterialTileStrings,
  createWorkOrderStrings,
} from '../../../constants/strings';
import imgDelete from '../../../assets/images/trash_icon.png';
import { appColors } from '../../../constants/colors';
import imgGo from '../../../assets/images/go@2x.png';
import imgFailure from '../../../assets/images/failure@2x.png';
import imgSuccess from '../../../assets/images/success.png';
import imgGoSelected from '../../../assets/images/go-selected@2x.png';
import imgSearchIcon from '../../../assets/images/search-icon@2x.png';
import MaterialDetailsModal from '../material-details-modal';
import CustomTextInput from '../custom-textinput/custom-textinput.component';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export default function ReserveMaterialTile({
  deleteItem, resetLineItem, index, onChangeValue, onMaterialSearch, item, showPCModal, isEditOrder, editable,
}) {
  const [showMoreForPOText, setShowMoreForPOText] = useState(false);
  const [showMoreForMemoText, setShowMoreForMemoText] = useState(false);
  const [showMaterialDetails, setShowMaterialDetails] = useState(false);

  const onDeletePress = () => {
    deleteItem(index - 1);
  };
  const resetLineItem1 = () => {
    resetLineItem(index - 1);
  };
  const onTextInputChange = (value, label) => {
    const details = {
      index: index - 1,
      key: label,
      value,
    };
    onChangeValue(details);
  };
  const onMoreClick = () => {
    setShowMaterialDetails(true);
  };

  const handleReflowPO = (rleState) => {
    const { clamped } = rleState;
    if (clamped) setShowMoreForPOText(true);
    else setShowMoreForPOText(false);
  };

  const handleReflowMemo = (rleState) => {
    const { clamped } = rleState;
    if (clamped) setShowMoreForMemoText(true);
    else setShowMoreForMemoText(false);
  };

  const onMaterialClick = async () => {
    await onMaterialSearch(
      index - 1,
      item[createWorkOrderStrings.MATERIAL],
    );
  };

  const noAction = () => { };
  const showPCModalFor = () => {
    showPCModal(index);
  };
  const closeDetailModal = () => {
    setShowMaterialDetails(false);
  };
  if (showMaterialDetails) {
    return (
      <MaterialDetailsModal
        title={reserveMaterialTileStrings.MATERIAL_DETAIL}
        data={{
          [reserveMaterialTileStrings.MATERIAL_PO_LONG]:
            item[createWorkOrderStrings.PO_TEXT],
          [createWorkOrderStrings.MATERIAL_MEMO]:
            item[createWorkOrderStrings.MATERIAL_MEMO],
        }}
        closeModal={closeDetailModal}
      />
    );
  }
  function renderView() {
    if (item.isMaterialFetched && !item.isMaterialFetchFail) {
      return (
        <div style={styles.goButton}>
          <img
            src={imgSuccess}
            alt="success"
            style={styles.imgIcon}
          />
        </div>
      );
    }
    if (item.errorSearchBar) {
      return (
        <div style={styles.goButton}>
          <img
            src={imgFailure}
            alt="failure"
            style={styles.imgIcon}
          />
        </div>
      );
    }
    if (item[createWorkOrderStrings.MATERIAL] === '') {
      return (
        <div style={styles.goButton}>
          <img
            src={imgGo}
            alt="go-disabled"
            style={styles.imgIcon}
          />
        </div>
      );
    }
    return (
      <div
        style={{ ...styles.goButton, cursor: 'pointer' }}
        onClick={onMaterialClick}
        role="presentation"
      >
        <img
          src={imgGoSelected}
          alt="go"
          style={styles.imgIcon}
        />
      </div>
    );
  }
  return (
    item.isMaterial !== 'D'
      ? (
        <div style={styles.lineItemTileContainer} className="border-bottom">
          <div style={styles.titleRow} className="row gx-0">
            <div style={styles.index}>
              <p
                style={{
                  ...styles.indexText,
                  color: item.isMaterialFetchFail
                    ? appColors.TEXT_ERROR_MODERATE
                    : appColors.TEXT_DARK_GRAYISH_BLUE,
                }}
              >
                {index <= 9 ? `0 ${index}` : index}
              </p>
              <div
                style={{
                  width: '85%',
                  marginLeft: 20,
                  ...styles.poText,
                  color: item.isMaterialFetchFail
                    ? appColors.TEXT_ERROR_MODERATE
                    : appColors.TEXT_DARK_GRAYISH_BLUE,
                }}
              >
                <ResponsiveEllipsis
                  text={
                    item.isMaterialFetchFail
                      ? item.failureMessage
                      : item[createWorkOrderStrings.PO_TEXT] || '-'
                  }
                  maxLine="1"
                  ellipsis=""
                  trimRight
                  basedOn="letters"
                  onReflow={handleReflowPO}
                />
              </div>
              {showMoreForPOText && (
                <div style={styles.moreButton} onClick={onMoreClick} role="presentation">
                  <p style={styles.moreButtonText}>
                    {reserveMaterialTileStrings.MORE}
                  </p>
                </div>
              )}
            </div>
            {(!isEditOrder || item.isMaterial === 'I' || editable) && (
              <div style={styles.delete} onClick={onDeletePress} role="presentation">
                <img src={imgDelete} alt="delete" style={styles.imgDelete} />
              </div>
            )}
          </div>
          <div style={styles.data}>
            <div style={styles.firstRow} className="row gx-0">
              <div style={styles.detailsContainer} className="col-lg-4">
                <div style={styles.searchContainer}>
                  <CustomTextInput
                    type="number"
                    editable={!isEditOrder || item.isMaterial === 'I'}
                    label={createWorkOrderStrings.MATERIAL}
                    value={item[createWorkOrderStrings.MATERIAL]}
                    onValueChange={(e) => onTextInputChange(e.value, createWorkOrderStrings.MATERIAL)}
                    onClearText={resetLineItem1}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        if (item[createWorkOrderStrings.MATERIAL] !== '') {
                          onMaterialClick();
                        } else {
                          noAction();
                        }
                      }
                    }}
                    rows={1}
                  />
                  {(!isEditOrder || item.isMaterial === 'I') && (
                    <>
                      <div
                        onClick={showPCModalFor}
                        style={styles.barcodeSmallButton}
                        role="presentation"
                      >
                        <img
                          src={imgSearchIcon}
                          alt="search-icon"
                          style={{ height: 20, width: 20 }}
                        />
                      </div>
                      {/* {item.isMaterialFetched
                      && !item.isMaterialFetchFail
                        ? (
                          <div style={styles.goButton}>
                            <img
                              src={imgSuccess}
                              alt="success"
                              style={styles.imgIcon}
                            />
                          </div>
                        )
                        : item.errorSearchBar ? (
                          <div style={styles.goButton}>
                            <img
                              src={imgFailure}
                              alt="failure"
                              style={styles.imgIcon}
                            />
                          </div>
                        ) : item[createWorkOrderStrings.MATERIAL] === '' ? (
                          <div style={styles.goButton}>
                            <img
                              src={imgGo}
                              alt="go-disabled"
                              style={styles.imgIcon}
                            />
                          </div>
                        ) : (
                          <div
                            style={{ ...styles.goButton, cursor: 'pointer' }}
                            onClick={onMaterialClick}
                            role="presentation"
                          >
                            <img
                              src={imgGoSelected}
                              alt="go"
                              style={styles.imgIcon}
                            />
                          </div>
                        )} */}
                        {renderView()}
                    </>
                  )}
                </div>
              </div>
              <div style={styles.memoView} className="col-lg-8">
                <p style={styles.labelText}>
                  {createWorkOrderStrings.MATERIAL_MEMO}
                </p>
                <div style={styles.memoContainer}>
                  <div
                    style={{
                      width: 'inherit',
                      marginLeft: 20,
                      ...styles.poText,
                      color: appColors.TEXT_DARK_GRAYISH_BLUE,
                    }}
                  >
                    <ResponsiveEllipsis
                      text={
                        item.isMaterialFetchFail
                          ? ''
                          : item[createWorkOrderStrings.MATERIAL_MEMO]
                          || '-'
                      }
                      maxLine="1"
                      ellipsis=""
                      trimRight
                      basedOn="letters"
                      onReflow={handleReflowMemo}
                    />
                  </div>
                  {showMoreForMemoText && (
                    <div style={styles.moreButton} onClick={onMoreClick} role="presentation">
                      <p style={{ ...styles.moreButtonText }}> ...More </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div style={styles.secondRow} className="row gx-0">
              <div
                style={styles.quantityContainer}
                className="col-lg-2 col-md-4 col-sm-6"
              >
                <CustomTextInput
                  editable={false}
                  label={createWorkOrderStrings.BIN_LOCATION}
                  value={item[createWorkOrderStrings.BIN_LOCATION]}
                  rows={1}
                />
              </div>
              <div
                style={styles.quantityContainer}
                className="col-lg-2 col-md-4 col-sm-6"
              >
                <CustomTextInput
                  editable={false}
                  label={createWorkOrderStrings.UNIT_OF_MEASURE}
                  value={item[createWorkOrderStrings.UNIT_OF_MEASURE]}
                  rows={1}
                />
              </div>
              <div
                style={styles.quantityContainer}
                className="col-lg-2 col-md-4 col-sm-6"
              >
                <CustomTextInput
                  editable={false}
                  label={createWorkOrderStrings.UNRES_QTY}
                  value={item[createWorkOrderStrings.UNRES_QTY]?.toString()}
                  rows={1}
                />
              </div>
              <div style={styles.quantityContainer} className="col-lg-2 col-md-4">
                <CustomTextInput
                  editable={
                    editable
                    && !item.isMaterialFetchFail
                    && item.isMaterialFetched
                  }
                  label={createWorkOrderStrings.QTY_RQD}
                  value={item[createWorkOrderStrings.QTY_RQD]}
                  rows={1}
                  type="number"
                  onValueChange={(e) => onTextInputChange(e.value, createWorkOrderStrings.QTY_RQD)}
                />
              </div>
              <div style={styles.quantityContainer} className="col-lg-2">
                <CustomTextInput
                  editable={
                    editable
                    && !item.isMaterialFetchFail
                    && item.isMaterialFetched
                  }
                  label={createWorkOrderStrings.UNLOAD_POINT}
                  value={item[createWorkOrderStrings.UNLOAD_POINT]}
                  rows={1}
                  type="text"
                  autoCapitalize={reserveMaterialTileStrings.CHARACTERS}
                  onValueChange={(e) => onTextInputChange(
                    e.value,
                    createWorkOrderStrings.UNLOAD_POINT,
                  )}
                />
              </div>
              <div style={styles.quantityContainer} className="col-lg-2">
                <CustomTextInput
                  editable={
                    editable
                    && !item.isMaterialFetchFail
                    && item.isMaterialFetched
                  }
                  label={createWorkOrderStrings.RECIPIENT}
                  value={item[createWorkOrderStrings.RECIPIENT]}
                  rows={1}
                  description={reserveMaterialTileStrings.OPTIONAL}
                  autoCapitalize={reserveMaterialTileStrings.CHARACTERS}
                  type="text"
                  onValueChange={(e) => onTextInputChange(e.value, createWorkOrderStrings.RECIPIENT)}
                />
              </div>
            </div>
          </div>
        </div>
      )
      : ''
  );
}

ReserveMaterialTile.propTypes = {
  deleteItem: PropTypes.func,
  editable: PropTypes.bool,
  index: PropTypes.number,
  isEditOrder: PropTypes.bool,
  item: PropTypes.shape({
    errorSearchBar: PropTypes.bool,
    failureMessage: PropTypes.string,
    isMaterial: PropTypes.string,
    isMaterialFetchFail: PropTypes.bool,
    isMaterialFetched: PropTypes.bool,
  }),
  onChangeValue: PropTypes.func,
  onMaterialSearch: PropTypes.func,
  resetLineItem: PropTypes.func,
  showPCModal: PropTypes.func,
  isMaterial: PropTypes.func,
};

ReserveMaterialTile.defaultProps = {
  deleteItem: () => { },
  editable: false,
  index: 0,
  isEditOrder: false,
  item: {},
  onChangeValue: () => { },
  onMaterialSearch: () => { },
  resetLineItem: () => { },
  showPCModal: () => { },
  isMaterial: () => { },
};
