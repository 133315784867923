import {
  sortArrayByKey,
  getRevisions,
  getCreatedBy,
} from '../../pages/week-schedule/week-schedule.helper';

export const groupScheduleData = (data) => {
  const sortedData = sortArrayByKey(data, ['revision']);
  const groupedRevisions = getRevisions(sortedData);
  return Object.entries(groupedRevisions);
};

export const groupScheduleDataByCreatedBy = (data) => {
  const sortedData = sortArrayByKey(data, ['createdBy']);
  const groupedRevisions = getCreatedBy(sortedData);
  return Object.entries(groupedRevisions);
};

// export const unifyQueries = (data) => {
//   //   let singleQuery = data[0];
//   //   singleQuery.data
//   // //       newData = {...query[0]};
//   // //       newData.data = [];
//   // //       query.map(q => (newData.data = [...newData.data, ...q.data]));
//   // //
//   //     return singleQuery;
// };
