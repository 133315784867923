import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { showLoader, hideLoader, updateLastUpdatedTime } from '../../../redux/actions/global.actions';
import { getLastConfirmatinosResults } from '../../pages/work-confirm/work-confirm.helper';
import { groupConfirmationData } from './last-confirmations.helper';
import ConfirmationList from '../confirmation-list';
import EmptyList from '../empty-list/empty-list.component';
import { loaderMessages, lastConfStrings, queryKeyStrings } from '../../../constants/strings';

export default function LastConfirmations() {
  const globalReducer = useSelector((state) => state.globalReducer);
  const { plantId, userPersonalData, lastUpdatedDate } = globalReducer;
  const dispatch = useDispatch();
  const {
    data, isSuccess, dataUpdatedAt, errorUpdatedAt,
  } = useQuery({
    onSuccess: () => dispatch(hideLoader()),
    onError: () => dispatch(hideLoader()),
    queryKey: `${queryKeyStrings.LASTCONFIRMATIONSV1}-${plantId}`,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      dispatch(showLoader(loaderMessages.LAST_CONFIRMATIONS));
      const response = await getLastConfirmatinosResults(plantId, userPersonalData.personalNo);
      dispatch(hideLoader());
      return response;
    },
    retry: 0,
    // eslint-disable-next-line
    select: ({ data }) => groupConfirmationData(Array.isArray(data.message.data) ? data.message.data : []),
    staleTime: 1000 * 60 * 15,
    cacheTime: 1000 * 60 * 10,
  });

  useEffect(() => {
    const dates = [dataUpdatedAt, errorUpdatedAt, lastUpdatedDate];
    dispatch(updateLastUpdatedTime(Math.max.apply(null, dates)));
    // eslint-disable-next-line
  }, [dataUpdatedAt, errorUpdatedAt])

  return (
    <div style={{ height: 'calc(100% - 100px)' }}>
      {isSuccess && data.length !== 0 ? (
        <ConfirmationList orders={data} />
      ) : (
        <EmptyList
          message={lastConfStrings.NO_CONF_FOUND}
        />
      )}
    </div>
  );
}
