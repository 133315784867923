const CONFIRMATION = {
  operationFields: [
    { label: 'Order Type', size: '3', key: 'orderType' },
    { label: 'Activity Type', size: '3', key: 'activityType' },
    { label: 'Main Work Center', size: '3', key: 'mnWrkCtrId' },
    { label: 'Basic Start Date', size: '3', key: 'basicStartDate' },
    { label: 'Basic Finish Date', size: '3', key: 'basicFinishDate' },
    { label: 'Priority', size: '3', key: 'priority' },
    { label: '# of People Assigned', size: '3', key: 'peopleAssigned' },
  ],
  modalFields: [
    { label: 'Order Type', size: '6', key: 'orderType' },
    { label: 'Activity Type', size: '6', key: 'activityType' },
    { label: 'Main Work Center', size: '6', key: 'mnWrkCtrId' },
    { label: 'Basic Start Date', size: '6', key: 'basicStartDate' },
    { label: 'Basic Finish Date', size: '6', key: 'basicFinishDate' },
    { label: 'Priority', size: '6', key: 'priority' },
    { label: '# of People Assigned', size: '6', key: 'peopleAssigned' },
    { label: 'System Status', size: '6', key: 'sysStatus' },
    { label: 'User Status', size: '6', key: 'userStatus' },
    { label: 'Functional Location ID', size: '6', key: 'funLoc' },
    {
      label: 'Functional Location Description',
      size: '6',
      key: 'funLocDescription',
    },
    { label: 'Work Order Long Text', size: '12', key: 'wlongText' },
    { label: 'Operational Work Center', size: '6', key: 'workCenter' },
    { label: 'Operational Short Text', size: '6', key: 'description' },
    { label: 'Operational Long Text', size: '12', key: 'longText' },
  ],
  moreFields: [
    { label: 'System Status', size: '3', key: 'sysStatus' },
    { label: 'User Status', size: '3', key: 'userStatus' },
    { label: 'Functional Location ID', size: '3', key: 'funLoc' },
    {
      label: 'Functional Location Description',
      size: '3',
      key: 'funLocDescription',
    },
    { label: 'Work Order Long Text', size: '12', key: 'longText' },
  ],
  CONFIRMATION_DETAILS: {
    title: 'Work Confirmation Details',
    type: {
      label: 'Confirmation Type',
      types: ['Partial Confirmation (PCNF)', 'Final Confirmation (CNF)'],
    },
    quick_remarks: {
      label: 'Quick Remarks',
      remarks: [
        'Planner Action Required',
        'Facilitator Action Required',
        'Ok - No Action Required',
      ],
    },
    confirmation_label: 'Confirmation Text',
    noConfirmationsText: 'No Confirmation History',
  },
};
export default CONFIRMATION;
