import PropTypes from 'prop-types';
import React from 'react';
import styles from './custom-icon-button.module.scss';

export default function CustomIconButton({
  dataId,
  children,
  disabled,
  onPress,
  customClass,
  customStyle,
}) {
  return (
    <button
      data-testid={dataId}
      className={`${styles.customIconButton} ${customClass || ''}`}
      style={customStyle}
      onClick={onPress}
      disabled={disabled}
      type="button"
    >
      {children}
    </button>
  );
}

CustomIconButton.propTypes = {
  children: PropTypes.element,
  customClass: PropTypes.string,
  customStyle: PropTypes.shape({}),
  dataId: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
};

CustomIconButton.defaultProps = {
  children: null,
  customClass: '',
  customStyle: {},
  dataId: '',
  disabled: false,
  onPress: () => { },
};
