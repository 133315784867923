import { connect } from 'react-redux';

import AssetView from './asset-view.component';
import {
  showLoader,
  hideLoader,
  addBreadCrumb,
  updateSelectedPage,
  resetBreadCrumbs,
  deleteBreadCrumb,
} from '../../../redux/actions/global.actions';
import {
  updateEquipment,
  updateFlocValue,
} from '../work-order/work-order.actions';

const mapStateToProps = (state) => ({
  global: state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  deleteBreadCrumb: (value) => dispatch(deleteBreadCrumb(value)),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  hideLoader: () => dispatch(hideLoader()),
  resetBreadCrumbs: () => dispatch(resetBreadCrumbs()),
  updateFlocValue: (data) => dispatch(updateFlocValue(data)),
  updateEquipment: (data) => dispatch(updateEquipment(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetView);
