import { createWorkOrderStrings } from '../../../constants/strings';

const staticItemsData = {
  [createWorkOrderStrings.ORDER_TYPE]: [
    {
      name: 'ZM01 - BREAKDOWN ORDER',
      value: 'ZM01',
      items: [
        {
          name: '130 - PRODUCTION DOWN',
          value: '130',
        },
        {
          name: '131 - PRODUCTION REDUCED CAPACITY',
          value: '131',
        },
        {
          name: '104 - EQUIPMENT DOWN',
          value: '104',
        },
        {
          name: '105 - EQUIPMENT REDUCED CAPACITY',
          value: '105',
        },
      ],
    },
    {
      name: 'ZM07 - CORRECTIVE ORDER',
      value: 'ZM07',
      items: [
        // {
        //     name: "152 - CONSUMABLES",
        //     value: "152",
        // },
        {
          name: '106 - IMPROMPTU FROM MAINT',
          value: '106',
        },
        {
          name: '107 - PMT IMPROMPTU FROM OPS',
          value: '107',
        },
        // {
        //     name: "100 - BREAKDOWN MAINT FOLLOW UP",
        //     value: "100",
        // },
        // {
        //     name: "113 - MAINT INSPEC FOLLOW UP",
        //     value: "113",
        // },
        // {
        //     name: "121 - FOLLOW UP FROM OPS PAPER INSP FOR MAINT ACTION",
        //     value: "121",
        // },
        // {
        //     name: "122 - FOLLOW UP FROM OPS PAPER INSP FOR NON-MAINT ACTION",
        //     value: "122",
        // },
        // {
        //     name: "128 - PREDICTIVE FOLLOW UP",
        //     value: "128",
        // },
        // {
        //     name: "129 - PREVENTIVE FOLLOW UP",
        //     value: "129",
        // },
        // {
        //     name: "149 - IMPROVEMENT FOLLOW UP",
        //     value: "149",
        // },
        // {
        //     name: "150 - REFURBISHMENT FOLLOW UP",
        //     value: "150",
        // },
        // {
        //     name: "160 - PREDICTIVE SMDO",
        //     value: "160",
        // },
        // {
        //     name: "135 - RE-INSPECTION",
        //     value: "135",
        // },
      ],
    },
    {
      name: 'ZM08 - IMPROVEMENT ORDER',
      value: 'ZM08',
      items: [
        {
          name: '151 - ENERGY IMPROVEMENT',
          value: '151',
        },
        {
          name: '102 - ENGINEERING IMPROVEMENT',
          value: '102',
        },
        {
          name: '103 - ENVIRONMENTAL IMPROVEMENT',
          value: '103',
        },
        {
          name: '114 - MAINTENANCE IMPROVEMENT',
          value: '114',
        },
        {
          name: '115 - MANAGEMENT OF PROCESS RISK IMPROVEMENT',
          value: '115',
        },
        {
          name: '123 - OPERATIONAL IMPROVEMENT',
          value: '123',
        },
        {
          name: '140 - SAFETY IMPROVEMENT',
          value: '140',
        },
      ],
    },
    {
      name: 'ZM09 - REFURBISHMENT ORDER',
      value: 'ZM09',
      items: [
        // {
        //     name: "152 - CONSUMABLES - LOW COST/BUILD MATERIAL REQ'TS",
        //     value: "152",
        // },
        {
          name: '136 - REPAIR BY EXTERNAL RESOURCES',
          value: '136',
        },
        {
          name: '137 - REPAIR BY INTERNAL RESOURCES (GERDAU)',
          value: '137',
        },
        {
          name: '138 - REWORK BY EXTERNAL RESOURCES',
          value: '138',
        },
        {
          name: '139 - REWORK BY INTERNAL RESOURCES (GERDAU)',
          value: '139',
        },
      ],
    },
    {
      name: 'ZM10 - OTHER WORK ROUTINES',
      value: 'ZM10',
      items: [
        {
          name: '161 - ACCIDENT CDM',
          value: '161',
        },
        {
          name: '125 - IMPROVEMENT',
          value: '125',
        },
        {
          name: '158 - MISCELLANEOUS ACTIVITY',
          value: '158',
        },
        {
          name: '155 - PLANNED CORRECTIVE',
          value: '155',
        },
        {
          name: '156 - PREDICTIVE',
          value: '156',
        },
        {
          name: '157 - PREVENTIVE',
          value: '157',
        },
        {
          name: '126 - PERIODIC',
          value: '126',
        },
        {
          name: '127 - UNPLANNED',
          value: '127',
        },
      ],
    },
  ],
  [createWorkOrderStrings.FUNC_LOC_LEV_2]: [
    {
      name: 'SY1 - SCRAP YARD',
      value: 'SY1 - SCRAP YARD',
      items: [
        {
          name: 'func3 - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
          items: [
            {
              name: 'Func4',
              value: 'SCRH - SCRAP HANDLING',
              items: [
                {
                  name: 'Func5',
                  value: 'SCRH - SCRAP HANDLING',
                  items: [
                    {
                      name: 'Func6',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                  ],
                },
                {
                  name: 'CRAN - CRANES',
                  value: 'CRAN - CRANES',
                  items: [
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                  ],
                },
                {
                  name: 'BLDG - BUILDINGS AND GROUNDS',
                  value: 'BLDG - BUILDINGS AND GROUNDS',
                },
                {
                  name: 'SCRH - SCRAP HANDLING',
                  value: 'SCRH - SCRAP HANDLING',
                },
                {
                  name: 'CRAN - CRANES',
                  value: 'CRAN - CRANES',
                },
                {
                  name: 'BLDG - BUILDINGS AND GROUNDS',
                  value: 'BLDG - BUILDINGS AND GROUNDS',
                },
                {
                  name: 'SCRH - SCRAP HANDLING',
                  value: 'SCRH - SCRAP HANDLING',
                },
                {
                  name: 'CRAN - CRANES',
                  value: 'CRAN - CRANES',
                },
                {
                  name: 'BLDG - BUILDINGS AND GROUNDS',
                  value: 'BLDG - BUILDINGS AND GROUNDS',
                },
              ],
            },
            {
              name: 'CRAN - CRANES',
              value: 'CRAN - CRANES',
              items: [
                {
                  name: 'Func5',
                  value: 'SCRH - SCRAP HANDLING',
                  items: [
                    {
                      name: 'Func6',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                  ],
                },
                {
                  name: 'CRAN - CRANES',
                  value: 'CRAN - CRANES',
                  items: [
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                    {
                      name: 'SCRH - SCRAP HANDLING',
                      value: 'SCRH - SCRAP HANDLING',
                    },
                    {
                      name: 'CRAN - CRANES',
                      value: 'CRAN - CRANES',
                    },
                    {
                      name: 'BLDG - BUILDINGS AND GROUNDS',
                      value: 'BLDG - BUILDINGS AND GROUNDS',
                    },
                  ],
                },
                {
                  name: 'BLDG - BUILDINGS AND GROUNDS',
                  value: 'BLDG - BUILDINGS AND GROUNDS',
                },
                {
                  name: 'SCRH - SCRAP HANDLING',
                  value: 'SCRH - SCRAP HANDLING',
                },
                {
                  name: 'CRAN - CRANES',
                  value: 'CRAN - CRANES',
                },
                {
                  name: 'BLDG - BUILDINGS AND GROUNDS',
                  value: 'BLDG - BUILDINGS AND GROUNDS',
                },
                {
                  name: 'SCRH - SCRAP HANDLING',
                  value: 'SCRH - SCRAP HANDLING',
                },
                {
                  name: 'CRAN - CRANES',
                  value: 'CRAN - CRANES',
                },
                {
                  name: 'BLDG - BUILDINGS AND GROUNDS',
                  value: 'BLDG - BUILDINGS AND GROUNDS',
                },
              ],
            },
            {
              name: 'BLDG - BUILDINGS AND GROUNDS',
              value: 'BLDG - BUILDINGS AND GROUNDS',
            },
            {
              name: 'SCRH - SCRAP HANDLING',
              value: 'SCRH - SCRAP HANDLING',
            },
            {
              name: 'CRAN - CRANES',
              value: 'CRAN - CRANES',
            },
            {
              name: 'BLDG - BUILDINGS AND GROUNDS',
              value: 'BLDG - BUILDINGS AND GROUNDS',
            },
            {
              name: 'SCRH - SCRAP HANDLING',
              value: 'SCRH - SCRAP HANDLING',
            },
            {
              name: 'CRAN - CRANES',
              value: 'CRAN - CRANES',
            },
            {
              name: 'BLDG - BUILDINGS AND GROUNDS',
              value: 'BLDG - BUILDINGS AND GROUNDS',
            },
          ],
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'MS1 - MELT SHOP',
      value: 'MS1 - MELT SHOP',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'MM1 - MEDIUM SECTION MILL',
      value: 'MM1 - MEDIUM SECTION MILL',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'QA1 - QUALITY CONTROL',
      value: 'QA1 - QUALITY CONTROL',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'LG1 - LOGISTICS',
      value: 'LG1 - LOGISTICS',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'GP1 - GENERAL PLANT',
      value: 'GP1 - GENERAL PLANT',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
  ],
};

export default staticItemsData;
