import { createWorkOrderStrings } from '../../../constants/strings';

const staticEOItemsData = {
  [createWorkOrderStrings.ORDER_TYPE]: [
    {
      name: 'ZM01 - BREAKDOWN ORDER',
      value: 'ZM01',
      items: [
        {
          name: '130 - PRODUCTION DOWN',
          value: '130',
        },
        {
          name: '131 - PRODUCTION REDUCED CAPACITY',
          value: '131',
        },
        {
          name: '104 - EQUIPMENT DOWN',
          value: '104',
        },
        {
          name: '105 - EQUIPMENT REDUCED CAPACITY',
          value: '105',
        },
        {
          name: '161 - FAILURE DUE TO ACCIDENT',
          value: '161',
        },
      ],
    },
    {
      name: 'ZM02 - PREDICTIVE ORDER',
      value: 'ZM02',
      items: [
        {
          name: '110 - ISOLATION OIL',
          value: '110',
        },
        {
          name: '112 - LUBRICATION OIL',
          value: '112',
        },
        {
          name: '162 - MEASURABLE INSPECTION',
          value: '162',
        },
        {
          name: '118 - MOTOR ISOLATION',
          value: '118',
        },
        {
          name: '159 - NON DESTRUCTIVE TESTINGT',
          value: '159',
        },
        {
          name: '119 - OFFLINE MOTOR TESTING',
          value: '119',
        },
        {
          name: '120 - ONLINE MOTOR TESTING',
          value: '120',
        },
        {
          name: '124 - OSCILLATOR MONITORING',
          value: '124',
        },
        {
          name: '145 - THERMOGRAPHIC',
          value: '106',
        },
        {
          name: '146 - ULTRASOUND THICKNESS',
          value: '146',
        },
        {
          name: '147 - ULTRASOUND AIRBORNE',
          value: '147',
        },
        {
          name: '148 - VIBRATION',
          value: '148',
        },
      ],
    },
    {
      name: 'ZM03 - PREVENTIVE ORDER',
      value: 'ZM03',
      items: [
        {
          name: '108 - INSPECTION WITH PREPARATION',
          value: '108',
        },
        {
          name: '111 - LUBRICATION',
          value: '111',
        },
        {
          name: '132 - PROGRAMMED REPAIR OR REPLACEMENT',
          value: '132',
        },
        {
          name: '163 - TEST FUNCTIONAL',
          value: '163',
        },
      ],
    },
    {
      name: 'ZM04 - MAINTENANCE INSPECTION ORDER',
      value: 'ZM04',
      items: [
        {
          name: '109 - INSPECTOR CHECK LIST',
          value: '109',
        },
        {
          name: '117 - MOBILE EQUIPMENT INSPECTION BY MAINT',
          value: '117',
        },
        {
          name: '141 - SHIFT ROUNDS',
          value: '141',
        },
        {
          name: '143 - SPONSOR ROUNDS',
          value: '143',
        },
      ],
    },
    {
      name: 'ZM05 - OPERATIONAL INSPECTION ORDER',
      value: 'ZM05',
      items: [
        {
          name: '117 - MOBILE EQUIPMENT INSPECTION BY NON-MAINT',
          value: '117',
        },
        {
          name: '133 - REC',
          value: '133',
        },
        {
          name: '142 - HUT DOWN INSPECTION',
          value: '142',
        },
        {
          name: '144 - START UP INSPECTION',
          value: '144',
        },
      ],
    },
    {
      name: 'ZM06 - INTEGRATION ORDER',
      value: 'ZM06',
      items: [
        {
          name: '101 - COMMON',
          value: '101',
        },
        {
          name: '134 - REGULATORY STANDARDS',
          value: '134',
        },
      ],
    },
    {
      name: 'ZM07 - CORRECTIVE ORDER',
      value: 'ZM07',
      items: [
        {
          name: '152 - CONSUMABLES',
          value: '152',
        },
        {
          name: '106 - IMPROMPTU FROM MAINT',
          value: '106',
        },
        {
          name: '107 - PMT IMPROMPTU FROM OPS',
          value: '107',
        },
        {
          name: '100 - BREAKDOWN MAINT FOLLOW UP',
          value: '100',
        },
        {
          name: '113 - MAINT INSPEC FOLLOW UP',
          value: '113',
        },
        {
          name: '121 - FOLLOW UP FROM OPS PAPER INSP FOR MAINT ACTION',
          value: '121',
        },
        {
          name: '122 - FOLLOW UP FROM OPS PAPER INSP FOR NON-MAINT ACTION',
          value: '122',
        },
        {
          name: '128 - PREDICTIVE FOLLOW UP',
          value: '128',
        },
        {
          name: '129 - PREVENTIVE FOLLOW UP',
          value: '129',
        },
        {
          name: '149 - IMPROVEMENT FOLLOW UP',
          value: '149',
        },
        {
          name: '150 - REFURBISHMENT FOLLOW UP',
          value: '150',
        },
        {
          name: '160 - PREDICTIVE SMDO',
          value: '160',
        },
        {
          name: '135 - RE-INSPECTION',
          value: '135',
        },
      ],
    },
    {
      name: 'ZM08 - IMPROVEMENT ORDER',
      value: 'ZM08',
      items: [
        {
          name: '151 - ENERGY IMPROVEMENT',
          value: '151',
        },
        {
          name: '102 - ENGINEERING IMPROVEMENT',
          value: '102',
        },
        {
          name: '103 - ENVIRONMENTAL IMPROVEMENT',
          value: '103',
        },
        {
          name: '114 - MAINTENANCE IMPROVEMENT',
          value: '114',
        },
        {
          name: '115 - MANAGEMENT OF PROCESS RISK IMPROVEMENT',
          value: '115',
        },
        {
          name: '123 - OPERATIONAL IMPROVEMENT',
          value: '123',
        },
        {
          name: '140 - SAFETY IMPROVEMENT',
          value: '140',
        },
      ],
    },
    {
      name: 'ZM09 - REFURBISHMENT ORDER',
      value: 'ZM09',
      items: [
        // {
        //     name: "152 - CONSUMABLES - LOW COST/BUILD MATERIAL REQ'TS",
        //     value: "152",
        // },
        {
          name: '136 - REPAIR BY EXTERNAL RESOURCES',
          value: '136',
        },
        {
          name: '137 - REPAIR BY INTERNAL RESOURCES (GERDAU)',
          value: '137',
        },
        {
          name: '138 - REWORK BY EXTERNAL RESOURCES',
          value: '138',
        },
        {
          name: '139 - REWORK BY INTERNAL RESOURCES (GERDAU)',
          value: '139',
        },
      ],
    },
    {
      name: 'ZM10 - OTHER WORK ROUTINES',
      value: 'ZM10',
      items: [
        {
          name: '161 - ACCIDENT CDM',
          value: '161',
        },
        {
          name: '125 - IMPROVEMENT',
          value: '125',
        },
        {
          name: '158 - MISCELLANEOUS ACTIVITY',
          value: '158',
        },
        {
          name: '155 - PLANNED CORRECTIVE',
          value: '155',
        },
        {
          name: '156 - PREDICTIVE',
          value: '156',
        },
        {
          name: '157 - PREVENTIVE',
          value: '157',
        },
        {
          name: '126 - PERIODIC',
          value: '126',
        },
        {
          name: '127 - UNPLANNED',
          value: '127',
        },
      ],
    },
    {
      name: 'ZM11 - METEROLOGY W/OUT INSPECTION',
      value: 'ZM11',
      items: [
        {
          name: '101 - COMMON',
          value: '101',
        },
        {
          name: '134 - REGULATORY STANDARDS',
          value: '134',
        },
      ],
    },
    {
      name: 'ZM12 - METEROLOGY W/OUT INSPECTION',
      value: 'ZM12',
      items: [
        {
          name: "154 - WRITE-OFF STORES OBSOLETE MAT'L >365",
          value: '154',
        },
      ],
    },
  ],
  [createWorkOrderStrings.FUNC_LOC_LEV_2]: [
    {
      name: 'SY1 - SCRAP YARD',
      value: 'SY1 - SCRAP YARD',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'MS1 - MELT SHOP',
      value: 'MS1 - MELT SHOP',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'MM1 - MEDIUM SECTION MILL',
      value: 'MM1 - MEDIUM SECTION MILL',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'QA1 - QUALITY CONTROL',
      value: 'QA1 - QUALITY CONTROL',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'LG1 - LOGISTICS',
      value: 'LG1 - LOGISTICS',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
    {
      name: 'GP1 - GENERAL PLANT',
      value: 'GP1 - GENERAL PLANT',
      items: [
        {
          name: 'SCRH - SCRAP HANDLING',
          value: 'SCRH - SCRAP HANDLING',
        },
        {
          name: 'CRAN - CRANES',
          value: 'CRAN - CRANES',
        },
        {
          name: 'BLDG - BUILDINGS AND GROUNDS',
          value: 'BLDG - BUILDINGS AND GROUNDS',
        },
      ],
    },
  ],
};

export default staticEOItemsData;
