import { connect } from 'react-redux';

import WeekSchedule from './week-schedule.component';
import {
  showLoader,
  hideLoader,
  addBreadCrumb,
  resetBreadCrumbs,
  updateGlobalSearchOrder,
  updateSelectedPage,
  updateBreadCrumb,
} from '../../../redux/actions/global.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  resetBreadCrumbs: (value) => dispatch(resetBreadCrumbs(value)),
  updateGlobalSearchOrder: (value) => dispatch(updateGlobalSearchOrder(value)),
  hideLoader: () => dispatch(hideLoader()),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  updateBreadCrumb: (value) => dispatch(updateBreadCrumb(value)),

});

export default connect(mapStateToProps, mapDispatchToProps)(WeekSchedule);
