import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import styles from './custom-modal.module.scss';
import Close from '../../../assets/svgs/icon_close';
import { modalStyles } from '../../../constants/styles';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default function CustomModal({
  customHeight,
  customWidth,
  children,
  header,
  buttons,
  actions,
  icon,
}) {
  return (
    <Modal
      isOpen
      style={modalStyles}
    >
      <div
        className={styles.modalContainer}
        style={{
          width: customWidth,
          backgroundColor: 'white',
          borderRadius: 4,
          maxHeight: '100%',
          overflowX: header.scroll && 'scroll',
          height: customHeight,
        }}
      >
        <header
          className={styles.modal_header}
          style={{
            justifyContent: header.exit ? 'space-between' : 'center',
            backgroundColor: header.bgColor,
            height: header?.height,
            color: header?.color,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          {/* {header.exit && <div />} */}
          {!header.title.includes('data:') ? (
            <div>{header.title}</div>
          ) : (
            <img src={header.title} alt="Header modal icon" />
          )}
          {header.exit && (
              <span className={styles.closeButton} onClick={actions.cancel} onKeyDown={(e) => { if (e.keyCode === 13) actions.cancel(); }} tabIndex={0} role="button">
                {!icon ? <Close /> : <img src={icon} width="15" height="15" alt="Header-icon" />}
              </span>
          )}
        </header>
        {children}
        <div className={styles.stickyFooter}>
          {buttons && buttons}
        </div>
      </div>
    </Modal>
  );
}

CustomModal.propTypes = {
  actions: PropTypes.shape({
    cancel: PropTypes.func,
  }),
  buttons: PropTypes.element,
  children: PropTypes.element,
  customWidth: PropTypes.string,
  customHeight: PropTypes.string,
  header: PropTypes.shape({
    bgColor: PropTypes.string,
    exit: PropTypes.bool,
    title: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.string,
    scroll: PropTypes.bool,
  }),
  icon: PropTypes.element,
};

CustomModal.defaultProps = {
  actions: {},
  buttons: null,
  children: null,
  customWidth: null,
  customHeight: null,
  header: {},
  icon: null,
};
