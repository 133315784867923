import React from 'react';
import PropTypes from 'prop-types';
import InfoPill from '../info-pill';
import { orderStrings, scheduleContentStrings } from '../../../constants/strings';
import styles from './schedule-list.module.scss';
// import { scheduleContentStrings } from '../../../constants/strings';

function ScheduleContent({ data }) {
  const {
    title,
    order,
    earliestStart,
    operation,
    priority,
    systemStatus,
    userStatus,
    workcenter,
    createdBy,
  } = data;
  return (
    <>
      <div className="row gx-0">
        <div className="col-auto">
          <h5 className={`${styles.operationContent__title}`}>{title}</h5>
        </div>
      </div>
      <div className="row gx-0">
        <div className="col-auto">
          <InfoPill info={order} label={orderStrings.ORDER} />
        </div>
      </div>
      <div className="row gx-0">
        <div className="col-auto mr-2">
          <InfoPill
            label={scheduleContentStrings.EARLIEST_START}
            info={earliestStart}
          />
        </div>
        <div className="col-auto">
          <InfoPill info={operation} label={orderStrings.OPERATION} />
        </div>
      </div>
      <div className="row gx-0">
        <div className="col-auto mr-2">
          <InfoPill
            label={scheduleContentStrings.SYSTEM_STATUS}
            info={systemStatus}
          />
        </div>
        {createdBy && (
          <div className="col-auto mr-2">
            <InfoPill
              label={scheduleContentStrings.CREATED_BY}
              info={createdBy}
            />
          </div>
        )}
        <div className="col-auto">
          <InfoPill info={userStatus} label={orderStrings.USER_STATUS} />
        </div>
      </div>
      <div className="row gx-0">
        <div className="col-auto mr-2">
          <InfoPill
            label={scheduleContentStrings.WORK_CENTER}
            info={workcenter}
          />
        </div>
        <div className="col-auto">
          <InfoPill info={priority} label={orderStrings.PRIORITY} />
        </div>
      </div>
    </>
  );
}

ScheduleContent.propTypes = {
  data: PropTypes.shape({
    createdBy: PropTypes.string,
    earliestStart: PropTypes.string,
    operation: PropTypes.string,
    order: PropTypes.string,
    priority: PropTypes.string,
    systemStatus: PropTypes.string,
    title: PropTypes.string,
    userStatus: PropTypes.string,
    workcenter: PropTypes.string,
  }),
};

ScheduleContent.defaultProps = {
  data: null,
};

export default ScheduleContent;
