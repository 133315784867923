import { workConfirmationTypes } from '../../../redux/actions/action-types';

const initialState = {
  orderId: '',
  operations: undefined,
  orderType: '',
  activityType: '',
  mnWrkCtrId: '',
  basicStartDate: '',
  basicFinishDate: '',
  priority: '',
  sysStatus: '',
  userStatus: '',
  funLoc: '',
  funLocDescription: '',
  workCenter: '',
  shortDescription: '',
  personnelList: [],
  lastConfirmation: undefined,
};

const workConfirmationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case workConfirmationTypes.UPDATE_WORK_CONFIRMATION:
      return {
        ...state,
        ...action.payload,
      };
    case workConfirmationTypes.RESET_WORK_CONFIRMATION:
      return {
        initialState,
        personnelList: [...state.personnelList],
      };
    case workConfirmationTypes.UPDATE_PERSONNEL_LIST:
      return {
        ...state,
        personnelList: action.payload,
      };
    case workConfirmationTypes.UPDATE_LAST_CONFIRMATION:
      return {
        ...state,
        lastConfirmation: action.payload,
      };
    default:
      return state;
  }
};

export default workConfirmationReducer;
