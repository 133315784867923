import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { groupBy, cloneDeep } from 'lodash';
import { useQuery } from 'react-query';
// import './spares-management.styles.css';
import {
  loaderMessages,
  pages,
  queryStrings,
  sparesMgtStrings,
  queryKeyStrings,
} from '../../../constants/strings';
import getTransactions from './spares-management-external-repairs.helper';
import FormSubHeader from '../../controls/form-sub-header';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import queryClient from '../../../utilities/queryClient';
import ContentField from '../../controls/content-field';
import DateRangePicker from '../../controls/date-range-picker';
import { sparesMgtHeaders } from '../../../constants/enums';
import SparesMgtTransaction from '../../controls/spares-mgt-transaction';
import imgFilterClose from '../../../assets/images/filter-close.png';
import CustomTextInput from '../../controls/custom-textinput/custom-textinput.component';
import CustomDropdown from '../../controls/custom-dropdown';
import { checkNotNull, formatDate, checkArrayNotNull } from '../../../Helpers/common-helpers';
import styles from './spares-management-external-repairs.module.scss';

export default function AdminSetup({
  addBreadCrumb, global, hideLoader, resetBreadCrumbs, showLoader, sparesMgtState, updateSelectedPage, updateTransactions,
}) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [isRetracted, setRetracted] = useState(false);

  const [materialId, setMaterialId] = useState('');
  const [materialDes, setMaterialDes] = useState('');
  const [filteredTransactions, setFilteredTransactions] = useState({});
  const [movementType, setMovementType] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);

  const filterTransactions = (id = materialId, des = materialDes, dates = dateRange, mvmtType = movementType?.value) => {
    const filteredTransactionsData = cloneDeep(sparesMgtState.transactions);
    if (checkNotNull(id)) {
      Object.keys(filteredTransactionsData).forEach((item) => {
        if (!item.includes(id)) {
          delete filteredTransactionsData[item];
        }
      });
    }
    if (checkNotNull(des)) {
      Object.keys(filteredTransactionsData).forEach((item) => {
        if (!filteredTransactionsData[item][0].materialDescription.toLowerCase().includes(des.toLowerCase())) {
          delete filteredTransactionsData[item];
        }
      });
    }

    if (checkArrayNotNull(dates) && checkNotNull(dates[0]) && checkNotNull(dates[1])) {
      Object.keys(filteredTransactions).forEach((item) => {
        filteredTransactions[item] = filteredTransactions[item].filter((transaction) => formatDate(transaction.postingDate) >= dates[0] && formatDate(transaction.postingDate) <= dates[1]);
      });
    }

    if (checkNotNull(mvmtType)) {
      Object.keys(filteredTransactions).forEach((item) => {
        filteredTransactions[item] = filteredTransactions[item].filter((transaction) => transaction.movementType === mvmtType);
      });
    }
    Object.keys(filteredTransactions).forEach((item) => {
      if (!filteredTransactions[item].length > 0) {
        delete filteredTransactionsData[item];
      }
    });

    setFilteredTransactions(filteredTransactionsData);
  };

  const onDateClick = (data) => {
    setDateRange(data);
    filterTransactions(undefined, undefined, data);
  };

  const toggleRetracted = () => setRetracted((state) => !state);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const initialiseData = async () => {
    try {
      showLoader(loaderMessages.LOADING_TRANSACTIONS);
      let transactionData = [];
      transactionData = await getTransactions(global.plantId);
      hideLoader();
      return groupBy(transactionData, 'materialId');
    } catch (e) {
      hideLoader();
      throw e;
    }
  };

  const sparesMgtQuery = useQuery({
    queryKey: [queryKeyStrings.SPARES_MANAGEMENT_EXT_RPR, global.plantId],
    queryFn: async () => {
      const response = await initialiseData();
      return response;
    },
    select: (data) => data,
    onError: (e) => {
      console.log(e);
    },
    onSettled: () => hideLoader(),
    staleTime: 1000 * 60,
    enabled: global.plantId != null,
    cacheTime: 1000 * 60,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const onDropdownSelect = (data) => {
    setMovementType({
      name: data.name,
      value: data.value,
    });
    filterTransactions(undefined, undefined, undefined, data.value);
  };
  React.useEffect(() => {
    const data = queryClient.getQueryData([
      queryKeyStrings.SPARES_MANAGEMENT_EXT_RPR,
      global.plantId,
    ]);
    // showLoader('Preparing Forms')
    if (data) {
      updateTransactions(data);
      setFilteredTransactions(data);
      hideLoader();
    }

    // eslint-disable-next-line
  }, [sparesMgtQuery.isSuccess]);

  React.useEffect(() => {
    resetBreadCrumbs(() => {
      updateSelectedPage(pages.HOME);
    });
    addBreadCrumb({
      name: pages.SPARES_MANAGEMENT,
      action: null,
    });
  }, []);

  const onMaterialIdChange = (e) => {
    setMaterialId(e.value);
    filterTransactions(e.value);
  };

  const onMaterialDesChange = (e) => {
    setMaterialDes(e.value.toUpperCase());
    filterTransactions(undefined, e.value.toUpperCase());
  };

  return (
    <ContentWithBreadcrumb>
      <div className="row gx-0">
        <div className="col d-flex no-gutters p-0">
          <div
            className={`${styles.sidebarContainer} ${!isRetracted && styles.sticky}`}
          >
            <aside
              style={{ position: 'fixed' }}
              className={`${styles.sidebar} ${isRetracted && styles.retracted} pl-2`}
            >
              <div className="d-flex justify-content-between">
                <p className={`${styles.filterHeading} m-0`}>Filter By</p>
                <div onClick={toggleRetracted} role="presentation">
                  <img src={imgFilterClose} height={25} width={25} alt="filter-icon" />
                </div>
              </div>
              <div className="dropdown-divider" />
              <div className="ml-2 mr-1">
                {!isRetracted
                  && (
                    <div>
                      <div className="mb-2">
                        <p className={`${styles.labelTextStyle} mb-2`}>{sparesMgtStrings.POSTING_DATE}</p>
                        <DateRangePicker onOkClick={onDateClick} value={dateRange} />
                      </div>
                      <div className="mb-2">
                        <CustomTextInput
                          editable
                          type="number"
                          label={sparesMgtStrings.MATERIAL_ID}
                          value={materialId}
                          onValueChange={onMaterialIdChange}
                          rows={1}
                        />
                      </div>
                      <div className="mb-2">
                        <CustomTextInput
                          editable
                          label={sparesMgtStrings.MATERIAL_DES}
                          value={materialDes}
                          onValueChange={onMaterialDesChange}
                          autoCapitalize="characters"
                          rows={1}
                        />
                      </div>
                      <div className="mb-2">
                        <CustomDropdown
                          showSelect
                          data={{
                            items: [{ name: 'Z19', value: 'Z19' }, { name: 'Z20', value: 'Z20' }],
                            value: '',
                            name: 'Movement Type',
                            selectedObject: movementType,
                          }}
                          editable
                          updateDropDownItem={onDropdownSelect}
                          zIndex={1000}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </aside>
          </div>

          <div className="row gx-0 " style={{ marginLeft: !isRetracted ? 10 : 40, marginRight: 10 }}>
            <div style={{ width: isRetracted ? windowWidth - 60 : windowWidth - 280 }}>
              {
                Object.keys(filteredTransactions).map((item, index) => (
                  <FormSubHeader
                    style={{ backgroundColor: index % 2 === 0 ? '#BFCCD9' : 'rgba(191, 204, 217, 0.5)' }}
                    headerContent={(
                      <div className="d-flex align-items-center">
                        <p className={`${styles.formHeaderMaterial} mb-0 mr-2`}>{item.slice(-8)}</p>
                        <p className={`${styles.formHeaderMaterialDes} mb-0 mr-2 mt-0`}>{filteredTransactions[item][0]?.materialDescription}</p>
                        <ContentField bootStrapClass={`${styles.formHeaderMaterialCount}`}>
                          {filteredTransactions[item].length}
                        </ContentField>
                      </div>
                    )}
                  >
                    <table className={`${styles.sparesTable}`} style={{ width: isRetracted ? windowWidth - 60 : windowWidth - 280 }}>
                      <thead className={`${styles.sparesTableHead}`}>
                        <th className={`${styles.sparesTableHeader}`} aria-label="sparestable" style={{ width: '3%' }} />
                        {
                          sparesMgtHeaders.map((heading) => (
                            <th className={`${!heading.customWidth && `col-${heading.flexValue}`} ${styles.sparestableHeader} border`} style={{ width: heading.customWidth, paddingLeft: 10 }}>
                              {heading.name}
                            </th>
                          ))
                        }
                      </thead>
                      <tbody>
                        {
                          filteredTransactions[item].map((transaction, filteredTransactionsIndex) => <SparesMgtTransaction transaction={transaction} index={filteredTransactionsIndex} isRetracted={isRetracted} />)
                        }
                      </tbody>
                    </table>
                  </FormSubHeader>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </ContentWithBreadcrumb>
  );
}

AdminSetup.propTypes = {
  addBreadCrumb: PropTypes.func,
  global: PropTypes.shape({
    plantId: PropTypes.number,
  }),
  hideLoader: PropTypes.func,
  resetBreadCrumbs: PropTypes.func,
  showLoader: PropTypes.func,
  sparesMgtState: PropTypes.shape({
    transactions: PropTypes.objectOf(PropTypes.shape()),
  }),
  updateSelectedPage: PropTypes.func,
  updateTransactions: PropTypes.func,
};
AdminSetup.defaultProps = {
  addBreadCrumb: () => { },
  global: PropTypes.shape({
    plantId: null,
  }),
  hideLoader: () => { },
  resetBreadCrumbs: () => { },
  showLoader: () => { },
  sparesMgtState: PropTypes.shape({
    transactions: { },
  }),
  updateSelectedPage: () => { },
  updateTransactions: () => { },
};
