import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './date-picker.styles.css';
import './calendar.styles.css';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import imgCalendar from '../../../assets/images/calendar.png';
import Clear from '../../../assets/images/Clear.png';
import Leftarrow from '../../../assets/images/left-arrow.png';
import Rightarrow from '../../../assets/images/rightarrow.png';

export default function CustomDatePicker({
  value, minDate, onChange, disabled = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleCalendar = () => {
    setIsOpen(!isOpen);
  };
  const clearIcon = () => {
    if (value !== null) {
      if (isOpen) {
        return (<img alt="" src={Clear} style={{ height: '14px', width: '14px' }} />);
      }
    }
    return null;
  };
  return (

    <div>
      <DatePicker
        onChange={onChange}
        calendarClassName="calendar-bottom"
        calendar
        value={value}
        // popoverProps={{
        //   placement: 'bottom',
        //   positionFixed: true,
        // }}
        className={`date-picker-container ${!disabled && (isOpen ? 'date-picker-changed' : 'date-picker')} ${value == null && 'textColor'} `}
        calendarIcon={<img alt="" src={imgCalendar} style={{ height: '14px', width: '14px', marginLeft: '10px' }} />}
        // clearIcon={value != null ? !isOpen ? <img alt="" src={Clear} style={{ height: '14px', width: '14px', marginLeft: '10px' }} /> : null : null}
        clearIcon={clearIcon()}
        disabled={disabled}
        minDate={minDate}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        format="MM/dd/yyyy"
        locale="en-US"
        onCalendarOpen={toggleCalendar}
        onCalendarClose={toggleCalendar}
        showNeighboringMonth={false}
        prevLabel={<img src={Leftarrow} alt="left-arrow" />}
        nextLabel={<img src={Rightarrow} alt="right-arrow" />}
        // showLeadingZeros={true}
        // tileDisabled={({ date }) => date.getDay() === 0 || date.getDay() === 6}
      />
      {isOpen && <div className="dropdown-divider ml-2 mr-2" />}
    </div>
  );
}

CustomDatePicker.propTypes = {
  value: PropTypes.string,
  minDate: PropTypes.string,
  // isChanged: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,

};
CustomDatePicker.defaultProps = {
  value: '',
  minDate: '',
  disabled: false,
  onChange: () => { },
};
