import { connect } from 'react-redux';

import Login from './login.component';
import { showLoader, hideLoader } from '../../../redux/actions/global.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
