import PropTypes from 'prop-types';
import React from 'react';
import { format } from 'date-fns';
import {
  sparesMgtHeaders,
  sparesMgtOptionalHeaders,
} from '../../../constants/enums';
import imgPlusSquare from '../../../assets/images/plus-square.png';
import imgDashSquare from '../../../assets/images/dash-square.png';
import { sparesMgtStrings } from '../../../constants/strings';
import ContentField from '../content-field';
import { formatDate } from '../../../Helpers/common-helpers';
// import './spares-mgt-transaction.css';
import styles from './spares-mgt-transaction.module.scss';

function SparesMgtTransaction({ transaction, index, isRetracted }) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const bgColor = { backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F2F8FC' };

  const [openAddDetails, setOpenAddDetails] = React.useState(false);

  const toggleAdditionalDetails = () => setOpenAddDetails(!openAddDetails);

  return (
    <>
      <tr style={{ ...bgColor }}>
        <td style={{ width: '3%' }}>
          <div
            className="d-flex justify-content-center align-items-center"
            onClick={toggleAdditionalDetails}
            role="presentation"
          >
            <img
              src={openAddDetails ? imgDashSquare : imgPlusSquare}
              alt="iconSquare"
              height={15}
              width={15}
            />
          </div>
        </td>
        {sparesMgtHeaders.map((col) => {
          switch (col.name) {
            case sparesMgtStrings.PLANT_STO_LOC:
              return (
                <td
                  className="pt-2 pb-2 border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    <ContentField bootStrapClass={`${styles.transactionData} d-inline mr-1`}>
                      {transaction.plantId}
                    </ContentField>
                    {transaction.plantName.split('-')[2]}
                  </div>
                  <div className={`${styles.transactionData} mt-1`}>
                    {transaction.storageLocation}
                  </div>
                </td>
              );
            case sparesMgtStrings.POSTING_DATE:
              return (
                <td
                  className="border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {format(formatDate(transaction.postingDate), 'MM/dd/yyyy')}
                  </div>
                </td>
              );
            case sparesMgtStrings.MOVEMENT_TYPE:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    <ContentField bootStrapClass={`${styles.transactionData} d-inline mr-1`}>
                      {transaction.movementType}
                    </ContentField>
                    {transaction.movementTypeText}
                  </div>
                </td>
              );
            case sparesMgtStrings.QTY:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    <ContentField bootStrapClass={`${styles.transactionData} d-inline mr-1`}>
                      {transaction.qtyofUnitOfEntry}
                    </ContentField>
                    {transaction.unitOfEntry}
                  </div>
                </td>
              );
            case sparesMgtStrings.DOC_DATE:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {format(formatDate(transaction.postingDate), 'MM/dd/yyyy')}
                  </div>
                </td>
              );
            case sparesMgtStrings.ENTRY_DATE:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {format(formatDate(transaction.entryDate), 'MM/dd/yyyy')}
                  </div>
                </td>
              );
            case sparesMgtStrings.USERNAME:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {transaction.userName}
                  </div>
                </td>
              );
            case sparesMgtStrings.VENDOR:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {transaction.vendorName}
                  </div>
                </td>
              );
            case sparesMgtStrings.GOODS_RECIPIENT:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {transaction.recipient}
                  </div>
                </td>
              );
            case sparesMgtStrings.MATERIAL_DOC:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {transaction.materialDocument}
                    <ContentField bootStrapClass={`${styles.transactionData} d-inline ml-1`}>
                      {parseInt(transaction.materialDocItem, 10)}
                    </ContentField>
                  </div>
                </td>
              );
            case sparesMgtStrings.DOCUMENT_HEADER_TEXT:
              return (
                <td
                  className=" border"
                  style={{ width: col.customWidth, paddingLeft: 10 }}
                >
                  <div className={`${styles.transactionData} d-inline`}>
                    {transaction.documentHeaderText}
                  </div>
                </td>
              );
            default:
              return null;
          }
        })}
      </tr>

      {openAddDetails && (
        <tr>
          <td colSpan={12}>
            <table
              style={{
                width: isRetracted ? windowWidth - 60 : windowWidth - 280,
              }}
            >
              <tr>
                <th
                  aria-label="sparesTransaction"
                  className={`${styles.sparesTransactionTableHeader}`}
                  style={{ width: '3%' }}
                />
                {sparesMgtOptionalHeaders.map((heading) => (
                  <td
                    className={`${styles.sparesTransactionTableHeader} border`}
                    style={{ width: heading.customWidth, paddingLeft: 10 }}
                  >
                    {heading.name}
                  </td>
                ))}
              </tr>
              <tr className="pt-1 pd-1">
                {/* <td style={{ width: '3%' }} /> */}
                {sparesMgtOptionalHeaders.map((col) => {
                  switch (col.name) {
                    case sparesMgtStrings.REFERENCE_UNLOAD_POINT:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} d-inline mt-1 mb-1`}>
                            {transaction.referenceDocNo || '-'}
                          </div>
                          <div className={`${styles.transactionData} mt-1`}>
                            {transaction.unloadingPoint || '-'}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.ORDER_OPERATION_ACTIVITY:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} d-inline mt-1 mb-1`}>
                            {transaction.orderId || '-'}
                          </div>
                          <div className={`${styles.transactionData} mt-1`}>
                            {transaction.operationActivity || '-'}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.PURCHASE_ORDER:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} mt-1`}>
                            {transaction.PurchaseOrderNo || '-'}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.COUNTER:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} mt-1`}>
                            {transaction.PurchaseOrderNo || '-'}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.SMART_ITEM:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} d-inline mt-1 mb-1`}>
                            {transaction.itemText || '-'}
                          </div>
                          <div className={`${styles.transactionData} mt-1`}>
                            {transaction.itemText || '-'}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.MOV_REASON_MOV_INDICATOR:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} d-inline mt-1 mb-1`}>
                            {transaction.reasonForMovement || '-'}
                          </div>
                          <div className={`${styles.transactionData} mt-1`}>
                            {transaction.movementIndicator || '-'}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.CUSTOMER_CONSUMPTION:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} d-inline`}>
                            {transaction.consumption}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.RECEIPT_INDICATOR:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} d-inline`}>
                            {transaction.userName}
                          </div>
                        </td>
                      );
                    case sparesMgtStrings.ITEM_AUTO_CREATED:
                      return (
                        <td className=" border" style={{ paddingLeft: 10 }}>
                          <div className={`${styles.transactionData} d-inline`}>
                            {transaction.itemAutoCreated}
                          </div>
                        </td>
                      );
                    default:
                      return null;
                  }
                })}
              </tr>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}

SparesMgtTransaction.propTypes = {
  transaction: PropTypes.shape({
    PurchaseOrderNo: PropTypes.string,
    consumption: PropTypes.string,
    documentHeaderText: PropTypes.string,
    entryDate: PropTypes.string,
    itemAutoCreated: PropTypes.string,
    itemText: PropTypes.string,
    materialDocItem: PropTypes.string,
    materialDocument: PropTypes.string,
    movementIndicator: PropTypes.string,
    movementType: PropTypes.string,
    movementTypeText: PropTypes.string,
    operationActivity: PropTypes.string,
    orderId: PropTypes.string,
    plantId: PropTypes.number,
    plantName: PropTypes.shape({
      split: PropTypes.func,
    }),
    postingDate: PropTypes.string,
    qtyofUnitOfEntry: PropTypes.string,
    reasonForMovement: PropTypes.string,
    recipient: PropTypes.string,
    referenceDocNo: PropTypes.string,
    storageLocation: PropTypes.string,
    unitOfEntry: PropTypes.string,
    unloadingPoint: PropTypes.string,
    userName: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  index: PropTypes.number,
  isRetracted: PropTypes.bool,
};
SparesMgtTransaction.defaultProps = {
  transaction: {},
  index: null,
  isRetracted: false,
};

export default SparesMgtTransaction;
