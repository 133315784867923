import React from 'react';
import Home from './pages/home';
import WorkOrder from './pages/work-order';
import WorkConfirmation from './pages/work-confirm';
import WeekSchedule from './pages/week-schedule';
import EInspection from './pages/e-inspection';
import { pages } from '../constants/strings';
import InspectionSchedule from './pages/inspection-schedule';
import Hydrostats from './pages/hydrostats';
import CriticalInspection from './pages/critical-inspection';
import SingleConfirmation from './pages/single-confirmation';
import ChangeOrder from './pages/change-order';
import AssetView from './pages/asset-view';
import EStores from './pages/e-stores';
import SparesManagement from './pages/spares-management-external-repairs';
import NewPage from './pages/spares-management';

export default function Root(props) {
  let component = null;
  switch (props.selectedPage) {
    case pages.HOME: {
      component = <Home />;
      break;
    }
    case pages.WORK_ORDER: {
      component = <WorkOrder />;
      break;
    }
    case pages.E_STORES: {
      component = <EStores />;
      break;
    }
    case pages.SPARES_MANAGEMENT_EXTERNAL_REPAIRS: {
      component = <SparesManagement />;
      break;
    }
    case pages.WORK_ORDER_FROM_ASSET: {
      component = <WorkOrder />;
      break;
    }
    case pages.FOLLOWUP_ORDER: {
      component = <WorkOrder />;
      break;
    }
    case pages.EDIT_ORDER: {
      component = <WorkOrder />;
      break;
    }
    case pages.WORK_ORDER_FROM_SPARES : {
      component = <WorkOrder />;
      break;

    }
    case pages.CONFIRM: {
      component = <WorkConfirmation />;
      break;
    }
    case pages.WEEK_SCHEDULE: {
      component = <WeekSchedule />;
      break;
    }
    case pages.EINSPECTION: {
      component = <EInspection />;
      break;
    }
    case pages.INSPECTION_SCHEDULE: {
      component = <InspectionSchedule />;
      break;
    }
    case pages.HYDROSTATS: {
      component = <Hydrostats />;
      break;
    }
    case pages.CRITICAL_INSPECTION: {
      component = <CriticalInspection />;
      break;
    }
    case pages.SINGLE_CONFIRMATION: {
      component = <SingleConfirmation />;
      break;
    }
    case pages.CHANGE_WORK_ORDER: {
      component = <ChangeOrder />;
      break;
    }
    case pages.ASSET_VIEW: {
      component = <AssetView />;
      break;
    }
    case pages.SPARES_MANAGEMENT: {
      component = <NewPage />;
      break;
    }
    default:
      component = <Home />;
  }
  return component;
}
