import PropTypes from 'prop-types';
import React from 'react';
import styles from './login.module.scss';
import imgHomeLogo from '../../../assets/images/home-logo@3x.png';
import { homeStrings, generalStrings, loaderMessages } from '../../../constants/strings';
import { loginRequest } from '../../../Helpers/auth-config';
import ButtonModal from '../../controls/button-modal/button-modal.component';

export default function Login({ instance, loginError, showLoader }) {
  const onLoginClick = () => {
    showLoader(loaderMessages.LOGIN);
    instance.loginRedirect(loginRequest);
  };
  const onLogoutClick = () => {
    // setLoginError(false)
    sessionStorage.clear();
  };
  return (
    <div
      className={
        `d-flex flex-row my-0 mx-auto  justify-content-center ${styles.loginContainer}`
      }
      // align="center"
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img
          src={imgHomeLogo}
          className={`img-fluid ${styles.gdHomeLogo}`}
          alt="home-logo"
        />
        <div>
          <button onClick={onLoginClick} className={styles.buttonStyle} type="button">
            {generalStrings.LOGIN}
          </button>
        </div>
        <p className={styles.instroText}>{homeStrings.instructions}</p>
      </div>
      {loginError && (
        <ButtonModal
          isError
          data={{
            instructionsText1: homeStrings.errorMessage,
            buttonText: generalStrings.LOGOUT,
          }}
          noOfButtons={1}
          onButtonPress={onLogoutClick}
        />
      )}
    </div>
  );
}

Login.propTypes = {
  instance: PropTypes.shape({
    loginRedirect: PropTypes.func,
  }),
  loginError: PropTypes.bool,
  showLoader: PropTypes.func,
};

Login.defaultProps = {
  instance: {},
  loginError: false,
  showLoader: () => { },
};
