import PropTypes from 'prop-types';
import React from 'react';
import CustomModal from '../../controls/custom-modal';
import CustomButton from '../../controls/custom-button';
import IconError from '../../../assets/images/icon_error.png';
import { generalErrorStrings } from '../../../constants/strings';

export default function GeneralError({ message, visible, onClose }) {
  return (
    <>
      {visible && (
        <CustomModal
          customWidth="350px"
          actions={{ cancel: onClose }}
          header={{ title: IconError, bgColor: '#C74A34' }}
          buttons={(
            <div className="modal__footer">
              <CustomButton
                customStyle={{ width: '100%' }}
                onPress={onClose}
                value={generalErrorStrings.OK}
              />
            </div>
          )}
        >
          <div className="modal__content row-col">
            <span style={{ textAlign: 'center', display: 'block' }}>
              {message}
            </span>
          </div>
        </CustomModal>
      )}
    </>
  );
}

GeneralError.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

GeneralError.defaultProps = {
  message: '',
  onClose: () => { },
  visible: false,
};
