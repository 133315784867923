import { inspSchedulingTypes } from '../../../redux/actions/action-types';

export const updateInspectionSchedule = (value) => ({
  type: inspSchedulingTypes.UPDATE_INSPECTION_LIST,
  payload: value,
});

export const updateBasicSchedule = (value) => ({
  type: inspSchedulingTypes.UPDATE_BASIC_SCHEDULE,
  payload: value,
});

export const updateDailySchedule = (value) => ({
  type: inspSchedulingTypes.UPDATE_DAILY_SCHEDULE,
  payload: value,
});

export const updateDeleteBaSch = (value) => ({
  type: inspSchedulingTypes.DELETE_BASIC_SCHEDULE,
  payload: value,
});

export const updateDeletedElements = (value) => ({
  type: inspSchedulingTypes.UPDATED_DELETED_ELEMENTS,
  payload: value,
});
export const updateDeleteDailyScheduleData = (value) => ({
  type: inspSchedulingTypes.UPDATED_DELETED_DAILY_SCH,
  payload: value,
});

export const updateCalendarDates = (value) => ({
  type: inspSchedulingTypes.UPDATE_CALENDAR_DATES,
  payload: value,
});
export const updateDailySchDB = (value) => ({
  type: inspSchedulingTypes.UPDATE_DAILY_SCH_DB,
  payload: value,
});
export const updateBasicSchDB = (value) => ({
  type: inspSchedulingTypes.UPDATE_DAILY_SCH_DB,
  payload: value,
});
