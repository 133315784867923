import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from '@azure/msal-react';
import styles from './user-feedback-modal.module.scss';
import imgCloseModal from '../../../assets/images/close-modal@2x.png';
import CustomButton from '../custom-button/custom-button.component';
import { checkNotNull, uuidv4 } from '../../../Helpers/common-helpers';
import GraphManager from '../../../Helpers/GraphManager';
import ButtonModal from '../button-modal';
import imgErrorSize from '../../../assets/images/error-size@2x.png';
import AddAttachment from '../add-attachment';
import { loginSilentRequest } from '../../../Helpers/auth-config';
import { modalStyles } from '../../../constants/styles';
import { loaderMessages, profileStrings, userFeedbackStrings } from '../../../constants/strings';

export default function UserFeedback({
  closeModal, global, hideLoader, showLoader,
}) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const [feedbackTxt, setFeedbackTxt] = React.useState('');
  const [showConfirmModal, setConfirmModalStatus] = React.useState(false);
  const [imgList, setImgList] = React.useState([]);
  const [base64List, setBase64List] = React.useState([]);
  const [totalAttachmentSize, setAttachmentSize] = React.useState(0.0);
  const sendMail = async () => {
    showLoader(loaderMessages.SENDING_FEEDBACK);
    let tokens;
    if (account) {
      tokens = await instance.acquireTokenSilent({
        ...loginSilentRequest,
        account,
      });
    }
    const content = {
      text: feedbackTxt,
      attachments: base64List.map((item) => ({
        [profileStrings.DATA_TYPE]: profileStrings.MS_TYPE,
        name: item.name,
        contentBytes: item.base64Code,
        contentType: profileStrings.IMAGE_TYPE,
      })),
      browserDetails : navigator.userAgent,
    };
    await GraphManager.sendFeedbackMail(tokens.accessToken, content, global.version);
    hideLoader();
    setConfirmModalStatus(true);
  };
  const deleteImageFromList = (e) => {
    const index = Number(e.target.id);
    const imgFiles = [...imgList];
    const deletedElement = imgFiles.splice(index, 1);
    setAttachmentSize(
      parseFloat(
        totalAttachmentSize - parseFloat(deletedElement[0].size),
      ).toFixed(2),
    );
    setImgList(imgFiles);
    const base64Files = [...base64List];
    base64Files.splice(index, 1);
    setBase64List(base64Files);
  };
  const onFileUpload = async (e) => {
    if (e.target.files[0]) {
      const imgURL = window.URL.createObjectURL(e.target.files[0]);
      const reader = new FileReader();
      const fileName = e.target.files[0].name;
      reader.readAsDataURL(e.target.files[0]);
      const id = uuidv4();
      reader.onloadend = () => {
        const imgFiles = [...base64List];
        imgFiles.push({
          id,
          base64Code: reader.result.substring(
            reader.result.indexOf(',') + 1,
            reader.result.length,
          ),
          name: fileName,
        });
        setBase64List(imgFiles);
      };
      const imgFiles = [...imgList];
      const imgSize = (e.target.files[0].size / 1000000).toFixed(2);
      imgFiles.push({
        id,
        dataURI: imgURL,
        name: fileName,
        size: imgSize,
      });

      let attachmentSize = parseFloat(totalAttachmentSize);

      attachmentSize += parseFloat(imgSize);
      setImgList(imgFiles);
      setAttachmentSize(attachmentSize.toFixed(2));
    }
  };

  const updateFeedbackTxt = (e) => {
    setFeedbackTxt(e.target.value);
  };
  const onConfirmModalPress = () => {
    setConfirmModalStatus(false);
    closeModal();
  };

  return (
    <Modal
      isOpen
      style={modalStyles}
    >
      <div className={`${styles.modalView}`}>
        <div className={`${styles.feedbackHeader} d-flex align-items-center justify-content-center`}>
          <div className={styles.feedbackHeaderText}>{profileStrings.FEEDBACK_TEXT}</div>
          <div
            onClick={closeModal}
            onKeyDown={(e) => { if (e.keyCode === 13) closeModal(); }}
            className={styles.closeIcon}
            tabIndex={0}
            role="button"
          >
            <img
              src={imgCloseModal}
              alt="close-modal"
              width={17}
              height={17}
            />
          </div>
        </div>
        <div className={`border-bottom ${styles.titleContainer}`}>
          <div
            className={`${styles.feedbackTitle} my-2 mt-2`}
          >
            <div>{profileStrings.FEEDBACK}</div>
          </div>
          <div className={`${styles.instText} mt-2`}>
            <div>{profileStrings.INSTRUCTION}</div>
          </div>
          <div className={`mt-2 ${styles.feedbackContainer}`}>
            <textarea
              className={`${styles.feedbackText}`}
              onChange={updateFeedbackTxt}
            />
          </div>
        </div>
        <div className={styles.attachmentContainer}>
          <div className={`${styles.feedbackTitle} my-2`}>
            {userFeedbackStrings.ATTACHMENTS}{' '}
            {totalAttachmentSize}
            {' '}
            {userFeedbackStrings.MB}
            {' '}
            {parseFloat(totalAttachmentSize) > 3 && (
              <span className={`${styles.errorText}`}>
                {' '}
                <img
                  src={imgErrorSize}
                  alt="error-size"
                  height={15}
                  width={15}
                />
                {' '}
                {profileStrings.ATTACHMENT_INSTRUCTION}
              </span>
            )}
          </div>
          <div className={`${styles.instText}`}>
            {profileStrings.ATTACHMENT_SIZE}
          </div>
          <div className={styles.attachmentDiv}>
            <AddAttachment
              id="user-feedback-modal"
              onDelete={deleteImageFromList}
              onFileUpload={onFileUpload}
              imgList={imgList}
              editable
            />
          </div>
        </div>
        <div className={`${styles.submitButton}`}>
          <CustomButton
            customStyle={{
              color:
                checkNotNull(feedbackTxt) && parseFloat(totalAttachmentSize) < 3
                  ? 'white'
                  : 'rgba(140, 150, 155, 1)',
            }}
            onPress={sendMail}
            disabled={
              !(
                checkNotNull(feedbackTxt) && parseFloat(totalAttachmentSize) < 3
              )
            }
            value={profileStrings.SUBMIT_FEEDBACK}
          />
        </div>
      </div>
      {showConfirmModal && (
        <ButtonModal
          isSuccess
          noOfButtons={1}
          data={{
            buttonText: 'Close',
            instructionsText1: profileStrings.FEEDBACK_ACK,
          }}
          onButtonPress={onConfirmModalPress}
        />
      )}
    </Modal>
  );
}

UserFeedback.propTypes = {
  closeModal: PropTypes.func,
  global: PropTypes.shape({
    version: PropTypes.string,
  }),
  hideLoader: PropTypes.func,
  showLoader: PropTypes.func,
};

UserFeedback.defaultProps = {
  closeModal: () => { },
  global: {},
  hideLoader: () => { },
  showLoader: () => { },
};