import { uniqueId, cloneDeep } from 'lodash';
import { adminSetupTypes } from '../../../redux/actions/action-types';
import { adminSetupStrings } from '../../../constants/strings';
import { checkNotNull, checkArrayNotNull } from '../../../Helpers/common-helpers';

const newWCAssignment = {
  [adminSetupStrings.PERSONELL]: {
    previousValue: '',
    value: '',
  },
  [adminSetupStrings.PLANT]: {
    previousValue: '',
    value: '',
  },
  [adminSetupStrings.EINSP_DB_URL]: {
    previousValue: '',
    value: '',
  },
  [adminSetupStrings.WC_DESCRIPTION]: '',
  [adminSetupStrings.WC_ID]: {
    items: [],
    previousValue: '',
    value: '',
  },
  editable: true,
  newLine: true,
};

const initialState = {
  workCenterAssignments: [],
  workCenters: [],
};

const checkValidations = (workCenterAssignments) => {
  const savedRows = workCenterAssignments.filter((item) => !item.editable);
  const wc = workCenterAssignments.map((row) => {
    if (row.editable) {
      if (
        !checkNotNull(row[adminSetupStrings.PLANT].value)
        || !checkNotNull(row[adminSetupStrings.WC_ID].value)
        || !checkNotNull(row[adminSetupStrings.PERSONELL].value)
      ) {
        row.saveErrorMessage = 'Missed required information';
      } else if (
        checkArrayNotNull(savedRows)
        && savedRows.find(
          (item) => item[adminSetupStrings.PERSONELL].value === row[adminSetupStrings.PERSONELL].value
            && item[adminSetupStrings.PLANT].value === row[adminSetupStrings.PLANT].value
            && item[adminSetupStrings.WC_ID].value === row[adminSetupStrings.WC_ID].value,
        )
      ) {
        row.saveErrorMessage = 'Duplicate Entry';
      } else {
        row.saveErrorMessage = '';
      }
      if (row.newLine) {
        row.resetErrorMessage = 'Changes cannot revert on a new entry';
      } else {
        row.resetErrorMessage = '';
      }
    }
    return row;
  });
  return wc;
};

const adminSetupReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case adminSetupTypes.ADD_WC_ASSIGNMENT: {
      const addedWCAssignments = [...state.workCenterAssignments];
      addedWCAssignments.push({ ...cloneDeep(newWCAssignment), id: uniqueId() });
      return {
        ...state,
        workCenterAssignments: checkValidations(addedWCAssignments),
      };
    }
    case adminSetupTypes.UPDATE_WC_ASSIGNMENT: {
      const updatedWCAssignments = [...state.workCenterAssignments];
      let wcData = updatedWCAssignments.find(
        (item) => item.id === action.payload.index,
      );
      if (
        action.payload.key === adminSetupStrings.PLANT
        && wcData[adminSetupStrings.PLANT] !== action.payload.value
      ) {
        wcData[adminSetupStrings.WC_ID].items = state.workCenters
          .filter(
            (item) => item[adminSetupStrings.PLANT] === action.payload.value,
          )
          .map((item) => (
            { ...item, name: item[adminSetupStrings.WC_ID] }
          ));
        wcData[adminSetupStrings.WC_ID].value = '';
        wcData[adminSetupStrings.WC_DESCRIPTION] = '';
        wcData[adminSetupStrings.PLANT].value = action.payload.value;
      }
      if (action.payload.key === adminSetupStrings.WC_ID) {
        const workCenterObj = state.workCenters.find(
          (item) => item[adminSetupStrings.WC_ID] === action.payload.value,
        );
        wcData[adminSetupStrings.WC_DESCRIPTION] = workCenterObj[adminSetupStrings.WC_DESCRIPTION];
        wcData[adminSetupStrings.EINSP_DB_URL] = {
          previousValue: workCenterObj[adminSetupStrings.EINSP_DB_URL],
          value: workCenterObj[adminSetupStrings.EINSP_DB_URL],
        };
        wcData[adminSetupStrings.WC_ID].value = action.payload.value;
        wcData.work_center_id = action.payload.id;
      }
      if (action.payload.key === adminSetupStrings.PERSONELL) {
        wcData.person_id = action.payload.id;
        wcData[adminSetupStrings.PERSONELL].value = action.payload.value;
      }
      wcData = {
        ...wcData,
        [action.payload.key]: {
          ...wcData[action.payload.key],
          value: action.payload.value,
        },
      };
      return {
        ...state,
        workCenterAssignments: checkValidations(updatedWCAssignments),
      };
    }
    case adminSetupTypes.EDIT_WC_ASSIGNMENT: {
      const editWCAssignments = [...state.workCenterAssignments];
      const editWcData = editWCAssignments.find(
        (item) => item.id === action.payload.index,
      );
      editWcData.editable = action.payload.value;
      return {
        ...state,
        workCenterAssignments: checkValidations(editWCAssignments),
      };
    }
    case adminSetupTypes.DELETE_WC_ASSIGNMENT: {
      const deleteWCAssignments = [...state.workCenterAssignments];
      deleteWCAssignments.splice(action.payload, 1);
      return {
        ...state,
        workCenterAssignments: checkValidations(deleteWCAssignments),
      };
    }
    case adminSetupTypes.RESET_WC_ASSIGNMENT: {
      const resetWCAssignments = [...state.workCenterAssignments];
      const resetDataIndex = resetWCAssignments.findIndex(
        (item) => item.id === action.payload,
      );
      resetWCAssignments[resetDataIndex] = {
        ...resetWCAssignments[resetDataIndex],
        [adminSetupStrings.PLANT]: {
          ...resetWCAssignments[resetDataIndex][adminSetupStrings.PLANT],
          value:
            resetWCAssignments[resetDataIndex][adminSetupStrings.PLANT]
              .previousValue,
        },
        [adminSetupStrings.WC_ID]: {
          ...resetWCAssignments[resetDataIndex][adminSetupStrings.WC_ID],
          items: state.workCenters
            .filter(
              (item) => item[adminSetupStrings.PLANT] === resetWCAssignments[resetDataIndex][adminSetupStrings.PLANT].previousValue,
            )
            .map((item) => (
              { ...item, name: item[adminSetupStrings.WC_ID] }
            )),
          value:
            resetWCAssignments[resetDataIndex][adminSetupStrings.WC_ID]
              .previousValue,
        },
        [adminSetupStrings.PERSONELL]: {
          ...resetWCAssignments[resetDataIndex][adminSetupStrings.PERSONELL],
          value:
            resetWCAssignments[resetDataIndex][adminSetupStrings.PERSONELL]
              .previousValue,
        },
        [adminSetupStrings.EINSP_DB_URL]: {
          ...resetWCAssignments[resetDataIndex][adminSetupStrings.EINSP_DB_URL],
          value:
            resetWCAssignments[resetDataIndex][adminSetupStrings.EINSP_DB_URL]
              .previousValue,
        },
        [adminSetupStrings.WC_DESCRIPTION]: state.workCenters.find(
          (item) => item[adminSetupStrings.WC_ID] === resetWCAssignments[resetDataIndex][adminSetupStrings.WC_ID].previousValue,
        )[adminSetupStrings.WC_DESCRIPTION],
      };
      return {
        ...state,
        workCenterAssignments: checkValidations(resetWCAssignments),
      };
    }
    case adminSetupTypes.ADD_WC_ASSIGNMENTS:
      return {
        ...state,
        workCenterAssignments: checkValidations(action.payload),
      };
    case adminSetupTypes.ADD_WORK_CENTERS:
      return {
        ...state,
        workCenters: action.payload,
      };
    case adminSetupTypes.COPY_ASSIGNMENT: {
      const copyLineWCAssignments = [...state.workCenterAssignments];
      copyLineWCAssignments.push({
        ...action.payload,
        editable: true,
        id: undefined,
        newLine: true,
      });
      return {
        ...state,
        workCenterAssignments: checkValidations(copyLineWCAssignments),
      };
    }
    default:
      return state;
  }
};

export default adminSetupReducer;
