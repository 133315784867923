import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import {
  hideLoader,
  updateParentFollowupOrder,
} from '../../../redux/actions/global.actions';
import {
  loaderMessages,
  pages,
  weekScheduleStrings,
  changeOrderStrings,
} from '../../../constants/strings';
import { mountLocalWork } from './week-schedule.helper';
import { getWorkOrderResults } from '../work-confirm/work-confirm.helper';
import SCHEDULE from './week-schedule.strings';
// import './week-schedule.styles.css';
import LabeledField from '../../controls/labeled-field';
import CustomButton from '../../controls/custom-button';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import { handleSingleConfirmation } from '../single-confirmation/single-confirmation.helper';
import PageLoader from '../../controls/page-loader';
import imgAcAtt from '../../../assets/images/active-attachment.png';
import OrderAttachment from '../../modals/OrderAttachment';
import mountAttachmentList from '../../modals/OrderAttachment/order-attachment.helper';
import { checkArrayNotNull } from '../../../Helpers/common-helpers';
import imgDisAtt from '../../../assets/images/disabled-attachment.png';
import styles from './week-schedule.module.scss';

export default function WeekSchedule(props) {
  const globalReducer = useSelector((state) => state.globalReducer);
  const weekScheduleReducer = useSelector((state) => state.weekScheduleReducer);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const {
    addBreadCrumb,
    resetBreadCrumbs,
    updateGlobalSearchOrder,
    updateSelectedPage,
  } = props;

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const dispatch = useDispatch();

  // const { data, isLoading } = useQuery({
  //   queryKey: weekScheduleReducer.scheduleOrder.orderId,
  //   queryFn: async () => getWorkOrderResults(
  //     globalReducer.plantId,
  //     weekScheduleReducer.scheduleOrder.orderId,
  //   ),
  //   select: (selectedData) => {
  //     const operation = selectedData.operations.filter(
  //       (operationData) => operationData.activity === weekScheduleReducer.scheduleOrder.activity,
  //     )[0];

  //     return mountLocalWork(
  //       globalReducer.userData,
  //       weekScheduleReducer.scheduleOrder,
  //       operation,
  //       data,
  //     );
  //   },
  //   retry: 2,
  //   onSuccess: () => dispatch(hideLoader()),
  //   staleTime: 1000 * 60 * 30,
  // });
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: weekScheduleReducer.scheduleOrder.orderId,
    queryFn: async () => {
      try{
        const response = await getWorkOrderResults(
          globalReducer.plantId,
          weekScheduleReducer.scheduleOrder.orderId,
        );
        return response;
      }catch(e){
        throw e;
      }
    },
    // eslint-disable-next-line
    select: (data) => {
      const operation = data.operations.filter(
        (selectOperation) => selectOperation.activity
          === weekScheduleReducer.scheduleOrder.activity,
      )[0];

      return mountLocalWork(
        globalReducer.userData,
        weekScheduleReducer.scheduleOrder,
        operation,
        data,
      );
    },
    retry: 2,
    onSuccess: () => dispatch(hideLoader()),
    staleTime: 1000 * 60 * 30,
  });

  React.useEffect(() => {
    if (data?.searchOrder) {
      resetBreadCrumbs();
      addBreadCrumb({
        name: `${weekScheduleStrings.WORK_ORDER} ${data?.searchOrder?.orderId}`,
        action: null,
      });
    }
    /* eslint-disable-next-line */
  }, [isSuccess]);

  useEffect(() => {
    if (isLoading) {
      setLoadingOrder(true);
    } else {
      setLoadingOrder(false);
    }
  }, [isLoading]);

  const handleFollowUp = () => {
    resetBreadCrumbs(() => {
      updateSelectedPage(pages.HOME);
    });
    dispatch(updateSelectedPage(pages.FOLLOWUP_ORDER));
    dispatch(updateParentFollowupOrder(data.searchOrder));
  };

  const handleChangeOrder = () => {
    updateGlobalSearchOrder(data.searchOrder);
    // resetBreadCrumbs(() => {
    // updateSelectedPage(pages.HOME);
    // });
    addBreadCrumb({
      name: changeOrderStrings.SELECT_OPERATION,
      action: () => {
        updateSelectedPage(pages.CHANGE_WORK_ORDER);
      },
    });
    updateSelectedPage(pages.CHANGE_WORK_ORDER);
  };
  return (
    <div style={{ height: '100%' }}>
      <div>
        <ContentWithBreadcrumb title={weekScheduleStrings.SCHEDULE} />
        <div className="ml-3">
          <CustomButton
            outline
            disabled={!checkArrayNotNull(data?.attachments)}
            icon={!checkArrayNotNull(data?.attachments) ? imgDisAtt : imgAcAtt}
            onPress={() => setShowAttachmentModal(true)}
            customStyle={{ marginLeft: 5, marginRight: 5 }}
            value={(
              <p className="m-0">
                {weekScheduleStrings.ATTACHMENTS}
                <span
                  className={`${
                    !checkArrayNotNull(data?.attachments)
                      ? 'border'
                      : styles.attachmentLength
                    }`}
                >
                  {data?.attachments?.length || 0}
                </span>
              </p>
            )}
          />
        </div>
      </div>
      <div style={{}}>
        {
          isError && 
          <div align="center">
             No Records Found
             </div>
        }
        <div className={`${styles.woinfosWrapper} col pl-4`}>
          <div className="row gx-0">
            {isSuccess
              && SCHEDULE.operationFields.map(({ label, size, key }) => (
                <LabeledField
                  key={key}
                  fieldClass={`${styles.woinfo} col-${size}`}
                  label={label}
                >
                  <h5 style={{ whiteSpace: 'pre-wrap' }}>{data && data[key]}</h5>
                </LabeledField>
              ))}
          </div>
          {
            isSuccess &&
          <div className={`${styles.buttonsWrapper} d-flex`}>
            <CustomButton
              value={SCHEDULE.buttons.confirmation}
              customStyle={{ marginRight: '20px' }}
              onPress={() => handleSingleConfirmation(dispatch, data, false)}
            />
            <CustomButton
              value={SCHEDULE.buttons.followup}
              customStyle={{ marginRight: '20px' }}
              onPress={handleFollowUp}
            />
            <CustomButton
              value={SCHEDULE.buttons.changeOrder}
              onPress={handleChangeOrder}
            />
          </div>
          }
        </div>
      </div>
      <OrderAttachment
        attachments={mountAttachmentList(
          data?.attachments ? data?.attachments : [],
          globalReducer.userData.mail,
          data?.searchOrder.orderId,
        )}
        visible={showAttachmentModal}
        onClose={() => setShowAttachmentModal(false)}
      />
      {loadingOrder && <PageLoader message={loaderMessages.WEEK_SCHEDULE} />}
    </div>
  );
}

WeekSchedule.propTypes = {
  /** the content will be create by composition, will be passed by the children */
  addBreadCrumb: PropTypes.func,
  resetBreadCrumbs: PropTypes.func,
  updateGlobalSearchOrder: PropTypes.func,
  updateSelectedPage: PropTypes.func,
};

WeekSchedule.defaultProps = {
  addBreadCrumb: () => { },
  resetBreadCrumbs: () => { },
  updateGlobalSearchOrder: () => { },
  updateSelectedPage: () => { },
};
