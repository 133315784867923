// import {BASE_URI} from '../constants/endpoints';
// import {authProvider} from './auth-provider';
import config from '../utilities/config';
import { plantDetails } from '../constants/enums';
import { createWorkOrderStrings } from '../constants/strings';

export const getURL = (relativeUrl) => config.BASE_URL + relativeUrl;

export const getInspSchURL = (relativeUrl) => config.INS_SCH_BASE_URL + relativeUrl;

export const formatDate = (date) => {
  const year = parseInt(date.substring(0, 4), 10);
  const month = parseInt(date.substring(4, 6), 10) - 1;
  const day = parseInt(date.substring(6, 8), 10);
  return new Date(year, month, day);
};

export const mapResultsToMaterials = ((results, sapName) => (
  results.map((item, index) => ({
    [createWorkOrderStrings.MATERIAL_MEMO]: item.MEMOText,
    [createWorkOrderStrings.PO_TEXT]: item.POText,
    [createWorkOrderStrings.MATERIAL]: item.bomComponent,
    isMaterialFetched: true,
    // [createWorkOrderStrings.REQ_DATE]: props.createWorkOrdersState[createWorkOrderStrings.BASIC_START_DATE],
    [createWorkOrderStrings.RECIPIENT]: sapName,
    [createWorkOrderStrings.UNLOAD_POINT]: '',
    [createWorkOrderStrings.QTY_RQD]: '',
    [createWorkOrderStrings.BIN_LOCATION]: item.stageBin,
    [createWorkOrderStrings.UNIT_OF_MEASURE]: item.unitOfMeasure,
    index,
  }))
));

export const prepareWorkCenterItems = (data) => data.map((item) => ({
  name: `${item.wrkCtrId} - ${item.wrkCtrDesc}`,
  value: item.wrkCtrId,
}));

export const getJobAidURL = (relativeUrl = '') => config.JOB_AID_FORMS_URL + relativeUrl;
export const getOrderValue = (workOrderValue) => {
  const workOrderLength = workOrderValue.length;
  const paddingZero = '0';
  return `${paddingZero.repeat(12 - workOrderLength)}${workOrderValue}`;
};
export const checkNotNull = (value) => {
  if (
    value === null
    || value === undefined
    || (typeof value === 'string' && value === '')
  ) {
    return false;
  }
  return true;
};

export const getLastWordInString = (data) => {
  const n = data.split(' ');
  return n[n.length - 1];
};
export const checkArrayNotNull = (value) => {
  if (value !== null && value !== undefined && value.length > 0) {
    return true;
  }
  return false;
};

export const getSAPName = (givenName, lastName) => {
  const sapName = givenName.toUpperCase().charAt(0)
    + lastName.toUpperCase().replace(/ /g, '').substring(0, 10);
  return sapName;
};

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

export const removeSessionCookie = () => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.includes('"authority":') && key.includes('scope')) {
      sessionStorage.removeItem(key);
    }
  });
};

export const prepareFunctionLocationObject = (locationArray) => {
  try {
    const funcLocArray = locationArray.map((element) => {
      const loc3Items = element.descendant.map((loc3Item) => {
        let loc3Obj;
        if (!loc3Item.category.includes('BOM')) {
          loc3Obj = {
            name: `${loc3Item.functionLocation.replace(/^0+/, '')} - ${
              loc3Item.description
            }`,
            value: loc3Item.functionLocation.replace(/^0+/, ''),
            category: loc3Item.category,
          };
          if (checkArrayNotNull(loc3Item.descendant)) {
            const loc4Items = loc3Item.descendant.map((loc4Item) => {
              let loc4Obj;
              if (!loc4Item.category.includes('BOM')) {
                loc4Obj = {
                  name: `${loc4Item.functionLocation.replace(/^0+/, '')} - ${
                    loc4Item.description
                  }`,
                  value: loc4Item.functionLocation.replace(/^0+/, ''),
                  category: loc4Item.category,
                };
                if (checkArrayNotNull(loc4Item.descendant)) {
                  const loc5Items = loc4Item.descendant.map((loc5Item) => {
                    let loc5Obj;
                    if (!loc5Item.category.includes('BOM')) {
                      loc5Obj = {
                        name: `${loc5Item.functionLocation.replace(
                          /^0+/,
                          '',
                        )} - ${loc5Item.description}`,
                        value: loc5Item.functionLocation.replace(/^0+/, ''),
                        category: loc5Item.category,
                      };
                      if (checkArrayNotNull(loc5Item.descendant)) {
                        const loc6Items = loc5Item.descendant.map((loc6Item) => {
                          let loc6Obj;
                          if (!loc6Item.category.includes('BOM')) {
                            loc6Obj = {
                              name: `${loc6Item.functionLocation.replace(
                                /^0+/,
                                '',
                              )} - ${loc6Item.description}`,
                              value: loc6Item.functionLocation.replace(
                                /^0+/,
                                '',
                              ),
                              category: loc6Item.category,
                            };
                            return loc6Obj;
                          }
                          return undefined;
                        });
                        loc5Obj.items = loc6Items.filter(
                          (item) => item !== undefined,
                        );
                      }
                      return loc5Obj;
                    }
                    return undefined;
                  });
                  loc4Obj.items = loc5Items.filter((item) => item !== undefined);
                  return loc4Obj;
                }
                return loc4Obj;
              }
              return undefined;
            });
            loc3Obj.items = loc4Items.filter((item) => item !== undefined);
          }
        }
        return loc3Obj;
      });
      if (!element.category.includes('BOM')) {
        const lev2Obj = {
          name: `${element.functionLocation} - ${element.description}`,
          value: element.functionLocation,
          items: loc3Items.filter((item) => item !== undefined),
        };
        return lev2Obj;
      }
      return undefined;
    }).filter((item) => item !== undefined);
    return funcLocArray;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getPlantIDByName = (name) => Object.keys(plantDetails).find((key) => name.includes(plantDetails[key]));

export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line
  var r = (Math.random() * 16) | 0,
    // eslint-disable-next-line
    v = c === 'x' ? r : (r & 0x3) | 0x8;
  return v.toString(16);
});
