import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { differenceInMinutes, format } from 'date-fns';
import { useQuery } from 'react-query';
import { useMsal, useAccount } from '@azure/msal-react';
// import { useEffect } from 'react';
import styles from './work-order.module.scss';
import CustomDropdown from '../../controls/custom-dropdown';
import CustomTextInput from '../../controls/custom-textinput/custom-textinput.component';
import {
  loaderMessages,
  createWorkOrderStrings,
  pages,
  workOrderStrings,
  singleConfirmationStrings,
  queryKeyStrings,
} from '../../../constants/strings';

import {
  onCreateOrdersPost,
  getMaterialDetails,
  getFormsData,
} from './work-order.helper';
import ButtonModal from '../../controls/button-modal';
import {
  createWorkOrderModal,
  invalidWCModal,
  funcLocFailApiModal,
  confExecutionFailure,
  confDetailsHeaders,
} from '../../../constants/enums';
import { getSAPName, uuidv4, mapResultsToMaterials, checkArrayNotNull } from '../../../Helpers/common-helpers';
import imgInfoIcon from '../../../assets/images/info-icon@2x.png';
import imgAddRow from '../../../assets/images/plus.png';
import CustomRadioButton from '../../controls/custom-radio-button';
import CustomMultiSelect from '../../controls/custom-multi-select/custom-multi-select.component';
// import { format } from 'date-fns';
import AddAttachment from '../../controls/add-attachment';
import ProductCatalogModal from '../../controls/product-catalog-modal/product-catalog-modal.component';
import ReserveMaterialTile from '../../controls/reserve-material-tile/reserve-material-tile.component';
import imgDisabledAssetViewIcon from '../../../assets/images/disabled-asset-view@2x.png';
import imgAcAtt from '../../../assets/images/active-attachment.png';
import imgAcAsset from '../../../assets/images/active-asset-view.png';
import imgAcConf from '../../../assets/images/active-confirmation@3x.png';
import imgAcEdit from '../../../assets/images/active-edit-wo.png';
import imgDisAtt from '../../../assets/images/disabled-attachment.png';
import imgHeaderInfoIcon from '../../../assets/images/header-info-icon.png';
import imgSuccess from '../../../assets/images/success.png';
import imgBack from '../../../assets/images/icon-back.png';
import imgWarning from '../../../assets/images/warning-fill.png';
// import IconPlus from '../../../assets/images/icon_plus.png';
// import {
//   personnelTemplate,
// } from '../work-confirm/work-confirm.helper';
// import { uuidv4 } from '../../../Helpers/common-helpers';
import { loginSilentRequest } from '../../../Helpers/auth-config';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import { handleSingleConfirmation } from '../single-confirmation/single-confirmation.helper';
import { getWorkOrderResults, listPersonnelData } from '../work-confirm/work-confirm.helper';
// import { useQuery } from 'react-query';
import queryClient from '../../../utilities/queryClient';
// import CustomSectionalSelect from '../../controls/custom-sectional-select';
import OrderAttachment from '../../modals/OrderAttachment';
import mountAttachmentList from '../../modals/OrderAttachment/order-attachment.helper';
import CustomButton from '../../controls/custom-button';
import AssetViewModal from '../../controls/asset-view-modal';
// import { addReserveLineItem } from './work-order.actions';
// import { mapResultsToMaterials } from '../../../Helpers/common-helpers';
import ContentField from '../../controls/content-field';
import SubHeader from '../../controls/sub-header/sub-header.component';
import DatePicker from '../../controls/date-picker';
import MultiDropdown from '../../controls/multi-dropdown/multi-dropdown';
// import { timeWorked } from './work-order.helper';
// import ResourceAssignment from '../../controls/resource-assignment';
// import {
//   updatePersonnelList,
// } from '../../pages/work-confirm/work-confirm.actions';
import CONFIRMATION from '../single-confirmation/single-confirmation.strings';
// import { confDetailsHeaders } from '../../../constants/enums';
// import { singleConfirmationStrings } from '../../../constants/strings';
// import ImmediateExecution from '../../controls/Immediate-Execution/immediate-execution';
import { ImmediateExecution } from '../single-confirmation/single-confirmation.component';

export default function WorkOrder({
  global,
  createWorkOrdersState,
  showLoader,
  updateSelectedPage,
  hideLoader,
  updateDropDownItem,
  resetBreadCrumbs,
  updateBreadCrumb,
  updateValue,
  updateSuccessValue,
  updateFailureValue,
  updateWOValue,
  updateMultiSelect,
  updateParentFollowupOrder,
  addBreadCrumb,
  deleteBreadCrumb,
  resetState,
  updateCheckBoxStatus,
  updateIsPickerOpenStatus,
  initialiseData,
  deleteReserveLineItem,
  resetLineItem,
  updateMaterial,
  updateReserveMaterials,
  addReserveLineItem,
  updateFlocValue,
  updateEquipment,
}) {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const account = useAccount(accounts[0]);
  const [imgList, setImgList] = React.useState([]);
  const [showAssetViewModal, setShowAssetViewModal] = React.useState(false);
  const [base64List, setBase64List] = React.useState([]);
  const [indexForPC, setIndexForPC] = React.useState(0);
  const [showPCModal, setShowPCModal] = useState(false);
  const [funcLocModal, setFuncLocModal] = React.useState(false);
  const [editable, setEditable] = React.useState(true);
  const [attachments, setAttachments] = useState([]);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [assignedPersonnel, setAssignedPersonnel] = useState([]);
  const [confirmationText, setConfirmationText] = useState('');
  const [confirmationType, setConfirmationType] = useState(null);
  // const [quickRemarks, setQuickRemarks] = useState([]);
  const [data, setData] = useState([]);

  const initialise = async (initialData) => {
    try {
      showLoader(loaderMessages.PREPARING_FORMS);
      resetState();
      let followUpOrderDetails = null;
      let typeOfOrder = '';
      if (global.selectedPage === pages.WORK_ORDER_FROM_ASSET) {
        typeOfOrder = workOrderStrings.WORK_ORDER_FROM_ASSET;
      } else if (global.selectedPage === pages.FOLLOWUP_ORDER) {
        typeOfOrder = workOrderStrings.FOLLOW_UP;
      } else if (global.selectedPage === pages.EDIT_ORDER) {
        typeOfOrder = workOrderStrings.EDIT_ORDER;
      }
      if (
        global.selectedPage === pages.FOLLOWUP_ORDER
        || global.selectedPage === pages.WORK_ORDER_FROM_ASSET
      ) {
        followUpOrderDetails = global.followUpParentOrder;
      }
      if (global.selectedPage === pages.EDIT_ORDER) {
        followUpOrderDetails = global.globalSearchOrder;
        const { imageUrl } = followUpOrderDetails;
        const { prtDocuments } = followUpOrderDetails.selectedOperation;
        let initialAttachmentsData = [];
        if (imageUrl) {
          initialAttachmentsData = [...imageUrl];
          const imageList = [];
          const initialBase64List = [];
          imageUrl.forEach((urlDetails) => {
            const id = uuidv4();
            initialBase64List.push({
              id,
              base64Code: urlDetails.url,
              name: urlDetails.description,
              isUploaded: true,
            });
            imageList.push({
              id,
              dataURI: urlDetails.url,
              name: urlDetails.description,
              isUploaded: true,
            });
          });
          setImgList(imageList);
          setBase64List(initialBase64List);
        }
        if (prtDocuments) {
          // let prtModDocuments = prtDocuments.map((item) => {
          //   return {
          //     ...item,
          //     docFile: item.docFile.concat(`${global.userData.mail.split('@')[0]}/${global.globalSearchOrder.orderId}`)
          //   }
          // })
          initialAttachmentsData = [...initialAttachmentsData, ...prtDocuments];
        }
        setAttachments(initialAttachmentsData);
        updateWOValue(global.globalSearchOrder);
      }
      let dataFromSpares = null;
      if (global.selectedPage === pages.WORK_ORDER_FROM_SPARES) {
        dataFromSpares = global.dataFromSpares;
      }
      return await initialiseData(
        global.userData,
        global.plantId,
        followUpOrderDetails,
        initialData,
        typeOfOrder,
        dataFromSpares,
      );
    } catch (e) {
      hideLoader();
      console.log('error');
      console.log(JSON.stringify(e));
      throw e;
    }
  };

  const fetchForms = async () => {
    try {
      setImgList([]);
      showLoader(loaderMessages.PREPARING_FORMS);
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      return await getFormsData(global, response.idToken);
    } catch (e) {
      hideLoader();
      console.log('error');
      console.log(JSON.stringify(e));
      setFuncLocModal(true);
      throw e;
    }
  };
  const onQuerySettled = async () => {
    resetState();
    setImgList([]);
    setBase64List([]);
    setAttachments([]);
    const querySettledData = queryClient.getQueryData([queryKeyStrings.FLOC, global.selectedPage == "Work Order From Spares" ? global.dataFromSpares?.plantId : global.plantId]);
    setData(querySettledData);

    // showLoader('Preparing Forms')
    if (querySettledData) {
      await initialise(querySettledData);
      hideLoader();
    }
  };

  const funcQueryOnChange = async () => {
    const status = queryClient.getQueryState([queryKeyStrings.FLOC, global.selectedPage == "Work Order From Spares" ? global.dataFromSpares?.plantId : global.plantId]);
    if (status?.error) {
      await queryClient.prefetchQuery(
        [queryKeyStrings.FLOC, global.selectedPage == "Work Order From Spares" ? global.dataFromSpares?.plantId : global.plantId],
        fetchForms,
      );
    }
    onQuerySettled();
  };

  // const [assignedPersonnel, setAssignedPersonnel] = useState([]);
  // const [personnelAvailablelist, setPersonnelAvailablelist] =
  //   useState(personell || []);

  // useEffect(() => {
  //   let newList = [...(personnel || [])];
  //   assignedPersonnel.forEach(({ value }) => {
  //     let index = newList.findIndex((item) => item.value === value);
  //     index !== -1 && newList.splice(index, 1);
  //   });
  //   setPersonnelAvailablelist(newList);
  // }, [assignedPersonnel, personnel]);

  // React.useEffect(() => {
  //   const { personalNo, name } = global.userPersonalData;
  //   const localPersonnel = [];
  //   let splitedName = ['', ''];

  //   let orderedList = [
  //     // ...personell.filter(
  //     //   ({ workCenter }) => workCenter === order.workCenter,
  //     // ),
  //     // ...personell.filter(
  //     //   ({ workCenter }) => workCenter !== order.workCenter,
  //     // ),
  //   ];
  //   dispatch(updatePersonnelList(orderedList));

  //   if (
  //     // workConfirmationReducer.lastConfirmation &&
  //     personalNo.length > 1 &&
  //     name.length > 1
  //   ) {
  //     splitedName = name.split(' ');

  //     localPersonnel.push(
  //       personnelTemplate(`${splitedName[1]}, ${splitedName[0]}`, personalNo),
  //     );
  //   }

  //   // personnelInfo.forEach(({ name, personnelNumber }) => {
  //   //   personnelNumber !== global.userPersonalData.personalNo &&
  //   //     localPersonnel.push(personnelTemplate(name, personnelNumber));
  //   // });
  //   setAssignedPersonnel(localPersonnel);

  //   // eslint-disable-next-line
  // }, []);

  React.useEffect(() => {
    resetState();
    if (global.selectedPage !== pages.EDIT_ORDER) {
      resetBreadCrumbs();
      if (global.selectedPage === pages.FOLLOWUP_ORDER) {
        addBreadCrumb({
          name: workOrderStrings.CREATE_FOLLOW_UP_WO,
          action: null,
        });
      } else {
        addBreadCrumb({
          name: workOrderStrings.CREATE_WORK_ORDER,
          action: null,
        });
      }
      setEditable(true);
    } else {
      addBreadCrumb({
        name: workOrderStrings.VIEW_WO,
        action: () => {
          // updateSelectedPage(pages.EDIT_ORDER);
          setEditable(false);
        },
      });
      setEditable(false);
    }
    funcQueryOnChange();
    /* eslint-disable-next-line */
  }, [global.selectedPage]);

  const deleteImageFromList = (e) => {
    const index = parseInt(e.target.id, 10);
    const imgFiles = [...imgList];
    imgFiles.splice(index, 1);
    setImgList(imgFiles);
    const base64Files = [...base64List];
    base64Files.splice(index, 1);
    setBase64List(base64Files);
  };

  const onFileUpload = async (e) => {
    if (e.target.files[0]) {
      const imgURL = window.URL.createObjectURL(e.target.files[0]);
      const reader = new FileReader();
      const fileName = e.target.files[0].name;
      reader.readAsDataURL(e.target.files[0]);
      const id = uuidv4();
      reader.onloadend = () => {
        const imgFiles = [...base64List];
        imgFiles.push({
          id,
          base64Code: reader.result.substring(
            reader.result.indexOf(',') + 1,
            reader.result.length,
          ),
          name: fileName,
          isUploaded: false
        });
        setBase64List(imgFiles);
      };
      const imgFiles = [...imgList];
      const imgSize = (e.target.files[0].size / 1000000).toFixed(2);
      imgFiles.push({
        id,
        dataURI: imgURL,
        name: fileName,
        size: imgSize,
      });
      setImgList(imgFiles);
    }
  };

  const funcQuery = useQuery({
    queryKey: [queryKeyStrings.FLOC, global.selectedPage == "Work Order From Spares" ? global.dataFromSpares?.plantId : global.plantId],
    // eslint-disable-next-line
    queryFn: async () => await fetchForms(),
    // onSuccess: onQuerySettled,
    select: (funcQueryData) => funcQueryData,
    onError: (e) => {
      console.log(e);
    },
    staleTime: 1000 * 60 * 60 * 24 * 7,
    enabled: global.plantId != null,
    cacheTime: 1000 * 60 * 60 * 24 * 7,
    refetchOnWindowFocus: true,
    retry: false,
  });

  useEffect(() => {
    setConfirmationType(null);
    setAssignedPersonnel([]);
    setConfirmationText('');
  }, [createWorkOrdersState[createWorkOrderStrings.IMMEDIATE_EXECUTION]]);

  React.useEffect(() => {
    onQuerySettled();
    // eslint-disable-next-line
  }, [funcQuery.isSuccess]);

  const handleFollowUp = () => {
    resetBreadCrumbs(() => {
      updateSelectedPage(pages.HOME);
    });
    updateSelectedPage(pages.FOLLOWUP_ORDER);
    updateParentFollowupOrder(createWorkOrdersState.orderData);
  };

  const onOkClick = () => {
    updateFailureValue({ value: false, errorMessage: '' });
  };
  const updateDateValue = (date) => {
    let formattedDate = '';
    if (date != null) {
      const year = date.toLocaleString('default', { year: 'numeric' });
      const month = date.toLocaleString('default', { month: '2-digit' });
      const day = date.toLocaleString('default', { day: '2-digit' });
      formattedDate = `${year}-${month}-${day}`;
    }

    const obj = {
      key: createWorkOrderStrings.BASIC_START_DATE,
      value: formattedDate,
    };
    updateValue(obj);
  };
  const goToConfirmation = async (isEditOrder = false) => {
    updateSuccessValue(false);
    let { orderData } = createWorkOrdersState;
    if (!isEditOrder) {
      try {
        showLoader(workOrderStrings.FETCHING_ORDER_DETAILS);
        const orderResponse = await getWorkOrderResults(
          global.plantId,
          createWorkOrdersState.newWorkOrderValue,
        );
        orderData = orderResponse;
        hideLoader();
      } catch (e) {
        hideLoader();
        console.log(e);
      }
    }
    const operationsData = global.selectedPage === pages.EDIT_ORDER
      ? orderData.selectedOperation
      : orderData.operations[0];
    handleSingleConfirmation(
      dispatch,
      { ...orderData, ...operationsData },
      false,
    );
  };
  const onSaveClick = async () => {
    try {
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      const confirmationDetails = {
        finalConf:
          confirmationType === singleConfirmationStrings.FINAL_CONFIRMATION
            ? 'X'
            : '',
        confText: confirmationText,
        personnelDetails: assignedPersonnel.map((personnel) => (
          {
            startDate: personnel.startDate,
            endDate: personnel.endDate,
            actualDuration: personnel.actualDuration.decimal,
            personalNumber: personnel.value,
          }
        )),
      };
      showLoader(global.selectedPage === pages.EDIT_ORDER ? loaderMessages.UPDATING_ORDER : loaderMessages.CREATE_WO);
      let sapName = global.userPersonalData.sapUserID;
      if (!sapName) {
        const personalData = await listPersonnelData(global);
        sapName = personalData.userPersonalData.sapUserID;
      }
      const orderObj = await onCreateOrdersPost(
        createWorkOrdersState,
        global.plantId,
        base64List,
        response.idToken,
        global.userPersonalData.sapUserID
        || getSAPName(global.userData.givenName, global.userData.surname),
        global.selectedPage === pages.EDIT_ORDER
          ? global.globalSearchOrder
          : null,
        confirmationDetails,
      );
      updateWOValue(orderObj);
      updateSuccessValue(true);
      hideLoader();
    } catch (e) {
      updateFailureValue({ value: true, errorMessage: e });
      hideLoader();
    }
  };
  const goToHome = () => {
    updateSuccessValue(false);
    setFuncLocModal(false);
    updateSelectedPage(pages.HOME);
  };

  const confirmFromEditOrder = () => {
    updateBreadCrumb({
      name: workOrderStrings.VIEW_WO,
      action: () => {
        updateSelectedPage(pages.EDIT_ORDER);
        setEditable(false);
      },
    });
    goToConfirmation(true);
  };

  const confirmFromCreateOrder = () => goToConfirmation(false);

  const updateDuration = (obj) => {
    const index = obj.value.indexOf('.');
    if (index >= 0) {
      obj.value = obj.value.substring(0, index + 2);
    }
    updateValue(obj);
  };

  const updateOpDesc = (obj) => {
    updateValue(obj);
    // if (obj.value.length <= 40) {
    obj.key = createWorkOrderStrings.OP_DESC;
    obj.value = createWorkOrdersState[
      createWorkOrderStrings.OP_DESC
    ].value.replace(
      createWorkOrdersState[createWorkOrderStrings.OP_DESC].value.slice(0, 40),
      obj.value.slice(0, 40),
    );
    updateValue(obj);
    // }
  };
  const onFollowUpClick = async () => {
    updateSuccessValue(false);
    await onQuerySettled();
  };
  const dateValues = createWorkOrdersState[createWorkOrderStrings.BASIC_START_DATE].value.split(
    '-',
  );
  const closePCModal = () => {
    setShowPCModal(false);
  };
  const showPCatalogModal = (index) => {
    setIndexForPC(index);
    setShowPCModal(true);
  };
  const updateMaterialDetails = (index, materialDetails) => {
    updateMaterial({
      [createWorkOrderStrings.MATERIAL_MEMO]: materialDetails.materialMEMO,
      [createWorkOrderStrings.PO_TEXT]: materialDetails.POLongText,
      [createWorkOrderStrings.MATERIAL]: materialDetails.material.slice(-8),
      [createWorkOrderStrings.UNRES_QTY]: materialDetails.unrestrictedQuantity,
      [createWorkOrderStrings.UNIT_PRICE]:
        `$ ${parseFloat(materialDetails.unityPrice).toFixed(2).toString()}`,
      [createWorkOrderStrings.BIN_LOCATION]: materialDetails.stageBin,
      [createWorkOrderStrings.UNIT_OF_MEASURE]: materialDetails.unitOfMeasure,
      isMaterialFetched: true,
      [createWorkOrderStrings.REQ_DATE]:
        createWorkOrdersState[createWorkOrderStrings.BASIC_START_DATE],
      [createWorkOrderStrings.RECIPIENT]:
        global.userPersonalData.sapUserID || getSAPName(global.userData.givenName, global.userData.surname),
      index,
      errorSearchBar: false,
      isMaterialFetchFail: false,
    });
    setShowPCModal(false);
  };

  const retryFuncLoc = async () => {
    setFuncLocModal(false);
    await funcQueryOnChange();
  };

  const onSearchClick = async (index, materialValue) => {
    try {
      showLoader(loaderMessages.GET_MATERIAL);
      const materialDetails = await getMaterialDetails(
        materialValue,
        global.plantId,
      );
      updateMaterialDetails(index, materialDetails);
    } catch (e) {
      const onSearchClickData = {
        index,
        failureMessage: e || 'MATERIAL NOT FOUND',
        [createWorkOrderStrings.PO_TEXT]: '',
        isMaterialFetched: false,
        errorSearchBar: true,
        isMaterialFetchFail: true,
        [createWorkOrderStrings.MATERIAL]: materialValue,
      };
      updateMaterial(onSearchClickData);
    } finally {
      hideLoader();
    }
  };

  function renderReserveMaterials() {
    return createWorkOrdersState.reserveMaterials.map((item, index) => (
      <ReserveMaterialTile
        item={item}
        index={index + 1}
        deleteItem={deleteReserveLineItem}
        onChangeValue={updateReserveMaterials}
        resetLineItem={resetLineItem}
        onMaterialSearch={onSearchClick}
        showPCModal={showPCatalogModal}
        editable={editable}
        isEditOrder={global.selectedPage === pages.EDIT_ORDER}
      />
    ));
  }

  const closeAssetViewModal = () => setShowAssetViewModal(false);
  const openAssetViewModal = () => setShowAssetViewModal(true);

  const addMaterialToOrder = (selectedMaterials) => {
    closeAssetViewModal();
    const sapName = global.userPersonalData.sapUserID
      || getSAPName(global.userData.givenName, global.userData.surname);
    const materials = mapResultsToMaterials(selectedMaterials, sapName);
    materials.forEach((material, index) => {
      dispatch(addReserveLineItem());
      dispatch(
        updateMaterial({
          ...material,
          isMaterial: 'I',
          index: createWorkOrdersState.reserveMaterials.length + index,
        }),
      );
    });
    return materials;
  };
  const headerContent = (
    <div className="d-flex">
      <p className="p-0 mb-0 mr-2">Header Information</p>
      <div className="d-flex align-items-center">
        <ContentField bootStrapClass="py-1 px-1">
          <p
            className={`${styles.contentFieldText} ${styles.contentFieldSmall} p-0 mb-0 mr-1`}
          >
            <span className={`${styles.asterikRequired} mr-1`}>
              {workOrderStrings.ASTERIK}
            </span>
            {workOrderStrings.INDICATES_REQUIRED}
          </p>
        </ContentField>
      </div>
    </div>
  );
  // const [equipmentdata, setEquipmentdata] = useState('');
  const [equipmentvalue, setEquipmentvalue] = useState([]);
  // const [value, setValue] = useState(null)
  const displayValue = (value, displayEquipmentvalue) => {
    setEquipmentvalue(displayEquipmentvalue);
    const obj = {
      items: displayEquipmentvalue,
      name: '',
      value: '',
    };
    updateEquipment(obj);
  };
  const updateEquipments = (updateEquipmentData) => {
    // setEquipmentdata(data)
    const obj = {
      items: equipmentvalue,
      name: updateEquipmentData,
      value: updateEquipmentData.value,
    };
    updateEquipment(obj);
  };
  const reserveHeaderContent = (
    <div className="d-flex">
      <p className="p-0 mb-0 mr-2">{workOrderStrings.RESERVE_MATERIALS}</p>
      {global.globalSearchOrder && (
        <div className="d-flex align-items-center">
          <ContentField bootStrapClass="py-1 px-1">
            <p className={`${styles.contentFieldRes} p-0 mb-0 mr-1 `}>
              <span className={`${styles.contentFieldBold}`}>
                {workOrderStrings.RES_ID}
              </span>
              {global.globalSearchOrder?.reservationNumber}
            </p>
          </ContentField>
        </div>
      )}
      <div className="d-flex align-items-center">
        {createWorkOrdersState.funcLocValue?.value?.split('-').length >= 3 && (
          <CustomButton
            outline
            customStyle={{
              marginLeft: 5,
              marginRight: 5,
              fontSize: 12,
              padding: 0,
              paddingLeft: 5,
              paddingRight: 5,
              backgroundColor: '#F1F5F8',
            }}
            icon={
              createWorkOrdersState.funcLocValue == null
                ? imgDisabledAssetViewIcon
                : imgAcAsset
            }
            disabled={createWorkOrdersState.funcLocValue == null}
            onPress={openAssetViewModal}
            value={workOrderStrings.ASSET_VIEW}
          />
        )}
      </div>
    </div>
  );
  function icon() {
    if (editable) {
      if (createWorkOrdersState.canBeSaved) {
        return imgSuccess;
      }
      return imgWarning;
    }
    return imgHeaderInfoIcon;
  }
  function reserveIcon() {
    if (editable) {
      if (createWorkOrdersState.canBeSaved) {
        return imgSuccess;
      }
      return imgWarning;
    }
    return imgHeaderInfoIcon;
  }
  function displayConfirmationText(item) {
    if (item.confirmationText === CONFIRMATION.CONFIRMATION_DETAILS.FAR) {
      return CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[1];
    }
    if (item.confirmationText === CONFIRMATION.CONFIRMATION_DETAILS.PAR) {
      return CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[0];
    }
    return CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[2];
  }

  return (
    <ContentWithBreadcrumb
      title={
        global.selectedPage === pages.EDIT_ORDER
          ? ''
          : workOrderStrings.CREATE_WORK_ORDER
      }
    >
      {global.selectedPage === pages.EDIT_ORDER && (
        <div className={`${styles.headerSticky}`}>
          <div className="d-flex align-items-center">
            <h1
              className={`${styles.headerTitle} d-flex  align-items-center`}
              style={{ width: 'fit-content', paddingLeft: 5 }}
            >
              {`${workOrderStrings.WORK_ORDER} # ${global.globalSearchOrder.orderId}`}
            </h1>
            <div className="d-flex flex-direction-row ml-3">
              <ContentField>
                <p className={`p-0 m-0 ${styles.contentFieldText}`}>
                  {global.globalSearchOrder.shortDescription}
                </p>
              </ContentField>
              <ContentField bootStrapClass="ml-2">
                <p className={`p-0 m-0 ${styles.contentFieldText}`}>
                  <span className={`${styles.opHeading}`}>
                    {workOrderStrings.OPERATION}
                  </span>
                  {global.globalSearchOrder?.selectedOperation?.description}
                </p>
              </ContentField>
            </div>
          </div>
          <div style={{ paddingLeft: 25, display: 'flex', flexDirection: 'row' }}>
            {!editable ? (
              <div className="d-flex flex-direction-row justify-content-between mr-2 mt-2">
                <CustomButton
                  disabled={attachments.filter((item) => checkArrayNotNull(item)).length === 0}
                  outline
                  icon={attachments.filter((item) => checkArrayNotNull(item)).length === 0 ? imgDisAtt : imgAcAtt}
                  onPress={() => setShowAttachmentModal(true)}
                  customStyle={{ marginLeft: 5, marginRight: 5 }}
                  value={(
                    <p className="m-0">
                      {workOrderStrings.ATTACHMENTS}
                      <span
                        className={`${attachments.filter((item) => checkArrayNotNull(item)).length > 0
                          ? styles.attachmentLength
                          : styles.attachmentLengthDisabled
                        }`}
                      >
                        {attachments.filter((item) => checkArrayNotNull(item)).length}
                      </span>
                    </p>
                  )}
                />
                <CustomButton
                  outline
                  onPress={confirmFromEditOrder}
                  customStyle={{ marginLeft: 5, marginRight: 5 }}
                  icon={imgAcConf}
                  value={workOrderStrings.CREATE_CONFIRMATION}
                />
                <CustomButton
                  outline
                  onPress={handleFollowUp}
                  customStyle={{ marginLeft: 5, marginRight: 5 }}
                  icon={imgAcConf}
                  value={workOrderStrings.CREATE_FOLLOW_UP}
                />
                <CustomButton
                  outline
                  customStyle={{ marginLeft: 5, marginRight: 5 }}
                  icon={imgAcAsset}
                  onPress={openAssetViewModal}
                  value={workOrderStrings.ASSET_VIEW}
                />
                <CustomButton
                  customStyle={{ marginLeft: 5, marginRight: 5 }}
                  outline
                  onPress={() => {
                    updateBreadCrumb({
                      name: workOrderStrings.VIEW_WO,
                      action: () => {
                        setEditable(false);
                      },
                    });
                    addBreadCrumb({
                      name: workOrderStrings.EDIT_WORK_ORDER,
                      action: null,
                    });
                    setEditable(true);
                  }}
                  icon={imgAcEdit}
                  value={workOrderStrings.MODIFY}
                />
              </div>
            ) : (
              <div className="d-flex flex-direction-row justify-content-between mr-2">
                <CustomButton
                  disabled={attachments.length === 0}
                  outline
                  icon={attachments.length === 0 ? imgDisAtt : imgAcAtt}
                  onPress={() => setShowAttachmentModal(true)}
                  customStyle={{ marginLeft: 5, marginRight: 5 }}
                  value={(
                    <p className="m-0">
                      {workOrderStrings.ATTACHMENTS}
                      <span
                        className={`${attachments.length > 0
                          ? styles.attachmentLength
                          : styles.attachmentLengthDisabled
                        }`}
                      >
                        {attachments.length}
                      </span>
                    </p>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={`d-flex ${styles.woContainer}`}>
        <div className="container-fluid my-1 mb-2">
          <SubHeader
            // icon={
            //   editable
            //     ? createWorkOrdersState.canBeSaved
            //       ? imgSuccess
            //       : imgWarning
            //     : imgHeaderInfoIcon
            // }
            icon={icon()}
            headerContent={headerContent}
          >
            <div className="mx-3">
              <div className="row px-2" style={{ flexWrap: 'wrap' }}>
                <div className="col-lg-3 pb-2">
                  <CustomDropdown
                    data={createWorkOrdersState[createWorkOrderStrings.USER]}
                    style={{ zIndex: 900 }}
                    editable={editable}
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomDropdown
                    required
                    data={
                      createWorkOrdersState[createWorkOrderStrings.ORDER_TYPE]
                    }
                    updateDropDownItem={(orderTypeData) => {
                      const updatedData = { ...orderTypeData };
                      if (global.selectedPage === pages.FOLLOWUP_ORDER) {
                        updatedData.isFollowUp = global.selectedPage === pages.FOLLOWUP_ORDER;
                        updatedData.parentOrderType = global.followUpParentOrder.orderType;
                      }
                      updateDropDownItem(updatedData);
                    }}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 880 }}
                    editable={(global.selectedPage !== pages.EDIT_ORDER)}
                    // editable={!global.selectedPage === pages.EDIT_ORDER}
                    withSmartTyping
                  />
                </div>
                {createWorkOrdersState[createWorkOrderStrings.ORDER_TYPE]
                  .selectedObject?.value === 'ZM07' && (
                    <div className="col-lg-3 pb-2">
                      <p className={`p-0 m-0 ${styles.labelTextStyle}`}>
                        {createWorkOrderStrings.IMMEDIATE_EXECUTION}
                      </p>
                      <div
                        style={{
                          marginTop: '5px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CustomRadioButton
                          height={30}
                          width={30}
                          value={
                            createWorkOrdersState[
                              createWorkOrderStrings.IMMEDIATE_EXECUTION
                            ].value
                          }
                          onClick={() => updateCheckBoxStatus({
                            key: createWorkOrderStrings.IMMEDIATE_EXECUTION,
                            value:
                              !createWorkOrdersState[
                                createWorkOrderStrings.IMMEDIATE_EXECUTION
                              ].value,
                          })}
                          editable={global.selectedPage !== pages.EDIT_ORDER}
                        // editable={!global.selectedPage === pages.EDIT_ORDER}
                        />
                        <span
                          className={`${styles.kittingText}`}
                          style={{
                            color:
                              global.selectedPage === pages.EDIT_ORDER
                                ? '#53626A'
                                : 'black',
                          }}
                        >
                          {' '}
                          {workOrderStrings.IMM_EXEC_TEXT}
                        </span>
                      </div>
                    </div>
                )}
                <div className="col-lg-3 pb-2">
                  <CustomDropdown
                    required
                    data={
                      createWorkOrdersState[
                        createWorkOrderStrings.ACTIVITY_TYPE
                      ]
                    }
                    updateDropDownItem={updateDropDownItem}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 860 }}
                    editable={(global.selectedPage !== pages.EDIT_ORDER)}
                    withSmartTyping
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomDropdown
                    required
                    data={
                      createWorkOrdersState[
                        createWorkOrderStrings.MAIN_WORK_CENTER
                      ]
                    }
                    showSelect
                    updateDropDownItem={updateDropDownItem}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 840 }}
                    editable={editable}
                    withSmartTyping
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomDropdown
                    required
                    data={
                      createWorkOrdersState[createWorkOrderStrings.PRIORITY]
                    }
                    showSelect
                    updateDropDownItem={updateDropDownItem}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 820 }}
                    editable={
                      editable
                      && !(
                        createWorkOrdersState[createWorkOrderStrings.ORDER_TYPE].selectedObject?.value === 'ZM01'
                        || (createWorkOrdersState[createWorkOrderStrings.ORDER_TYPE].selectedObject?.value === 'ZM07'
                        && createWorkOrdersState[createWorkOrderStrings.IMMEDIATE_EXECUTION].value)
                      )
                    }
                    withSmartTyping
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomDropdown
                    required
                    data={
                      createWorkOrdersState[
                        createWorkOrderStrings.SYS_CONDITION
                      ]
                    }
                    showSelect
                    updateDropDownItem={updateDropDownItem}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 800 }}
                    editable={editable}
                    withSmartTyping
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <p className={`${styles.labelTextStyle} p-0 m-0`}>
                    {createWorkOrderStrings.BASIC_START_DATE}
                  </p>
                  {editable ? (
                    <div style={{ marginTop: 5, backgroundColor: 'white' }}>
                      <DatePicker
                        className={`${styles.calendarField}`}
                        value={
                          createWorkOrdersState[
                            createWorkOrderStrings.BASIC_START_DATE
                          ].value !== ''
                            ? new Date(
                              dateValues[0],
                              dateValues[1] - 1,
                              dateValues[2],
                            )
                            : ''
                        }
                        onChange={updateDateValue}
                      />
                    </div>
                  ) : (
                    <div className={`${styles.nameText}`}>
                      <span
                        className={`${styles.nameText}`}
                        style={{ color: '#53626A' }}
                      >
                        {createWorkOrdersState[
                          createWorkOrderStrings.BASIC_START_DATE
                        ].value !== ''
                          ? format(
                            new Date(
                              dateValues[0],
                              dateValues[1] - 1,
                              dateValues[2],
                            ),
                            'dd/MM/yyyy',
                          )
                          : '-'}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomTextInput
                    label={createWorkOrderStrings.REVISION}
                    value={
                      createWorkOrdersState[createWorkOrderStrings.REVISION]
                        .value
                    }
                    style={{ zIndex: 780 }}
                    onValueChange={updateValue}
                    autoCapitalize="characters"
                    editable={editable}
                    rows={1}
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomMultiSelect
                    data={createWorkOrdersState[createWorkOrderStrings.IMPACT]}
                    updateMultiSelect={updateMultiSelect}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 760 }}
                    editable={editable}
                    isEditOrder={global.selectedPage === pages.EDIT_ORDER}
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomMultiSelect
                    data={
                      createWorkOrdersState[createWorkOrderStrings.SPECIALITY]
                    }
                    updateMultiSelect={updateMultiSelect}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 740 }}
                    editable={editable}
                    isEditOrder={global.selectedPage === pages.EDIT_ORDER}
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <p className={`${styles.labelText} p-0 m-0`}>
                    {createWorkOrderStrings.KITTING}
                  </p>
                  <div
                    style={{
                      marginTop: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <CustomRadioButton
                      value={
                        createWorkOrdersState[createWorkOrderStrings.KITTING]
                          .value
                      }
                      onClick={() => updateCheckBoxStatus({
                        key: createWorkOrderStrings.KITTING,
                        value:
                          !createWorkOrdersState[
                            createWorkOrderStrings.KITTING
                          ].value,
                      })}
                      height={30}
                      width={30}
                      editable={editable}
                    />
                    <span className={`${styles.kittingText}`}>
                      {workOrderStrings.REQUIRES_KITTING}
                    </span>
                  </div>
                </div>

                {/* <div className='col-lg-3 pb-2'>
                  <CustomDropdown
                    data={
                      createWorkOrdersState[
                      createWorkOrderStrings.FUNC_LOC_LEV_1
                      ]
                    }
                    style={{ zIndex: 720 }}
                    editable={editable}
                  />
                </div> */}
                <div className="col-lg-3 pb-2" style={{ zIndex: 700 }}>
                {data?.funcLocDetails && <MultiDropdown
                    data={data?.funcLocDetails}
                    selectedObject={createWorkOrdersState.funcLocValue}
                    name={workOrderStrings.FUNCTIONAL_LOCATION}
                    updateValues={(value, equipmentval) => displayValue(value, equipmentval)}
                    updateFlocValue={updateFlocValue}
                    value={createWorkOrdersState.funcLocValue?.name}
                    editable={editable}
                    childElement="items"
                    childName="name"
                    required
                  />
                }
                </div>
                <div className="col-lg-3 pb-2" style={{ zIndex: 680 }}>
                  {equipmentvalue.length > 0 ? (
                    <CustomDropdown
                      data={{
                        items: equipmentvalue,
                        value: createWorkOrdersState.Equipment?.value,
                        name: workOrderStrings.EQUIPMENT,
                        selectedObject:
                          createWorkOrdersState.Equipment.selectedObject,
                      }}
                      updateDropDownItem={(updateEquipmentData) => updateEquipments(updateEquipmentData)}
                      editable
                    />
                  ) : (
                    ''
                  )}
                </div>
                {/* {equipmentvalue.length>0  && (
                    <div className='col-lg-3 pb-2'>
                      <CustomSectionalSelect
                       data={
                          createWorkOrdersState[
                          createWorkOrderStrings.EQUIPMENT
                          ]
                        }
                        updateDropDownItem={updateSectionalMultiSelect}
                        style={{ zIndex: 600 }}
                        editable={editable}
                      />
                    </div>
                  )} */}
                <div className="col-12 pb-2">
                  <CustomTextInput
                    label={createWorkOrderStrings.SHORT_TEXT}
                    rows={3}
                    description={createWorkOrderStrings.SHORT_DESC}
                    value={
                      createWorkOrdersState[createWorkOrderStrings.SHORT_TEXT]
                        .value
                    }
                    required
                    onValueChange={updateOpDesc}
                    style={{ zIndex: 580 }}
                    autoCapitalize="characters"
                    editable={editable}
                    childElement="items"
                    childName="name"
                  />
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={imgInfoIcon}
                      alt="info-icon"
                      className={`${styles.infoIcon}`}
                    />
                    <p className={`${styles.labelText} ml-1 mb-0`}>
                      {workOrderStrings.SHORT_TEXT_PREVIEW}
                      {' '}
                      <span className={`${styles.nameText} ml-0 mpl-0`}>
                        {createWorkOrdersState[
                          createWorkOrderStrings.SHORT_TEXT
                        ].value
                          .slice(0, 40)
                          .toUpperCase()}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SubHeader>
          <SubHeader
            icon={editable ? imgSuccess : imgHeaderInfoIcon}
            headerContent="Operation Details"
          >
            <div className="px-4">
              <div className="row px-2">
                <div className="col-lg-4 pb-2">
                  <CustomDropdown
                    data={
                      createWorkOrdersState[createWorkOrderStrings.OP_WK_CNTR]
                    }
                    updateDropDownItem={updateDropDownItem}
                    updatePickerOpenStatus={updateIsPickerOpenStatus}
                    style={{ zIndex: 530 }}
                    editable={editable}
                    withSmartTyping
                  />
                </div>
                <div className="col-lg-4 pb-2">
                  <CustomTextInput
                    label={createWorkOrderStrings.NO_OF_RQD_RES}
                    value={
                      createWorkOrdersState[
                        createWorkOrderStrings.NO_OF_RQD_RES
                      ].value
                    }
                    type="number"
                    rows={1}
                    style={{ zIndex: 520 }}
                    onValueChange={updateValue}
                    autoCapitalize="characters"
                    editable={editable}
                  />
                </div>
                <div className="col-lg-4 pb-2">
                  <CustomTextInput
                    label={createWorkOrderStrings.OP_DURATION}
                    value={
                      createWorkOrdersState[createWorkOrderStrings.OP_DURATION]
                        .value
                    }
                    type="number"
                    rows={1}
                    step="0.1"
                    style={{ zIndex: 500 }}
                    onValueChange={updateDuration}
                    editable={editable}
                  />
                </div>
              </div>
              <div className="row gx-0 px-2">
                <div className="col-12 pb-2">
                  <CustomTextInput
                    label={createWorkOrderStrings.OP_DESC}
                    description={createWorkOrderStrings.SHORT_DESC}
                    value={
                      createWorkOrdersState[createWorkOrderStrings.OP_DESC]
                        .value
                    }
                    onValueChange={updateValue}
                    style={{ zIndex: 475 }}
                    autoCapitalize="characters"
                    editable={editable}
                    rows={3}
                  />
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={imgInfoIcon}
                      alt="info-icon"
                      className={`${styles.infoIcon}`}
                    />
                    <p className={`${styles.labelText} ml-1 mb-0`}>
                      {workOrderStrings.SHORT_TEXT_PREVIEW}
                      {' '}
                      <span className={`${styles.nameText} ml-0 pl-0`}>
                        {createWorkOrdersState[
                          createWorkOrderStrings.OP_DESC
                        ].value
                          .slice(0, 40)
                          .toUpperCase()}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SubHeader>
          <SubHeader
            icon={editable ? imgSuccess : imgHeaderInfoIcon}
            headerContent="Attachments"
          >
            <div className="row gx-0 px-4">
              {(editable || imgList?.length > 0) && (
                <div className="d-flex" style={{ width: '100%' }}>
                  <AddAttachment
                    id="work-order-create-attachment"
                    imgList={imgList}
                    onDelete={deleteImageFromList}
                    onFileUpload={onFileUpload}
                    customStyle={{
                      width: '100%',
                      height: '100%',
                    }}
                    editable={editable}
                    isEditOrder={global.selectedPage === pages.EDIT_ORDER}
                  />
                </div>
              )}
            </div>
          </SubHeader>

          {/* <div className='sideHeading'>Resource Assignments</div> */}
          {/* {assignedPersonnel.map((personnel, index) =>
            <ResourceAssignment
              position={window.innerHeight}
              listValue={personnel}
              key={personnel !== null && personnel.value + index}
              list={personnelAvailablelist}
              onChange={(item) => handlePersonnelChange(item, index)}
              onDelete={() => handleDeletePersonnel(index)}
              onSpread={() =>
                handleSpreadDuration(
                  personnel.actualDuration,
                  personnel.endDate,
                )
              }
              firstRow={index === 0}
            />
          )}
          {personnelAvailablelist.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer',
                marginTop: 20,
              }}>
              <div
                style={{
                  height: 40,
                  width: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleAddPersonnel}>
                <img src={imgAddRow} alt='add-row' height={25} width={25} />
              </div>
            </div>
          )} */}
          <SubHeader
            // icon={
            //   editable
            //     ? createWorkOrdersState.reserveMaterialCanBeSaved
            //       ? imgSuccess
            //       : imgWarning
            //     : imgHeaderInfoIcon
            // }
            icon={reserveIcon()}
            headerContent={reserveHeaderContent}
          >
            <div className="row gx-0 px-4">
              <div style={{ width: '100%', marginRight: 20 }}>
                {renderReserveMaterials()}
                {editable && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      cursor: 'pointer',
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    <CustomButton
                      outline
                      icon={imgAddRow}
                      value={workOrderStrings.ADD_MATERIAL}
                      onPress={addReserveLineItem}
                    />
                  </div>
                )}
              </div>
            </div>
          </SubHeader>
          {createWorkOrdersState[createWorkOrderStrings.IMMEDIATE_EXECUTION]
            .value && (
              <ImmediateExecution
                assignedPersonnelImmediateExecution={(assignedPersonnelData) => setAssignedPersonnel(assignedPersonnelData)}
                confirmationTextImmediateExecution={(confirmationTextData) => setConfirmationText(confirmationTextData)}
                confirmationTypeImmediateExecution={(confirmationTypeData) => setConfirmationType(confirmationTypeData)}
                // quickRemarks={(quickRemarksData) => setQuickRemarks(quickRemarksData)}
                activeSubmitImmediateExecution={(activeSubmitData) => console.log(activeSubmitData)}
                clearAll={true}
                submitted={false}
              />
          )}
          {global.selectedPage === pages.EDIT_ORDER && (
            <SubHeader
              icon={editable ? imgSuccess : imgHeaderInfoIcon}
              headerContent={CONFIRMATION.CONFIRMATION_DETAILS.title}
            >
              {global.globalSearchOrder?.selectedOperation?.confDetails ? (
                <table className={`${styles.table}`}>
                  <thead>
                    <tr>
                      {confDetailsHeaders.map((heading) => (
                        <th>{heading}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {global.globalSearchOrder?.selectedOperation?.confDetails.map(
                      (item, index) => (
                        <tr
                          className={`${index % 2 === 0 ? styles.lightblue : styles.white
                          } ${styles.td}`}
                        >
                          <td>
                            <span className={`${styles.confDetailsType}`}>
                              {item.confirmStatus}
                            </span>
                            {item.confirmStatus === 'CNF'
                              ? 'Final Confirmation'
                              : 'Partial Confirmation'}
                          </td>
                          <td>{item.sapUserId}</td>
                          <td>
                            {item.confStartDate}
                            -
                            {item.confStartTime}
                          </td>
                          <td>
                            {item.confFinishDate}
                            -
                            {item.confFinishTime}
                          </td>
                          <td>
                            {(
                              differenceInMinutes(
                                new Date(
                                  item.confFinishDate.split('/')[2],
                                  item.confFinishDate.split('/')[0],
                                  item.confFinishDate.split('/')[1],
                                  item.confFinishTime
                                    .split(':')[1]
                                    .split(' ')[1] === 'PM'
                                    ? parseInt(item.confFinishTime.split(':')[0], 10) + 12
                                    : item.confFinishTime.split(':')[0],
                                  item.confFinishTime
                                    .split(':')[1]
                                    .split(' ')[0],
                                  0,
                                ),
                                new Date(
                                  item.confStartDate.split('/')[2],
                                  item.confStartDate.split('/')[0],
                                  item.confStartDate.split('/')[1],
                                  item.confStartTime
                                    .split(':')[1]
                                    .split(' ')[1] === 'PM'
                                    ? parseInt(item.confStartTime.split(':')[0], 10) + 12
                                    : item.confStartTime.split(':')[0],
                                  item.confStartTime
                                    .split(':')[1]
                                    .split(' ')[0],
                                  0,
                                ),
                              ) / 60
                            ).toFixed(2)}
                            hrs
                          </td>
                          <td>
                            {/* {item.confirmationText === CONFIRMATION.CONFIRMATION_DETAILS.FAR
                              ? CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[1]
                              : item.confirmationText === CONFIRMATION.CONFIRMATION_DETAILS.PAR
                                ? CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[0]
                                : CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[2]} */}
                            {displayConfirmationText(item)}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  {CONFIRMATION.CONFIRMATION_DETAILS.noConfirmationsText}
                </div>
              )}
            </SubHeader>
          )}
          <div style={{ height: 100 }} />
          {editable && global.selectedPage !== pages.EDIT_ORDER && (
            <div
              className="border-top"
              style={{
                position: 'fixed',
                bottom: 0,
                padding: 10,
                paddingRight: 0,
                paddingBottom: 5,
                width: '100%',
                backgroundColor: 'white',
                overflowX: 'scroll',
              }}
            >
              <div
                className="d-flex align-items-center justify-content-end"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <CustomButton
                  value={workOrderStrings.EXIT_WITHOUT_CREATING_WO}
                  outline
                  icon={imgBack}
                  iconStyle={{ width: 10 }}
                  customStyle={{
                    padding: 5,
                    fontSize: 14,
                    marginRight: 20,
                    float: 'left',
                  }}
                  onPress={goToHome}
                />
                <CustomButton
                  value={workOrderStrings.CREATE_WORK_ORDER}
                  disabled={
                    !(
                      createWorkOrdersState.canBeSaved && createWorkOrdersState.reserveMaterialCanBeSaved
                      && (createWorkOrdersState[
                        createWorkOrderStrings.IMMEDIATE_EXECUTION
                      ].value
                        ? confirmationType !== null
                        && assignedPersonnel.length > 0
                        && assignedPersonnel.filter(
                          ({ value, startDate }) => startDate === undefined || value === '',
                        ).length === 0
                        : true)
                    )
                  }
                  customStyle={{
                    padding: 5,
                    fontSize: 14,
                    marginRight: 20,
                    float: 'right',
                  }}
                  onPress={onSaveClick}
                />
              </div>
            </div>
          )}
        </div>
        {createWorkOrdersState.postSuccesfull && (
          // {true &&
          <ButtonModal
            noOfButtons={global.selectedPage !== pages.EDIT_ORDER ? 2 : 2}
            isSuccess
            workOrderNumber={createWorkOrdersState.newWorkOrderValue}
            onButtonPress={{
              firstButtonPress: goToHome,
              // secondButtonPress: confirmFromCreateOrder,
              secondButtonPress: onFollowUpClick,
            }}
            data={{
              ...createWorkOrderModal,
              secondButton: {
                value:
                  global.selectedPage === pages.FOLLOWUP_ORDER
                    ? 'Create Additional Follow Up order'
                    : 'Create Another order',
              },
            }}
            isCreated={global.selectedPage !== pages.EDIT_ORDER}
          />
        )}
        {createWorkOrdersState.isPostingFailed
        && createWorkOrdersState.newWorkOrderValue !== '' && (
        <ButtonModal
          noOfButtons={2}
          data={{
            ...confExecutionFailure,
            instructionsText1: `Work Order ${createWorkOrdersState.newWorkOrderValue} Created.`,
            // instructionsText2: createWorkOrdersState.errorMessage,
            instructionsText2: 'Failed to do Confirmation',
            // instructionsText1:'SV'
          }}
          onButtonPress={{
            firstButtonPress: onOkClick,
            secondButtonPress: confirmFromCreateOrder,
          }}
        />
        )}
        {createWorkOrdersState.isPostingFailed
        && createWorkOrdersState.newWorkOrderValue === undefined && (
        <ButtonModal
          noOfButtons={1}
          isError
          data={{
            ...invalidWCModal,
            instructionsText1: createWorkOrdersState.errorMessage,
          }}
          onButtonPress={onOkClick}
        />
        )}
        {funcLocModal && (
          // {true &&
          <ButtonModal
            noOfButtons={2}
            isError
            onButtonPress={{
              firstButtonPress: retryFuncLoc,
              secondButtonPress: goToHome,
            }}
            data={funcLocFailApiModal}
          />
        )}
      </div>
      {showPCModal && (
        <ProductCatalogModal
          title={workOrderStrings.PRODUCT_CATALOG}
          index={indexForPC - 1}
          onProductCatalogSearch={updateMaterialDetails}
          closePCModal={closePCModal}
          plantId={global.plantId}
        />
      )}
      {showAssetViewModal && (
        <AssetViewModal
          closeModal={closeAssetViewModal}
          editable={editable}
          onAddMaterials={addMaterialToOrder}
          flocValue={createWorkOrdersState.funcLocValue}
          equipValue={
            createWorkOrdersState[createWorkOrderStrings.EQUIPMENT]
              .selectedObject?.value
          }
        />
      )}
      {editable && global.selectedPage === pages.EDIT_ORDER && (
        <div
          className={`d-flex flex-direction-row justify-content-between ${styles.footerEdit} shadow pl-4 pr-4`}
        >
          <CustomButton
            outline
            onPress={() => {
              setEditable(false);
              deleteBreadCrumb(-1);
              onQuerySettled();
            }}
            icon={imgBack}
            iconStyle={{ width: 10 }}
            value={workOrderStrings.EXIT_WITHOUT_CHANGES}
          />
          <CustomButton
            onPress={onSaveClick}
            disabled={
              !(
                createWorkOrdersState.canBeSaved
                && createWorkOrdersState.reserveMaterialCanBeSaved
              )
            }
            value={workOrderStrings.SAVE_CHANGES}
          />
        </div>
      )}
      <OrderAttachment
        attachments={mountAttachmentList(
          attachments,
          global.userData.mail,
          global.globalSearchOrder?.orderId,
        )}
        visible={showAttachmentModal}
        onClose={() => setShowAttachmentModal(false)}
      />
    </ContentWithBreadcrumb>
  );
}

WorkOrder.propTypes = {
  addBreadCrumb: PropTypes.func,
  addReserveLineItem: PropTypes.func,
  createWorkOrdersState: PropTypes.shape({
    Equipment: PropTypes.shape({
      selectedObject: PropTypes.objectOf(PropTypes.shape()),
      value: PropTypes.string,
    }),
    canBeSaved: PropTypes.bool,
    errorMessage: PropTypes.string || PropTypes.object,
    funcLocValue: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    isPostingFailed: PropTypes.bool,
    newWorkOrderValue: PropTypes.string,
    orderData: PropTypes.shape({
      operations: PropTypes.instanceOf(Array),
      selectedOperation: PropTypes.objectOf(PropTypes.shape()),
    }),
    postSuccesfull: PropTypes.bool,
    reserveMaterialCanBeSaved: PropTypes.bool,
    reserveMaterials: PropTypes.instanceOf(Array),
  }),
  deleteBreadCrumb: PropTypes.func,
  deleteReserveLineItem: PropTypes.func,
  global: PropTypes.shape({
    breadCrumbs: PropTypes.shape({
      length: PropTypes.number,
    }),
    followUpParentOrder: PropTypes.shape({
      orderType: PropTypes.string,
    }),
    globalSearchOrder: PropTypes.shape({
      orderId: PropTypes.number,
      reservationNumber: PropTypes.number,
      selectedOperation: PropTypes.shape({
        confDetails: PropTypes.shape({
          map: PropTypes.func,
        }),
        description: PropTypes.string,
      }),
      shortDescription: PropTypes.string,
    }),
    plantId: PropTypes.number,
    selectedPage: PropTypes.string,
    userData: PropTypes.shape({
      givenName: PropTypes.string,
      mail: PropTypes.string,
      surname: PropTypes.string,
    }),
    userPersonalData: PropTypes.shape({
      sapUserID: PropTypes.func,
    }),
  }),
  hideLoader: PropTypes.func,
  initialiseData: PropTypes.func,
  personnel: PropTypes.shape({
    actualDuration: PropTypes.shape({
      decimal: PropTypes.number,
    }),
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    personnel: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  resetBreadCrumbs: PropTypes.func,
  resetLineItem: PropTypes.func,
  resetState: PropTypes.func,
  showLoader: PropTypes.func,
  updateBreadCrumb: PropTypes.func,
  updateCheckBoxStatus: PropTypes.func,
  updateDropDownItem: PropTypes.func,
  updateEquipment: PropTypes.func,
  updateFailureValue: PropTypes.func,
  updateFlocValue: PropTypes.func,
  updateIsPickerOpenStatus: PropTypes.func,
  updateMaterial: PropTypes.func,
  updateMultiSelect: PropTypes.func,
  updateParentFollowupOrder: PropTypes.func,
  updateReserveMaterials: PropTypes.func,
  updateSelectedPage: PropTypes.func,
  updateSuccessValue: PropTypes.func,
  updateValue: PropTypes.func,
  updateWOValue: PropTypes.func,
};

WorkOrder.defaultProps = {
  addBreadCrumb: () => {},
  addReserveLineItem: () => {},
  createWorkOrdersState: PropTypes.shape({
    Equipment: PropTypes.shape({
      selectedObject: { },
      value: '',
    }),
    canBeSaved: false,
    errorMessage: '',
    funcLocValue: PropTypes.shape({
      name: '',
      value: '',
    }),
    isPostingFailed: false,
    newWorkOrderValue: '',
    orderData: PropTypes.shape({
      operations: [],
      selectedOperation: { },
    }),
    postSuccesfull: false,
    reserveMaterialCanBeSaved: false,
    reserveMaterials: [],
  }),
  deleteBreadCrumb: () => {},
  deleteReserveLineItem: () => {},
  global: PropTypes.shape({
    breadCrumbs: PropTypes.shape({
      length: null,
    }),
    followUpParentOrder: PropTypes.shape({
      orderType: '',
    }),
    globalSearchOrder: PropTypes.shape({
      orderId: null,
      reservationNumber: null,
      selectedOperation: PropTypes.shape({
        confDetails: PropTypes.shape({
          map: () => {},
        }),
        description: '',
      }),
      shortDescription: '',
    }),
    plantId: null,
    selectedPage: '',
    userData: PropTypes.shape({
      givenName: '',
      mail: '',
      surname: '',
    }),
    userPersonalData: PropTypes.shape({
      sapUserID: () => {},
    }),
  }),
  hideLoader: () => {},
  initialiseData: () => {},
  personnel: PropTypes.shape({
    actualDuration: PropTypes.shape({
      decimal: null,
    }),
    endDate: '',
    startDate: '',
    personnel: PropTypes.shape({
      value: '',
    }),
  }),
  resetBreadCrumbs: () => {},
  resetLineItem: () => {},
  resetState: () => {},
  showLoader: () => {},
  updateBreadCrumb: () => {},
  updateCheckBoxStatus: () => {},
  updateDropDownItem: () => {},
  updateEquipment: () => {},
  updateFailureValue: () => {},
  updateFlocValue: () => {},
  updateIsPickerOpenStatus: () => {},
  updateMaterial: () => {},
  updateMultiSelect: () => {},
  updateParentFollowupOrder: () => {},
  updateReserveMaterials: () => {},
  updateSelectedPage: () => {},
  updateSuccessValue: () => {},
  updateValue: () => {},
  updateWOValue: () => {},
};
