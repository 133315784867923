import PropTypes from 'prop-types';
import React from 'react';
import styles from './inspection-line-item.module.scss';
import ArrowRight from '../../../assets/svgs/icon_arrow-right';

export default function InspectionLineItem({ title, details, onClick }) {
  return (
    <div role="button" tabIndex={0} className={`border-bottom ${styles.inspectionItem} flex-col d-flex justify-content-between align-items-center`} onClick={onClick} onKeyDown={(e) => { if (e.keyCode === 13) onClick(); }}>
      <div className={`${styles.lineItem}`}>
        <p className={`${styles.lineItem_title}`}>{title}</p>
        <p className={`${styles.lineItem_details}`}>{details.detail_1}</p>
        <p className={`${styles.lineItem_details}`}>{details.detail_2}</p>
        <p className={`${styles.lineItem_details}`}>{details.detail_3}</p>
      </div>
      <div className="mr-4">
        <ArrowRight />
      </div>
    </div>
  );
}

InspectionLineItem.propTypes = {
  details: PropTypes.shape({
    detail_1: PropTypes.string,
    detail_2: PropTypes.string,
    detail_3: PropTypes.string,
  }),
  onClick: PropTypes.func,
  title: PropTypes.string,
};

InspectionLineItem.defaultProps = {
  details: {},
  onClick: () => { },
  title: '',
};
