import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import {
  listPersonnelData,
  personnelTemplate,
} from '../work-confirm/work-confirm.helper';

import {
  resetFinalConfirmation,
  updatePersonnel,
} from '../single-confirmation/single-confirmation.actions';
import {
  hideLoader,
  showLoader,
  updatePersonnalOption,
  updateFacilPlannerData,
} from '../../../redux/actions/global.actions';
import styles from './home.module.scss';

import WeekSchedule from '../../controls/week-schedule';
import LastConfirmations from '../../controls/last-confirmations';
import TabNavigation from '../../controls/tab-navigation';
import { getWeekScheduleResults, getFacilWrkCntr } from '../week-schedule/week-schedule.helper';
import { appColors } from '../../../constants/colors';
import PageLoader from '../../controls/page-loader';
import AdminSetup from '../admin-setup';
import { loaderMessages, generalStrings, queryKeyStrings } from '../../../constants/strings';

const TABS = {
  'My Schedule': WeekSchedule,
  'My Confirmations (Last 7 Days)': LastConfirmations,
  'Follow-Up Backlog': WeekSchedule,
};

const ADMINTABS = {
  'My Schedule': WeekSchedule,
  'My Confirmations (Last 7 Days)': LastConfirmations,
  'Follow-Up Backlog': WeekSchedule,
  'Admin Setup': AdminSetup,
};

export default function Home({ global }) {
  const globalReducer = useSelector((state) => state.globalReducer);
  const {
    plantId,
    userData,
    userPersonalData,
    lastUpdatedDate,
  } = globalReducer;
  const { personalNo } = userPersonalData;
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    queryClient.invalidateQueries(`${queryKeyStrings.ORDERSCREATEDBYMEV1}-${personalNo}-${userData?.mail}-${plantId}`);
    queryClient.invalidateQueries(`${queryKeyStrings.BACKLOGSCHEDULE}-${personalNo}-${userData?.mail}-${plantId}`);
    queryClient.invalidateQueries(`${queryKeyStrings.PERSONALSCHEDULEV1}-${personalNo}-${userData?.mail}-${plantId}`);
    queryClient.invalidateQueries(`${queryKeyStrings.WCSCHEDULEV2}-${personalNo}-${userData?.mail}-${plantId}`);
    queryClient.invalidateQueries(`${queryKeyStrings.FACILSCHEDULEV1}-${personalNo}-${userData?.mail}-${plantId}`);
    queryClient.invalidateQueries(`${queryKeyStrings.LASTCONFIRMATIONSV1}-${plantId}`);
  };

  const prefetchPersonalSchedule = async () => {
    await queryClient.prefetchQuery(
      `${queryKeyStrings.personalScheduleV1}-${personalNo}-${userData?.mail}`,
      async () => (
        getWeekScheduleResults({
          plant: parseInt(plantId, 10),
          pernr: parseInt(personalNo, 10),
          userId: userData?.mail.split('@')[0],
        })
      ),
      {
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        retry: false,
        refetchOnWindowFocus: false,
      },
    );
  };

  const query = useQuery({
    queryKey: `${queryKeyStrings.PERSONNALDATAV1}-${globalReducer.userData?.mail}-${plantId}`,
    queryFn: async () => {
      const response = await listPersonnelData(globalReducer);
      return response;
    },
    enabled: plantId !== null && userData !== null,
    onSettled: () => dispatch(hideLoader()),
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60 * 24,
    retry: false,
  });

  const facilPlannerQuery = useQuery({
    queryKey: `${queryKeyStrings.FACILDATAV1}-${globalReducer.userData?.mail}-${plantId}`,
    queryFn: async () => {
      await getFacilWrkCntr({
        plant: plantId,
        planner: userData?.mail.split('@')[0],
      });
    },
    enabled: plantId !== null && userData !== null,
    onSettled: () => dispatch(hideLoader()),
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60 * 24,
    retry: false,
  });

  useEffect(() => {
    if (facilPlannerQuery.isSuccess && facilPlannerQuery.data) {
      dispatch(updateFacilPlannerData(facilPlannerQuery.data));
    }
    // eslint-disable-next-line
  }, [facilPlannerQuery.isSuccess, plantId]);

  useEffect(() => {
    dispatch(resetFinalConfirmation());
    if (plantId !== null && personalNo !== '' && userData !== null) {
      prefetchPersonalSchedule();
    }
    // eslint-disable-next-line
  }, [userData, plantId]);

  useEffect(() => {
    if (query.status === 'loading') dispatch(showLoader(loaderMessages.INITIALISING_DATA));
    // eslint-disable-next-line
  }, [query.status]);

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (query.isSuccess && query.data) {
      const localPersonnelOptions = [];
      const personalInfo = query.data.personalData;
      const userPersonalInfo = query.data.userPersonalData;
      // const { personalData, userPersonalData } = query.data;

      const personalNos = personalInfo.map((no) => no.personalNo);
      const noDuplicatedPersonnel = personalInfo.filter(
        (personalDetails, index) => !personalNos.includes(personalDetails.personalNo, index + 1),
      );
      noDuplicatedPersonnel.forEach((personnelData) => {
        const splitedName = personnelData.name.split(' ');
        if (personnelData.personalNo !== userPersonalInfo.personalNo) {
          localPersonnelOptions.push({
            label: `${splitedName[1]}, ${splitedName[0]} - ${personnelData.personalNo}`,
            value: personnelData.personalNo,
            workCenter: personnelData.workCenter,
          });
        }
      });

      if (userPersonalInfo.name !== '') {
        localPersonnelOptions.push(
          personnelTemplate(userPersonalInfo.name, userPersonalInfo.personalNo),
        );
      }

      dispatch(updatePersonnel(localPersonnelOptions));
      if (Object.prototype.hasOwnProperty.call(userPersonalInfo, generalStrings.NAME)) {
        dispatch(updatePersonnalOption(userPersonalInfo));
      }
    }
    // eslint-disable-next-line
  }, [query.isSuccess]);

  const isAdmin = (userData?.mail.split('@')[0].toLowerCase() === 'brenda.wallace' || userData?.mail.split('@')[0].toLowerCase() === 'ken.hicks' || userData?.mail.split('@')[0].toLowerCase() === 'leonardo.mattos' || userData?.mail.split('@')[0].toLowerCase() === 'sainag.chunduru');

  return (
    <div className="row gx-0">
      <div className="col d-flex flex-column p-0">
        <div
          className="border-bottom"
          style={{
            fontFamily: 'OpenSans-Regular',
            paddingBottom: 10,
            paddingLeft: 20,
            marginTop: 56,
            fontSize: 18,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {
            global.userData?.displayName
            && (
              <div>
                {`Welcome ${global.userData?.displayName} !`}
                <span
                  onClick={onLogout}
                  style={{
                    fontFamily: 'OpenSans-Regular',
                    fontSize: 14,
                    marginLeft: 10,
                    color: appColors.TEXT_STRONG_BLUE,
                    cursor: 'pointer',
                  }}
                  role="presentation"
                >
                  Not you? Click here to logout.
                </span>
              </div>
            )
          }
          {
            lastUpdatedDate > 0
            && (
              <div className={`${styles.lastUpdatedDateStyle} mr-4`}>
                {`Results are as of ${new Date(lastUpdatedDate).toLocaleString()}`}
                <span
                  onClick={invalidateQueries}
                  style={{
                    fontFamily: 'OpenSans-Regular',
                    fontSize: 14,
                    marginLeft: 10,
                    color: appColors.TEXT_STRONG_BLUE,
                    cursor: 'pointer',
                  }}
                  role="presentation"
                >
                  Refresh Now
                </span>
              </div>
            )
          }
        </div>
        {query.isSuccess && <TabNavigation tabs={isAdmin ? ADMINTABS : TABS} colValue={isAdmin ? 3 : 4} />}
        {query.isLoading && <PageLoader message="Loading Personal Data ..." style={{ height: '600px' }} />}
      </div>
    </div>
  );
}

Home.propTypes = {
  global: PropTypes.shape({
    userData: PropTypes.shape({
      displayName: PropTypes.string,
    }),
  }),
};

Home.defaultProps = {
  global: {},
};
