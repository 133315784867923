import PropTypes from 'prop-types';
import React from 'react';
import styles from './attachment-icon.module.scss';
import IconPaperclip from '../../../assets/svgs/icon_paperclip';

const AttachmentsIcon = React.memo(({ length }) => (
  <span className={styles.clipContainer}>
    <IconPaperclip fill={length !== 0 ? '#0578BE' : '#7F99B2'} />
    {length !== 0 && <span className={styles.clipNumber}>{length}</span>}
  </span>
));

AttachmentsIcon.propTypes = {
  length: PropTypes.number,
};

AttachmentsIcon.defaultProps = {
  length: 0,
};

export default AttachmentsIcon;
