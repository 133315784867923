import PropTypes from 'prop-types';
import React from 'react';

export default function RoundedArrowLeft({ fill }) {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.3125C15.3516 0.3125 19.6875 4.64844 19.6875 10C19.6875 15.3516 15.3516 19.6875 10 19.6875C4.64844 19.6875 0.3125 15.3516 0.3125 10C0.3125 4.64844 4.64844 0.3125 10 0.3125ZM8.87109 5.92188L11.8203 8.75H4.6875C4.16797 8.75 3.75 9.16797 3.75 9.6875V10.3125C3.75 10.832 4.16797 11.25 4.6875 11.25H11.8203L8.87109 14.0781C8.49219 14.4414 8.48438 15.0469 8.85547 15.418L9.28516 15.8438C9.65234 16.2109 10.2461 16.2109 10.6094 15.8438L15.793 10.6641C16.1602 10.2969 16.1602 9.70312 15.793 9.33984L10.6094 4.15234C10.2422 3.78516 9.64844 3.78516 9.28516 4.15234L8.85547 4.57812C8.48438 4.95312 8.49219 5.55859 8.87109 5.92188Z"
        fill={fill || '#0578BE'}
      />
    </svg>
  );
}

RoundedArrowLeft.propTypes = {
  fill: PropTypes.string,
};

RoundedArrowLeft.defaultProps = {
  fill: '',
};
