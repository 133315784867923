import { format } from 'date-fns';
import parse from 'date-fns/parse';
import {
  reservationStrings,
  createWorkOrderStrings,
} from '../../../constants/strings';
import { getURL } from '../../../Helpers/common-helpers';
import {
  POST_ORDERS,
  COST_CENTER_RESERVATION,
} from '../../../constants/endpoints';
import { HttpPut, HttpPost } from '../../../services/api-services';

export const checkMaterialValidationClass = (valueClass) => {
  if (valueClass.startsWith('B')) {
    throw reservationStrings.VAL_CLS_MIS;
  }
};
function prepareCCObject(plantId, orderData, reserveMaterials, sapName) {
  return {
    plantId,
    costCenter: orderData.costCenterId,
    createdBy: sapName,
    reservationItems: reserveMaterials
      .map((item) => {
        let obj = {};
        if (item.isMaterialFetched) {
          obj = {
            materialNo: item[createWorkOrderStrings.MATERIAL],
            goodsRecipent: item[createWorkOrderStrings.RECIPIENT],
            unloadingPoint: item[createWorkOrderStrings.UNLOAD_POINT],
            quantity: item[createWorkOrderStrings.QTY_RQD],
          };
          return obj;
        }
        return undefined;
      })
      .filter((item) => item !== undefined),
  };
}

const prepareWOObject = (orderData, reserveMaterials) => {
  const userStatus = orderData.userStatus?.split(' ');
  return {
    activity: orderData.selectedOperation.activity,
    orderId: orderData.orderId,
    plantId: orderData.plant,
    userName: orderData.createdBy,
    reservationNumber: orderData.reservationNumber,
    shortText: orderData.shortDescription,
    longText:
      orderData.longText?.length > 40
        ? orderData.longText
        : orderData.shortDescription,
    priority: orderData.priority,
    orderType: orderData.orderType,
    activityType: orderData.activityType,
    mainWorkCenter: orderData.mnWrkCtrId,
    fuctionalLoc: orderData.funLoc,
    sysCondition: orderData.sysCondition,
    superiorOrderId: '',
    startDate: format(
      parse(orderData.selectedOperation.earlyStartDt, 'yyyyMMdd', new Date()),
      'yyyy-MM-dd',
    ),
    revision: orderData.revisionNumber,
    equipment: orderData.equipNr,
    impact: userStatus.filter((item) => item.includes('10')),
    speciality: userStatus.filter((item) => item.includes('01')),
    kitting: userStatus.includes('04RK'),
    immediateExecution: userStatus.includes('04AD'),
    operation: {
      mainWorkCenter: orderData.selectedOperation.workCenter,
      shortText: orderData.selectedOperation.description,
      duration: `${orderData.selectedOperation.opDuration}`,
      controlKey: orderData.selectedOperation.controlKey,
      numberCapacities: parseInt(orderData.selectedOperation.numberOfCapacities, 10),
      longText:
        orderData.selectedOperation?.longText?.length > 40
          ? orderData.selectedOperation.longText
          : orderData.selectedOperation.description,
    },
    component: [
      // ...orderData.selectedOperation.materials
      //     .map((item, index) => {
      //         return {
      //             materialNo: item.materialReserved,
      //             goodsRecipent: item.recipient,
      //             unloadingPoint: item.unloadingPoint,
      //             reqmntQty: item.reqQuantity,
      //             itemCategory: 'L',
      //             reservationNumber: orderData.reservationNumber,
      //             reservationItem: item.isMaterial === 'I' ? '0000' : String(index + 1).padStart(4, '0'),
      //         }
      //     }),
      ...reserveMaterials
        .map((item) => {
          let obj = {};
          if (item.isMaterialFetched) {
            obj = {
              materialNo: item[createWorkOrderStrings.MATERIAL],
              goodsRecipent: item[createWorkOrderStrings.RECIPIENT],
              unloadingPoint: item[createWorkOrderStrings.UNLOAD_POINT],
              reqmntQty: item[createWorkOrderStrings.QTY_RQD],
              itemCategory: 'L',
              reservationNumber: orderData.reservationNumber,
              reservationItem: '0000',
              isMaterial: 'I',
            };
            return obj;
          }
          return undefined;
        })
        .filter((item) => item !== undefined),
    ],
  };
};

export const woReservation = async (orderData, reserveMaterials) => {
  try {
    const apiURL = getURL(POST_ORDERS);
    const reqObj = prepareWOObject(orderData, reserveMaterials);
    let result = await HttpPut(apiURL, reqObj);
    return result.data.message.data.order;
  } catch (e) {
    console.error(e);
  }
};

export const costCenterReservation = async (
  plantId,
  orderData,
  reserveMaterials,
  sapName,
) => {
  try {
    const apiURL = getURL(COST_CENTER_RESERVATION);
    const reqObj = prepareCCObject(plantId, orderData, reserveMaterials, sapName);
    await HttpPost(apiURL, reqObj);
  } catch (e) {
    console.error(e);
  }
};

export const wbsReservation = async (
  plantId,
  wbsData,
  reserveMaterials,
  sapName,
) => {
  try {
    const apiURL = getURL(COST_CENTER_RESERVATION);
    const reqObj = {
      plantId,
      createdBy: sapName,
      costCenter: null,
      wbs: wbsData.value,
      prUname: sapName,
      billOfLadding: sapName,
      reservationItems: reserveMaterials
        .map((item) => {
          let obj = {};
          if (item.isMaterialFetched) {
            obj = {
              materialNo: item[createWorkOrderStrings.MATERIAL],
              // goodsRecipent: item[createWorkOrderStrings.RECIPIENT],
              goodsRecipent: sapName,
              unloadingPoint: item[createWorkOrderStrings.UNLOAD_POINT],
              quantity: item[createWorkOrderStrings.QTY_RQD],
            };
            return obj;
          }
          return undefined;
        })
        .filter((item) => item !== undefined),
    };
    await HttpPost(apiURL, reqObj);
  } catch (e) {
    console.error(e);
  }
};
