import PropTypes from 'prop-types';
import React, {
  useState, useEffect, useRef
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMsal, useAccount } from '@azure/msal-react';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import exportFromJSON from 'export-from-json';
import { useQueryClient } from 'react-query';
// import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
// import { saveAs } from 'file-saver';
import CustomButton from '../../controls/custom-button';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import bluePlus from '../../../assets/images/plus.png';
import CustomModal from '../../controls/custom-modal';
import SubHeaderBorder from '../../controls/sub-header-with-border';
import CustomDropdown from '../../controls/custom-dropdown/custom-dropdown.component';
import CustomTextInput from '../../controls/custom-textinput/custom-textinput.component';
import CustomRadioButton from '../../controls/custom-radio-button';
import './spares-management.css';
import trash from '../../../assets/images/delete-red.svg';
import { checkArrayNotNull, checkNotNull, getURL, uuidv4 } from '../../../Helpers/common-helpers';
import warning from '../../../assets/images/warning-fill.png';
import blueTick from '../../../assets/images/tick-blue.png';
import success from '../../../assets/images/success.png';
import {
  HttpDelete, HttpGet, HttpPost, HttpPut,
} from '../../../services/api-services';
import { AREA_GROUPS, SPARES, SPARES_INFO, SPARES_MGT_WITH_MATERIAL_ID, SPARES_MATERIAL_ID } from '../../../constants/endpoints';
import {
  queryKeyStrings, newPageStrings, materialInfoStrings, siteStrings, advancedDetails, customDropdownStrings, pages, queryStrings
} from '../../../constants/strings';
import blueClose from '../../../assets/images/Close.svg';
import ButtonModal from '../../controls/button-modal';
import {
  sparesManagementModal,
  newPageClearDataModal,
} from '../../../constants/enums';
import plus from '../../../assets/images/plus-white.svg';
import AGGridTable from './ag-grid-table-component';
import styles from './spares-management.module.scss';
import excelSheet from '../../../assets/images/spares_management_template.xlsx';
import info from '../../../assets/images/info.svg';
import cross from '../../../assets/images/Icons.svg';
import download from '../../../assets/images/cloud-download.svg';
import imgClearInput from '../../../assets/images/clear-input.png';
import externalRepairIcon from '../../../assets/images/external-spares.svg';
import { getFormsData, uploadFileToS3 } from '../work-order/work-order.helper';
import { loginSilentRequest } from '../../../Helpers/auth-config';
import FormSubHeader from '../../controls/form-sub-header';
import ContentField from '../../controls/content-field';
import SparesMgtTransaction from '../../controls/spares-mgt-transaction';
import { sparesMgtHeaders } from '../../../constants/enums';
import { groupBy } from 'lodash';
import Tippy from '@tippyjs/react';
import MultiDropdown from '../../controls/multi-dropdown/multi-dropdown';
import { plantDetails } from '../../../constants/enums';
import queryClients from '../../../utilities/queryClient';
import launchGME from '../../../assets/images/launch-gme.svg';


function NewPage({
  showLoader, hideLoader, addBreadCrumb, resetBreadCrumbs, global, updateSelectedPage, dataFromSpares,
}) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [openModal, setOpenModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [clearModalData, setClearModalData] = useState(false);
  const [modalStatus, setModalStatus] = useState(null);
  const [isPostingSuccess, setIsPostingSuccess] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [filterAreaGroup, setFilterAreaGroup] = useState(null);
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const queryClient = useQueryClient();
  const [subHeaderData, setSubHeaderData] = useState([{
    opened: true, advancedDetailsOpen: true, attachedFiles: [], unSavedFile: null, unSavedDescription: '', advancedDetails: {}, [newPageStrings.SITE]: plantDetails[global.globalReducer.plantId].toUpperCase(), flocDetails: queryClients.getQueryData([queryStrings.FLOC, global.globalReducer.plantId]) || []
  }]);
  const [areaGroupData, setAreaGroupData] = useState(null);
  const [repairStatusModal, setRepairStatusModal] = useState(false);
  const [repairStatusValue, setRepairStatusValue] = useState(null);
  const [editRepairStatusIndex, setEditRepairStatusIndex] = useState(null);
  const [editEntries, setEditEntries] = useState(false);
  const [sapMatlModal, setSapMatlModal] = useState(false);
  const [materialActiveTab, setMaterialActiveTab] = useState(materialInfoStrings.MATERIAL_DATA);
  const [filterSite, setFilterSite] = useState(null);
  const [filteredData, setFilterdData] = useState([]);
  const [attachmentDescriptionValue, setAttachmentDescriptionValue] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [editSubheaderData, setEditSubHeaderData] = useState(null);
  const [initialEditData, setInitialEditData] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [viewTableData, setViewTableData] = useState(null);
  const [attachedFile, setAttachedFile] = useState(null);
  const [externalRepairModal, setExternalRepairModal] = useState(false);
  const [filteredTransactions, setFilteredTransactions] = useState(null);
  const [sapMaterialData, setSapMaterialData] = useState(null);
  const [storesItem, setStoresItem] = useState(false);
  const [unRestrictedQuantity, setUnrestrictedQuantity] = useState(null);
  const [flocData, setFlocData] = useState(null);
  const [plantId, setPlantId] = useState(global.globalReducer.plantId);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    funcQueryOnChange();
  }, [plantId, index]);

  const funcQueryOnChange = async () => {
    const status = queryClients.getQueryState([queryStrings.FLOC, plantId]);
    if (!status) {
      await queryClients.prefetchQuery(
        [queryStrings.FLOC, plantId],
        fetchForms,
      );
    }
    onQuerySettled(plantId);
  };
  const fetchForms = async () => {
    try {
      showLoader(newPageStrings.FETCHING_FLOC_DATA);
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      let res = await getFormsData({ plantId }, response.idToken);
      hideLoader();
      return res;
    } catch (e) {
      hideLoader();
      console.log('error');
      console.log(JSON.stringify(e));
      throw e;
    }
  };
  const onQuerySettled = async (plantId) => {
    const querySettledData = queryClients.getQueryData([queryStrings.FLOC, plantId]);
    let updatedData = subHeaderData;
    updatedData[index].flocDetails = querySettledData;
    setSubHeaderData(updatedData);
    setFlocData(querySettledData);
    return querySettledData;
  };
  useEffect(() => {
    let newFilteredData = rowData;
    if (filterSite?.value) {
      newFilteredData = newFilteredData?.filter(
        (item) => item.plant_id === filterSite.value,
      );
    }
    if (filterAreaGroup?.name) {
      newFilteredData = newFilteredData?.filter(
        (item) => item.area_group === filterAreaGroup?.name,
      );
    }
    if (checkNotNull(filterSearchValue)) {
      newFilteredData = newFilteredData?.filter((item) => item?.equip_component_description?.toUpperCase().includes(filterSearchValue));
    }
    setFilterdData(newFilteredData);
  }, [filterSite, filterAreaGroup, filterSearchValue, rowData]);

  const getAreaGroups = async () => {
    const apiURL = `${getURL(AREA_GROUPS)}`;
    const response = await HttpGet(apiURL);
    if (response.data.message.data.error) {
      throw response.data.message.data.error.message;
    }
    return response.data.message.data;
  };
  // eslint-disable-next-line
  const onAreaGroupsData = async () => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.AREA_GROUPS}/`,
        queryFn: async () => {
          const response = await getAreaGroups();
          return response;
        },
        select: (areaGroups) => areaGroups,
        retry: false,
        staleTime: 1000,
      });
      setAreaGroupData(data);
      return data;
    } catch (e) {
      console.log(new Error(e));
      hideLoader();
    }
  };
  const getSpares = async () => {
    const apiURL = `${getURL(SPARES)}`;
    const response = await HttpGet(apiURL);
    if (response.data.message.data.error) {
      throw response.data.message.data.error.message;
    }
    return response.data.message.data;
  };
  const getMaterials = async (materialId) => {
    const apiURL = getURL(SPARES_MGT_WITH_MATERIAL_ID);
    const params = { materialId: materialId };
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
      throw response.data.message.data.error.message;
    }
    return response.data.message.data;
  };
  const getSapMaterials = async (sapMaterialId, isFromSapInput = false) => {
    const apiURL = `${getURL(SPARES_MATERIAL_ID)}`;
    const params = { materialId: sapMaterialId };
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
      if (isFromSapInput) {
        setStoresItem(false);
      }
      throw response.data.message.data.error[0].description;
    }
    if (isFromSapInput) {
      setStoresItem(true);
      setUnrestrictedQuantity(response.data.message.data[0].unRestrictedQuantity);
    }
    return response.data.message.data;
  };
  const onSparesData = async () => {
    try {
      showLoader(newPageStrings.FETCHING_SPARES_DATA);
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SPARES}/`,
        queryFn: async () => {
          const response = await getSpares();
          return response;
        },
        select: (spares) => spares,
        retry: false,
        staleTime: 1000,
      });
      setIsDeleted(false);
      setIsEdited(false);
      setRowData(data);
      setFilterSite(filterSite || {
        name: siteStrings.filter((item) => item.value === global.globalReducer.plantId.toString())[0].name.toUpperCase(),
        value: parseInt(global.globalReducer.plantId, 10),
      });
      hideLoader();
    } catch (e) {
      hideLoader();
      console.log(new Error(e));
    }
  };
  const onMaterialData = async (params) => {
    try {
      showLoader(newPageStrings.FETCHING_MATERIAL_DATA);
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SPARES}/`,
        queryFn: async () => {
          const response = await getMaterials(params.data.sap_matl_id);
          return response;
        },
        select: (spares) => spares,
        retry: false,
        staleTime: 1000,
      });
      setFilteredTransactions(groupBy(data, 'materialId'));
      setExternalRepairModal(true);
      hideLoader();
    } catch (e) {
      hideLoader();
      setExternalRepairModal(true);
      console.log(new Error(e));
    }
  };
  const onSapMaterialData = async (sapMatlId) => {
    try {
      showLoader(newPageStrings.FETCHING_MATERIAL_DATA);
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SPARES}/`,
        queryFn: async () => {
          const response = await getSapMaterials(sapMatlId);
          return response;
        },
        select: (spares) => spares,
        retry: false,
        staleTime: 1000,
      });
      setSapMaterialData(data);
      setSapMatlModal(true);
      hideLoader();
    } catch (e) {
      hideLoader();
      setSapMatlModal(true);
      console.log(new Error(e));
    }

  };
  useEffect(() => {
    resetBreadCrumbs();
    addBreadCrumb({
      name: newPageStrings.SPARES_MANAGEMENT,
      action: null,
    });
    onAreaGroupsData();
  }, []);
  useEffect(() => {
    onSparesData();
  }, [isPostingSuccess, isDeleted, isEdited]);
  const toggleSubheader = (index, boolean) => {
    const newArray = [...subHeaderData];
    if (!boolean) {
      newArray[index].opened = !newArray[index].opened;
      newArray[index].advancedDetailsOpen = !newArray[index].advancedDetailsOpen;
    } else {
      newArray[index].advancedDetailsOpen = !newArray[index].advancedDetailsOpen;
    }
    setSubHeaderData(newArray);
  };
  const exportSelectedRows = () => {
    const fileName = newPageStrings.SPARES_MANAGEMENT_EXPORT_XLSX;
    const exportType = newPageStrings.XLS;
    if (selectedRows?.length) {
      return exportFromJSON({ data: selectedRows, fileName, exportType });
    }
    return exportFromJSON({ data: filteredData, fileName, exportType });
  };
  const openLaunchGME = () => {
    let a = document.createElement('a');
    a.href = newPageStrings.LAUNCH_GME_LINK;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  const onNavItemClick = (target) => {
    if (target !== state.selectedPage) {
      resetBreadCrumbs(() => {
        updateSelectedPage(pages.HOME);
      });
      updateSelectedPage(target);
    }
  };
  const state = useSelector((state1) => state1.globalReducer);
  const onGoToWorkOrderClick = (target, selectedItem) => {
    let obj = {
      OrderType: {
        "key": "Order Type",
        "value": "ZM09",
        "name": "ZM09 - REFURBISHMENT ORDER"
      },
      systemCondition: {
        "key": "System Condition",
        "value": "5",
        "name": "5 - RUNTIME",
      },
      priority: {
        "key": "Priority",
        "value": "4",
        "name": "4 - 2 WEEKS"
      },
      activityType: {
        "key": "Activity Type",
        "value": "136",
        "name": "136 - REPAIR BY EXTERNAL RESOURCES"
      },
      orderDescription: { key: 'Order Description', value: `Repair of ${selectedItem.data.equip_component_description}` },
      operationDescription: { key: 'Operation Description', value: `Repair of ${selectedItem.data.equip_component_description}` },
      plantId: selectedItem.data.plant_id,
      sap_floc_id: {
        name: selectedItem.data.sap_floc_id,
        value: selectedItem.data.sap_floc_id,
      },

    }
    dataFromSpares(obj);
    resetBreadCrumbs(() => {
      updateSelectedPage(pages.HOME);
    });
    updateSelectedPage(target);
  };
  const addNewSubheader = async () => {
    const newArray = [
      ...subHeaderData,
      {
        opened: true,
        advancedDetailsOpen: true,
        attachedFiles: [],
        advancedDetails: {},
        [newPageStrings.SITE]: plantDetails[global.globalReducer.plantId].toUpperCase(),
        flocDetails: await onQuerySettled(global.globalReducer.plantId)
      },
    ];
    setSubHeaderData(newArray);
  };
  const setModal = async () => {
    let updatedData = subHeaderData;
    updatedData[0].flocDetails = queryClients.getQueryData([queryStrings.FLOC, global.globalReducer.plantId]);
    setSubHeaderData(updatedData);
    setOpenModal(true);
  };
  const convertFileToJson = () => {
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        workbook.SheetNames.forEach((sheet) => {
          const rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet],
          );
          const newArray = rowObject.map((item) => ({
            opened: true,
            advancedDetailsOpen: true,
            attachedFiles: [],
            [newPageStrings.ADDITIONAL_DESCRIPTION]: item[newPageStrings.ADDITIONAL_DESCRIPTION],
            [newPageStrings.AREA_GROUP]: item[newPageStrings.AREA_GROUP],
            [newPageStrings.CRITICAL_SPARE]: item[newPageStrings.CRITICAL_SPARE],
            [newPageStrings.COMPONENT_DESCRIPTION]: item[newPageStrings.COMPONENT_DESCRIPTION],
            [newPageStrings.IS_ASSET]: item[newPageStrings.IS_ASSET],
            [newPageStrings.UOM]: item[newPageStrings.UOM],
            [newPageStrings.QTY_REQUIRED]: item[newPageStrings.QTY_REQUIRED],
            [newPageStrings.QTY_ON_HAND]: item[newPageStrings.QTY_ON_HAND],
            [newPageStrings.REPAIR_STATUS]: item[newPageStrings.REPAIR_STATUS],
            [newPageStrings.REPAIRABLE]: item[newPageStrings.REPAIRABLE],
            [newPageStrings.SAP_MAT_ID]: item[newPageStrings.SAP_MAT_ID],
            [newPageStrings.SITE]: item[newPageStrings.SITE],
            [newPageStrings.STORES_ITEM]: item[newPageStrings.STORES_ITEM],
            advancedDetails: {
              area_sub_group_1_id: item[newPageStrings.AREA_SUB_GROUP_1],
              area_sub_group_2_id: item[newPageStrings.AREA_SUB_GROUP_2],
              class_id: item[newPageStrings.SPARES_CLASS],
              ac_dc_id: item[newPageStrings.AC_DC_VALUE],
              construction_material_id: item[newPageStrings.CONSTRUCTION_MATERIAL],
              frame_id: item[newPageStrings.FRAME_VALUE],
              phase_id: item[newPageStrings.PHASE_VALUE],
              storage_location_id: item[newPageStrings.STORAGE_LOCATION],
              location_used_id: item[newPageStrings.LOCATION_USED_VALUES],
              notes: item[newPageStrings.NOTES],
              model_nbr: item[newPageStrings.MODEL_NBR],
              install_time: item[newPageStrings.INSTALL_TIME],
              qty_in_repair: item[newPageStrings.QTY_IN_REPAIR],
              horsepower: item[newPageStrings.HORSE_POWER],
              serial_nbr: item[newPageStrings.SERIAL_NBR],
              output_rpm: item[newPageStrings.OUTPUT_RPM],
              mfg_part_nbr: item[newPageStrings.MFG_PART_NBR],
              mfg_drawing_ref: item[newPageStrings.MFG_DRAWING_REF],
              estimated_cost: item[newPageStrings.ESTIMATED_COST],
              input_rpm: item[newPageStrings.INPUT_RPM],
              servicer_factor: item[newPageStrings.SERVICER_FACTOR],
              updates: item[newPageStrings.UPDATES],
              amperage: item[newPageStrings.AMPERAGE],
              sap_equip_id: item[newPageStrings.SAP_EQUIP_ID],
              lead_time: item[newPageStrings.LEAD_TIME],
              sap_floc_id: item[newPageStrings.SAP_FLOC_ID],
              mfg_oem: item[newPageStrings.MFG_OEM],
              voltage: item[newPageStrings.VOLTAGE],
              ratio: item[newPageStrings.RATIO],
            }
          }));
          setSubHeaderData(newArray);
          setBulkModal(false);
        });
      };
    }
  };
  const downloadExcelFile = () => {
    const a = document.createElement('a');
    a.href = excelSheet;
    a.download = newPageStrings.SPARES_MANAGEMENT_TEMPLATE;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const getPlantValue = (value) => {
    const plantValue = areaGroupData?.plantSites
      .filter((item) => item.site_title === value)[0]
      .plant_id.toString();
    return plantValue;
  };
  const getPlantTitle = (value) => {
    const plantTitle = areaGroupData?.plantSites
      .filter((item) => item.plant_id.toString() === value.toString())[0]
      ?.site_title;
    return plantTitle;
  };
  const getAreaGroupValue = (value) => {
    const areaGroupId = areaGroupData?.areaGroups
      ?.filter((item) => item.area_group === value)[0]
      .area_group_id.toString();
    return areaGroupId;
  };
  const getAreaGroupValue1 = (value) => {
    const areaGroupId1 = areaGroupData?.areaSubGroups1.filter((item) => item.area_sub_group_1 === value)[0].area_sub_group_1_id.toString();
    return areaGroupId1;
  };
  const getAreaGroupValue2 = (value) => {
    const areaGroupId2 = areaGroupData?.areaSubGroups2.filter((item) => item.area_sub_group_2 === value)[0].area_sub_group_2_id.toString();
    return areaGroupId2;
  };
  const getStorageLocationValue = (value) => {
    const storageLocation = areaGroupData?.storage_locations.filter((item) => item.storage_location === value)[0].storage_location_id.toString();
    return storageLocation;
  };
  const getLocationUsedValue = (value) => {
    const locationUsedValue = areaGroupData?.location_used_values.filter((item) => item.location_used === value)[0].location_used_id.toString();
    return locationUsedValue;
  };
  const getSparesClassValue = (value) => {
    const sparesClassValue = areaGroupData?.spares_class.filter((item) => item.spares_class === value)[0].class_id.toString();
    return sparesClassValue;
  };
  const getStoresItemValue = (value) => {
    const storesItemValue = areaGroupData?.storeItems.filter((item) => item.stores_item === value)[0].stores_item_id.toString();
    return storesItemValue;
  };
  const getFrameValue = (value) => {
    const frameValue = areaGroupData?.frame_values.filter((item) => item.frame_value.toString() === value.toString())[0].frame_id.toString();
    return frameValue;
  };
  const getConstructionMaterialValue = (value) => {
    const constructionMaterialValue = areaGroupData?.construction_materials.filter((item) => item.construction_material === value)[0].construction_material_id.toString();
    return constructionMaterialValue;
  };
  const getAcDcValue = (value) => {
    const acdcValue = areaGroupData?.ac_dc_values.filter((item) => item.ac_dc_value === value)[0].ac_dc_id.toString();
    return acdcValue;
  };
  const getPhaseValue = (value) => {
    const phaseValue = areaGroupData?.phase_values.filter((item) => item.phase_value.toString() === value.toString())[0].phase_id.toString();
    return phaseValue;
  };
  const getUomValue = (value) => {
    const uomId = areaGroupData?.uom
      ?.filter((item) => item.unit_of_measure === value)[0]
      .uom_id.toString();
    return uomId;
  };
  const checkAdvancedDetails = (advDetails) => {
    let flag = 1;
    if (Object.keys(advDetails).length !== 0) {
      Object.values(advDetails).map((item) => {
        if (checkNotNull(item)) {
          flag = 0;
        }
        return null;
      });
      return flag;
    }
    return flag;
  };
  const clearAdvancedDetails = (index) => {
    const newArray = subHeaderData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          advancedDetails: {},
        };
      }
      return item;
    });
    setSubHeaderData(newArray);
  };
  const saveData = async () => {
    try {
      setOpenModal(false);
      showLoader(newPageStrings.SUBMITTING_SPARES);
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      const reqObj = await Promise.all(subHeaderData.map(async (item) => ({
        stores_item: item.attachedFiles && await uploadFileToS3(item.attachedFiles, response.idToken),
        // Attachments: [],
        plant_id: getPlantValue(item[newPageStrings.SITE]),
        area_group_id: getAreaGroupValue(item[newPageStrings.AREA_GROUP]),
        sap_matl_id: item[newPageStrings.SAP_MAT_ID] && item[newPageStrings.SAP_MAT_ID].toString(),
        qty_on_hand: item[newPageStrings.QTY_ON_HAND],
        qty_required: item[newPageStrings.QTY_REQUIRED],
        uom_id:
          item[newPageStrings.UOM] && getUomValue(item[newPageStrings.UOM]),
        equip_component_description: item[newPageStrings.COMPONENT_DESCRIPTION],
        additional_desc_info: item[newPageStrings.ADDITIONAL_DESCRIPTION],
        repair_status: item[newPageStrings.REPAIR_STATUS] || false,
        critical_spare: item[newPageStrings.CRITICAL_SPARE] || false,
        repairable: item[newPageStrings.REPAIRABLE] || false,
        is_asset: item[newPageStrings.IS_ASSET] || false,
        ...item?.advancedDetails,
        area_sub_group_1_id: item.advancedDetails?.area_sub_group_1_id && getAreaGroupValue1(item.advancedDetails.area_sub_group_1_id),
        area_sub_group_2_id: item.advancedDetails?.area_sub_group_2_id && getAreaGroupValue2(item.advancedDetails.area_sub_group_2_id),
        phase_id: item.advancedDetails?.phase_id && getPhaseValue(item.advancedDetails.phase_id),
        ac_dc_id: item.advancedDetails?.ac_dc_id && getAcDcValue(item.advancedDetails.ac_dc_id),
        construction_material_id: item.advancedDetails?.construction_material_id && getConstructionMaterialValue(item.advancedDetails.construction_material_id),
        frame_id: item.advancedDetails?.frame_id && getFrameValue(item.advancedDetails.frame_id),
        storage_location_id: item.advancedDetails?.storage_location_id && getStorageLocationValue(item.advancedDetails.storage_location_id),
        location_used_id: item.advancedDetails?.location_used_id && getLocationUsedValue(item.advancedDetails.location_used_id),
        class_id: item.advancedDetails?.class_id && getSparesClassValue(item.advancedDetails.class_id),
        created_by: global.globalReducer.userPersonalData.sapUserID,
        modified_by: global.globalReducer.userPersonalData.sapUserID,
        sap_floc_id: item?.advancedDetails?.sap_floc_id?.value,

        // stores_item_id: '2'
      })));
      const apiUrl = `${getURL(SPARES)}`;
      const result = await HttpPost(apiUrl, reqObj);
      setModalStatus(result.data.message.data);
      setIsPostingSuccess(true);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.log(
        error?.response?.data?.message?.data?.error[0]?.description,
        'error',
      );
      setIsPostingSuccess(false);
      setModalStatus(
        error?.response?.data?.message?.data?.error[0]?.description,
      );
    }

    // setModalStatus(result);
  };

  // const onFileUpload = async (e) => {
  //   if (e.target.files[0]) {
  //     const imgURL = window.URL.createObjectURL(e.target.files[0]);
  //     const reader = new FileReader();
  //     const fileName = e.target.files[0].name;
  //     reader.readAsDataURL(e.target.files[0]);
  //     const id = uuidv4();
  //     reader.onloadend = () => {
  //       const imgFiles = [...base64List];
  //       imgFiles.push({
  //         id,
  //         base64Code: reader.result.substring(
  //           reader.result.indexOf(',') + 1,
  //           reader.result.length,
  //         ),
  //         name: fileName,
  //       });
  //       setBase64List(imgFiles);
  //     };
  //     const imgFiles = [...attachedFile];
  //     const imgSize = (e.target.files[0].size / 1000000).toFixed(2);
  //     imgFiles.push({
  //       id,
  //       dataURI: imgURL,
  //       name: fileName,
  //       size: imgSize,
  //     });
  //     setAttachedFile(imgFiles);
  //   }
  // };
  const editViewEntries = async (value, sparesId) => {
    try {
      let repairStatusData = rowData.filter((item) => item.spares_id === sparesId)[0];
      showLoader(newPageStrings.EDITING_REPAIR_STATUS);
      const reqObj = {
        repair_status: value,
        equip_component_description: repairStatusData?.equip_component_description,
        additional_desc_info: repairStatusData?.additional_desc_info,
        plant_id: typeof repairStatusData?.plant_id === 'number' ? repairStatusData?.plant_id : getPlantValue(repairStatusData?.plant_id),
        critical_spare: repairStatusData?.critical_spare,
        is_asset: repairStatusData?.is_asset,
        repairable: repairStatusData?.repairable,
        sap_matl_id: repairStatusData?.sap_matl_id,
        area_group_id: getAreaGroupValue(repairStatusData?.area_group),
        uom_id: repairStatusData?.unit_of_measure && getUomValue(repairStatusData?.unit_of_measure),
        qty_on_hand: repairStatusData?.qty_on_hand,
        qty_required: repairStatusData?.qty_required,
        area_sub_group_1_id: (repairStatusData?.area_sub_group_1 && getAreaGroupValue1(repairStatusData?.area_sub_group_1)) || '1',
        area_sub_group_2_id: (repairStatusData?.area_sub_group_2 && getAreaGroupValue2(repairStatusData?.area_sub_group_2)) || '1',
        phase_id: (repairStatusData?.phase_value && getPhaseValue(repairStatusData?.phase_value)) || '1',
        ac_dc_id: (repairStatusData?.ac_dc_value && getAcDcValue(repairStatusData?.ac_dc_value)) || '1',
        construction_material_id: (repairStatusData?.construction_material && getConstructionMaterialValue(repairStatusData?.construction_material)) || '1',
        frame_id: (repairStatusData?.frame_value && getFrameValue(repairStatusData?.frame_value)) || '1',
        storage_location_id: (repairStatusData?.storage_location && getStorageLocationValue(repairStatusData?.storage_location)) || '1',
        location_used_id: (repairStatusData?.location_used && getLocationUsedValue(repairStatusData?.location_used)) || '1',
        class_id: (repairStatusData?.spares_class && getSparesClassValue(repairStatusData?.spares_class)) || '1',
        stores_item_id: (repairStatusData?.stores_item && getStoresItemValue(repairStatusData?.stores_item)) || '1',
        notes: repairStatusData?.notes,
        model_nbr: repairStatusData?.model_nbr,
        install_time: repairStatusData?.install_time,
        qty_in_repair: repairStatusData?.qty_in_repair,
        horsepower: repairStatusData?.horsepower,
        serial_nbr: repairStatusData?.serial_nbr,
        output_rpm: repairStatusData?.output_rpm,
        mfg_part_nbr: repairStatusData?.mfg_part_nbr,
        mfg_drawing_ref: repairStatusData?.mfg_drawing_ref,
        estimated_cost: repairStatusData?.estimated_cost,
        input_rpm: repairStatusData?.input_rpm,
        servicer_factor: repairStatusData?.servicer_factor,
        updates: repairStatusData?.updates,
        amperage: repairStatusData?.amperage,
        sap_equip_id: repairStatusData?.sap_equip_id,
        lead_time: repairStatusData?.lead_time,
        mfg_oem: repairStatusData?.mfg_oem,
        sap_floc_id: repairStatusData?.sap_floc_id,
        voltage: repairStatusData?.voltage,
        ratio: repairStatusData?.ratio,
        created_by: repairStatusData.created_by,
        modified_by: repairStatusData.modified_by,
        Attachments: repairStatusData?.Attachments || [],
      };
      const apiUrl = `${getURL(SPARES)}/${sparesId}`;
      const result = await HttpPut(apiUrl, reqObj);
      console.log(result);
      setIsEdited(true);
      hideLoader();
    } catch (e) {
      hideLoader();
      console.log(e);
    }
  };
  const editData = async () => {
    try {
      setEditModal(false);
      showLoader(newPageStrings.EDITING_SPARES_DATA);
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      const attachments = editSubheaderData.Attachments.filter((item) => !item.stores_item);
      const attachmentWithStoresItem = editSubheaderData.Attachments.filter((item) => item.stores_item);
      const newAttachmentObj = await uploadFileToS3(attachments, response.idToken);
      newAttachmentObj.forEach((item) => {
        attachmentWithStoresItem.push({
          stores_item: item,
          attachment_id: '',
        });
      });
      const reqObj = {
        equip_component_description: editSubheaderData?.equip_component_description,
        additional_desc_info: editSubheaderData?.additional_desc_info,
        plant_id: typeof editSubheaderData?.plant_id === 'number' ? editSubheaderData?.plant_id : getPlantValue(editSubheaderData?.plant_id),
        critical_spare: editSubheaderData?.critical_spare,
        is_asset: editSubheaderData?.is_asset,
        repairable: editSubheaderData?.repairable,
        sap_matl_id: editSubheaderData?.sap_matl_id,
        repair_status: editSubheaderData?.repair_status,
        area_group_id: getAreaGroupValue(editSubheaderData?.area_group),
        uom_id: editSubheaderData?.unit_of_measure && getUomValue(editSubheaderData?.unit_of_measure),
        qty_on_hand: editSubheaderData?.qty_on_hand,
        qty_required: editSubheaderData?.qty_required,
        area_sub_group_1_id: (editSubheaderData?.area_sub_group_1 && getAreaGroupValue1(editSubheaderData?.area_sub_group_1)) || '1',
        area_sub_group_2_id: (editSubheaderData?.area_sub_group_2 && getAreaGroupValue2(editSubheaderData?.area_sub_group_2)) || '1',
        phase_id: (editSubheaderData?.phase_value && getPhaseValue(editSubheaderData?.phase_value)) || '1',
        ac_dc_id: (editSubheaderData?.ac_dc_value && getAcDcValue(editSubheaderData?.ac_dc_value)) || '1',
        construction_material_id: (editSubheaderData?.construction_material && getConstructionMaterialValue(editSubheaderData?.construction_material)) || '1',
        frame_id: (editSubheaderData?.frame_value && getFrameValue(editSubheaderData?.frame_value)) || '1',
        storage_location_id: (editSubheaderData?.storage_location && getStorageLocationValue(editSubheaderData?.storage_location)) || '1',
        location_used_id: (editSubheaderData?.location_used && getLocationUsedValue(editSubheaderData?.location_used)) || '1',
        class_id: (editSubheaderData?.spares_class && getSparesClassValue(editSubheaderData?.spares_class)) || '1',
        stores_item_id: (editSubheaderData?.stores_item && getStoresItemValue(editSubheaderData?.stores_item)) || '1',
        notes: editSubheaderData?.notes,
        model_nbr: editSubheaderData?.model_nbr,
        install_time: editSubheaderData?.install_time,
        qty_in_repair: editSubheaderData?.qty_in_repair,
        horsepower: editSubheaderData?.horsepower,
        serial_nbr: editSubheaderData?.serial_nbr,
        output_rpm: editSubheaderData?.output_rpm,
        mfg_part_nbr: editSubheaderData?.mfg_part_nbr,
        mfg_drawing_ref: editSubheaderData?.mfg_drawing_ref,
        estimated_cost: editSubheaderData?.estimated_cost,
        input_rpm: editSubheaderData?.input_rpm,
        servicer_factor: editSubheaderData?.servicer_factor,
        updates: editSubheaderData?.updates,
        amperage: editSubheaderData?.amperage,
        sap_equip_id: editSubheaderData?.sap_equip_id,
        lead_time: editSubheaderData?.lead_time,
        mfg_oem: editSubheaderData?.mfg_oem,
        sap_floc_id: editSubheaderData?.sap_floc_id,
        voltage: editSubheaderData?.voltage,
        ratio: editSubheaderData?.ratio,
        created_by: editSubheaderData.created_by,
        modified_by: editSubheaderData.modified_by,
        Attachments: attachmentWithStoresItem,
      };
      const newReqObj = {};
      Object.keys(reqObj).forEach((key) => {
        if (checkNotNull(reqObj[key]) && key !== 'Attachments') {
          newReqObj[key] = reqObj[key].toString();
        } else if (!checkNotNull(reqObj[key])) {
          newReqObj[key] = null;
        } else {
          newReqObj[key] = reqObj[key];
        }
      });
      showLoader(newPageStrings.EDITING_SPARES_DATA);
      const apiUrl = `${getURL(SPARES)}/${editSubheaderData.spares_id}`;
      const result = await HttpPut(apiUrl, newReqObj);
      setAttachedFile(null);
      setAttachmentDescriptionValue('');
      setEditModal(false);
      setIsEdited(true);
    } catch (e) {
      hideLoader();
      console.log(e);
    }
  };

  const handleSubheaderChange = (index, fieldName, value, isAdvanced) => {
    const newArray = [...subHeaderData];
    if (isAdvanced) {
      newArray[index].advancedDetails[fieldName] = value;
    } else {
      newArray[index][fieldName] = value;
    }
    setSubHeaderData(newArray);
  };
  const handleAttachedFiles = (file, description, index) => {
    // const imgURL = window.URL.createObjectURL(file);
    const reader = new FileReader();
    const fileName = file.name;
    reader.readAsDataURL(file);
    const id = uuidv4();
    let base64Code = '';
    const imgSize = (file.size / 1000000).toFixed(2);
    const newArray = [...subHeaderData];

    reader.onloadend = () => {
      base64Code = reader.result.substring(
        reader.result.indexOf(',') + 1,
        reader.result.length,
      );

      newArray[index].attachedFiles.push({
        id,
        name: fileName,
        size: imgSize,
        description,
        base64Code,
        userName: state.userPersonalData.sapUserID,
      });
      setSubHeaderData(newArray);
      setAttachedFile(null);
      setAttachmentDescriptionValue('');
    };
  };
  const deleteObject = (index) => {
    const newArray = [...subHeaderData];
    newArray.splice(index, 1);
    setSubHeaderData(newArray);
  };
  const checkModaldata = () => {
    let canBeSubmit = false;
    // eslint-disable-next-line
    subHeaderData?.forEach((item) => {
      if (
        checkNotNull(item[newPageStrings.SITE])
        && checkNotNull(item[newPageStrings.AREA_GROUP])
        && checkNotNull(item[newPageStrings.COMPONENT_DESCRIPTION])
        // && checkNotNull(item.advancedDetails.ac_dc_id)
        // && checkNotNull(item.advancedDetails.area_sub_group_1_id)
        // && checkNotNull(item.advancedDetails.area_sub_group_2_id)
        // && checkNotNull(item.advancedDetails.class_id)
        // && checkNotNull(item.advancedDetails.construction_material_id)
        // && checkNotNull(item.advancedDetails.frame_id)
        // && checkNotNull(item.advancedDetails.location_used_id)
        // && checkNotNull(item.advancedDetails.phase_id)
        // && checkNotNull(item.advancedDetails.storage_location_id)
      ) {
        canBeSubmit = true;
      } else {
        canBeSubmit = false;
      }
    });
    return canBeSubmit;
  };
  const onCloseClick = () => {
    setClearModalData(true);
  };
  const clearData = () => {
    const arr = [{
      opened: true, advancedDetailsOpen: true, attachedFiles: [], advancedDetails: {}, [newPageStrings.SITE]: plantDetails[global.globalReducer.plantId].toUpperCase(), flocDetails: []
    }];
    setSubHeaderData(arr);
    setClearModalData(false);
    setOpenModal(false);
  };

  const unClearData = () => {
    setClearModalData(false);
    setOpenModal(true);
  };
  const deleteRowData = async (data) => {
    try {
      let newdata = [];
      if (Array.isArray(data)) {
        newdata = data;
      } else {
        newdata.push(data);
      }
      setDeleteSelected(false);
      showLoader(newPageStrings.DELETING_DATA);
      const sparesIds = newdata.map((item) => item.spares_id);
      const sparesIdString = sparesIds.join(',');
      const apiURL = (`${getURL(SPARES)}?sparesId=${sparesIdString}`);
      const result = await HttpDelete(apiURL);
      console.log(result);
      setSelectedRows(null);
      setIsDeleted(true);
      hideLoader();
    } catch (e) {
      console.log(e, 'error');
    }
  };
  const selectedData = (data) => {
    setSelectedRows(data);
  };
  const deleteRow = (data) => {
    const newArray = [];
    newArray.push(data);
    setSelectedRows(newArray);
    setDeleteSelected(true);
  };
  const onDataCloseClick = () => {
    setOpenModal(false);
    setSubHeaderData([{
      opened: true, advancedDetailsOpen: true, attachedFiles: [], advancedDetails: {}, [newPageStrings.SITE]: plantDetails[global.globalReducer.plantId].toUpperCase(), flocDetails: []
    }]);
  };
  const colourCellRenderer = (params) => (
    <div className="border2">
      <span>{(params.value)}</span>
    </div>
  );
  const iconRenderer = (params) => (
    params.value ? (
      <img src={success} alt="success" height="18" width="18" />
    ) : (
        '-'
      )
  );
  const deleteItem = (value) => {
    deleteRow(value.data);
  };
  const addAttachmentFile = () => {
    const reader = new FileReader();
    const fileName = attachedFile.name;
    reader.readAsDataURL(attachedFile);
    const id = uuidv4();
    let base64Code = '';
    const imgSize = (attachedFile.size / 1000000).toFixed(2);
    const updatedObject = { ...editSubheaderData };

    reader.onloadend = () => {
      base64Code = reader.result.substring(
        reader.result.indexOf(',') + 1,
        reader.result.length,
      );

      updatedObject.Attachments.push({
        id,
        name: fileName,
        size: imgSize,
        base64Code,
        userName: state.userPersonalData.sapUserID,
      });
      setEditSubHeaderData(updatedObject);
      setAttachedFile(null);
      setAttachmentDescriptionValue('');
    };
  };

  const viewEntries = (data) => {
    setRepairStatusModal(true);
    setRepairStatusValue(data?.value);
    setEditRepairStatusIndex(data.data.spares_id);
  };
  const linkRenderer = (params) => (
    <p className="viewEntries" onClick={() => viewEntries(params)} role="presentation">
      {newPageStrings.VIEW_ENTRIES}
    </p>
  );
  const qtyRequiredRenderer = (params) => (
    params.value && (
      <p>
        {params.value}
        <span className="valueDescription">{params.data.uom_id}</span>
      </p>
    )
  );
  const qtyOnHandrenderer = (params) => (
    params.value && (
      <p>
        {params.value}
        <span className="valueDescription">{params.data.uom_id}</span>
      </p>
    )
  );
  // const deleteRenderer = (params) => (
  //   <img
  //     src={trash}
  //     onClick={() => deleteItem(params)}
  //     height="16px"
  //     width="16px"
  //     alt="Delete"
  //     role="presentation"
  //   />
  // );
  const downloadFile = (params) => {
    const fileUrl = params.data.stores_item;
    const fileName = fileUrl.split('/')[fileUrl.split('/').length - 1];
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const downloadRenderer = (params) => (
    <img
      src={download}
      onClick={() => downloadFile(params)}
      height="16px"
      width="16px"
      alt="Upload"
      role="presentation"
    />
  );
  // const deleteFile = (params) => {
  //   editSubheaderData?.Attachments?.splice(params.rowIndex, 1);
  //   const newData = {
  //     ...editSubheaderData,
  //   };
  //   setEditSubHeaderData(newData);
  // };
  // const deleteRendererforEdit = (params) => (
  //   <img
  //     src={trash}
  //     onClick={() => deleteFile(params)}
  //     height="16px"
  //     width="16px"
  //     alt="Delete"
  //     role="presentation"
  //   />
  // );
  const nameRenderer = (params) => {
    const value = params.data?.stores_item?.split('/').length ? params.data.stores_item.split('/')[params.data.stores_item.split('/').length - 1] : params.data.name;
    return (
      <p>{value}</p>
    );
  };
  const sapMatlRenderer = (params) => (
    <div className="d-flex gap-3">
      <p className="viewEntries" role="presentation" onClick={() => onSapMaterialData(params.value)}>
        {params.value}
      </p>
      {params.value && <img src={info} alt="info" width="15px" height="15px" style={{ marginTop: '13px' }} />}
    </div>
  );
  const externalRepairRenderer = (params) => (
    <div className="d-flex gap-3">
      <p className="viewEntries" role="presentation" onClick={() => onMaterialData(params)}>
        {newPageStrings.VIEW_ENTRIES}
      </p>
      <img src={externalRepairIcon} alt="info" width="15px" height="15px" style={{ marginTop: 13 }} />
    </div>
  );
  const getEditData = async (sparesId) => {
    const apiURL = getURL(SPARES_INFO);
    const params = { sparesId }
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
      throw response.data.message.data.error.message;
    }
    return response.data.message.data;
  };

  const PopupCellRenderer = (props) => {
    const tippyRef = useRef();
    const [visible, setVisible] = useState(false);
    const show = () => {
      setVisible(true);
    }
    const hide = () => {
      setVisible(false);
    }

    const dropDownContent = (
      <div className="menu-container">
        <div className="menu-item">
          <CustomButton
            customStyle={{
              padding: 5,
              marginRight: 20,
              border: '1px solid white',
            }}
            onPress={() => {
              hide();
              viewItem(props);
            }}
            value={newPageStrings.MATERIAL_INFO}
            outline
          />

        </div>
        <div className="menu-item">
          <CustomButton
            customStyle={{
              padding: 5,
              marginRight: 20,
              border: '1px solid white',
            }}
            onPress={() => {
              hide();
              editItem(props);
            }}
            value={newPageStrings.EDIT_SPARES}
            outline
            disabled={!(props.data.plant_id.toString() === global.globalReducer.plantId || (global.globalReducer.userData.userPrincipalName == 'brenda.wallace@gerdau.com' || global.globalReducer.userData.userPrincipalName == 'ken.hicks@gerdau.com' || global.globalReducer.userData.userPrincipalName == 'sainag.chunduru@gerdau.com'))}
          />
        </div>
        <div className="menu-item">
          <CustomButton
            customStyle={{
              padding: 5,
              marginRight: 20,
              border: '1px solid white',
            }}
            onPress={() => {
              hide();
              onGoToWorkOrderClick(pages.WORK_ORDER_FROM_SPARES, props)
            }}
            value={newPageStrings.CREATE_REFURB_ORDER}
            outline
          />
        </div>
        {/* <div className="menu-item">
              <CustomButton
                customStyle={{
                  padding: 5,
                  marginRight: 20,
                  border: '1px solid white',
                }}
                onPress={() => {
                  hide();
                  // onGoToWorkOrderClick(pages.WORK_ORDER_FROM_SPARES,props)
                }}
                value='Add External Repair'
                outline
              />
            </div> */}
        <div className="menu-item">
          <CustomButton
            customStyle={{
              padding: 5,
              marginRight: 20,
              border: '1px solid white',
              color: '#C74A34'
            }}
            onPress={() => {
              hide();
              deleteItem(props);
            }}
            value={newPageStrings.DELETE_SPARES}
            outline
          />
        </div>
      </div>
    );

    return (
      <Tippy
        ref={tippyRef}
        content={dropDownContent}
        visible={visible}
        onClickOutside={hide}
        allowHTML={true}
        arrow={false}
        appendTo={document.body}
        interactive={true}
        placement="left"
        offset={[30, 30, 0, 0]}
      >
        <p onClick={visible ? hide : show} style={{ cursor: 'pointer' }}>
          ...
              </p>
      </Tippy>
    );
  };
  const onEditClick = async (sparesId) => {
    try {
      showLoader(`${newPageStrings.FETCHING_DATA_OF_SPARES_ID} ${sparesId}`);
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SPARES_INFO}/${sparesId}`,
        queryFn: async () => {
          const response = await getEditData(sparesId);
          return response;
        },
        select: (sparesInfo) => sparesInfo,
        retry: false,
        staleTime: 1000,
      });
      setInitialEditData(data[0]);
      setEditSubHeaderData(data[0]);
      setEditModal(true);
      hideLoader();
    } catch (e) {
      console.log(e);
    }
  };
  const editItem = async (value) => {
    setPlantId(value.data.plant_id);
    onEditClick(value.data.spares_id);
  };
  const viewItem = (value) => {
    setViewModal(true);
    setViewTableData(value.data);
  };
  // const editRenderer = (params) => (
  //   <img
  //     src={editIcon}
  //     onClick={() => editItem(params)}
  //     height="24px"
  //     width="24px"
  //     alt="Edit"
  //     role="presentation"
  //   />
  // );
  // const viewRenderer = (params) => (
  //   <img
  //     src={viewIcon}
  //     onClick={() => viewItem(params)}
  //     height="18px"
  //     width="18px"
  //     alt="view icon"
  //     role="presentation"
  //   />  // );

  // eslint-disable-next-line
  const [columnDefs, setColumnDefs] = React.useState([
    {
      field: 'plant_id',
      // filter: 'agRichSelectCellEditor',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: 'Site',
      width: '130px',
      headerTooltip: 'Site',
      editable: true,
      tooltipField: 'plant_id',
    },
    {
      field: 'area_group',
      cellRenderer: colourCellRenderer,
      headerName: 'Area Group',
      width: '200px',
      headerTooltip: 'Area Group',
      tooltipField: 'area_group',
      editable: true,
    },
    {
      field: 'sap_matl_id',
      headerName: 'SAP Matl ID',
      width: '150px',
      cellRenderer: sapMatlRenderer,
      headerTooltip: 'SAP Matl ID',
      tooltipField: 'sap_matl_id',
    },
    {
      field: 'equip_component_description',
      headerName: 'Equip / Component Description',
      width: '400px',
      headerTooltip: 'Equip / Component Description',
      tooltipField: 'equip_component_description',
    },
    {
      field: 'additional_desc_info',
      headerName: 'Add"l Desc',
      headerTooltip: 'Add"l Desc',
      tooltipField: 'additional_desc_info',
    },
    {
      field: 'critical_spare',
      cellRenderer: iconRenderer,
      headerName: 'Critical Spare',
      width: '140px',
      headerTooltip: 'Critical Spare',
      tooltipField: 'critical_spare',
    },
    // { field: 'stores_item' },
    {
      field: 'qty_required',
      headerName: 'Qty Req',
      cellRenderer: qtyRequiredRenderer,
      width: '120px',
      headerTooltip: 'Qty Req',
      tooltipField: 'qty_required',
    },
    {
      field: 'qty_on_hand',
      headerName: 'Qty on Hand',
      cellRenderer: qtyOnHandrenderer,
      width: '140px',
      headerTooltip: 'Qty on Hand',
    },
    // {
    //   headerName: 'QoH Type',
    //   cellRenderer: qoHTypeRenderer,
    //   width: '140px',
    //   headerTooltip: 'Qty on Hand',
    // },
    {
      field: 'repairable',
      cellDataType: 'text',
      headerName: 'Repairable',
      width: '140px',
      headerTooltip: 'Repairable',
      tooltipField: 'repairable',
    },
    {
      headerName: 'External Repairs',
      cellRenderer: externalRepairRenderer,
    },
    {
      field: 'repair_status',
      cellRenderer: linkRenderer,
      headerName: 'Repair Notes',
      width: '140px',
      headerTooltip: 'Repair Notes',
      editable: false,
    },
    {
      field: 'is_asset',
      cellRenderer: iconRenderer,
      headerName: 'Is Asset',
      width: '120px',
      headerTooltip: 'Is Asset',
    },
    {
      field: 'stores_item',
      headerName: 'Stores Item',
      width: '140px',
      headerTooltip: 'Stores Item',
      tooltipField: 'stores_item',
    },
    {
      field: 'uom_id',
      headerName: 'UOM',
      width: '120px',
      headerTooltip: 'UOM',
      tooltipField: 'uom_id',
    },
    {
      headerName: '',
      cellRenderer: PopupCellRenderer,
      pinned: 'right',
      colId: 'action',
      editable: false,
      width: '60px',
      filter: false,
    },
  ]);

  const openPurchaseOrderRowData = sapMaterialData && sapMaterialData.map((item) => ({
    purchaseOrder: item.purchaseOrder,
    recipient: item.recipient,
    vendor: item.vendorName,
    deliveryDate: item.reqDate,
    orderQty: item.orderQuantity,
  }))
  const deliveryDateRenderer = (params) => {
    const year = params.data.deliveryDate.substring(0, 4);
    const month = params.data.deliveryDate.substring(4, 6);
    const day = params.data.deliveryDate.substring(6, 8);
    return <p>{day}-{month}-{year}</p>
  }
  const openPurchaseOrderColumnDefs = [
    {
      field: 'purchaseOrder',
      headerName: 'Purchase Order',
      flex: 1,
      // width: '150px',
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 1,
      tooltipField: 'vendor',
      // width: '150px',
    },
    {
      cellRenderer: deliveryDateRenderer,
      headerName: 'Delivery Date',
      flex: 1,
      // width: '150px',
    },
    {
      field: 'orderQty',
      headerName: 'Order Qty',
      flex: 1,
      // width: '150px',
    },
    {
      field: 'recipient',
      headerName: 'Recipient',
      flex: 1,
      // width: '150px',
    },
  ];
  const openPurchaseRequistionRowData = sapMaterialData && sapMaterialData.map((item) => ({
    purchaseRequistion: item.purchaseOrder,
    recipient: item.recipient,
    deliveryDate: item.reqDate,
    orderQty: item.orderQuantity,
  }))
  const openPurchaseRequistionColumnDefs = [
    {
      field: 'purchaseRequistion',
      headerName: 'Purchase Requistion',
      flex: 1,
      // width: '150px',
    },
    {
      cellRenderer: deliveryDateRenderer,
      headerName: 'Delivery Date',
      flex: 1,
      // width: '150px',
    },
    {
      field: 'orderQty',
      headerName: 'Order Qty',
      flex: 1,
      // width: '150px',
    },
    {
      field: 'recipient',
      headerName: 'Recipient',
      flex: 1,
      // width: '150px',
    },
  ];
  const vendorAgreementsRowData = sapMaterialData && sapMaterialData.map((item) => ({
    // recipient: item.recipient,
    vendor: item.vendorName,
    plant: item.plant,
    agreementPrice: 'Coming soon...',
  }))
  const vendorAgreementsColumnDefs = [
    {
      field: 'plant',
      headerName: 'Plant',
      flex: 1,
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 1,
      tooltipField: 'vendor',
    },
    {
      field: 'agreementPrice',
      headerName: 'Agreement Price',
      flex: 1,
    },
  ];

  const deleteAttachment = (index, rowIndex) => {
    subHeaderData[index].attachedFiles?.splice(rowIndex, 1);
    const updatedArray = [...subHeaderData];
    setSubHeaderData(updatedArray);
  };
  const deleteRendererforAdd = (params) => (
    <img
      src={trash}
      onClick={() => deleteAttachment(params.api.gos.gridOptions.gridId, params.rowIndex)}
      height="16px"
      width="16px"
      alt="Delete"
      role="presentation"
    />
  );
  const typeRenderer = (params) => (
    <p>{params.data.name.split('.')[params.data.name.split('.').length - 1]}</p>
  );
  const attachmentColDefs = [
    {
      field: 'name',
      headerName: 'File Name',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      cellRenderer: typeRenderer,
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 1,
    },
    {
      headerName: '',
      cellRenderer: deleteRendererforAdd,
      width: '67px',
      filter: false,
    },
  ];
  const editAttachmentDefs = [
    {
      cellRenderer: nameRenderer,
      headerName: 'File Name',
      flex: 1,
      filter: false,
    },
    {
      headerName: '',
      cellRenderer: downloadRenderer,
      width: '67px',
      filter: false,
    },
    // {
    //   headerName: '',
    //   cellRenderer: deleteRendererforEdit,
    //   width: '67px',
    //   filter: false,
    // },
  ];
  const clearAll = () => {
    setFilterSite(null);
    setFilterAreaGroup(null);
    setFilterSearchValue('');
  };
  const handleEditDataChange = (fieldName, value) => {
    const updatedData = {
      ...editSubheaderData,
      [fieldName]: value,
    };
    setEditSubHeaderData(updatedData);
  };
  const totalHeight = subHeaderData.reduce((total, item) => {
    total += item.opened ? 790 : 60;
    if (item.opened) {
      total += item.advancedDetailsOpen ? 1060 : 40;
      // eslint-disable-next-line
      total += item.attachedFiles.length === 0 ? 0 : item.attachedFiles.length === 1 ? 100 : item.attachedFiles.length === 2 ? 150 : 200;
    }
    return total;
  }, 0);
  // const totalHeight = (subHeaderData?.filter((item) => item.opened).length ?? 0) * 2000 + ((subHeaderData?.filter((item) => !item.opened).length ?? 0) * 60);
  return (
    <ContentWithBreadcrumb>
      <div className="d-flex justify-content-between mt-1">
        <div className="d-flex flex-row" style={{ width: '90%' }}>
          <div className="col-lg-2 pb-2" style={{ zIndex: 2, width: '20%' }}>
            <CustomDropdown
              data={{
                name: newPageStrings.SITE,
                value: filterSite?.name,
                selectedObject: filterSite,
                items: areaGroupData?.plantSites,
              }}
              editable
              updateDropDownItem={(data) => setFilterSite(data)}
              // required
              style={{ zIndex: 2000 }}
              childName="site_title"
              childValue="plant_id"
              showSelect
            />
          </div>
          <div className="col-lg-2 pb-2" style={{ zIndex: 2, width: '20%' }}>
            <CustomDropdown
              data={{
                name: newPageStrings.AREA_GROUP,
                value: filterAreaGroup?.name,
                selectedObject: filterAreaGroup,
                items: areaGroupData?.areaGroups,
              }}
              editable
              updateDropDownItem={(data) => setFilterAreaGroup(data)}
              // required
              style={{ zIndex: 2000 }}
              childName="area_group"
              childValue="area_group_id"
              showSelect
            // style={{ zIndex: 2000 - index }}
            />
          </div>
          <div style={{ width: '20%' }}>
            <CustomTextInput
              label={newPageStrings.QUICK_FILTER}
              value={filterSearchValue}
              onValueChange={(data) => setFilterSearchValue(data.value.toUpperCase())}
              autoCapitalize="characters"
              placeholder={newPageStrings.FILTER_BY}
              editable
              rows={1}
            />
          </div>
          <div className={`${styles.valueDescription} ml-3 mt-4`} style={{ height: '30px' }}>
            {`${filteredData?.length ? filteredData.length : '0'} Results`}
          </div>
          <div className="mt-4 ml-2">
            <CustomButton
              onPress={clearAll}
              customStyle={{
                // backgroundColor: '#BFC9D0',
                // color: '#FFFFFF',
                height: '30px',
                width: '120px',
              }}
              customTextStyle={{ fontSize: 12 }}
              value={newPageStrings.CLEAR_FILTERS}
              icon={cross}
              disabled={!filterSite?.value && !filterAreaGroup?.name && !filterSearchValue}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="d-flex flex-row gap-3">
            <CustomButton
              onPress={exportSelectedRows}
              customStyle={{
                backgroundColor: 'white',
                color: '#0578BE',
                height: '30px',
                border: '1px solid #BFCCD9',
                whiteSpace: 'nowrap',
              }}
              customTextStyle={{ fontSize: 12 }}
              value={newPageStrings.EXPORT}
              disabled={!checkArrayNotNull(selectedRows) && !checkArrayNotNull(filteredData)}
            />
            <CustomButton
              onPress={openLaunchGME}
              customStyle={{
                backgroundColor: 'white',
                color: '#0578BE',
                height: '30px',
                border: '1px solid #BFCCD9',
                whiteSpace: 'nowrap',
              }}
              customTextStyle={{ fontSize: 12 }}
              icon={launchGME}
              value='Launch GME'
            />
            <CustomButton
              onPress={() => setDeleteSelected(true)}
              customStyle={{
                backgroundColor: 'white',
                color: '#003366',
                height: '30px',
                border: '1px solid #0578BE',
                whiteSpace: 'nowrap',
              }}
              customTextStyle={{ fontSize: 12 }}
              value={newPageStrings.DELETE_SELECTED}
              icon={trash}
              disabled={!selectedRows?.length}
            />
            <CustomButton
              onPress={() => onNavItemClick(pages.SPARES_MANAGEMENT_EXTERNAL_REPAIRS)}
              customStyle={{
                backgroundColor: 'white',
                color: '#0578BE',
                height: '30px',
                border: '1px solid #BFCCD9',
                whiteSpace: 'nowrap',
              }}
              customTextStyle={{ fontSize: 12 }}
              icon={externalRepairIcon}
              value='External Repairs'
            />
            <CustomButton
              onPress={setModal}
              customStyle={{
                backgroundColor: '#0578BE',
                color: '#FFFFFF',
                height: '30px',
                whiteSpace: 'nowrap',
              }}
              customTextStyle={{ fontSize: 12 }}
              value={newPageStrings.ADD_SPARES}
              icon={plus}
            />
          </div>
        </div>
      </div>
      {openModal && (
        <CustomModal
          customWidth="70%"
          customHeight="85%"
          actions={{
            cancel: subHeaderData.length > 0 && checkModaldata()
              ? () => onCloseClick()
              : () => onDataCloseClick(),
          }}
          header={{
            title: newPageStrings.ADD_SPARES,
            color: '#192D38',
            bgColor: '#F2F8FC',
            exit: true,
            // scroll: true,
          }}
          icon={blueClose}
          buttons={(
            <div>
              <div className="mt-3 pb-3">
                <CustomButton
                  onPress={addNewSubheader}
                  customStyle={{
                    backgroundColor: 'white',
                    color: '#0578BE',
                    width: '95%',
                    marginLeft: 20,
                    border: '1px solid #0578BE',
                  }}
                  customTextStyle={{ fontSize: 12 }}
                  value={newPageStrings.ADD_ANOTHER_SPARE}
                  icon={bluePlus}
                />
              </div>
              <div className="d-flex flex-row justify-content-between pb-3">
                <div className="d-flex flex-row justify-content-start ml-3">
                  <CustomButton
                    customStyle={{
                      padding: 5,
                      marginRight: 20,
                      border: '1px solid white',
                    }}
                    onPress={() => {
                      setBulkModal(true);
                      setSelectedFile(null);
                    }}
                    value={newPageStrings.BULK_IMPORT}
                    outline
                  // border={false}
                  />
                  <CustomButton
                    customStyle={{ padding: 5, border: '1px solid white' }}
                    onPress={() => downloadExcelFile()}
                    value={newPageStrings.DOWNLOAD_TEMPLATE}
                    outline
                  />
                </div>
                <div className="d-flex flex-row justify-content-end  mr-3">
                  <CustomButton
                    customStyle={{
                      padding: 5,
                      marginRight: 20,
                      border: '1px solid white',
                    }}
                    onPress={() => setSubHeaderData([{
                      opened: true, advancedDetailsOpen: true, attachedFiles: [], advancedDetails: {}, [newPageStrings.SITE]: plantDetails[global.globalReducer.plantId].toUpperCase(), flocDetails: []
                    }])}
                    value={newPageStrings.CLEAR_ALL_SECTIONS}
                    outline
                  />
                  <CustomButton
                    customStyle={{ padding: 5 }}
                    onPress={saveData}
                    value={newPageStrings.SUBMIT}
                    outline
                    disabled={!checkModaldata()}
                  />
                </div>
              </div>
            </div>
          )}
        >
          <div
            className="p-3"
            style={{ height: '75%', overflow: 'scroll', width: '100%' }}
          >
            <AutoSizer>
              {({ width }) => (
                <List
                  width={width}
                  height={totalHeight}
                  rowCount={subHeaderData?.length}
                  rowHeight={({ index }) => (subHeaderData[index].opened && 2100) || 120}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  rowRenderer={({ index, key }) => {
                    const item = subHeaderData[index];
                    return (
                      <div key={key} className="mt-4">
                        <SubHeaderBorder
                          key={index}
                          headerContent={newPageStrings.SPARES_DETAILS}
                          icon={
                            checkNotNull(item[newPageStrings.SITE])
                              && checkNotNull(item[newPageStrings.AREA_GROUP])
                              && checkNotNull(
                                item[newPageStrings.COMPONENT_DESCRIPTION],
                              )
                              ? success
                              : warning
                          }
                          headerDescription={
                            (
                              <>
                                <span className={styles.valueDescription}>
                                  {index + 1}
                                </span>
                                {item[newPageStrings.SITE] ? (
                                  <span className={styles.valueDescription}>
                                    {item[newPageStrings.SITE]}
                                  </span>
                                ) : (
                                    ' '
                                  )}
                                {item[newPageStrings.AREA_GROUP] ? (
                                  <span className={styles.valueDescription}>
                                    {item[newPageStrings.AREA_GROUP]}
                                  </span>
                                ) : (
                                    ' '
                                  )}
                                {item[newPageStrings.COMPONENT_DESCRIPTION] ? (
                                  <span className={styles.valueDescription}>
                                    {item[newPageStrings.COMPONENT_DESCRIPTION]}
                                  </span>
                                ) : (
                                    ' '
                                  )}
                                <span
                                  className={styles.headerDescription}
                                  onClick={() => deleteObject(index)}
                                  role="presentation"
                                >
                                  <img
                                    src={trash}
                                    height="12"
                                    width="12"
                                    style={{ marginTop: '-3px' }}
                                    alt="delete"
                                  />
                                </span>
                              </>
                            )
                          }
                          toggleSubHeader={() => toggleSubheader(index, false)}
                          index={index}
                        >
                          <div className="row px-2" style={{ flexWrap: 'wrap' }}>
                            <div
                              className="col-lg-2 pb-2"
                              style={{ zIndex: 2000 - index }}
                            >
                              <CustomDropdown
                                data={{
                                  name: newPageStrings.SITE,
                                  value: item[newPageStrings.SITE] || plantDetails[global.globalReducer.plantId].toUpperCase(),
                                  selectedObject: {
                                    key: newPageStrings.SITE,
                                    name: item[newPageStrings.SITE] || plantDetails[global.globalReducer.plantId].toUpperCase(),
                                    value: item[newPageStrings.SITE] || global.globalReducer.plantId,
                                  },
                                  items: areaGroupData?.plantSites,
                                }}
                                editable
                                updateDropDownItem={(data) => {
                                  const updatedData = subHeaderData;
                                  updatedData[index].advancedDetails.sap_floc_id = null,
                                    updatedData[index][newPageStrings.SITE] = data.name,
                                    setSubHeaderData(updatedData);
                                  setIndex(index);
                                  setPlantId(data.value);
                                }}
                                required
                                style={{ zIndex: 2000 - index }}
                                childName="site_title"
                                childValue="plant_id"
                              />
                            </div>
                            <div
                              className="col-lg-2 pb-2"
                              style={{ zIndex: 2000 - index }}
                            >
                              <CustomDropdown
                                data={{
                                  name: newPageStrings.AREA_GROUP,
                                  value: item[newPageStrings.AREA_GROUP],
                                  selectedObject: {
                                    key: newPageStrings.SITE,
                                    name: item[newPageStrings.AREA_GROUP],
                                    value: item[newPageStrings.AREA_GROUP],
                                  },
                                  items: areaGroupData?.areaGroups,
                                }}
                                updateDropDownItem={(data) => handleSubheaderChange(
                                  index,
                                  newPageStrings.AREA_GROUP,
                                  data.name,
                                )}
                                editable
                                required
                                childName="area_group"
                                childValue="area_group_id"
                                style={{ zIndex: 2000 - index }}
                              />
                            </div>
                            <div className="col-lg-2 pb-2">
                              <CustomTextInput
                                label={newPageStrings.SAP_MATERIAL_ID}
                                value={item[newPageStrings.SAP_MAT_ID]}
                                editable
                                rows={1}
                                onValueChange={async (data) => {
                                  handleSubheaderChange(
                                    index,
                                    newPageStrings.SAP_MAT_ID,
                                    data.value
                                  );
                                  if (data.value?.length === 8) {
                                    let response = await getSapMaterials(data.value, true);
                                    console.log(response);
                                    response && handleSubheaderChange(
                                      index,
                                      newPageStrings.QTY_ON_HAND,
                                      response[0].unRestrictedQuantity,
                                    );
                                  }
                                  if (data.value?.length < 8) {
                                    if (subHeaderData[index][newPageStrings.QTY_ON_HAND]) {
                                      handleSubheaderChange(
                                        index,
                                        newPageStrings.QTY_ON_HAND,
                                        '',
                                      );
                                      setUnrestrictedQuantity(null);
                                      setStoresItem(false);
                                    }
                                  }
                                }}
                                maxLength={8}
                                isFilled={item[newPageStrings.SAP_MAT_ID]?.length == 8 && storesItem}
                              />
                              {item[newPageStrings.SAP_MAT_ID]?.length == 8 && storesItem ? <div><img src={success} alt='success-icon' height='12px' width='12px' /> <span className='ml-1 stores'>Stores</span></div> : <div><img src={blueTick} alt='blue-tick' height='12px' width='12px' style={{ marginTop: '-4px' }} /><span className='ml-1 nonStores'>Non-Stores</span></div>}
                            </div>
                            <div className="col-lg-2 pb-2">
                              <CustomTextInput
                                label={newPageStrings.QUANTITY_ON_HAND}
                                value={item[newPageStrings.QTY_ON_HAND]}
                                editable
                                disable={storesItem}
                                rows={1}
                                onValueChange={(data) => handleSubheaderChange(
                                  index,
                                  newPageStrings.QTY_ON_HAND,
                                  data.value,
                                )}
                                type="number"
                              // disable
                              />
                            </div>
                            <div className="col-lg-2 pb-2">
                              <CustomTextInput
                                label={newPageStrings.QUANTITY_REQUIRED}
                                value={item[newPageStrings.QTY_REQUIRED]}
                                editable
                                rows={1}
                                onValueChange={(data) => handleSubheaderChange(
                                  index,
                                  newPageStrings.QTY_REQUIRED,
                                  data.value,
                                )}
                                type="number"
                              />
                            </div>
                            <div className="col-lg-2 pb-2" style={{ zIndex: 2 }}>
                              <CustomDropdown
                                data={{
                                  name: newPageStrings.UNIT_OF_MEASURE,
                                  value: item[newPageStrings.UOM],
                                  selectedObject: {
                                    key: newPageStrings.UNIT_OF_MEASURE,
                                    name: item[newPageStrings.UOM],
                                    value: item[newPageStrings.UOM],
                                  },
                                  items: areaGroupData?.uom,
                                }}
                                updateDropDownItem={(data) => handleSubheaderChange(
                                  index,
                                  newPageStrings.UOM,
                                  data.name,
                                )}
                                editable
                                childName="unit_of_measure"
                                childValue="uom_id"
                              // required
                              />
                            </div>
                            <div className="col-lg-6 pb-2">
                              <CustomTextInput
                                label={newPageStrings.EQUIPMENT_DESCRIPTION}
                                value={
                                  item[newPageStrings.COMPONENT_DESCRIPTION]
                                }
                                editable
                                rows={1}
                                required
                                onValueChange={(data) => handleSubheaderChange(
                                  index,
                                  newPageStrings.COMPONENT_DESCRIPTION,
                                  data.value,
                                )}
                              />
                            </div>
                            <div className="col-lg-6 pb-2">
                              <CustomTextInput
                                label={newPageStrings.ADDTL_DESCRIPTION}
                                value={
                                  item[newPageStrings.ADDITIONAL_DESCRIPTION]
                                }
                                editable
                                rows={1}
                                onValueChange={(data) => handleSubheaderChange(
                                  index,
                                  newPageStrings.ADDITIONAL_DESCRIPTION,
                                  data.value,
                                )}
                              />
                            </div>
                            <div className="col-lg-12 pb-2">
                              <CustomTextInput
                                label={newPageStrings.REPAIR_STATUS}
                                value={item[newPageStrings.REPAIR_STATUS]}
                                editable
                                rows={1}
                                onValueChange={(data) => handleSubheaderChange(
                                  index,
                                  newPageStrings.REPAIR_STATUS,
                                  data.value,
                                )}
                              />
                            </div>
                            <div className="col-lg-3 pb-2">
                              <CustomRadioButton
                                value={item[newPageStrings.CRITICAL_SPARE]}
                                height={30}
                                width={30}
                                editable
                                onClick={() => handleSubheaderChange(
                                  index,
                                  newPageStrings.CRITICAL_SPARE,
                                  !item[newPageStrings.CRITICAL_SPARE],
                                )}
                              />
                              <span className={styles.kittingText}>
                                {newPageStrings.CRITICAL_SPARE}
                              </span>
                            </div>
                            <div className="col-lg-3 pb-2">
                              <CustomRadioButton
                                value={item[newPageStrings.STORES_ITEM]}
                                height={30}
                                width={30}
                                editable
                                onClick={() => handleSubheaderChange(
                                  index,
                                  newPageStrings.STORES_ITEM,
                                  !item[newPageStrings.STORES_ITEM],
                                )}
                              />
                              <span className={styles.kittingText}>
                                {newPageStrings.STORES_ITEM}
                              </span>
                            </div>
                            <div className="col-lg-3 pb-2">
                              <CustomRadioButton
                                value={item[newPageStrings.REPAIRABLE]}
                                height={30}
                                width={30}
                                editable
                                onClick={() => handleSubheaderChange(
                                  index,
                                  newPageStrings.REPAIRABLE,
                                  !item[newPageStrings.REPAIRABLE],
                                )}
                              />
                              <span className={styles.kittingText}>
                                {newPageStrings.REPAIRABLE}
                              </span>
                            </div>
                            <div className="col-lg-3 pb-2">
                              <CustomRadioButton
                                value={item[newPageStrings.IS_ASSET]}
                                height={30}
                                width={30}
                                editable
                                onClick={() => handleSubheaderChange(
                                  index,
                                  newPageStrings.IS_ASSET,
                                  !item[newPageStrings.IS_ASSET],
                                )}
                              />
                              <span className={styles.kittingText}>
                                {newPageStrings.IS_ASSET}
                              </span>
                            </div>
                            {item.flocDetails?.funcLocDetails && <div className="col-lg-3 pb-2" style={{ height: '70px' }}>
                              <MultiDropdown
                                data={item.flocDetails?.funcLocDetails}
                                editable
                                childElement="items"
                                childName="name"
                                // required
                                style={{ zIndex: 900 }}
                                selectedObject={item.advancedDetails.sap_floc_id ? item.advancedDetails.sap_floc_id : { name: '', value: '' }}
                                updateFlocValue={(data) => handleSubheaderChange(
                                  index,
                                  'sap_floc_id',
                                  data,
                                  true
                                )}
                                name='Sap Floc Id'
                                value={item.sap_floc_id?.value}
                              />
                            </div>}
                            <div>
                              <div className={styles.dashedBorder} style={{ margin: '10px 0px' }}>
                                <input
                                  type="file"
                                  // accept=".xls,.xlsx"
                                  onChange={(e) => {
                                    let updatedData = subHeaderData;
                                    updatedData[index].unSavedFile = e.target.files[0];
                                    setSubHeaderData([...updatedData]);
                                  }}
                                  className={styles.noShowFile}
                                />
                                <div style={{ textAlign: 'center' }} className={styles.uploadText}>
                                  {newPageStrings.DRAG_FILE}
                                </div>
                              </div>
                              <div className="mt-2">
                                <p className={styles.labelText}>{newPageStrings.FILE}</p>
                                <div style={{ marginTop: '-10px' }}>
                                  {item?.unSavedFile ? (
                                    <div className={styles.selectedFile}>
                                      {item?.unSavedFile?.name}
                                    </div>
                                  ) : (
                                      <div>-</div>
                                    )}
                                </div>
                              </div>
                              <CustomTextInput
                                label={newPageStrings.DESCRIPTION_OPTIONAL}
                                value={item?.unSavedDescription}
                                onValueChange={(data) => {
                                  const updatedData = subHeaderData;
                                  updatedData[index].unSavedDescription = data.value.toUpperCase();
                                  setSubHeaderData([...updatedData]);
                                }}
                                autoCapitalize="characters"
                                editable
                                rows={1}
                              />
                              <CustomButton
                                onPress={() => handleAttachedFiles(item?.unSavedFile, item?.unSavedDescription, index)}
                                customStyle={{
                                  backgroundColor: 'white',
                                  color: '#0578BE',
                                  width: '100%',
                                  marginTop: 20,
                                  border: '1px solid #0578BE',
                                }}
                                customTextStyle={{ fontSize: 12 }}
                                value={newPageStrings.SAVE}
                                disabled={!item?.unSavedFile}
                              />
                            </div>
                            {
                              item.attachedFiles.length ? (
                                <div
                                  className="ag-theme-alpine"
                                  style={{
                                    width: '100%',
                                    // eslint-disable-next-line
                                    height: item.attachedFiles.length === 0 ? 0 : item.attachedFiles.length === 1 ? 100 : item.attachedFiles.length === 2 ? 150 : 200,
                                    marginTop: '30px',
                                  }}
                                >
                                  <AGGridTable
                                    columnDefs={attachmentColDefs}
                                    rowData={item.attachedFiles}
                                    rowIndex={index}
                                  />
                                </div>
                              ) : ''
                            }
                          </div>
                          <div className="m-3">
                            <SubHeaderBorder
                              headerContent="Advanced Details"
                              headerDescription={(
                                // <>
                                <CustomButton
                                  value="Clear All"
                                  icon={trash}
                                  customStyle={{
                                    backgroundColor: 'white',
                                    color: 'red',
                                    width: '95%',
                                    marginLeft: 20,
                                    border: '1px solid red',
                                  }}
                                  onPress={() => clearAdvancedDetails(index)}
                                  disabled={checkAdvancedDetails(item.advancedDetails)}
                                />
                              )}
                              toggleSubHeader={() => toggleSubheader(index, true)}
                            >
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Area Sub Group 1
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'area_sub_group_1_id', e.target.value, true)} value={item.advancedDetails?.area_sub_group_1_id || ''}>
                                    <option value="" disabled>Select Area Group 1</option>
                                    {
                                      areaGroupData?.areaSubGroups1.map((subAreaGroup1) => (
                                        <option>{subAreaGroup1.area_sub_group_1}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Area Sub Group 2
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'area_sub_group_2_id', e.target.value, true)} value={item.advancedDetails?.area_sub_group_2_id || ''}>
                                    <option value="" disabled>Select Area Group 2</option>
                                    {
                                      areaGroupData?.areaSubGroups2.map((subAreaGroup2) => (
                                        <option>{subAreaGroup2.area_sub_group_2}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Spares Class
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'class_id', e.target.value, true)} value={item.advancedDetails?.class_id || ''}>
                                    <option value="" disabled>Select Spares Class</option>
                                    {
                                      areaGroupData?.spares_class.map((ele) => (
                                        <option>{ele.spares_class}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Ac Dc Value
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'ac_dc_id', e.target.value, true)} value={item.advancedDetails?.ac_dc_id || ''}>
                                    <option value="" disabled>Select Ac Dc Value</option>
                                    {
                                      areaGroupData?.ac_dc_values.map((ele) => (
                                        <option>{ele.ac_dc_value}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Construction Material Value
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'construction_material_id', e.target.value, true)} value={item.advancedDetails?.construction_material_id || ''}>
                                    <option value="" disabled>Select Construction Material Value</option>
                                    {
                                      areaGroupData?.construction_materials.map((ele) => (
                                        <option>{ele.construction_material}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Frame Value
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'frame_id', e.target.value, true)} value={item.advancedDetails?.frame_id || ''}>
                                    <option value="" disabled>Select Frame Value</option>
                                    {
                                      areaGroupData?.frame_values.map((ele) => (
                                        <option>{ele.frame_value}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Phase Value
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'phase_id', e.target.value, true)} value={item.advancedDetails?.phase_id || ''}>
                                    <option value="" disabled>Select Phase Value</option>
                                    {
                                      areaGroupData?.phase_values.map((ele) => (
                                        <option>{ele.phase_value}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Storage Location
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'storage_location_id', e.target.value, true)} value={item.advancedDetails?.storage_location_id || ''}>
                                    <option value="" disabled>Select Storage Location</option>
                                    {
                                      areaGroupData?.storage_locations.map((ele) => (
                                        <option>{ele.storage_location}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Location Used Values
                                  {/* <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span> */}
                                </div>
                                <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'location_used_id', e.target.value, true)} value={item.advancedDetails?.location_used_id || ''}>
                                    <option value="" disabled>Select Location Used Values</option>
                                    {
                                      areaGroupData?.location_used_values.map((ele) => (
                                        <option>{ele.location_used}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              {/* <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                <div style={{ width: '30%' }} className="item1 pl-3">
                                  Class
                                </div>
                                <div style={{ width: '70%' }} className="d-flex flex-row">
                                  <select className="selectDropdown" onChange={(e) => handleSubheaderChange(index, 'spares_class', e.target.value, true)} value={item.advancedDetails?.spares_class || ''}>
                                    {
                                      areaGroupData?.areaSubGroups2.map((subAreaGroup2) => (
                                        <option>{subAreaGroup2.area_sub_group_2}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div> */}
                              {
                                advancedDetails.map((detail) => (
                                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                                    <div style={{ width: '30%' }} className="item1 pl-3">
                                      {detail.split('_').map((ele) => (
                                        <span>
                                          {`${ele[0].toUpperCase()}${ele.slice(1)} `}
                                        </span>
                                      ))}
                                    </div>
                                    <div style={{ width: '70%' }} className="d-flex flex-row">
                                      <input
                                        type={(detail === 'input_rpm' || detail === 'output_rpm' || detail === 'horsepower' || detail === 'lead_time' || detail === 'lead_time' || detail === 'install_time' || detail === 'estimated_cost' || detail === 'qty_in_repair') ? 'number' : 'text'}
                                        value={(detail === 'created_by' || detail === 'modified_by') ? global.globalReducer.userPersonalData.sapUserID : item.advancedDetails[detail] || ''}
                                        onChange={(e) => handleSubheaderChange(index, detail, e.target.value, true)}
                                        className="inputBox"
                                        readOnly={(detail === 'created_by' || detail === 'modified_by')}
                                      />
                                      <div
                                        style={{
                                          cursor: 'pointer', width: 15,
                                        }}
                                        onClick={() => handleSubheaderChange(index, detail, '')}
                                        role="presentation"
                                      >
                                        {
                                          item.advancedDetails[detail]
                                          && (
                                            <img src={imgClearInput} alt="clear" height={13} width={13} />
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }

                            </SubHeaderBorder>
                          </div>
                        </SubHeaderBorder>
                      </div>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
          {bulkModal && (
            <CustomModal
              customWidth="480px"
              customHeight="315px"
              actions={{ cancel: () => setBulkModal(false) }}
              header={{
                title: newPageStrings.BULK_IMPORT,
                color: '#192D38',
                bgColor: '#F2F8FC',
                exit: true,
                height: '33px',
              }}
              icon={blueClose}
              buttons={(
                <div className="d-flex flex-row justify-content-center align-items-end mb-4 ">
                  <CustomButton
                    customStyle={{
                      backgroundColor: 'white',
                      color: '#003366',
                      width: '94%',
                      // height:'33px'
                    }}
                    customTextStyle={{ fontSize: 12 }}
                    value={newPageStrings.BULK_IMPORT}
                    disabled={!selectedFile}
                    onPress={() => convertFileToJson()}
                  />
                </div>
              )}
            >
              <div className={styles.dashedBorder}>
                <input
                  type="file"
                  accept=".xls,.xlsx"
                  onChange={(event) => setSelectedFile(event.target.files[0])}
                  className={styles.noShowFile}
                />
                <div style={{ textAlign: 'center' }} className={styles.uploadText}>
                  {newPageStrings.DRAG_FILE}
                </div>
              </div>
              <div className="m-3">
                <p className={styles.labelText}>{newPageStrings.FILE}</p>
                <div style={{ marginTop: '-10px' }}>
                  {selectedFile ? (
                    <div className={styles.selectedFile}>
                      {selectedFile?.name}
                    </div>
                  ) : (
                      <div>-</div>
                    )}
                </div>
              </div>
            </CustomModal>
          )}
        </CustomModal>
      )}
      {modalStatus && !isPostingSuccess && (
        <ButtonModal
          noOfButtons={1}
          isError
          data={{
            ...sparesManagementModal,
            instructionsText1: modalStatus,
          }}
          onButtonPress={() => {
            setOpenModal(false);
            setSubHeaderData([{
              opened: true, advancedDetailsOpen: true, attachedFiles: [], advancedDetails: {}, [newPageStrings.SITE]: plantDetails[global.globalReducer.plantId].toUpperCase(), flocDetails: []
            }]);
            setModalStatus(null);
          }}
        />
      )}
      {modalStatus && isPostingSuccess && (
        <ButtonModal
          noOfButtons={1}
          isSuccess
          data={{
            ...sparesManagementModal,
            instructionsText1: modalStatus,
          }}
          onButtonPress={() => {
            setOpenModal(false);
            setSubHeaderData([{
              opened: true, advancedDetailsOpen: true, attachedFiles: [], advancedDetails: {}, [newPageStrings.SITE]: plantDetails[global.globalReducer.plantId].toUpperCase(), flocDetails: []
            }]);
            setModalStatus(null);
            setIsPostingSuccess(false);
          }}
        />
      )}
      {clearModalData && (
        <ButtonModal
          noOfButtons={2}
          data={{
            ...newPageClearDataModal,
          }}
          onButtonPress={{
            firstButtonPress: clearData,
            // secondButtonPress: confirmFromCreateOrder,
            secondButtonPress: unClearData,
          }}
        />
      )}
      {deleteSelected && (
        <CustomModal
          customWidth="320px"
          customHeight="170px"
          actions={{ cancel: () => setDeleteSelected(false) }}
          header={{
            title: newPageStrings.CONFIRM_DELETE,
            color: '#192D38',
            bgColor: '#FFEB99',
            exit: true,
            height: '40px',
          }}
          icon={blueClose}
          buttons={(
            <div className="ml-3 mr-3 d-flex flex-row justify-content-between">
              <CustomButton
                onPress={() => setDeleteSelected(false)}
                customStyle={{
                  backgroundColor: 'white',
                  color: '#0578BE',
                  height: '30px',
                  border: '1px solid #0578BE',
                }}
                customTextStyle={{ fontSize: 12 }}
                value={newPageStrings.CANCEL}
              />
              <CustomButton
                onPress={() => deleteRowData(selectedRows)}
                customStyle={{
                  backgroundColor: '#C74A34',
                  color: 'white',
                  height: '30px',
                  borderRadius: '3px',
                  border: '1px solid #C74A34',
                }}
                customTextStyle={{ fontSize: 12 }}
                value={newPageStrings.DELETE}
              />
            </div>
          )}
        >
          <div className={`${styles.deleteText} mt-3 mb-3 ml-2 mr-2`}>
            {newPageStrings.DELETE_SELECTED_SPARES_MESSAGE}
          </div>
        </CustomModal>
      )}
      {editModal && (
        <CustomModal
          customWidth="70%"
          customHeight="80%"
          actions={{
            cancel: () => {
              setEditModal(false);
              setFlocData(null);
              setPlantId(global.globalReducer.plantId)
            }
          }}
          header={{
            title: newPageStrings.EDIT_SPARES,
            color: '#192D38',
            bgColor: '#F2F8FC',
            exit: true,
          }}
          icon={blueClose}
          buttons={(
            <div className="d-flex flex-row justify-content-end mb-4 mr-4">
              <CustomButton
                customStyle={{
                  padding: 5,
                  marginRight: 20,
                  border: '1px solid white',
                }}
                onPress={() => setEditSubHeaderData(initialEditData)}
                value="Revert Changes"
                outline
              // disabled
              />
              <CustomButton
                customStyle={{ padding: 5 }}
                onPress={editData}
                value={newPageStrings.SAVE}
                outline
              // disabled={false}
              // disabled={!editSubheaderData?.plant_id || !editSubheaderData?.area_group || !editSubheaderData?.equip_component_description}
              />
            </div>
          )}
        >
          <div
            className="p-3"
            style={{ height: '85%', overflow: 'scroll', width: '100%' }}
          >
            <SubHeaderBorder
              headerContent={newPageStrings.SPARES_DETAILS}
              icon={
                checkNotNull(editSubheaderData?.plant_id)
                  && checkNotNull(editSubheaderData?.area_group)
                  && checkNotNull(
                    editSubheaderData?.equip_component_description,
                  )
                  ? success
                  : warning
              }
              headerDescription={
                (
                  <>
                    <span className={styles.valueDescription}>
                      {0 + 1}
                    </span>
                    {editSubheaderData?.plant_id ? (
                      <span className={styles.valueDescription}>
                        {editSubheaderData?.plant_id}
                      </span>
                    ) : (
                        ' '
                      )}
                    {editSubheaderData?.area_group ? (
                      <span className={styles.valueDescription}>
                        {editSubheaderData?.area_group}
                      </span>
                    ) : (
                        ' '
                      )}
                    {editSubheaderData?.equip_component_description ? (
                      <span className={styles.valueDescription}>
                        {editSubheaderData?.equip_component_description}
                      </span>
                    ) : (
                        ' '
                      )}
                    <span
                      className={styles.headerDescription}
                      onClick={() => deleteObject(0)}
                      role="presentation"
                    >
                      <img
                        src={trash}
                        height="12"
                        width="12"
                        style={{ marginTop: '-3px' }}
                        alt="delete"
                      />
                    </span>
                  </>
                )
              }
            // index={index}
            >
              <div className="row px-2" style={{ flexWrap: 'wrap' }}>
                <div
                  className="col-lg-2 pb-2"
                  style={{ zIndex: 2000 - 0 }}
                >
                  <CustomDropdown
                    data={{
                      name: newPageStrings.SITE,
                      value: (editSubheaderData?.plant_id),
                      selectedObject: {
                        key: newPageStrings.SITE,
                        name: (typeof editSubheaderData?.plant_id === 'number') ? getPlantTitle(editSubheaderData?.plant_id) : editSubheaderData?.plant_id,
                        value: (editSubheaderData?.plant_id),
                      },
                      items: areaGroupData?.plantSites,
                    }}
                    editable
                    updateDropDownItem={(data) => {
                      const updatedData = {
                        ...editSubheaderData,
                        sap_floc_id: null,
                        plant_id: data.name,
                      };
                      setEditSubHeaderData(updatedData);
                      setPlantId(data.value);
                    }}
                    required
                    style={{ zIndex: 2000 - 0 }}
                    childName="site_title"
                    childValue="plant_id"
                  />
                </div>
                <div
                  className="col-lg-2 pb-2"
                  style={{ zIndex: 2000 - 0 }}
                >
                  <CustomDropdown
                    data={{
                      name: newPageStrings.AREA_GROUP,
                      value: editSubheaderData?.area_group && getAreaGroupValue(editSubheaderData?.area_group),
                      selectedObject: {
                        key: newPageStrings.SITE,
                        name: editSubheaderData?.area_group,
                        value: editSubheaderData?.area_group && getAreaGroupValue(editSubheaderData?.area_group),
                      },
                      items: areaGroupData?.areaGroups,
                    }}
                    updateDropDownItem={(data) => handleEditDataChange(
                      'area_group',
                      data.name,
                    )}
                    editable
                    required
                    childName="area_group"
                    childValue="area_group_id"
                    style={{ zIndex: 2000 - 0 }}
                  />
                </div>
                <div className="col-lg-2 pb-2">
                  <CustomTextInput
                    label={newPageStrings.SAP_MATERIAL_ID}
                    value={editSubheaderData?.sap_matl_id}
                    editable
                    rows={1}
                    onValueChange={(data) => handleEditDataChange(
                      'sap_matl_id',
                      data.value,
                    )}
                  />
                </div>
                <div className="col-lg-2 pb-2">
                  <CustomTextInput
                    label={newPageStrings.QUANTITY_REQUIRED}
                    value={editSubheaderData?.qty_required}
                    editable
                    rows={1}
                    onValueChange={(data) => handleEditDataChange(
                      'qty_required',
                      data.value,
                    )}
                    type="number"
                  />
                </div>
                <div className="col-lg-2 pb-2">
                  <CustomTextInput
                    label={newPageStrings.QUANTITY_ON_HAND}
                    value={editSubheaderData?.qty_on_hand}
                    editable
                    rows={1}
                    onValueChange={(data) => handleEditDataChange(
                      'qty_on_hand',
                      data.value,
                    )}
                    type="number"
                  />
                </div>
                <div className="col-lg-2 pb-2" style={{ zIndex: 2 }}>
                  <CustomDropdown
                    data={{
                      name: newPageStrings.UNIT_OF_MEASURE,
                      value: editSubheaderData?.unit_of_measure,
                      selectedObject: {
                        key: newPageStrings.UNIT_OF_MEASURE,
                        name: editSubheaderData?.unit_of_measure,
                        value: editSubheaderData?.unit_of_measure,
                      },
                      items: areaGroupData?.uom,
                    }}
                    updateDropDownItem={(data) => handleEditDataChange(
                      'unit_of_measure',
                      data.name,
                    )}
                    editable
                    childName="unit_of_measure"
                    childValue="uom_id"
                  // required
                  />
                </div>
                <div className="col-lg-6 pb-2">
                  <CustomTextInput
                    label={newPageStrings.EQUIPMENT_DESCRIPTION}
                    value={
                      editSubheaderData?.equip_component_description
                    }
                    editable
                    rows={1}
                    required
                    onValueChange={(data) => handleEditDataChange(
                      'equip_component_description',
                      data.value,
                    )}
                  />
                </div>
                <div className="col-lg-6 pb-2">
                  <CustomTextInput
                    label={newPageStrings.ADDTL_DESCRIPTION}
                    value={
                      editSubheaderData?.additional_desc_info
                    }
                    editable
                    rows={1}
                    onValueChange={(data) => handleEditDataChange(
                      'additional_desc_info',
                      data.value,
                    )}
                  />
                </div>
                <div className="col-lg-12 pb-2">
                  <CustomTextInput
                    label={newPageStrings.REPAIR_STATUS}
                    value={editSubheaderData?.repair_status}
                    editable
                    rows={1}
                    onValueChange={(data) => handleEditDataChange(
                      'repair_status',
                      data.value,
                    )}
                  />
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomRadioButton
                    value={editSubheaderData?.critical_spare}
                    height={30}
                    width={30}
                    editable
                    onClick={() => handleEditDataChange(
                      'critical_spare',
                      !editSubheaderData?.critical_spare,
                    )}
                  />
                  <span className={styles.kittingText}>
                    {newPageStrings.CRITICAL_SPARE}
                  </span>
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomRadioButton
                    value={editSubheaderData?.stores_item}
                    height={30}
                    width={30}
                    editable
                    onClick={() => handleEditDataChange(
                      'stores_item',
                      !editSubheaderData?.stores_item,
                    )}
                  />
                  <span className={styles.kittingText}>
                    {newPageStrings.STORES_ITEM}
                  </span>
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomRadioButton
                    value={editSubheaderData?.repairable}
                    height={30}
                    width={30}
                    editable
                    onClick={() => handleEditDataChange(
                      'repairable',
                      !editSubheaderData?.repairable,
                    )}
                  />
                  <span className={styles.kittingText}>
                    {newPageStrings.REPAIRABLE}
                  </span>
                </div>
                <div className="col-lg-3 pb-2">
                  <CustomRadioButton
                    value={editSubheaderData?.is_asset}
                    height={30}
                    width={30}
                    editable
                    onClick={() => handleEditDataChange(
                      'is_asset',
                      !editSubheaderData?.is_asset,
                    )}
                  />
                  <span className={styles.kittingText}>
                    {newPageStrings.IS_ASSET}
                  </span>
                </div>
                {flocData && <div className="col-lg-3 pb-2" style={{ height: '70px' }}>
                  <MultiDropdown
                    data={flocData?.funcLocDetails}
                    editable
                    childElement="items"
                    childName="name"
                    style={{ zIndex: 900 }}
                    // required
                    selectedObject={{
                      name: editSubheaderData?.sap_floc_id,
                      value: editSubheaderData?.sap_floc_id,
                    }}
                    updateFlocValue={(data) => {
                      const updatedData = {
                        ...editSubheaderData,
                        sap_floc_id: data.value,
                      };
                      setEditSubHeaderData(updatedData);
                    }}
                    name='Sap Floc Id'
                    value={editSubheaderData?.sap_floc_id}
                  />
                </div>}
                <div>
                  <SubHeaderBorder
                    // key={index}
                    headerContent={newPageStrings.ATTACHMENTS}
                    headerDescription={
                      (
                        <span className={styles.valueDescription}>
                          {0 + 1}
                        </span>
                      )
                    }
                  >
                    <div className={styles.dashedBorder} style={{ width: '100%', margin: '0px' }}>
                      <input
                        type="file"
                        onChange={(event) => setAttachedFile(event.target.files[0])}
                        className={styles.noShowFile}
                      />
                      <div style={{ textAlign: 'center' }} className={styles.uploadText}>
                        {newPageStrings.DRAG_FILE}
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className={styles.labelText}>{newPageStrings.FILE}</p>
                      <div style={{ marginTop: '-10px' }}>
                        {attachedFile?.name ? (
                          <div className={styles.selectedFile}>
                            {attachedFile?.name}
                          </div>
                        ) : (
                            <div>-</div>
                          )}
                      </div>
                    </div>
                    <CustomTextInput
                      label={newPageStrings.DESCRIPTION_OPTIONAL}
                      value={attachmentDescriptionValue}
                      onValueChange={(data) => setAttachmentDescriptionValue(data.value.toUpperCase())}
                      autoCapitalize="characters"
                      editable
                      rows={1}
                    />
                    <CustomButton
                      onPress={addAttachmentFile}
                      customStyle={{
                        backgroundColor: 'white',
                        color: '#0578BE',
                        width: '100%',
                        marginTop: 20,
                        border: '1px solid #0578BE',
                      }}
                      customTextStyle={{ fontSize: 12 }}
                      value={newPageStrings.SAVE}
                      disabled={!attachedFile}
                    />
                    <div className="dropdown-divider ml-2 mr-2" />
                    {
                      editSubheaderData?.Attachments?.length ? (
                        <div
                          className="ag-theme-alpine"
                          style={{
                            width: '99%',
                            height: (editSubheaderData?.Attachments?.length === 1 && 100) || editSubheaderData?.Attachments?.length === 2 ? '150px' : '200px',
                          }}
                        >
                          <AGGridTable
                            rowData={editSubheaderData?.Attachments}
                            columnDefs={editAttachmentDefs}
                          />
                        </div>
                      ) : ''
                    }
                  </SubHeaderBorder>
                </div>
              </div>

              <div className="m-3">
                <SubHeaderBorder
                  headerContent="Advanced Details"
                  headerDescription={(
                    // <>
                    <CustomButton
                      value="Clear All"
                      icon={trash}
                      customStyle={{
                        backgroundColor: 'white',
                        color: 'red',
                        width: '95%',
                        marginLeft: 20,
                        border: '1px solid red',
                      }}
                      onPress={() => {
                        setEditSubHeaderData((prevData) => {
                          let updatedData = {
                            ...prevData,
                            area_sub_group_1: '',
                            area_sub_group_2: '',
                          };
                          advancedDetails.forEach((element) => {
                            updatedData = {
                              ...updatedData,
                              [element]: '',
                            };
                          });
                          return updatedData;
                        });
                      }}
                      disabled={editSubheaderData && checkAdvancedDetails(editSubheaderData)}
                    />
                  )}
                >
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Area Sub Group 1
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            area_sub_group_1: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        // onChange={(e) => handleSubheaderChange(index, 'area_sub_group_1', e.target.value, true)}
                        value={editSubheaderData?.area_sub_group_1 || ''}
                      >
                        {
                          areaGroupData?.areaSubGroups1.map((subAreaGroup1) => (
                            <option>{subAreaGroup1.area_sub_group_1}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Area Sub Group 2
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            area_sub_group_2: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        // onChange={(e) => handleSubheaderChange(index, 'area_sub_group_2', e.target.value, true)}
                        value={editSubheaderData?.area_sub_group_2 || ''}
                      >
                        {
                          areaGroupData?.areaSubGroups2.map((subAreaGroup2) => (
                            <option>{subAreaGroup2.area_sub_group_2}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Stores Item
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            stores_item: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        // onChange={(e) => handleSubheaderChange(index, 'area_sub_group_1', e.target.value, true)}
                        value={editSubheaderData?.stores_item || ''}
                      >
                        {
                          areaGroupData?.storeItems.map((ele) => (
                            <option>{ele.stores_item}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Spares Class
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            class_id: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.class_id || ''}
                      >
                        {
                          areaGroupData?.spares_class.map((ele) => (
                            <option>{ele?.spares_class}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Ac Dc Value
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            ac_dc_value: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.ac_dc_value || ''}
                      >
                        {
                          areaGroupData?.ac_dc_values.map((ele) => (
                            <option>{ele.ac_dc_value}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Construction Material Value
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            construction_material: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.construction_material || ''}
                      >
                        {
                          areaGroupData?.construction_materials.map((ele) => (
                            <option>{ele.construction_material}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Frame Value
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            frame_value: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.frame_value || ''}
                      >
                        {
                          areaGroupData?.frame_values.map((ele) => (
                            <option>{ele.frame_value}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Phase Value
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            phase_value: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.phase_value || ''}
                      >
                        {
                          areaGroupData?.phase_values.map((ele) => (
                            <option>{ele.phase_value}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Storage Location
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            storage_location: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.storage_location || ''}
                      >
                        {
                          areaGroupData?.storage_locations.map((ele) => (
                            <option>{ele.storage_location}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Location Used Values
                      <span className={`${styles.asterikRequired}`}>{customDropdownStrings.ASTERIK}</span>
                    </div>
                    <div style={{ width: '70%', paddingLeft: '7px' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            location_used: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.location_used || ''}
                      >
                        {
                          areaGroupData?.location_used_values.map((ele) => (
                            <option>{ele.location_used}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  {/* <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                    <div style={{ width: '30%' }} className="item1 pl-3">
                      Class
                    </div>
                    <div style={{ width: '70%' }} className="d-flex flex-row">
                      <select
                        className="selectDropdown"
                        onChange={(e) => {
                          const updatedData = {
                            ...editSubheaderData,
                            spares_class: e.target.value,
                          };
                          setEditSubHeaderData(updatedData);
                        }}
                        value={editSubheaderData?.spares_class || ''}
                      >
                        {
                          areaGroupData?.areaSubGroups2.map((subAreaGroup2) => (
                            <option>{subAreaGroup2.area_sub_group_2}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div> */}
                  {
                    advancedDetails.map((detail) => (
                      <div className="d-flex flex-row" style={{ borderBottom: '1px solid #BFC9D0' }}>
                        <div style={{ width: '30%' }} className="item1 pl-3">
                          {detail.split('_').map((ele) => (
                            <span>
                              {`${ele[0].toUpperCase()}${ele.slice(1)} `}
                            </span>
                          ))}
                        </div>
                        <div style={{ width: '70%' }} className="d-flex flex-row">
                          <input
                            type={(detail === 'input_rpm' || detail === 'output_rpm' || detail === 'horsepower' || detail === 'lead_time' || detail === 'lead_time' || detail === 'install_time' || detail === 'estimated_cost' || detail === 'qty_in_repair') ? 'number' : 'text'}
                            value={(editSubheaderData && editSubheaderData[detail]) || ''}
                            onChange={(e) => {
                              const updatedData = {
                                ...editSubheaderData,
                                [detail]: e.target.value,
                              };
                              setEditSubHeaderData(updatedData);
                            }}
                            className="inputBox"
                          />
                          <div
                            style={{
                              cursor: 'pointer', width: 15,
                            }}
                            onClick={() => {
                              const updatedData = {
                                ...editSubheaderData,
                                [detail]: '',
                              };
                              setEditSubHeaderData(updatedData);
                            }}
                            role="presentation"
                          >
                            {
                              editSubheaderData && editSubheaderData[detail]
                              && (
                                <img src={imgClearInput} alt="clear" height={13} width={13} />
                              )
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  }

                </SubHeaderBorder>
              </div>
            </SubHeaderBorder>
          </div>
        </CustomModal>

      )}
      {viewModal && (
        <CustomModal
          customWidth="50%"
          customHeight="70%"
          actions={{ cancel: () => setViewModal(false) }}
          header={{
            title: newPageStrings.SPARES_INFO,
            color: '#192D38',
            bgColor: '#F2F8FC',
            exit: true,
            height: '40px',
            scroll: true,
          }}
          icon={blueClose}
        >
          <div className="m-3" style={{ border: '1px solid rgb(237,240,243)' }}>
            {
              Object.entries(viewTableData).map((item) => (
                <div className="d-flex flex-row justify-content-between" style={{ borderBottom: '1px solid #BFC9D0' }}>
                  <div className="item1 p-1">
                    {item[0].split('_').map((ele) => (
                      <span>
                        {`${ele[0].toUpperCase()}${ele.slice(1)} `}
                      </span>
                    ))}
                  </div>
                  <div className="ml-1 itemData">{item[1] === true ? 'true' : item[1] || '-'}</div>
                </div>
              ))
            }
          </div>
        </CustomModal>
      )}

      <div
        className="ag-theme-alpine"
        style={{
          position: 'absolute',
          height: '82%',
          width: '100%',
          marginTop: '70px',
        }}
      >
        <AGGridTable
          selectedData={(data) => selectedData(data)}
          rowData={filteredData}
          columnDefs={columnDefs}
          // rowToEdit={rowDataIndexToEdit}
          editRowsData={editData}
        />
      </div>
      {repairStatusModal && (
        <CustomModal
          customWidth="50%"
          customHeight="35%"
          actions={{
            cancel: () => {
              setRepairStatusModal(false);
              setEditEntries(false);
            },
          }}
          header={{
            title: newPageStrings.EDIT_REPAIR_NOTES,
            color: '#003366',
            bgColor: '#e2e2e2',
            exit: true,
          }}
          icon={blueClose}
          buttons={(
            <div>
              {editEntries ? (
                <div className="d-flex justify-content-between">
                  <CustomButton
                    customStyle={{
                      backgroundColor: 'white',
                      color: '#003366',
                      border: 'none',
                      width: '50%',
                      // width: '100%'
                    }}
                    onPress={() => setEditEntries(false)}
                    value={newPageStrings.CANCEL}
                  />
                  <CustomButton
                    customStyle={{ marginRight: 20, width: '50%' }}
                    onPress={() => { setRepairStatusModal(false); setEditEntries(false); editViewEntries(repairStatusValue, editRepairStatusIndex); }}
                    value={newPageStrings.SAVE}
                  />
                </div>
              ) : (
                  <div>
                    <CustomButton
                      onPress={() => setEditEntries(true)}
                      value={newPageStrings.EDIT}
                      customStyle={{
                        backgroundColor: '#0578BE',
                        color: 'white',
                        width: '95%',
                        marginLeft: 20,
                        // marginRight:10
                      }}
                      customTextStyle={{ fontSize: 12 }}
                    />
                  </div>
                )}
            </div>
          )}
        >
          <div className="m-3 p-3">
            <CustomTextInput
              value={repairStatusValue}
              editable={editEntries}
              onValueChange={(data) => setRepairStatusValue(data.value)}
              rows={4}
            />
          </div>
        </CustomModal>
      )}
      {sapMatlModal && (
        <CustomModal
          customWidth="60%"
          actions={{
            cancel: () => {
              setSapMatlModal(false);
              setMaterialActiveTab(materialInfoStrings.MATERIAL_DATA);
              setSapMaterialData(null);
            },
          }}
          header={{
            title: newPageStrings.MATERIAL_INFO,
            color: '#003366',
            bgColor: '#e2e2e2',
            exit: true,
            scroll: true,
          }}
          icon={blueClose}
        >
          {checkNotNull(sapMaterialData) ? (
            <div>
              <div className="m-3 d-flex flex-row gap-5 ml-1">
                <div className={materialActiveTab === materialInfoStrings.MATERIAL_DATA ? styles.materialActiveTab : styles.materialTabs} onClick={() => setMaterialActiveTab(materialInfoStrings.MATERIAL_DATA)} role="presentation">
                  {materialInfoStrings.MATERIAL_DATA}
                </div>
                <div className={materialActiveTab === materialInfoStrings.OPEN_PURCHASE_ORDER ? styles.materialActiveTab : styles.materialTabs} onClick={() => setMaterialActiveTab(materialInfoStrings.OPEN_PURCHASE_ORDER)} role="presentation">
                  {materialInfoStrings.OPEN_PURCHASE_ORDER}
                </div>
                <div className={materialActiveTab === materialInfoStrings.OPEN_PURCHASE_REQUISTION ? styles.materialActiveTab : styles.materialTabs} onClick={() => setMaterialActiveTab(materialInfoStrings.OPEN_PURCHASE_REQUISTION)} role="presentation">
                  {materialInfoStrings.OPEN_PURCHASE_REQUISTION}
                </div>
                <div className={materialActiveTab === materialInfoStrings.VENDOR_AGREEMENTS ? styles.materialActiveTab : styles.materialTabs} onClick={() => setMaterialActiveTab(materialInfoStrings.VENDOR_AGREEMENTS)} role="presentation">
                  {materialInfoStrings.VENDOR_AGREEMENTS}
                </div>
              </div>
              {
                materialActiveTab === materialInfoStrings.MATERIAL_DATA && (
                  <div className="row px-2 m-2">
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Material Number</p>
                      <p>{sapMaterialData[0].materialNumber.slice(-8) || '-'}</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Plant</p>
                      <p>{sapMaterialData[0].plant || '-'}</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>MRP TYPE</p>
                      <p>{sapMaterialData[0].mrpType || '-'}</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Stock Status</p>
                      <p>-</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Bin Location</p>
                      <p>{sapMaterialData[0].binLocation || '-'}</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Unrestricted Qty</p>
                      <p>{sapMaterialData[0].unRestrictedQuantity || '-'}</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Unit Price</p>
                      <p>{sapMaterialData[0].unitPrice || '-'}</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>BOM Indicator</p>
                      <p>Coming soon</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Safety Stock</p>
                      <p>Coming soon</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Min Order Qty</p>
                      <p>Coming soon</p>
                    </div>
                    <div className="col-lg-3 pb-2">
                      <p className={styles.headerText}>Max Order Qty</p>
                      <p>Coming soon</p>
                    </div>
                    <div className="col-lg-8 pb-2">
                      <p className={styles.headerText}>Short Text</p>
                      <p>{sapMaterialData[0].materialShortDesc || '-'}</p>
                    </div>
                    <div className="col-lg-8 pb-2">
                      <p className={styles.headerText}>PO Long Text</p>
                      <p>{sapMaterialData[0].poText || '-'}</p>
                    </div>
                    <div className="col-lg-8 pb-2">
                      <p className={styles.headerText}>Material Memo</p>
                      <p>{sapMaterialData[0].memoText || '-'}</p>
                    </div>
                  </div>
                )
              }
              {
                materialActiveTab === materialInfoStrings.OPEN_PURCHASE_ORDER && (
                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: '190px',
                      margin: '30px',
                      // width: '95%',
                    }}
                  >
                    <AGGridTable
                      rowData={openPurchaseOrderRowData}
                      columnDefs={openPurchaseOrderColumnDefs}
                    />
                  </div>
                )
              }
              {
                materialActiveTab === materialInfoStrings.OPEN_PURCHASE_REQUISTION && (
                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: '190px',
                      margin: '30px',
                      // width: '95%',
                    }}
                  >
                    <AGGridTable
                      rowData={openPurchaseRequistionRowData}
                      columnDefs={openPurchaseRequistionColumnDefs}
                    />
                  </div>
                )
              }
              {
                materialActiveTab === materialInfoStrings.VENDOR_AGREEMENTS && (
                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: '190px',
                      margin: '30px',
                      // width: '95%',
                    }}
                  >
                    <AGGridTable
                      rowData={vendorAgreementsRowData}
                      columnDefs={vendorAgreementsColumnDefs}
                    />
                  </div>
                )
              }
            </div>
          ) : <p className='d-flex justify-content-center align-items-center' style={{ height: '100px' }}>No Records Found</p>
          }
        </CustomModal>
      )}
      {externalRepairModal && (
        <CustomModal
          customWidth="100%"
          // customHeight="80%"
          actions={{
            cancel: () => {
              setExternalRepairModal(false);
              setFilteredTransactions(null);
            },
          }}
          header={{
            title: 'Repair Status',
            color: '#003366',
            bgColor: '#e2e2e2',
            exit: true,
            scroll: true,
          }}
          icon={blueClose}
        >
          {
            checkNotNull(filteredTransactions) ? Object.keys(filteredTransactions).map((item, index) => (
              <FormSubHeader
                style={{ backgroundColor: index % 2 === 0 ? '#BFCCD9' : 'rgba(191, 204, 217, 0.5)' }}
                headerContent={(
                  <div className="d-flex align-items-center">
                    <p className={`${styles.formHeaderMaterial} mb-0 mr-2`}>{item.slice(-8)}</p>
                    <p className={`${styles.formHeaderMaterialDes} mb-0 mr-2 mt-0`}>{filteredTransactions[item][0]?.materialDescription}</p>
                    <ContentField bootStrapClass={`${styles.formHeaderMaterialCount}`}>
                      {filteredTransactions[item].length}
                    </ContentField>
                  </div>
                )}
              >
                <table className={`${styles.sparesTable}`} >
                  <thead className={`${styles.sparesTableHead}`}>
                    <th className={`${styles.sparesTableHeader}`} aria-label="sparestable" style={{ width: '3%' }} />
                    {
                      sparesMgtHeaders.map((heading) => (
                        <th className={`${!heading.customWidth && `col-${heading.flexValue}`} ${styles.sparestableHeader} border`} style={{ width: heading.customWidth, paddingLeft: 10 }}>
                          {heading.name}
                        </th>
                      ))
                    }
                  </thead>
                  <tbody>
                    {
                      filteredTransactions[item].map((transaction, filteredTransactionsIndex) => <SparesMgtTransaction transaction={transaction} index={filteredTransactionsIndex} isRetracted={false} />)
                    }
                  </tbody>
                </table>
              </FormSubHeader>
            )) : <p className='d-flex justify-content-center align-items-center' style={{ height: '100px' }}>No Records Found</p>
          }
        </CustomModal>

      )}
    </ContentWithBreadcrumb>
  );
}

NewPage.propTypes = {
  addBreadCrumb: PropTypes.func,
  hideLoader: PropTypes.func,
  resetBreadCrumbs: PropTypes.func,
  showLoader: PropTypes.func,
  global: PropTypes.shape({
    plantId: PropTypes.string,
    userPersonalData: PropTypes.shape({
      sapUserID: PropTypes.string,
    }),
  }),
};
NewPage.defaultProps = {
  addBreadCrumb: () => { },
  hideLoader: () => { },
  resetBreadCrumbs: () => { },
  showLoader: () => { },
  global: {},
};

export default NewPage;
