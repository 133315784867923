import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useMsal, useAccount } from '@azure/msal-react';
import { loginSilentRequest } from '../../../Helpers/auth-config';
import { getFormsData } from '../work-order/work-order.helper';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import CustomDropdown from '../../controls/custom-dropdown';
import CustomButton from '../../controls/custom-button';
import {
  assetViewStrings,
  loaderMessages,
  pages,
  queryKeyStrings,
} from '../../../constants/strings';
import imgCollapseUp from '../../../assets/images/collapse-up.png';
import imgCollapseDown from '../../../assets/images/collapse-down.png';
import MultiDropdown from '../../controls/multi-dropdown/multi-dropdown';
import { getBom, getDoc } from './asset-view.helper';
import AssetViewList from '../../controls/asset-view-list';
import { updateParentFollowupOrder } from '../../../redux/actions/global.actions';
import { getSAPName } from '../../../Helpers/common-helpers';
import styles from './asset-view.module.scss';

const STRINGS = {
  title: 'Asset',
  funcLocation: (number) => `Functional Location - Level ${number}`,
  equipmentLabel: 'Equipment (Optional)',
  searchButton: 'Search',
};

export default function AssetView({
  addBreadCrumb, hideLoader, resetBreadCrumbs, showLoader, updateSelectedPage, updateFlocValue, updateEquipment,
}) {
  const dispatch = useDispatch();

  const globalReducer = useSelector((state) => state.globalReducer);
  const { plantId, userData, userPersonalData } = globalReducer;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  // const [selectOpen, setSelectOpen] = useState(undefined);
  // const [equipmentItems, setEquipmentItems] = useState([]);

  const [flocValue, setFlocValue] = useState({
    name: `${plantId}-${(userData.officeLocation || assetViewStrings.US_TAMPA_OFFICE)}`,
    value: plantId,
  });

  const [isCollapseOpen, setCollapse] = React.useState(false);
  const [bomLoading, setBomLoading] = React.useState(false);
  const [equipValue, setEquipValue] = useState('');
  const [assetFailureMessage, setAssetFailureMessage] = useState('');
  const [docFailureMessage, setDocFailureMessage] = useState('');
  const [equipmentvalue, setEquipmentvalue] = useState([]);
  const [selectedObjValue, setSelectedObjValue] = useState({ name: '', value: '' });
  const toggleCollapse = () => setCollapse(!isCollapseOpen);
  const [functionLocations, setFunctionLocations] = useState({
    level1: {
      name: `${plantId}-${(userData.officeLocation || assetViewStrings.US_TAMPA_OFFICE)}`,
      value: plantId,
    },
  });
  const [searchResults, setSearchResults] = useState([]);
  const [docResults, setDocResults] = useState([]);

  const updateEquipmentData = (data) => {
    const obj = {
      items: equipmentvalue,
      name: data,
      value: data.value,
    };
    setEquipValue(data);
    updateEquipment(obj);
  };
  React.useEffect(() => {
    resetBreadCrumbs();
    addBreadCrumb({
      name: pages.ASSET_VIEW,
      action: null,
    });
  }, []);

  const fetchForms = async () => {
    try {
      // setImgList([]);
      showLoader(loaderMessages.PREPARING_FORMS);
      let response;
      if (account) {
        response = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        });
      }
      const data = await getFormsData(globalReducer, response.idToken);
      hideLoader();
      return data;
    } catch (e) {
      hideLoader();
      console.log('error');
      console.log(JSON.stringify(e));
      // setFuncLocModal(true);
      throw e;
    }
  };
  const funcQuery = useQuery({
    queryKey: [queryKeyStrings.FLOC, plantId],
    queryFn: async () => {
      const response = await fetchForms();
      return response;
    },
    onError: (e) => {
      console.log(e);
    },
    staleTime: 1000 * 60 * 60 * 24 * 7,
    enabled: plantId != null,
    cacheTime: 1000 * 60 * 60 * 24 * 7,
    refetchOnWindowFocus: true,
    retry: false,
  }, []);

  const handleCatch = (setMessage, e) => {
    setBomLoading(false);
    if (typeof e === 'string') {
      if (e.includes(assetViewStrings.NO_RECORDS_FOUND)) {
        setMessage(assetViewStrings.NO_BOM_FOUND);
      } else {
        setMessage(e);
      }
    } else {
      setMessage(assetViewStrings.SOMETHING_WENT_WRONG);
    }
  };

  const handleSearch = async (searchFlocValue, searchEquipValue) => {
    try {
      setAssetFailureMessage('');
      setDocFailureMessage('');
      setBomLoading(true);
      setCollapse(true);
      let params = {
        funLoc: searchFlocValue,
      };
      if (searchEquipValue) {
        params = {
          equipNo: searchEquipValue,
        };
      }
      setSearchResults([]);
      setDocResults([]);
      try {
        let results = await getBom(params);
        results = results.map((item) => {
          if (item.bomComponent) {
            return {
              ...item,
              selected: false,
            };
          }
          return undefined;
        }).filter((item) => item !== undefined);
        const materialIds = [...(new Set(results.map((item) => item.bomComponent)))].filter((item) => item !== undefined);
        const uniqueResults = [];
        materialIds.forEach((id) => {
          uniqueResults.push(results.find((item) => item.bomComponent === id));
        });
        setSearchResults(uniqueResults);
      } catch (e) {
        handleCatch(setAssetFailureMessage, e);
      }
      try {
        setBomLoading(true);
        const getDocResults = await getDoc(params);
        setDocResults(getDocResults);
      } catch (e) {
        handleCatch(setDocFailureMessage, e);
      }
      setBomLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onCreateOrderClick = () => {
    const order = {
      plant: `${plantId}`,
      funLoc: flocValue.value,
      equipNr: equipValue?.value,
      selectedOperation: {
        materials: searchResults?.filter((item) => item.selected).map((item) => (
          {
            memoText: item.MEMOText,
            poText: item.POText,
            materialReserved: item.bomComponent,
            unrestrictedQuantity: item.unrestrictedQuantity,
            stageBin: item.stageBin,
            unitOfMeasure: item.unitOfMeasure,
            isMaterialFetched: true,
            recipient: userPersonalData?.sapUserID
            || getSAPName(
              userData?.givenName,
              userData?.surname,
            ),
          }
        )),
      },
    };
    resetBreadCrumbs(() => {
      updateSelectedPage(pages.HOME);
    });
    dispatch(updateSelectedPage(pages.WORK_ORDER_FROM_ASSET));
    dispatch(updateParentFollowupOrder(order));
  };
  React.useEffect(() => {
    const newFunctionalLocationValues = { ...functionLocations };
    newFunctionalLocationValues.level1 = {
      ...functionLocations.level1,
      items: funcQuery?.data?.funcLocDetails,
    };
    setFunctionLocations(newFunctionalLocationValues);
    // eslint-disable-next-line
  }, [funcQuery.isSuccess, functionLocations.level1.items]);
  const displayValue = (value, y) => {
    if (value.length > 0) {
      setSelectedObjValue(value[value.length - 1]);
      setFlocValue(value[value.length - 1]);
      updateFlocValue('');
      setEquipmentvalue(y);
      setEquipValue('');
      const obj = {
        items: [],
        name: '',
        value: '',
      };
      updateEquipment(obj);
    } else {
      setSelectedObjValue({ name: '', value: '' });
      setEquipmentvalue([]);
    }
  };
  return (
    <ContentWithBreadcrumb>
      <div>
        <div className="col p-0 px-3 ">
          <div className="row gx-0">
            <div className="d-flex justify-content-between" style={{ cursor: 'pointer' }} onClick={toggleCollapse} role="presentation">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h1 className={`${styles.headerTitle}`}>{STRINGS.title}</h1>
                <p className={`${styles.selectedAsset}`}>
                  {flocValue.name}
                  {equipValue && ` / ${equipValue.name}`}
                </p>
              </div>
              <div
                className="iconDiv"
                style={{ marginRight: 30 }}
              >
                <img
                  alt="imgCollapse"
                  src={!isCollapseOpen ? imgCollapseUp : imgCollapseDown}
                  className="collapseIcon"
                  width="22px"
                  height="13px"
                />
              </div>
            </div>
          </div>
          {
            !isCollapseOpen
            && (
            <>
              <div className="row mx-3 my-2">
                <div className="col-4">
                  <CustomDropdown
                    editable={false}
                    data={{
                      name: STRINGS.funcLocation(1),
                      items: [functionLocations.level1],
                      value: functionLocations.level1.name,
                    }}
                    style={{ zIndex: 900 }}
                    withSmartTyping
                  />
                </div>
                <div className="col-4" style={{ zIndex: '1000' }}>
                {functionLocations.level1.items &&  <MultiDropdown
                    data={functionLocations.level1.items}
                    name={assetViewStrings.FUNCTIONAL_LOCATION}
                    selectedObject={selectedObjValue}
                    updateValues={(value, item) => displayValue(value, item)}
                    updateFlocValue={updateFlocValue}
                    value={selectedObjValue.name}
                    editable
                    childElement="items"
                    childName="name"
                  />
                }
                </div>
                <div className="col-4" style={{ zIndex: 800 }}>
                  {equipmentvalue.length > 0
                    ? (
                      <CustomDropdown
                        data={{
                          items: equipmentvalue,
                          value: equipValue,
                          name: assetViewStrings.EQUIPMENTS,
                          selectedObject: equipValue,
                        }}
                        updateDropDownItem={(data) => updateEquipmentData(data)}
                        editable
                      />
                    )
                    : ''}

                </div>
              </div>
              <div className="row mx-3">
                <div className="col">
                  <CustomButton
                    value={STRINGS.searchButton}
                    customStyle={{ width: '100%', marginBottom: 30 }}
                    onPress={() => handleSearch(flocValue.value, equipValue?.value)}
                  />
                </div>
              </div>
            </>
            )
          }
        </div>
        <AssetViewList editable bomLoading={bomLoading} assetFailureMessage={assetFailureMessage} docFailureMessage={docFailureMessage} bomResults={searchResults} setBomResults={setSearchResults} docResults={docResults} flocDetails={equipValue?.value ? null : flocValue} />
        <div
          className="w-100"
          style={{
            position: 'fixed',
            bottom: 0,
            padding: 10,
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <CustomButton customStyle={{ width: '100%' }} onPress={onCreateOrderClick} value={assetViewStrings.CREATE_ORDER} />
        </div>
      </div>
    </ContentWithBreadcrumb>
  );
}

AssetView.propTypes = {
  addBreadCrumb: PropTypes.func,
  global: PropTypes.shape({
    userData: PropTypes.shape({
      givenName: PropTypes.string,
      surname: PropTypes.string,
    }),
    userPersonalData: PropTypes.shape({
      sapUserID: PropTypes.string,
    }),
  }),
  hideLoader: PropTypes.func,
  resetBreadCrumbs: PropTypes.func,
  showLoader: PropTypes.func,
  updateEquipment: PropTypes.func,
  updateFlocValue: PropTypes.func,
  updateSelectedPage: PropTypes.func,
};

AssetView.defaultProps = {
  addBreadCrumb: () => { },
  global: {},
  hideLoader: () => { },
  resetBreadCrumbs: () => { },
  showLoader: () => { },
  updateSelectedPage: () => { },
  updateEquipment: () => { },
  updateFlocValue: () => { },
};
