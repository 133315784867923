import PropTypes from 'prop-types';
import React from 'react';
// import './delete-line-modal.styles.css';
import Modal from 'react-modal';
import styles from './delete-line-modal.module.scss';
import { deleteLineModalStrings } from '../../../constants/strings';

export default function DeleteLineModal(props) {
  const { isError, onCancelClick, onDeleteClick } = props;
  return (
    <Modal
      isOpen
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.90)',
          zIndex: 500000,
        },
        content: {
          backgroundColor: 'rgba(255, 255, 255, 0.0001)',
          borderWidth: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <div>
        <div
          style={{
            width: isError ? '370px' : '320px',
            backgroundColor: 'white',
            borderRadius: '5px',
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: '#C74A34',
              padding: '10px',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              color: 'white',
            }}
          >
            <p className="m-0" style={{ fontWeight: 600 }}>
              {deleteLineModalStrings.DELETE_LINE}
            </p>
          </div>
          <div className="">
            <div className="my-2 mx-3">
              <p
                className={`${styles.woText} my-2`}
                style={{ color: '#003366', fontFamily: 'Barlow-SemiBold' }}
              >
                {deleteLineModalStrings.ACTION_UNDONE}
              </p>
              <p className={`${styles.woText} my-2`}>
                {deleteLineModalStrings.ARE_U_SURE_DELETE_LINE}
              </p>
            </div>
            <div className={`d-flex flex-row justify-content-between ${styles.buttonContainer} align-items-center mt-3`}>
              <div
                className={`${styles.taskButtonStyle} d-flex align-items-center justify-content-center`}
                style={{ backgroundColor: '#7F99B2' }}
                onClick={onCancelClick}
                role="presentation"
              >
                {deleteLineModalStrings.CANCEL}
              </div>
              <div
                className={`${styles.taskButtonStyle} d-flex align-items-center justify-content-center`}
                style={{ backgroundColor: '#C74A34' }}
                onClick={onDeleteClick}
                role="presentation"
              >
                {deleteLineModalStrings.DELETE}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
DeleteLineModal.propTypes = {
  isError: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};
DeleteLineModal.defaultProps = {
  isError: false,
  onCancelClick: () => { },
  onDeleteClick: () => { },
};
