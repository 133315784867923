import React from 'react';

export default function IconImg() {
  return (
    <svg
      fill="none"
      height={14}
      viewBox="0 0 17 14"
      width={17}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.001 4.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill="#192D38" />
      <path
        d="M2.001 0a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2h-12zm12 1a1 1 0 011 1v6.5l-3.776-1.947a.5.5 0 00-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 00-.63.062L1 11V2a1 1 0 011-1h12z"
        fill="#192D38"
      />
    </svg>
  );
}
