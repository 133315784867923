import { workConfirmationTypes } from '../../../redux/actions/action-types';

export const updateWorkConfirmValue = (value) => ({
  type: workConfirmationTypes.UPDATE_WORK_CONFIRMATION,
  payload: value,
});

export const resetWorkConfirmValue = () => ({
  type: workConfirmationTypes.RESET_WORK_CONFIRMATION,
});

export const updatePersonnelList = (value) => ({
  type: workConfirmationTypes.UPDATE_PERSONNEL_LIST,
  payload: value,
});

export const updateLastConfirmation = (value) => ({
  type: workConfirmationTypes.UPDATE_LAST_CONFIRMATION,
  payload: value,
});
