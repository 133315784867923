import PropTypes from 'prop-types';
import React from 'react';

export default function ArrowRight({ fill }) {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85161 10.261L7.07202 6.07325C7.31173 5.82203 7.61991 5.69631 7.99592 5.69631C8.366 5.69631 8.67749 5.82203 8.93036 6.07325L13.167 10.2772C13.386 10.4308 13.6353 10.5073 13.915 10.5073C14.2779 10.5073 14.5893 10.3786 14.8495 10.12L15.6199 9.35575C15.8728 9.091 15.9999 8.78493 15.9999 8.43849C15.9999 8.08566 15.8728 7.77637 15.6199 7.51113L8.93405 0.876942C8.68118 0.625724 8.36969 0.5 7.99961 0.5C7.6236 0.5 7.31542 0.625724 7.07571 0.876942L0.389842 7.51113C0.130397 7.76948 0 8.07851 0 8.43849C0 8.79206 0.130397 9.09765 0.389842 9.35575L1.16031 10.12C1.40659 10.3786 1.71477 10.5073 2.08485 10.5073C2.37952 10.5073 2.63521 10.4254 2.85161 10.261Z"
        fill={fill}
      />
    </svg>
  );
}

ArrowRight.propTypes = {
  fill: PropTypes.string,
};
ArrowRight.defaultProps = {
  fill: '',
};
