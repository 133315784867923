export const SEARCH = {
  headerTitle: 'Confirmation',
  placeholder: 'Search by Work Order #',
  defaultMessage: 'Search for your work order above to begin!',
  errorPersonnalList:
    'There was an issue retrieving personnel data. Please try again.',
  errorSearchNumber: (searchNumber) => `Search ‘${searchNumber}’ did not return any results. Please check your work order # and try again!`,
};

export const CONFIRMATION = {
  PERSONNEL: {
    title: 'Personnel Details',
  },
};

export const WORK_CONFIRMATION = {
  HEADER_TEXT: 'Confirmation - 12345678',
  OPERATIONS_TITLE: 'Select Your Operation',
  CONFIRMATION_DETAILS: {
    title: 'Work Confirmation Details',
    type: {
      label: 'Confirmation Type',
      types: ['Partial Confirmation (PCNF)', 'Final Confirmation (CNF)'],
    },
    quick_remarks: {
      label: 'Quick Remarks',
      remarks: [
        'Planner Action Required',
        'Facilitator Action Required',
        'Ok - No Action Required',
      ],
    },
    confirmation_label: 'Confirmation Text',
  },
  SUBMIT_TEXT: 'Submit Work Confirmation',
};
