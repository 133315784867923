import { eStoresTypes } from '../../../redux/actions/action-types';

export const addReserveLineItem = (value) => ({
  type: eStoresTypes.ADD_RESERVE_LINE_ITEM,
  payload: value,
});

export const deleteReserveLineItem = (index) => ({
  type: eStoresTypes.DELETE_RESERVE_LINE_ITEM,
  payload: index,
});

export const updateMaterial = (data) => ({
  type: eStoresTypes.UPDATE_MATERIAL,
  payload: data,
});

export const clearReserveMaterials = () => ({
  type: eStoresTypes.CLEAR_RESERVED_MATERIALS,
});

export const updateReserveMaterials = (value) => ({
  type: eStoresTypes.UPDATE_RESERVE_MATERIALS,
  payload: value,
});

export const resetLineItem = (index) => ({
  type: eStoresTypes.RESET_RESERVE_LINE_ITEM,
  payload: index,
});

export const updateOrder = (value) => ({
  type: eStoresTypes.E_STORES_UPDATE_ORDER,
  payload: value,
});

export const resetEStoresState = (value) => ({
  type: eStoresTypes.RESET_E_STORES_STATE,
  payload: value,
});

export const updateAttOrder = (value) => ({
  type: eStoresTypes.E_STORES_UPDATE_ATT_ORDER,
  payload: value,
});
export const getWbsData = (data) => ({
  type: eStoresTypes.GET_WBS_DATA,
  payload: data,
});
export const getWbsValue = (data) => ({
  type: eStoresTypes.WBS_VALUE,
  payload: data,
});
