import React from 'react';
import PropTypes from 'prop-types';
import styles from './single-operation.module.scss';

import ArrowRight from '../../../assets/svgs/icon_arrow-right';
import CustomIconButton from '../custom-icon-button';

function SingleOperation({ children, onClick, style = {} }) {
  return (
    <div
      className={`${styles.singleOperation} row gx-0 align-items-center`}
      onClick={onClick}
      style={{ ...style }}
      role="presentation"
    >
      {children && <div className="col-9 ml-2">{children}</div>}
      <div className="col-auto mr-3">
        <CustomIconButton onPress={onClick} dataId="single-operation-button">
          <ArrowRight fill="#0578BE" />
        </CustomIconButton>
      </div>
    </div>
  );
}

SingleOperation.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.shape()),
};

SingleOperation.defaultProps = {
  children: null,
  onClick: () => {},
  style: { },
};

export default SingleOperation;
