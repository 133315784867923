import PropTypes from 'prop-types';
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import './spares-management.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
// import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/rich-select';

function CustomTooltip(props) {
  const { value } = props;
  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: 'white' }}
    >
      {value}
    </div>
  );
}
CustomTooltip.propTypes = {
  value: PropTypes.string,
};
CustomTooltip.defaultProps = {
  value: '',
};

function AGGridTable({
  selectedData,
  rowData,
  columnDefs,
  rowIndex = 0,
}) {
  const gridOptions = {
    gridId: rowIndex, // Set the unique ID for the grid
  };
  const [rowDatas, setRowDatas] = React.useState(null);
  React.useEffect(() => {
    const array = rowData && [...rowData];
    setRowDatas(array);
  }, [rowData, rowData?.length]);
  const gridRef = React.useRef();
  const defaultColDef = React.useMemo(() => ({
    // editable: true,
    sortable: true,
    filter: true,
    editable: false,
    tooltipComponent: CustomTooltip,
    //   resizable: true,
  }), []);

  const icons = React.useMemo(() => (
    {
      menu: '<i class="fa fa-expand text-primary" style="width: 12px"/>',
      sortAscending: '<i class="fa fa-sort-asc"></i>',
      sortDescending: '<i class="fa fa-sort-desc" style="width: 12px"/>',
    }
  ));
  const onSelectionChanged = React.useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    selectedData(selectedRows);
  });
  return (
    <AgGridReact
      ref={gridRef}
      rowSelection="multiple"
      defaultColDef={defaultColDef}
      rowData={rowDatas}
      columnDefs={columnDefs}
      suppressMenuHide="true"
      icons={icons}
      rowMultiSelectWithClick="true"
      onSelectionChanged={onSelectionChanged}
      suppressRowClickSelection
      tooltipShowDelay={0}
      tooltipHideDelay={8000}
      gridOptions={gridOptions}
      // modules={AllModules}
    />
  );
}

AGGridTable.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.shape({})),
  selectedData: PropTypes.func,
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  rowIndex: PropTypes.number,
};
AGGridTable.defaultProps = {
  rowData: {},
  selectedData: () => {},
  columnDefs: {},
  rowIndex: 0,
};

export default AGGridTable;
