import PropTypes from 'prop-types';
import React from 'react';
// // import cx from 'classnames';
// import styles from './custom-radio-button.module.scss';
// import { uiStrings } from '../../../constants/strings';
import imgCheckFalse from '../../../assets/images/check-false@3x.png';
import imgCheckTrue from '../../../assets/images/check-true@3x.png';
import imgCheckReadFalse from '../../../assets/images/check-read-only-false@3x.png';
import imgCheckReadTrue from '../../../assets/images/check-read-only-true@3x.png';

export default function CustomRadioButton({
  value,
  customStyle,
  onClick,
  editable = true,
  height,
  width,
}) {
  // const styleClass = cx({
  //   [styles.disabledOuterDiv]: !editable,
  //   [styles.disabledInnerDiv]: !editable,
  //   [styles.innerCircleBorder]: type !== uiStrings.SQUARE,
  //   [styles.outerCircleBorder]: type !== uiStrings.SQUARE,
  //   [styles.innerSquareBorder]: type === uiStrings.SQUARE,
  //   [styles.outerSquareBorder]: type === uiStrings.SQUARE,
  // });

  if (editable) {
    return (
      <div onClick={editable ? onClick : () => { }} style={{ ...customStyle }} role="presentation">
        <img src={value ? imgCheckTrue : imgCheckFalse} alt="check-enabled" height={height} width={width} />
      </div>
    );
  }
  return (
    <div onClick={editable ? onClick : () => { }} style={{ ...customStyle }} role="presentation">
      <img src={value ? imgCheckReadTrue : imgCheckReadFalse} alt="check-enabled" height={height} width={width} />
    </div>
  );
}

CustomRadioButton.propTypes = {
  customStyle: PropTypes.shape({}),
  editable: PropTypes.bool,
  onClick: PropTypes.func,
  // type: PropTypes.string,
  value: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

CustomRadioButton.defaultProps = {
  customStyle: {},
  editable: false,
  onClick: () => { },
  // type: '',
  value: false,
  height: null,
  width: null,
};
