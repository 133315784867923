import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './list-operations.module.scss';
import ArrowDown from '../../../assets/svgs/icon_arrow-down';
import ArrowUp from '../../../assets/svgs/icon_arrow-up';

function ListOperations({
  collabsable,
  title,
  children,
  startClose = false,
  textColor,
}) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (startClose === true) {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={`${styles.labeledField__wrapper} row gx-0`}
        style={{ backgroundColor: title.color }}
        onClick={collabsable ? () => setOpen(!open) : () => { }}
        role="presentation"
      >
        {title && (
          <h4
            className={`${styles.operationsSubtitle} col-auto py-3 my-0`}
            style={{ backgroundColor: 'transparent', color: textColor || 'white' }}
          >
            {title.text}
            {title.description && <span style={{ fontFamily: 'Barlow-Regular' }}>{title.description}</span>}
          </h4>
        )}
        {collabsable && open
          ? (
            <span className="col-auto" onClick={() => setOpen(!open)} role="presentation">
              <ArrowUp fill={textColor || 'white'} />
            </span>
          )
          : (
            <span className="col-auto" onClick={() => setOpen(!open)} role="presentation">
              <ArrowDown fill={textColor || 'white'} />
            </span>
          )}
      </div>
      {open && children}
    </>
  );
}

ListOperations.propTypes = {
  children: PropTypes.element,
  collabsable: PropTypes.bool,
  startClose: PropTypes.bool,
  textColor: PropTypes.string,
  title: PropTypes.shape({
    color: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string,
  }),
};

ListOperations.defaultProps = {
  children: null,
  startClose: false,
  title: {},
  collabsable: false,
  textColor: '',
};

export default ListOperations;
