import { weekScheduleTypes } from '../../../redux/actions/action-types';

const initialState = {
  scheduleOrder: undefined,
};

const weekScheduleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case weekScheduleTypes.UPDATE_SCHEDULE_ORDER:
      return {
        ...state,
        scheduleOrder: action.payload,
      };
    default:
      return state;
  }
};

export default weekScheduleReducer;
