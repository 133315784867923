import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import config, { hostname } from './utilities/config';

if (hostname !== 'localhost') {
  datadogRum.init({
    applicationId: 'b1392661-0699-4a1f-8eb7-51a28f241b99',
    clientToken: 'pubbd2acd6fca58a8c12b9314b7a6eb1679',
    site: 'datadoghq.com',
    service: 'maintenance-portal-web',
    env: config.ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog //
    version: '3.7.3',
    sampleRate: 100,
    trackInteractions: true,
  });

  datadogLogs.init({
    clientToken: 'pubbd2acd6fca58a8c12b9314b7a6eb1679',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: 'maintenance-portal-web',
  });

  datadogLogs.setGlobalContext({ env: config.ENVIRONMENT });
  datadogRum.startSessionReplayRecording();
}

export const setDataDogUser = (userData) => {
  if (hostname !== 'localhost') {
    datadogRum.setUser({
      name: userData.displayName,
      email: userData.mail,
      site: userData.officeLocation,
    });
  }
  datadogLogs.setUser({
    name: userData.displayName,
    email: userData.mail,
    site: userData.officeLocation,
  });
};

export const someDDFunction = () => { };
