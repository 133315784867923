import PropTypes from 'prop-types';
import React from 'react';
import styles from './label-value-field.module.scss';

export default function LabelValueField({ label, value, bootStrapClass }) {
  return (
    <div className={`${styles.labelValueContainer} ${bootStrapClass}`}>
      <p className={`${styles.labelFieldText}`}>{label}</p>
      <p className={`${styles.valueText}`}>{value}</p>
    </div>
  );
}

LabelValueField.propTypes = {
  bootStrapClass: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};

LabelValueField.defaultProps = {
  bootStrapClass: '',
  label: '',
  value: '',
};
