import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import styles from './custom-loader.module.scss';
import { customLoaderStyles } from '../../../constants/styles';

Modal.setAppElement('#root');

export default function CustomLoader({ global, enabled }) {
  const message = global ? global.loaderMessage : 'Logging in';
  const value = global ? global.loaderEnabled : enabled;
  return (
    <Modal
      isOpen={value}
      style={customLoaderStyles}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Loader
          type="Oval"
          color="#0578BE"
          height={40}
          width={40}
          visible
        />
        <p
          className={`${styles.loaderMessage} d-flex justify-content-center align-items-center`}
        >
          {message}
        </p>
      </div>
    </Modal>
  );
}

CustomLoader.propTypes = {
  enabled: PropTypes.bool,
  global: PropTypes.shape({
    loaderEnabled: PropTypes.bool,
    loaderMessage: PropTypes.string,
  }),
};

CustomLoader.defaultProps = {
  enabled: false,
  global: {},
};
