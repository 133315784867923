import PropTypes from 'prop-types';
import React from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import { dateRangePickerStrings } from '../../../constants/strings';
import 'rsuite/dist/rsuite.min.css';
import './date-range-picker.styles.css';

export default function CustomDateRangePicker({
  onOkClick, value,
}) {
  const onSubmit = (val) => {
    onOkClick(val);
  };
  const onClean = () => {
    onOkClick([null, null]);
  };
  return (
    <div>
      <DateRangePicker
        size="md"
        placeholder={dateRangePickerStrings.START_DATE_TO_END_DATE}
        character={dateRangePickerStrings.CHARACTER_ARROW}
        onOk={onSubmit}
        onChange={onSubmit}
        onClean={onClean}
        value={value}
      />
    </div>
  );
}

CustomDateRangePicker.propTypes = {
  onOkClick: PropTypes.func,
  value: PropTypes.string,
};
CustomDateRangePicker.defaultProps = {
  onOkClick: () => { },
  value: '',
};
