import { sortArrayByKey } from '../../pages/week-schedule/week-schedule.helper';

const getConfirmations = (array) => {
  const revisions = array.reduce(
    (result, item) => ({
      ...result,
      [item.confirmationCreatedDate]: [
        ...(result[item.confirmationCreatedDate] || []),
        item,
      ],
    }),
    {},
  );
  return revisions;
};

export const groupConfirmationData = (data) => {
  const sortedData = sortArrayByKey(data, ['confirmationCreatedDate']);
  const groupedRevisions = getConfirmations(sortedData);
  return Object.entries(groupedRevisions);
};

export default () => { };
