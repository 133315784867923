import config from '../utilities/config';

const hydrostatusUrl = () => {
  if (config.ENVIRONMENT === 'DEV') {
    return 'https://hydrostats-web.dev.gerdaugln.tech/';
  }

  if (config.ENVIRONMENT === 'QA') {
    return 'https://hydrostats-web.qa.gerdaugln.tech/';
  }

  if (config.ENVIRONMENT === 'PROD') {
    return 'https://hydrostats-web.prd.gerdaugln.tech/';
  }
  return '';
};

export default hydrostatusUrl;
