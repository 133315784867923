import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
// import './tab-navigation.style.css';
import { setActiveTab } from '../../../redux/actions/global.actions';
import styles from './tab-navigation.module.scss';

function Tabs({ tabs, colValue }) {
  const { activeTab } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const ActiveTab = tabs[activeTab];

  return (
    <div className={`${styles.tabs} w-100`}>
      <div className={`${styles.tabs_header} row`}>
        {Object.keys(tabs).map((tab) => (
          <div
            key={tab}
            className={`col-${colValue} ${styles.tab} ${tab === activeTab ? styles.tab_active : ''}`}
            onClick={() => dispatch(setActiveTab(tab))}
            onKeyDown={(e) => { if (e.keyCode === 13) dispatch(setActiveTab(tab)); }}
            role="button"
            tabIndex={0}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className={`${styles.tabs_content} row gx-0`}>
        <div className="col">
          <ActiveTab tab={activeTab} />
        </div>
      </div>
    </div>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.shape({
    'My Schedule': PropTypes.func,
    'My Confirmations (Last 3 Days)': PropTypes.func,
  }),
  colValue: PropTypes.number,
};

Tabs.defaultProps = {
  tabs: PropTypes.shape({
    'My Schedule': null,
    'My Confirmations (Last 3 Days)': null,
  }),
  colValue: null,
};

export default Tabs;
