import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import styles from './button-modal.module.scss';
import { appColors } from '../../../constants/colors';
import imgSuccess from '../../../assets/images/success-modal@2x.png';
import imgError from '../../../assets/images/error@2x.png';
import imgWarning from '../../../assets/images/warning@2x.png';
import { modalStyles } from '../../../constants/styles';
import { buttonModalStrings } from '../../../constants/strings';

export default function ButtonModal({
  isSuccess, isError, workOrderNumber, isCreated, data, noOfButtons, onButtonPress,
}) {
  let imgHeaderIcon = imgWarning;
  if (isSuccess) imgHeaderIcon = imgSuccess;
  else if (isError) imgHeaderIcon = imgError;
  let bgColor = appColors.WARNING;
  if (isSuccess) bgColor = appColors.SUCCESS;
  else if (isError) bgColor = appColors.TEXT_ERROR_MODERATE;

  const divStyle = cx({
    [styles.buttonDiv]: true,
    [styles.errorWidth]: isError,
  });
  const splitBy = workOrderNumber.split(' ').includes('Confirmation') ? 2 : 1;
  return (
    <Modal
      isOpen
      style={modalStyles}
    >
      <div className="d-flex align-items-center justify-content-center">
        <div className={divStyle}>
          <div className={styles.headerDiv} style={{ backgroundColor: bgColor }}>
            <img src={imgHeaderIcon} height={25} width={25} alt="status" />
          </div>
          <div>
            <div className="my-3 mx-3">
              {
                workOrderNumber
                && (
                  <p className="woText">
                    {buttonModalStrings.WORK_ORDER}
                    <span className={styles.woText}>{workOrderNumber.split(' ')[workOrderNumber.split(' ').length - splitBy]}</span>
                    {isCreated ? buttonModalStrings.CREATED : buttonModalStrings.UPDATED}
                    {`${workOrderNumber.split(' ').includes('Confirmation') ? ' and Confirmed !' : ' '} `}
                  </p>
                )
              }
              {
                data.instructionsText1
                && <p className="woText mb-2">{data.instructionsText1}</p>
              }
              {
                data.instructionsText2
                && <p className="woText mb-0">{data.instructionsText2}</p>
              }
            </div>
            {
              noOfButtons === 3
              && (
                <div className={styles.buttonContainer}>

                  <div role="button" tabIndex={0} className={`${styles.taskButtonStyle} ${data.firstButton.type === 'Outline' && styles.taskButtonOutlineStyle} ${styles.buttonWidth} ${styles.buttonBottom}`} onClick={onButtonPress.firstButtonPress} onKeyDown={(e) => { if (e.keyCode === 13) onButtonPress.firstButtonPress(); }}>
                    {data.firstButton.value}
                  </div>
                  <div role="button" tabIndex={0} className={`${styles.taskButtonStyle} ${data.secondButton.type === 'Outline' && styles.taskButtonOutlineStyle} ${styles.buttonWidth} ${styles.buttonBottom}`} onClick={onButtonPress.secondButtonPress} onKeyDown={(e) => { if (e.keyCode === 13) onButtonPress.secondButtonPress(); }}>
                    {data.secondButton.value}
                  </div>
                  <div role="button" tabIndex={0} className={`${styles.taskButtonStyle} ${data.thirdButton.type === 'Outline' && styles.taskButtonOutlineStyle} ${styles.buttonWidth} `} onClick={onButtonPress.thirdButtonPress} onKeyDown={(e) => { if (e.keyCode === 13) onButtonPress.thirdButtonPress(); }}>
                    {data.thirdButton.value}
                  </div>
                </div>
              )
            }
            {
              noOfButtons === 2
              && (
                <div className={`d-flex flex-row justify-content-between ${styles.buttonContainer}`}>
                  <div role="button" tabIndex={0} className={styles.taskButtonStyle} onClick={onButtonPress.firstButtonPress} onKeyDown={(e) => { if (e.keyCode === 13) onButtonPress.firstButtonPress(); }}>
                    {data.firstButton.value}
                  </div>
                  <div role="button" tabIndex={0} className={styles.taskButtonStyle} onClick={onButtonPress.secondButtonPress} onKeyDown={(e) => { if (e.keyCode === 13) onButtonPress.secondButtonPress(); }}>
                    {data.secondButton.value}
                  </div>
                </div>
              )
            }
            {
              noOfButtons === 1
              && (
                <div role="button" tabIndex={0} className={`d-flex flex-row justify-content-between ${styles.buttonContainer}`} onClick={onButtonPress} onKeyDown={(e) => { if (e.keyCode === 13) onButtonPress(); }}>
                  <div role="button" tabIndex={0} className={styles.taskButtonStyle} onClick={onButtonPress} onKeyDown={(e) => { if (e.keyCode === 13) onButtonPress(); }}>
                    {data.buttonText}
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Modal>
  );
}

ButtonModal.propTypes = {
  data: PropTypes.shape({
    buttonText: PropTypes.string,
    firstButton: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
    }),
    instructionsText1: PropTypes.string,
    instructionsText2: PropTypes.string,
    secondButton: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
    }),
    thirdButton: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  isCreated: PropTypes.bool,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  noOfButtons: PropTypes.number,
  workOrderNumber: PropTypes.string,
  onButtonPress: PropTypes.oneOf([PropTypes.func, PropTypes.shape({
    firstButtonPress: PropTypes.func,
    secondButtonPress: PropTypes.func,
    thirdButtonPress: PropTypes.func,
  })]),
};

ButtonModal.defaultProps = {
  data: {},
  isCreated: false,
  isError: false,
  isSuccess: false,
  noOfButtons: 0,
  workOrderNumber: '',
  onButtonPress: () => { },
};
