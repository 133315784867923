import { connect } from 'react-redux';
import EStores from './e-stores.component';
import {
  showLoader,
  hideLoader,
  addBreadCrumb,
  updateSelectedPage,
  resetBreadCrumbs,
  deleteBreadCrumb,
} from '../../../redux/actions/global.actions';
import {
  resetLineItem,
  updateAttOrder,
  deleteReserveLineItem,
  addReserveLineItem,
  updateMaterial,
  updateReserveMaterials,
  updateOrder,
  resetEStoresState,
  getWbsData,
  getWbsValue,
} from './e-stores.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
  eStoresState: state.eStoresReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  deleteBreadCrumb: (value) => dispatch(deleteBreadCrumb(value)),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  hideLoader: () => dispatch(hideLoader()),
  resetBreadCrumbs: () => dispatch(resetBreadCrumbs()),
  deleteReserveLineItem: (index) => dispatch(deleteReserveLineItem(index)),
  resetLineItem: (index) => dispatch(resetLineItem(index)),
  updateMaterial: (value) => dispatch(updateMaterial(value)),
  updateAttOrder: (value) => dispatch(updateAttOrder(value)),
  resetEStoresState: (value) => dispatch(resetEStoresState(value)),
  updateOrder: (value) => dispatch(updateOrder(value)),
  updateReserveMaterials: (data) => dispatch(updateReserveMaterials(data)),
  addReserveLineItem: () => dispatch(addReserveLineItem('I')),
  getWbsData: (data) => dispatch(getWbsData(data)),
  getWbsValue: (data) => dispatch(getWbsValue(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EStores);
