import { connect } from 'react-redux';

import ChangeOrder from './change-order.component';
import {
  showLoader,
  hideLoader,
  addBreadCrumb,
  updateBreadCrumb,
  updateGlobalSearchOrder,
  updateSelectedPage,
  resetBreadCrumbs,
  deleteBreadCrumb,
} from '../../../redux/actions/global.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  updateBreadCrumb: (value) => dispatch(updateBreadCrumb(value)),
  deleteBreadCrumb: (value) => dispatch(deleteBreadCrumb(value)),
  updateGlobalSearchOrder: (value) => dispatch(updateGlobalSearchOrder(value)),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  hideLoader: () => dispatch(hideLoader()),
  resetBreadCrumbs: () => dispatch(resetBreadCrumbs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOrder);
