import PropTypes from 'prop-types';
import React from 'react';
// import cx from 'classnames';
import styles from './custom-button.module.scss';

export default function CustomButton({
  customStyle, onPress, disabled, value, outline = false, icon, iconStyle = {},
}) {
  const backgroundColor = () => {
    if (outline) {
      return 'white';
    }
    if (disabled) {
      return '#F1F5F8';
    }
    return '#0578BE';
  };
  const textColor = () => {
    if (outline) {
      return '#0578BE';
    }
    if (disabled) {
      return '#BFC9D0';
    }
    return 'white';
  };
  return (
    <button
      type="button"
      className={`${styles.customButton}`}
      style={{
        backgroundColor: backgroundColor(),
        color: textColor(),
        border: outline ? '#0578BE' : '#CFD7DC',
        borderWidth: 1,
        borderStyle: 'solid',
        ...customStyle,
      }}
      onClick={onPress}
      disabled={disabled}
    >
      {
        icon && <img alt="button-icon" className="mr-2" src={icon} style={{ height: 14, width: 14, ...iconStyle }} />
      }
      {value && value}
    </button>
  );
}

CustomButton.propTypes = {
  customStyle: PropTypes.shape({}),
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  iconStyle: PropTypes.objectOf(PropTypes.shape()),
  onPress: PropTypes.func,
  outline: PropTypes.bool,
  value: PropTypes.string,
};

CustomButton.defaultProps = {
  customStyle: {},
  disabled: false,
  onPress: () => { },
  outline: false,
  value: '',
  icon: '',
  iconStyle: {},
};
