import { createWorkOrdersTypes } from '../../../redux/actions/action-types';

export const updateValue = (value) => ({
  type: createWorkOrdersTypes.UPDATE_VALUE,
  payload: value,
});

export const updateWOValue = (value) => ({
  type: createWorkOrdersTypes.UPDATE_WO_VALUE,
  payload: value,
});

export const updateFuncLocData = (data) => ({
  type: createWorkOrdersTypes.UPDATE_FUNC_LOC_2,
  payload: data,
});

export const updateSuccessValue = (value) => ({
  type: createWorkOrdersTypes.UPDATE_WO_SUCCESS_STATUS,
  payload: value,
});

export const updateFailureValue = (value) => ({
  type: createWorkOrdersTypes.UPDATE_WO_FAILURE_STATUS,
  payload: value,
});

export const updateDropDownItem = (value) => ({
  type: createWorkOrdersTypes.UPDATE_DROP_DOWN_ITEM,
  payload: value,
});

export const updateParentOrderID = (value) => ({
  type: createWorkOrdersTypes.UPDATE_PARENT_ORDER_ID,
  payload: value,
});

export const updateType = (value) => ({
  type: createWorkOrdersTypes.UPDATE_TYPE,
  payload: value,
});

export const updateIsPickerOpenStatus = (value) => ({
  type: createWorkOrdersTypes.UPDATE_PICKER_OPEN_STATUS,
  payload: value,
});

export const updateSectionalMultiSelect = (value) => ({
  type: createWorkOrdersTypes.UPDATE_SECTIONAL_MULTI_SELECT,
  payload: value,
});

export const updateMultiSelect = (value) => ({
  type: createWorkOrdersTypes.UPDATE_MULTI_SELECT,
  payload: value,
});

export const resetCWOState = () => ({
  type: createWorkOrdersTypes.RESET_CREATE_WORK_ORDER_STATE,
});

export const updateWrkCtrItems = (value) => ({
  type: createWorkOrdersTypes.UPDATE_WRK_CTR_ITEMS,
  payload: value,
});

export const updateCheckBoxStatus = (data) => ({
  type: createWorkOrdersTypes.CHECKBOX_STATUS,
  payload: data,
});

export const addReserveLineItem = (value) => ({
  type: createWorkOrdersTypes.ADD_RESERVE_LINE_ITEM,
  payload: value,
});

export const updateEquipment = (value) => ({
  type: createWorkOrdersTypes.UPDATE_EQUIPMENT,
  payload: value,
});

export const deleteReserveLineItem = (index) => ({
  type: createWorkOrdersTypes.DELETE_RESERVE_LINE_ITEM,
  payload: index,
});
export const updateMaterial = (data) => ({
  type: createWorkOrdersTypes.UPDATE_MATERIAL,
  payload: data,
});
export const clearReserveMaterials = () => ({
  type: createWorkOrdersTypes.CLEAR_RESERVED_MATERIALS,
});

export const updateReserveMaterials = (value) => ({
  type: createWorkOrdersTypes.UPDATE_RESERVE_MATERIALS,
  payload: value,
});

export const resetLineItem = (index) => ({
  type: createWorkOrdersTypes.RESET_RESERVE_LINE_ITEM,
  payload: index,
});

export const updateFlocValue = (value) => ({
  type: createWorkOrdersTypes.UPDATE_FLOC_VALUE,
  payload: value,
});
