import { singleConfirmationTypes } from '../../../redux/actions/action-types';

export const updateOrder = (value) => ({
  type: singleConfirmationTypes.UPDATE_ORDER,
  payload: value,
});

export const resetOrder = () => ({
  type: singleConfirmationTypes.RESET_ORDER,
});

export const updatePersonnel = (value) => ({
  type: singleConfirmationTypes.UPDATE_PERSONNEL,
  payload: value,
});

export const setFinalConfirmation = (value) => ({
  type: singleConfirmationTypes.SET_FINAL_CONFIRMATION,
  payload: value,
});

export const resetFinalConfirmation = () => ({
  type: singleConfirmationTypes.RESET_FINAL_CONFIRMATION,
});
