import PropTypes from 'prop-types';
import React from 'react';

export default function Check({ fill }) {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6875 10C19.6875 15.3503 15.3503 19.6875 10 19.6875C4.64973 19.6875 0.3125 15.3503 0.3125 10C0.3125 4.64973 4.64973 0.3125 10 0.3125C15.3503 0.3125 19.6875 4.64973 19.6875 10ZM8.87945 15.1295L16.067 7.94195C16.311 7.69789 16.311 7.30215 16.067 7.05809L15.1831 6.17422C14.939 5.93012 14.5433 5.93012 14.2992 6.17422L8.4375 12.0359L5.70082 9.29918C5.45676 9.05512 5.06102 9.05512 4.81691 9.29918L3.93305 10.183C3.68898 10.4271 3.68898 10.8229 3.93305 11.0669L7.99555 15.1294C8.23965 15.3735 8.63535 15.3735 8.87945 15.1295Z"
        fill={fill || '#99CC68'}
      />
    </svg>
  );
}

Check.propTypes = {
  fill: PropTypes.string,
};

Check.defaultProps = {
  fill: '',
};
