import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { styles } from './asset-view-modal.styles';
import imgCloseModal from '../../../assets/images/close-modal@2x.png';
import { getBom, getDoc } from '../../pages/asset-view/asset-view.helper';
import { assetViewModalStrings } from '../../../constants/strings';
import AssetViewList from '../asset-view-list';
import CustomButton from '../custom-button';
// import { modalStyles } from '../../../constants/styles';

export default function AsseViewModal({
  closeModal, flocValue, equipValue, editable = true, onAddMaterials = null,
}) {
  const [bomLoading, setBomLoading] = React.useState(false);
  const [searchResults, setSearchResults] = useState([]);
  // const [failureMessage, setFailureMessage] = useState('');
  const [assetFailureMessage, setAssetFailureMessage] = useState('');
  const [docFailureMessage, setDocFailureMessage] = useState('');

  const [docResults, setDocResults] = useState([]);

  const onAddClick = () => {
    const selectedMaterials = searchResults.filter((item) => item.selected);
    onAddMaterials(selectedMaterials);
  };

  React.useEffect(() => {
    const handleCatch = (setMessage, e) => {
      setBomLoading(false);
      if (typeof e === 'string') {
        if (e.includes(assetViewModalStrings.NO_RECORDS_FOUND)) {
          setMessage(assetViewModalStrings.NO_BOM_FOUND);
        } else {
          setMessage(e);
        }
      } else {
        setMessage(assetViewModalStrings.SOMETHING_WENT_WRONG);
      }
    };
    const handleSearch = async () => {
      try {
        setAssetFailureMessage('');
        setDocFailureMessage('');
        setBomLoading(true);
        let params = {
          funLoc: flocValue.value,
        };
        if (equipValue) {
          params = {
            equipNo: equipValue,
          };
        }
        setSearchResults([]);
        setDocResults([]);
        try {
          let results = await getBom(params);
          results = results.map((item) => {
            if (item.bomComponent) {
              return {
                ...item,
                selected: false,
              };
            }
            return undefined;
          }).filter((item) => item !== undefined);
          const materialIds = [...(new Set(results.map((item) => item.bomComponent)))].filter((item) => item !== undefined);
          const uniqueResults = [];
          materialIds.forEach((id) => {
            uniqueResults.push(results.find((item) => item.bomComponent === id));
          });
          setSearchResults(uniqueResults);
        } catch (e) {
          handleCatch(setAssetFailureMessage, e);
        }
        try {
          setBomLoading(true);
          const docResult = await getDoc(params);
          setDocResults(docResult);
        } catch (e) {
          handleCatch(setDocFailureMessage, e);
        }
        setBomLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    handleSearch();
  }, [flocValue, equipValue]);

  return (
    <Modal
      isOpen
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.90)',
          zIndex: 500000,
        },
        content: {
          backgroundColor: 'rgba(255, 255, 255, 0.0001)',
          borderWidth: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'none',
        },
      }}
    >
      <div style={styles.modalContainer}>
        <div style={styles.modalView}>
          <div style={styles.titleView}>
            <p style={styles.titleText}>{assetViewModalStrings.HEADER_TEXT}</p>
            <div role="button" tabIndex={0} style={styles.closeButton} onClick={closeModal} onKeyDown={(e) => { if (e.keyCode === 13) closeModal(); }}>
              <img
                src={imgCloseModal}
                alt="close-modal"
                style={styles.imgClose}
              />
            </div>
          </div>
          <div style={styles.modalContent}>
            <div style={styles.flocTextDiv}>
              <p style={styles.flocHeading}>{assetViewModalStrings.FLOC_EQUIP}</p>
              <p style={styles.flocValue}>
                {flocValue.name}
                {equipValue && `/${equipValue}`}
              </p>
            </div>
            <div style={styles.listDiv}>
              <AssetViewList editable={editable} bomLoading={bomLoading} assetFailureMessage={assetFailureMessage} docFailureMessage={docFailureMessage} bomResults={searchResults} setBomResults={setSearchResults} docResults={docResults} flocDetails={equipValue ? null : flocValue} />
              {
                editable
                && (
                  <div style={styles.addMaterialButton}>
                    <CustomButton customStyle={styles.addMaterialButtonWidth} onPress={onAddClick} disabled={searchResults.filter((item) => item.selected)?.length === 0} value={assetViewModalStrings.BUTTON_TEXT} />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

AsseViewModal.propTypes = {
  closeModal: PropTypes.func,
  editable: PropTypes.bool,
  equipValue: PropTypes.string,
  flocValue: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  onAddMaterials: PropTypes.func,
};

AsseViewModal.defaultProps = {
  closeModal: () => { },
  editable: false,
  equipValue: '',
  flocValue: PropTypes.shape({
    name: '',
    value: '',
  }),
  onAddMaterials: () => { },
};
