import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { HttpGet } from '../../../services/api-services';
// import './e-stores.styles.css';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import {
  pages,
  reservationStrings,
  createWorkOrderStrings,
  loaderMessages,
  eStoresStrings,
  queryKeyStrings,
} from '../../../constants/strings';
import { GET_WBS_DATA } from '../../../constants/endpoints';
import CustomTextInput from '../../controls/custom-textinput/custom-textinput.component';
import imgGoSelected from '../../../assets/images/enabledArrow.png';
import imgGo from '../../../assets/images/disabledArrow.png';
import ReserveMaterialTile from '../../controls/reserve-material-tile/reserve-material-tile.component';
import ProductCatalogModal from '../../controls/product-catalog-modal/product-catalog-modal.component';
import { getSAPName, checkNotNull, getURL, checkArrayNotNull } from '../../../Helpers/common-helpers';
import imgAddRow from '../../../assets/images/add-row.png';
import { getWorkOrderResults, getCCResults } from '../work-confirm/work-confirm.helper';
import { getMaterialDetails } from '../work-order/work-order.helper';
import imgFailure from '../../../assets/images/failure@2x.png';
import imgSuccess from '../../../assets/images/success.png';
import {
  checkMaterialValidationClass, woReservation, costCenterReservation, wbsReservation,
} from './e-stores.helper';
import CustomModal from '../../controls/custom-modal';
import CustomButton from '../../controls/custom-button';
import CustomDropdown from '../../controls/custom-dropdown';
import ButtonModal from '../../controls/button-modal';
import styles from './e-stores.module.scss';
import MultiDropdown from '../../controls/multi-dropdown/multi-dropdown';
import { checkReserveMaterials } from './e-stores.reducer';
// import { getWbsData } from './e-stores.actions';
function EStores({
  addBreadCrumb, addReserveLineItem, updateReserveMaterials, resetLineItem, updateAttOrder, updateMaterial, eStoresState, updateOrder, deleteReserveLineItem, global, hideLoader, resetBreadCrumbs, showLoader, updateSelectedPage, resetEStoresState, getWbsData, getWbsValue,
}) {
  const [indexForPC, setIndexForPC] = React.useState(0);
  const [showPCModal, setShowPCModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [activeTab, setActiveTab] = React.useState(reservationStrings.WORK_ORDER);
  const [orderValue, setOrderValue] = React.useState('');
  // const [attentionOrderValue, setAttentionOrderValue] = React.useState('');
  // const [needWOForCC, setNeedWOForCC] = React.useState(false);
  const [indexNeedWO, setIndexNeedWO] = React.useState([]);
  const queryClient = useQueryClient();
  const [isOrderSearchFailed, setIsOrderSearchFailed] = React.useState(false);
  const [selectedOperation, setSelectedOperation] = React.useState(null);
  const [opPicker, setOpPicker] = React.useState(false);
  // const [isAttOrderSearchFailed, setIsAttOrderSearchFailed] = React.useState(false);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [reservationNumber, setReservationNumber] = React.useState(null);
  const toggleOpPicker = () => setOpPicker(!opPicker);
  const refreshMaterials = async (isAttOrderFetched, currentTab) => {
    const indexNeedWo = [];
    await eStoresState.reserveMaterials.forEach((item) => {
      if (item.isMaterialFetched) {
        if (currentTab === reservationStrings.COST_CENTER || currentTab === reservationStrings.WBS) {
          let isValMismatched = false;
          try {
            checkMaterialValidationClass(item[createWorkOrderStrings.VALUE_CALSS]);
          } catch (e) {
            isValMismatched = true;
          }
          if (isValMismatched) {
            indexNeedWo.push(item.index);
            if (isAttOrderFetched) {
              updateMaterial({
                ...item,
                isMaterialFetchFail: false,
                failureMessage: '',
                errorSearchBar: false,
              });
            } else {
              updateMaterial({
                ...item,
                isMaterialFetchFail: true,
                failureMessage: reservationStrings.VAL_CLS_MIS,
                errorSearchBar: true,
              });
            }
          }
        } else if (item.failureMessage === reservationStrings.VAL_CLS_MIS && item.isMaterialFetched) {
          updateMaterial({
            ...item,
            isMaterialFetchFail: false,
            failureMessage: '',
            errorSearchBar: false,
          });
        }
      }
    });
    setIndexNeedWO(indexNeedWo);
  };

  const onWOSearch = async (isAttOrder = false, attOrderValue = null) => {
    try {
      showLoader(loaderMessages.FINDING_ORDER);
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SEARCH_ORDER} - ${attOrderValue || orderValue}`,
        queryFn: async () => {
          const response = await getWorkOrderResults(global.plantId, attOrderValue || orderValue);
          return response;
        },
        select: (woSearchData) => woSearchData,
        retry: false,
        staleTime: 1000,
      });
      hideLoader();
      if (isAttOrder) {
        // setIsAttOrderSearchFailed(false)
        updateAttOrder(data);
        await refreshMaterials(true, reservationStrings.COST_CENTER);
      } else {
        setIsOrderSearchFailed(false);
        updateOrder(data);
      }
    } catch (e) {
      if (isAttOrder) {
        // setIsAttOrderSearchFailed(true)
      } else {
        setIsOrderSearchFailed(true);
        console.log(new Error(e));
        hideLoader();
      }
    }
  };
  const onWOButtonClick = () => onWOSearch();
  const onCCSearch = async () => {
    try {
      showLoader(loaderMessages.FINDING_CC);
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.SEARCH_CC} - ${orderValue}`,
        queryFn: async () => {
          const response = await getCCResults(global.plantId, orderValue);
          return response;
        },
        select: (ccSearchData) => ccSearchData,
        retry: false,
        staleTime: 1000,
      });
      updateOrder(data);
      hideLoader();
    } catch (e) {
      setIsOrderSearchFailed(true);
      console.log(new Error(e));
      hideLoader();
    }
  };
  // let state = useSelector(state => state.eStoresReducer);
  const getWBSResults = async (plantId) => {
    const apiURL = `${getURL(GET_WBS_DATA)}`;
    const params = { plantId };
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
      throw response.data.message.data.error.message;
    }
    return response.data.message.data;
  };
  const onWBSData = async () => {
    showLoader('Fetching Wbs Data');
    try {
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.WBS_DATA}/`,
        queryFn: async () => {
          const response = await getWBSResults(global.plantId);
          return response;
        },
        select: (wbsData) => wbsData,
        retry: false,
        staleTime: 1000,
      });
      getWbsData(data);
      hideLoader();
    } catch (e) {
      // setIsOrderSearchFailed(true);
      console.log(new Error(e));
      // hideLoader();
    }
  };

  React.useEffect(() => {
    resetBreadCrumbs(() => {
      updateSelectedPage(pages.HOME);
    });
    addBreadCrumb({
      name: eStoresStrings.E_STORES,
      action: null,
    });
    resetEStoresState();
  }, []);

  const closePCModal = () => {
    setShowPCModal(false);
  };
  const [tabValue, setTabValue] = useState(reservationStrings.WORK_ORDER);
  const onWOTabClick = async () => {
    if ((activeTab === reservationStrings.COST_CENTER) && (checkNotNull(eStoresState.orderData))) {
      setTabValue(reservationStrings.WORK_ORDER);
      setShowWarningModal(true);
    } else {
      setActiveTab(reservationStrings.WORK_ORDER);
      await refreshMaterials(checkNotNull(eStoresState.attOrderData), reservationStrings.WORK_ORDER);
    }
  };

  const onCCTabClick = async () => {
    if ((activeTab === reservationStrings.WORK_ORDER) && (checkNotNull(eStoresState.orderData))) {
      setTabValue(reservationStrings.COST_CENTER);
      setShowWarningModal(true);
    } else {
      setActiveTab(reservationStrings.COST_CENTER);
      await refreshMaterials(checkNotNull(eStoresState.attOrderData), reservationStrings.COST_CENTER);
    }
  };
  const onWBSTabClick = async () => {
    onWBSData();
    if (eStoresState.wbsSelectedValue) {
      getWbsValue({
        value: null,
      });
    }
    if ((activeTab === reservationStrings.WORK_ORDER || activeTab === reservationStrings.COST_CENTER) && (checkNotNull(eStoresState.orderData))) {
      setTabValue(reservationStrings.WBS);
      setShowWarningModal(true);
    } else {
      setActiveTab(reservationStrings.WBS);
      await refreshMaterials(checkNotNull(eStoresState.attOrderData), reservationStrings.WBS);
    }
  };

  const onValueChange = (obj) => {
    setOrderValue(obj.value);
    setIsOrderSearchFailed(false);
    setSelectedOperation(null);
    updateOrder(null);
  };
  const searchWo = (e) => {
    if (e.keyCode === 13) {
      if (activeTab === reservationStrings.WORK_ORDER) {
        return onWOButtonClick();
      }
      return onCCSearch();
    }
    return null;
  };

  // const onAttentionOrderChange = (obj) => {
  //   setAttentionOrderValue(obj.value);
  //   // setIsAttOrderSearchFailed(false)
  //   updateAttOrder(null);
  //   refreshMaterials(false, reservationStrings.COST_CENTER)
  // }

  const updateMaterialDetails = (index, materialDetails) => {
    let failureMessage = '';
    try {
      if ((activeTab === reservationStrings.COST_CENTER || activeTab === reservationStrings.WBS) && !checkNotNull(eStoresState.attOrderData)) {
        checkMaterialValidationClass(materialDetails.valueClass);
      }
    } catch (e) {
      setIndexNeedWO([...indexNeedWO, index]);
      failureMessage = e;
    }
    updateMaterial({
      [createWorkOrderStrings.MATERIAL_MEMO]: materialDetails.materialMEMO,
      [createWorkOrderStrings.PO_TEXT]: materialDetails.POLongText,
      [createWorkOrderStrings.MATERIAL]: materialDetails.material.slice(-8),
      [createWorkOrderStrings.UNRES_QTY]: materialDetails.unrestrictedQuantity,
      [createWorkOrderStrings.BIN_LOCATION]: materialDetails.stageBin,
      [createWorkOrderStrings.UNIT_OF_MEASURE]: materialDetails.unitOfMeasure,
      [createWorkOrderStrings.VALUE_CALSS]: materialDetails.valueClass,
      isMaterialFetched: true,
      [createWorkOrderStrings.RECIPIENT]:
        global.userPersonalData.sapUserID
        || getSAPName(
          global.userData.givenName,
          global.userData.surname,
        ),
      index,
      errorSearchBar: failureMessage !== '',
      failureMessage,
      isMaterialFetchFail: failureMessage !== '',
    });
    setShowPCModal(false);
  };

  const showPCatalogModal = (index) => {
    setIndexForPC(index);
    setShowPCModal(true);
  };
  const onSearchClick = async (index, materialValue) => {
    try {
      showLoader(loaderMessages.GET_MATERIAL);
      const materialDetails = await getMaterialDetails(
        materialValue,
        global.plantId,
      );
      updateMaterialDetails(index, materialDetails);
    } catch (e) {
      const data = {
        index,
        failureMessage: e,
        [createWorkOrderStrings.PO_TEXT]: '',
        isMaterialFetched: false,
        errorSearchBar: true,
        isMaterialFetchFail: true,
        [createWorkOrderStrings.MATERIAL]: materialValue,
      };
      updateMaterial(data);
    } finally {
      hideLoader();
    }
  };

  const onReserveMaterialSearchClick = (index, materialValue) => (onSearchClick(index, materialValue));

  const renderReserveMaterials = () => (
    eStoresState.reserveMaterials.map((item, index) => (
      <ReserveMaterialTile
        item={item}
        index={index + 1}
        deleteItem={deleteReserveLineItem}
        onChangeValue={updateReserveMaterials}
        resetLineItem={resetLineItem}
        onMaterialSearch={onReserveMaterialSearchClick}
        showPCModal={showPCatalogModal}
        editable
      />
    ))
  );

  const onWOKeyDown = (e) => {
    if (orderValue.length >= 8 && e.keyCode === 13) {
      onWOSearch();
    }
  };

  const onCCKeyDown = (e) => {
    if (orderValue.length >= 10 && e.keyCode === 13) {
      onCCSearch();
    }
  };

  const onNoClick = () => setShowWarningModal(false);
  const onYesClick = async () => {
    setOrderValue('');
    updateOrder(null);
    updateAttOrder(null);
    setActiveTab(tabValue);
    setShowWarningModal(false);
    await refreshMaterials(checkNotNull(eStoresState.attOrderData), tabValue);
  };

  const onReservationClick = async () => {
    let data;
    try {
      showLoader(eStoresStrings.SAVING_RESERVATION);
      if (activeTab === reservationStrings.WORK_ORDER) {
        data = await woReservation(eStoresState.orderData, eStoresState.reserveMaterials);
        hideLoader();
        setShowSuccessModal(true);
      } else if (activeTab === reservationStrings.WBS) {
        data = await wbsReservation(global.plantId, eStoresState.wbsSelectedValue, eStoresState.reserveMaterials, getSAPName(
          global.userData.displayName.split(' ')[0],
          global.userData.displayName.split(' ')[1],
        ));
        hideLoader();
        setShowSuccessModal(true);
      } else {
        data = await costCenterReservation(global.plantId, eStoresState.orderData, eStoresState.reserveMaterials, getSAPName(
          global.userData.givenName,
          global.userData.surname,
        ));
        hideLoader();
        setShowSuccessModal(true);
      }
      if(data) {
        setReservationNumber(data.reservationNumber)
      }
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };
  const onOkClick = () => {
    resetEStoresState();
    onWBSData();
    setOrderValue('');
    setShowSuccessModal(false);
  };

  // const onAttWOSearch = async () => await onWOSearch(true, attentionOrderValue)
  // const [wbsvalue,setwbsValue]=useState(null)
  // const [equipmentvalue,setEquipmentvalue]=useState([])
  // const multivalue=(x,y)=>{
  //   setwbsValue(x);
  //   setEquipmentvalue(y);
  //   console.log(equipmentvalue)
  // }
  const renderView = () => {
    if (isOrderSearchFailed) {
      return (
        <div>
          <img src={imgFailure} height={20} width={20} alt="error" style={{ marginTop: '3px' }} />
        </div>
      );
    }
    if (checkNotNull(eStoresState.orderData)) {
      return (
        <div>
          <img src={imgSuccess} height={20} width={20} alt="success" style={{ marginTop: '3px' }} />
        </div>
      );
    }
    if ((((activeTab === reservationStrings.WORK_ORDER) && (orderValue.length >= 8)) || ((activeTab === reservationStrings.COST_CENTER) && (orderValue.length >= 10)))) {
      return (
        <div style={{ cursor: 'pointer' }} onClick={activeTab === reservationStrings.WORK_ORDER ? onWOButtonClick : onCCSearch} role="presentation" onKeyDown={(e) => searchWo(e)}>
          <img style={{ marginTop: '3px' }} src={imgGoSelected} height={20} width={20} alt="go-enabled" />
        </div>
      );
    }
    return (
      <div>
        <img style={{ marginTop: '3px' }} src={imgGo} height={20} width={20} alt="go-disabled" />
      </div>
    );
  };

  return (
    <ContentWithBreadcrumb>
      <h1 className={`${styles.headerTitle} d-flex  align-items-center`} style={{ width: 'fit-content', paddingLeft: 5 }}>
        {`${reservationStrings.HEADER_TEXT} `}
        <span className={`${styles.headerBreadCrumb}`}>
          {
            (activeTab === reservationStrings.WBS
              && (checkNotNull(eStoresState.wbsSelectedValue.value)
                ? `${activeTab} # ${eStoresState.wbsSelectedValue.value}`
                : `No ${activeTab} Selected`))
              || (checkNotNull(eStoresState.orderData)
                ? `${activeTab} # ${orderValue}`
                : `No ${activeTab} Selected`)
          }

        </span>
      </h1>
      <div className="px-4">
        <div>
          <div className={`${styles.reservationHeader}`}>
            <div className={`${styles.tabContainer} mr-4 `}>
              <div onClick={onWOTabClick} className={`${styles.woTabView} ${activeTab === reservationStrings.WORK_ORDER ? styles.activeTab : styles.disabledTab}`} role="presentation">
                <p className={`${styles.tabText}`}>{reservationStrings.WORK_ORDER}</p>
              </div>
              <div onClick={onCCTabClick} className={`${styles.ccTabView} ${activeTab === reservationStrings.COST_CENTER ? styles.activeTab : styles.disabledTab}`} role="presentation">
                <p className={`${styles.tabText}`}>{reservationStrings.COST_CENTER}</p>
              </div>
              <div onClick={onWBSTabClick} className={`${styles.wbsTabView} ${activeTab === reservationStrings.WBS ? styles.activeTab : styles.disabledTab}`} role="presentation">
                <p className={`${styles.tabText}`}>{reservationStrings.WBS}</p>
              </div>
              {activeTab === reservationStrings.WORK_ORDER || activeTab === reservationStrings.COST_CENTER
                ? (
                  <div className="d-flex ml-2 ">
                    <CustomTextInput
                      editable
                      type="number"
                      rows={1}
                      placeholder={`${activeTab} #`}
                      isFailed={isOrderSearchFailed}
                      onValueChange={onValueChange}
                      value={orderValue}
                      onKeyDown={activeTab === reservationStrings.WORK_ORDER ? onWOKeyDown : onCCKeyDown}
                    />
                    <div className="ml-3">
                      {/* {
                        isOrderSearchFailed
                          ? (
                            <div>
                              <img src={imgFailure} height={20} width={20} alt="error" style={{ marginTop: '3px' }} />
                            </div>
                          )
                          : checkNotNull(eStoresState.orderData)
                            ? (
                              <div>
                                <img src={imgSuccess} height={20} width={20} alt="success" style={{ marginTop: '3px' }} />
                              </div>
                            )
                            : (
                              (((activeTab === reservationStrings.WORK_ORDER) && (orderValue.length >= 8)) || ((activeTab === reservationStrings.COST_CENTER) && (orderValue.length >= 10))))
                              ? (
                                <div style={{ cursor: 'pointer' }} onClick={activeTab === reservationStrings.WORK_ORDER ? onWOButtonClick : onCCSearch} role="presentation">
                                  <img style={{ marginTop: '3px' }} onClick={activeTab === reservationStrings.WORK_ORDER ? onWOButtonClick : onCCSearch} src={imgGoSelected} height={20} width={20} alt="go-enabled" />
                                </div>
                              )
                              : (
                                <div>
                                  <img style={{ marginTop: '3px' }} src={imgGo} height={20} width={20} alt="go-disabled" />
                                </div>
                              )
                      } */}
                      {renderView()}
                    </div>
                  </div>
                )
                : ''}
              {/* </div>:<div className="col" style={{marginTop:"-5px",zIndex:2000,width:"250px",height:"35.31px"}}><MultiDropdown selectedObject={{name:"SY1 - SCRAP YARD", value:"SY1 - SCRAP YARD"}} data={funcLocDetails} name='' updateValues={(value,equipmentvalue)=>multivalue(value,equipmentvalue)} updateFlocValue={(data)=>{console.log(data)}} editable/></div>} */}
              <div
                className="col"
                style={{
                  zIndex: 700, marginTop: '-5px', width: '350px', height: '35.31px',
                }}
              >
                {
                  activeTab === reservationStrings.WBS && checkArrayNotNull(eStoresState.wbsData)
                    && (
                      <MultiDropdown
                        data={eStoresState.wbsData}
                        selectedObject={eStoresState.wbsSelectedValue}
                        childElement="elements"
                        childName="shortDescription"
                        // name={workOrderStrings.FUNCTIONAL_LOCATION}
                        // updateValues={(value, equipmentval) => displayValue(value, equipmentval)}
                        updateFlocValue={getWbsValue}
                        value={eStoresState.wbsSelectedValue}
                        editable
                      />
                    )
                }
              </div>
            </div>

            <div className={`${styles.workOrderContainer} flex-grow-1`}>
              <div style={{
                display: 'flex',
                height: '35.31px',
                marginTop: '-5px',
                width: '350px',
              }}
              >
                {
                  (activeTab === reservationStrings.WORK_ORDER && eStoresState.orderData)
                  && (
                    <div className="col-4">
                      <CustomDropdown
                        data={{
                          // name: reservationStrings.SELECT_OPERATION,
                          name: '',
                          selectedObject: selectedOperation,
                          isPickerOpen: opPicker,
                          items: eStoresState.orderData.operations.map((item) => ({ name: `${item.activity} - ${item.description}`, value: item.activity })) || [],
                        }}
                        style={{ zIndex: 900 }}
                        editable={Boolean(eStoresState.orderData)}
                        updatePickerOpenStatus={toggleOpPicker}
                        updateDropDownItem={({ name, value }) => {
                          setOpPicker(false);
                          setSelectedOperation({ name, value });
                          updateOrder({
                            ...eStoresState.orderData,
                            selectedOperation: eStoresState.orderData.operations.find((item) => item.activity === value),
                          });
                        }}
                      />
                    </div>
                  )
                }
                {/* <div className="col-6">
                  <CustomTextInput
                    textStyle={isOrderSearchFailed ? { color: "#CC0000", textTransform: "uppercase" } : { textTransform: "uppercase" }}
                    label={reservationStrings.SHORT_TEXT}
                    editable={false}
                    value={isOrderSearchFailed ?activeTab === reservationStrings.COST_CENTER ? `Cost center does not exist` : `Work order does not exist` : (activeTab === reservationStrings.COST_CENTER ? eStoresState.orderData?.description : eStoresState.orderData?.shortDescription)}
                  />
                </div> */}
              </div>
              {/* <CustomTextInput
                label={reservationStrings.USER}
                editable={false}
                value={global.userData?.displayName}
              /> */}
            </div>
          </div>
        </div>
        {/* {
          indexNeedWO.length > 0 &&
          <div className="mt-3 py-3 d-flex attentionRow" >
            <div className="woAttentionContainer">
              <CustomTextInput
                label={reservationStrings.WO_ATTENTION}
                editable={false}
                value={global.userData?.displayName} />
              <div className="mx-3 d-flex align-items-end">
                <CustomTextInput
                  label={reservationStrings.WORK_ORDER}
                  value={attentionOrderValue}
                  type="number"
                  onValueChange={onAttentionOrderChange}
                  autoCapitalize="characters"
                  editable={true}
                />
                <div className="ml-3" style={{ marginBottom: 10 }}>
                  {
                    isAttOrderSearchFailed ?
                      <div>
                        <img src={imgFailure} height={20} width={20} alt="error" />
                      </div> : checkNotNull(eStoresState.attOrderData) ?
                        <div>
                          <img src={imgSuccess} height={20} width={20} alt="success" />
                        </div> :
                        attentionOrderValue.length >= 8 ?
                          <div style={{ cursor: "pointer" }} onClick={onAttWOSearch}>
                            <img src={imgGoSelected} height={20} width={20} alt="go-enabled" />
                          </div> :
                          <div>
                            <img src={imgGo} height={20} width={20} alt="go-disabled" />
                          </div>
                  }
                </div>
              </div>
            </div>
            <div className="flex-grow-1">
              <CustomTextInput
                textStyle={isAttOrderSearchFailed ? { color: "#CC0000", textTransform: "uppercase" } : { textTransform: "uppercase" }}
                label={reservationStrings.SHORT_TEXT}
                editable={false}
                value={isAttOrderSearchFailed ? `Work order does not exist` : eStoresState.attOrderData?.shortDescription}
              />
            </div>
          </div>
        } */}
        {
          (activeTab === reservationStrings.WORK_ORDER || activeTab === reservationStrings.COST_CENTER)
            ? (
              <div className={`${styles.btnText}`}>
                <p className={`${styles.btncolor}`}>
                  {eStoresState.orderData
                    ? `${orderValue} ${eStoresStrings.SELECTED}`
                    : `${activeTab.toUpperCase()} ${eStoresStrings.NOT_SELECTED}`}
                </p>
                <p className={`${styles.btncolor}`}>
                  {eStoresState.orderData
                    ? `${eStoresStrings.VALID} ${activeTab.toUpperCase()}`
                    : `${eStoresStrings.SELECT_A_VALID} ${activeTab.toUpperCase()}`}
                </p>
                <p className={`${activeTab === reservationStrings.COST_CENTER ? styles.btnnone : styles.btncolor}`}>
                  {selectedOperation && eStoresState.orderData ? `${selectedOperation.name} ${eStoresStrings.SELECTED}`
                    : eStoresStrings.OPERATION_NOT_SELECTED}
                </p>
                <p className={`${(eStoresState.orderData || selectedOperation) || activeTab === reservationStrings.COST_CENTER ? styles.btnnone : styles.btncolor}`}>
                  {eStoresStrings.SELECT_VALID_OPERATION}
                </p>
              </div>
            )
            : (
              <div className={`${styles.btnText}`}>
                <p className={`${styles.btncolor}`}>
                  {eStoresState.wbsSelectedValue.value
                    ? `${eStoresStrings.VALID} ${activeTab.toUpperCase()}`
                    : `${eStoresStrings.SELECT_A_VALID} ${activeTab.toUpperCase()}`}
                </p>
                <p className={`${styles.btncolor}`}>
                  {
                    eStoresState.wbsSelectedValue.value
                      ? `${eStoresStrings.SELECTED_WBS_ELEMENT} ${eStoresState.wbsSelectedValue.value}`
                      : `${eStoresStrings.WBS_NOT_SELECTED}`
                  }
                </p>

              </div>
            )
        }
        <div className="mt-3">
          <div>
            <h1 className={`${styles.titleText}`}>{reservationStrings.MATERIALS}</h1>
          </div>
          <div>

            {renderReserveMaterials()}
          </div>
          <div className={`${styles.addContainer}`}>
            <div
              className={`${styles.addRow}`}
              onClick={addReserveLineItem}
              role="presentation"
            >
              <img src={imgAddRow} alt="add-row" height={25} width={25} />
            </div>
          </div>
          <div className={`${styles.heightFooter}`} />
        </div>
        {
          showPCModal && (
            <ProductCatalogModal
              title={eStoresStrings.PRODUCT_CATALOG}
              index={indexForPC - 1}
              onProductCatalogSearch={updateMaterialDetails}
              closePCModal={closePCModal}
              plantId={global.plantId}
            />
          )
        }
        {
          showWarningModal
          && (
            <CustomModal
              customWidth="600px"
              header={{ title: reservationStrings.CHANGE_RES_TYPE, bgColor: '#003366' }}
              actions={{ cancel: () => setShowWarningModal(false) }}
              buttons={(
                <div className="d-flex flex-row justify-content-end mb-4 mr-4">
                  <CustomButton
                    customStyle={{ width: '20%', padding: 5, marginRight: 20 }}
                    onPress={onNoClick}
                    outline
                    value={eStoresStrings.NO}
                  />
                  <CustomButton
                    customStyle={{ width: '20%', padding: 5 }}
                    onPress={onYesClick}
                    value={eStoresStrings.YES}
                  />
                </div>
              )}
            >
              <div className={`${styles.modal__content} row gx-0 justify-content-center"`}>
                <div className="d-flex justify-content-center flex-column  align-items-center">
                  <p className={`${styles.resInsText}`}>
                    {reservationStrings.CHANGE_RES_TYPE_INS_1}
                    {` ${tabValue}`}
                  </p>
                  <p className={`${styles.resInsText}`}>
                    {reservationStrings.CHANGE_RES_TYPE_INS_2}
                  </p>
                </div>
              </div>
            </CustomModal>
          )
        }
        <div className={`${styles.saveResButton} w-100 border `}>
          <CustomButton
            customStyle={{ paddingLeft: 30, paddingRight: 30 }}
            onPress={onReservationClick}
            value={reservationStrings.SAVE_RESERVATION}
            disabled={activeTab === reservationStrings.WBS ? !(checkReserveMaterials(eStoresState.reserveMaterials, eStoresState.wbsSelectedValue.value)) : !eStoresState.canBeSaved || (activeTab === reservationStrings.WORK_ORDER && !checkNotNull(selectedOperation))}
          />
        </div>
      </div>
      {showSuccessModal && (
        <ButtonModal
          noOfButtons={1}
          isSuccess
          onButtonPress={onOkClick}
          data={{ buttonText: eStoresStrings.OK, instructionsText1: `${eStoresStrings.RESERVATION_SAVED} with ${reservationNumber}` }}
        />
      )}
    </ContentWithBreadcrumb>
  );
}
EStores.propTypes = {
  addBreadCrumb: PropTypes.func,
  addReserveLineItem: PropTypes.func,
  deleteReserveLineItem: PropTypes.func,
  eStoresState: PropTypes.shape({
    attOrderData: PropTypes.string,
    canBeSaved: PropTypes.bool,
    orderData: PropTypes.shape({
      operations: PropTypes.shape({
        find: PropTypes.func,
        map: PropTypes.func,
      }),
    }),
    reserveMaterials: PropTypes.shape({
      forEach: PropTypes.func,
      map: PropTypes.func,
    }),
    wbsData: PropTypes.arrayOf(PropTypes.shape({})),
    wbsSelectedValue: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  global: PropTypes.shape({
    plantId: PropTypes.number,
    userData: PropTypes.shape({
      displayName: PropTypes.shape({
        split: PropTypes.func,
      }),
      givenName: PropTypes.string,
      surname: PropTypes.string,
    }),
    userPersonalData: PropTypes.shape({
      sapUserID: PropTypes.string,
    }),
  }),
  hideLoader: PropTypes.func,
  resetBreadCrumbs: PropTypes.func,
  resetEStoresState: PropTypes.func,
  resetLineItem: PropTypes.func,
  showLoader: PropTypes.func,
  updateAttOrder: PropTypes.func,
  updateMaterial: PropTypes.func,
  updateOrder: PropTypes.func,
  updateReserveMaterials: PropTypes.func,
  updateSelectedPage: PropTypes.func,
  getWbsData: PropTypes.func,
  getWbsValue: PropTypes.func,
};
EStores.defaultProps = {
  addBreadCrumb: () => { },
  addReserveLineItem: () => { },
  deleteReserveLineItem: () => { },
  eStoresState: PropTypes.shape({
    attOrderData: '',
    canBeSaved: false,
    orderData: PropTypes.shape({
      operations: PropTypes.shape({
        find: () => { },
        map: () => { },
      }),
    }),
    reserveMaterials: PropTypes.shape({
      forEach: () => { },
      map: () => { },
    }),
  }),
  global: PropTypes.shape({
    plantId: null,
    userData: PropTypes.shape({
      givenName: '',
      surname: '',
    }),
    userPersonalData: PropTypes.shape({
      sapUserID: '',
    }),
  }),
  hideLoader: () => { },
  resetBreadCrumbs: () => { },
  resetEStoresState: () => { },
  resetLineItem: () => { },
  showLoader: () => { },
  updateAttOrder: () => { },
  updateMaterial: () => { },
  updateOrder: () => { },
  updateReserveMaterials: () => { },
  updateSelectedPage: () => { },
  getWbsData: () => { },
  getWbsValue: () => { },
};

export default EStores;
