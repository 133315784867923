import PropTypes from 'prop-types';
import React from 'react';

export default function ArrowRight({ fill }) {
  return (
    <svg
      fill="none"
      height="44"
      viewBox="0 0 27 44"
      width="27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0082 23.6573L6.94102 42.6362C6.02143 43.5515 4.53054 43.5515 3.61105 42.6362L1.38718 40.4226C0.469159 39.5088 0.467393 38.0279 1.38325 37.1119L16.4943 22L1.38325 6.88821C0.467393 5.97229 0.469159 4.49133 1.38718 3.57756L3.61105 1.36399C4.53064 0.448657 6.02153 0.448657 6.94102 1.36399L26.0081 20.3428C26.9277 21.258 26.9277 22.742 26.0082 23.6573Z"
        fill={fill || '#53626A'}
      />
    </svg>
  );
}

ArrowRight.propTypes = {
  fill: PropTypes.string,
};

ArrowRight.defaultProps = {
  fill: '',
};
