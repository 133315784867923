import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import { Provider, connect } from 'react-redux';
import './App.css';
import { useMsal } from '@azure/msal-react';
import { QueryClientProvider } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import store from './redux/store';
import CustomNavBar from './components/controls/custom-nav-bar';
import Root from './components/root.component';
import CustomLoader from './components/controls/custom-loader/custom-loader.component';
import CustomLoaderContainer from './components/controls/custom-loader';
import { showLoader, hideLoader } from './redux/actions/global.actions';
import Login from './components/pages/login';
import queryClient from './utilities/queryClient';
import { NotificationModal } from './Helpers/push-notifications';


const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

export default function App(props) {
  const { instance, accounts, inProgress } = useMsal();
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {accounts.length > 0 ? (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <AppContainer {...props} />
        ) : (
          <div className="h-100 d-flex p-0">
            {(inProgress === 'login' || inProgress === 'handleRedirect') && (
              // <CustomLoader enabled={true} />
              <CustomLoader enabled />
            )}
            <Login instance={instance} />
          </div>
        )}
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export function AppShell(props) {
  const {global} = props;
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);

  useEffect(() => {
    const updatePermissionState = () => {
      setIsPermissionGranted(Notification.permission === 'granted');
    };

    updatePermissionState();

    const handlePermissionChange = () => {
      updatePermissionState();
    };

    const permissionChangeHandler = () => {
      handlePermissionChange();
    };

    document.addEventListener('DOMContentLoaded', () => {
      Notification.requestPermission().then(() => {
        updatePermissionState();
        document.addEventListener('change', permissionChangeHandler);
      });
    });
  }, []);

  return (
    <>
      {isPermissionGranted ? (
        <>
          <CustomNavBar />
          <div className="app-container container-fluid h-100 p-0">
            <CustomLoaderContainer />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Root selectedPage={global.selectedPage} {...props} />
          </div>
        </>
      ) : <NotificationModal />
      }
    </>
  );
}

AppShell.propTypes = {
  global: PropTypes.shape({
    selectedPage: PropTypes.string,
  }),
};

AppShell.defaultProps = {
  global: PropTypes.shape({
    selectedPage: '',
  }),
};

const mapStateToProps = (state) => ({
  global: state.globalReducer,
});
const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  hideLoader: () => dispatch(hideLoader()),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppShell);
