import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import './sub-header.styles.css';
import imgUp from '../../../assets/images/sub-header-close.png';
import imgDown from '../../../assets/images/sub-header-open.png';
import styles from './sub-header.module.scss';

export default function SubHeader({ icon, children, headerContent }) {
  const [headerOpen, setHeaderOpen] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const onSubHeaderClick = () => {
    setIsMounted(!isMounted);
    setHeaderOpen(!headerOpen);
  };

  const mountedStyle = {
    animation: 'inAnimation 250ms ease-in',
  };
  const unmountedStyle = {
    animation: 'outAnimation 270ms ease-out',
    animationDelay: 1,
  };
  return (
    <>
      <div
        className={`${styles.subheaderContainer} shadow-sm`}
        onClick={onSubHeaderClick}
        role="presentation"
      >
        <div className={`${styles.headerCol}`}>
          {icon && (
            <div className={`${styles.headerIconDiv}`}>
              <img alt="header-icon" src={icon} height={14} width={14} />
            </div>
          )}
          <div className={`${styles.subHeaderContent}`}>{headerContent}</div>
        </div>
        <div className={`${styles.arrowDiv}`}>
          {headerOpen ? (
            <img alt="up-arrow" src={imgUp} className={`${styles.arrowIcon}`} />
          ) : (
            <img alt="down-arrow" src={imgDown} className={`${styles.arrowIcon}`} />
          )}
        </div>
      </div>
      {headerOpen && (
        <div
          className="childrenStyle"
          style={isMounted ? mountedStyle : unmountedStyle}
          onAnimationEnd={() => {
            if (!isMounted) setHeaderOpen(false);
          }}
        >
          {children}
        </div>
      )}
    </>
  );
}
SubHeader.propTypes = {
  children: PropTypes.element,
  headerContent: PropTypes.string,
  icon: PropTypes.element,
};
SubHeader.defaultProps = {
  children: '',
  headerContent: '',
  icon: '',
};
