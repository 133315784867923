import { sparesManagementTypes } from '../../../redux/actions/action-types';

const initialState = {
  transactions: [],
};

const sparesMgtReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case sparesManagementTypes.UPDATE_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    default:
      return state;
  }
};

export default sparesMgtReducer;
