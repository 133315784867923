import AWS from 'aws-sdk';
import parse from 'date-fns/parse';
import { format } from 'date-fns';
import parseJSON from 'parse-json';
import {
  updateValue,
  updateFuncLocData,
  updateType,
  updateDropDownItem,
  updateParentOrderID,
  updateMultiSelect,
  updateCheckBoxStatus,
  addReserveLineItem,
  updateMaterial,
  clearReserveMaterials,
  updateEquipment,
  updateWrkCtrItems,
  updateFlocValue,
} from './work-order.actions';
import { createWorkOrderStrings } from '../../../constants/strings';
import {
  checkNotNull,
  getURL,
  prepareWorkCenterItems,
  prepareFunctionLocationObject,
} from '../../../Helpers/common-helpers';
import { HttpPost, HttpGet, HttpPut } from '../../../services/api-services';
import {
  POST_ORDERS,
  CWO_IMAGES,
  GET_AWS_CREDS,
  MATERIAL_SEARCH,
  GET_WORK_CTR_BY_PLANT,
} from '../../../constants/endpoints';
import config from '../../../utilities/config';
import staticEOItemsData from './work-order-eo.data';
import {
  priorityCWItems,
  specialityWOItems,
  impactWOItems,
  workOrderSysCondtion,
} from '../../../constants/enums';

export const initialiseData = async (
  dispatch,
  userData,
  plantID,
  followUpOrderDetails,
  formsData,
  typeOfOrder,
  dataFromSpares
) => {
  try {
    const userValue = {
      key: createWorkOrderStrings.USER,
      value: userData.displayName,
    };
    dispatch(updateValue(userValue));
    let funcLocL1 = `${plantID} - US10 Tampa Office`;
    if (checkNotNull(userData.officeLocation)) {
      funcLocL1 = `${plantID} - ${userData.officeLocation}`;
    }
    const locationValue = {
      key: createWorkOrderStrings.FUNC_LOC_LEV_1,
      value: funcLocL1,
    };
    dispatch(updateValue(locationValue));
    const functionLocationData = formsData.funcLocDetails;
    dispatch(updateFuncLocData(functionLocationData));
    dispatch(updateWrkCtrItems(formsData.workCenterDetails));

    if (checkNotNull(followUpOrderDetails)) {
      const mnWkCtrObj = formsData.workCenterDetails.find(
        (item) => item.value === followUpOrderDetails.mnWrkCtrId,
      );
      if (mnWkCtrObj) {
        dispatch(
          updateDropDownItem({
            key: createWorkOrderStrings.MAIN_WORK_CENTER,
            value: mnWkCtrObj.value,
            name: mnWkCtrObj.name,
          }),
        );
      }
      dispatch(updateType(typeOfOrder));
      if (followUpOrderDetails.orderId) {
        dispatch(updateParentOrderID(followUpOrderDetails.orderId));
      }
      if (typeOfOrder === 'editorder') {
        const orderTypeObject = staticEOItemsData[createWorkOrderStrings.ORDER_TYPE].find((item) => item.value === followUpOrderDetails.orderType);
        dispatch(
          updateDropDownItem({
            key: createWorkOrderStrings.ORDER_TYPE,
            value: orderTypeObject.value,
            name: orderTypeObject.name,
            isFollowup: true,
          }),
        );
        const activityTypeObject = orderTypeObject.items.find(
          (item) => item.value === followUpOrderDetails.activityType,
        );
        dispatch(
          updateDropDownItem({
            key: createWorkOrderStrings.ACTIVITY_TYPE,
            value: activityTypeObject?.value,
            name: activityTypeObject?.name,
            isFollowup: true,
          }),
        );
        const priorityObject = priorityCWItems.find(
          (item) => item.value === followUpOrderDetails.priority,
        );
        dispatch(
          updateDropDownItem({
            key: createWorkOrderStrings.PRIORITY,
            value: priorityObject?.value,
            name: priorityObject?.name,
          }),
        );
        const sysConditionObj = workOrderSysCondtion.find(
          (item) => item.value === followUpOrderDetails.sysCondition,
        );
        dispatch(
          updateDropDownItem({
            key: createWorkOrderStrings.SYS_CONDITION,
            value: sysConditionObj?.value,
            name: sysConditionObj?.name,
          }),
        );
        dispatch(
          updateValue({
            key: createWorkOrderStrings.SHORT_TEXT,
            value:
              followUpOrderDetails?.longText?.length > 40
                ? followUpOrderDetails.longText
                : followUpOrderDetails.shortDescription,
          }),
        );
        dispatch(
          updateValue({
            key: createWorkOrderStrings.OP_DESC,
            value:
              followUpOrderDetails.selectedOperation?.longText?.length > 40
                ? followUpOrderDetails.selectedOperation.longText
                : followUpOrderDetails.selectedOperation.description,
          }),
        );
        dispatch(
          updateValue({
            key: createWorkOrderStrings.REVISION,
            value: followUpOrderDetails?.revisionNumber,
          }),
        );
        const opWkCtrObj = formsData.workCenterDetails.find(
          (item) => item.value === followUpOrderDetails.selectedOperation?.workCenter,
        );
        dispatch(
          updateDropDownItem({
            key: createWorkOrderStrings.OP_WK_CNTR,
            value: opWkCtrObj.value,
            name: opWkCtrObj.name,
          }),
        );
        dispatch(
          updateValue({
            key: createWorkOrderStrings.OP_DURATION,
            value: parseFloat(
              followUpOrderDetails.selectedOperation?.opDuration,
            ),
          }),
        );
        dispatch(
          updateValue({
            key: createWorkOrderStrings.NO_OF_RQD_RES,
            value: Number(
              followUpOrderDetails.selectedOperation?.numberOfCapacities,
            ),
          }),
        );
        try {
          dispatch(
            updateValue({
              key: createWorkOrderStrings.BASIC_START_DATE,
              value: format(
                parse(
                  followUpOrderDetails.selectedOperation.earlyStartDt,
                  'yyyyMMdd',
                  new Date(),
                ),
                'yyyy-MM-dd',
              ),
            }),
          );
        } catch (e) {
          console.log('error', JSON.stringify(e));
        }
        const userStatus = followUpOrderDetails.userStatus?.split(' ');
        if (followUpOrderDetails.userStatus.includes('04RK')) {
          dispatch(
            updateCheckBoxStatus({
              key: createWorkOrderStrings.KITTING,
              value: true,
            }),
          );
        }
        if (followUpOrderDetails.userStatus.includes('04AD')) {
          dispatch(
            updateCheckBoxStatus({
              key: createWorkOrderStrings.IMMEDIATE_EXECUTION,
              value: true,
            }),
          );
        }
        const speciality = userStatus.filter((item) => item.includes('01'));
        const impact = userStatus.filter((item) => item.includes('10'));
        const specialityItems = [...specialityWOItems];
        const impactItems = [...impactWOItems];
        impact.forEach((impactItem) => {
          const seletedIndex = impactItems.findIndex(
            (item) => item.value === impactItem,
          );
          if (seletedIndex !== -1) {
            impactItems[seletedIndex].selected = true;
            impactItems[seletedIndex].saved = true;
          }
        });
        if (impact.length > 0) {
          dispatch(
            updateMultiSelect({
              key: createWorkOrderStrings.IMPACT,
              value:
                impact.length > 1
                  ? 'Multiple Selected'
                  : impactWOItems[
                    impactWOItems.findIndex((item) => item.value === impact[0])
                  ].name,
              items: impactItems,
            }),
          );
        }
        speciality.forEach((specialityItem) => {
          const seletedIndex = specialityItems.findIndex(
            (item) => item.value === specialityItem,
          );
          if (seletedIndex !== -1) {
            specialityItems[seletedIndex].selected = true;
            specialityItems[seletedIndex].saved = true;
          }
        });
        if (speciality.length > 0) {
          dispatch(
            updateMultiSelect({
              key: createWorkOrderStrings.SPECIALITY,
              value:
                speciality.length > 1
                  ? 'Multiple Selected'
                  : specialityWOItems[
                    specialityWOItems.findIndex(
                      (item) => item.value === speciality[0],
                    )
                  ].name,
              items: specialityItems,
            }),
          );
        }
      }
      const materials = followUpOrderDetails.selectedOperation?.materials;
      if (materials) {
        dispatch(clearReserveMaterials());
        materials.forEach((material, index) => {
          dispatch(addReserveLineItem());
          dispatch(
            updateMaterial({
              [createWorkOrderStrings.MATERIAL_MEMO]: material.memoText,
              [createWorkOrderStrings.PO_TEXT]: material.poText,
              [createWorkOrderStrings.MATERIAL]:
                material.materialReserved.slice(-8),
              [createWorkOrderStrings.BIN_LOCATION]:
                material.storageBin || material.stageBin,
              [createWorkOrderStrings.UNIT_OF_MEASURE]: material.unitOfMeasure,
              [createWorkOrderStrings.UNRES_QTY]: material.unrestrictedQuantity,
              isMaterialFetched: true,
              // [createWorkOrderStrings.REQ_DATE]:
              // props.createWorkOrdersState[createWorkOrderStrings.BASIC_START_DATE],
              [createWorkOrderStrings.RECIPIENT]:
                material.recipient?.toUpperCase(),
              [createWorkOrderStrings.ITEM_NUMBER]: material.itemNumber,
              [createWorkOrderStrings.RESERVATION_ITEM]: material.reservationItem,
              [createWorkOrderStrings.UNLOAD_POINT]: material.unloadingPoint,
              [createWorkOrderStrings.QTY_RQD]: material.reqQuantity,
              // isMaterial:material.isMaterial,
              index,
            }),
          );
        });
      }

      const funcLoc1 = followUpOrderDetails.funLoc;
      if (funcLoc1) {
        dispatch(
          updateFlocValue({
            key: 'Functional Location',
            value: funcLoc1,
            name: funcLoc1,
          }),
        );
      }
      if (followUpOrderDetails.equipNr) {
        dispatch(
          updateEquipment(followUpOrderDetails.equipNr?.replace(/^0+/, '')),
        );
      }
    } else {
      dispatch(updateType('standalone'));
    }
    if(checkNotNull(dataFromSpares)) {
      dispatch(updateDropDownItem(dataFromSpares.OrderType))
      dispatch(updateDropDownItem(dataFromSpares.systemCondition))
      dispatch(updateDropDownItem(dataFromSpares.priority))
      dispatch(updateDropDownItem(dataFromSpares.activityType))
      dispatch(updateValue(dataFromSpares.orderDescription))
      dispatch(updateValue(dataFromSpares.operationDescription))
      // dispatch(updatePlantID(dataFromSpares.plantId))
      dispatch(updateFlocValue(dataFromSpares.sap_floc_id))
    }
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

export const getFuncLocData = async (plantId, token) => {
  try {
    // let apiURL = getURL(FUNCTION_LOCATIONS);
    // let params = {
    //   plant: plantId,
    //   category: 'T',
    // };
    // let searchResults = await HttpGet(apiURL, params);
    // let data = searchResults.data.message.data.documentFlow[0].descendant;
    // return await prepareFunctionLocationObject(data);
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const keys = await HttpGet(config.INS_SCH_BASE_URL + GET_AWS_CREDS, {}, header);
    AWS.config.update({
      accessKeyId: keys.data.body.accessKey,
      secretAccessKey: keys.data.body.secretKey,
    });
    const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
    const params = {
      Bucket: config.FLOC_BUCKET_NAME,
      Key: `${plantId}_bom.json`,
    };
    const searchResults = await s3
      .getObject({ ...params, ResponseContentDisposition: 'attachment' })
      .promise();
    return await prepareFunctionLocationObject(
      parseJSON(searchResults.Body.toString('utf-8')).message.data
        .documentFlow[0].descendant,
    );
    // return await prepareFunctionLocationObject(parseJSON(data));
  } catch (e) {
    console.log('e1', JSON.stringify(e));
    throw e;
  }
};

export const getMainWorkCenterData = async (plantId) => {
  try {
    const apiURL = getURL(GET_WORK_CTR_BY_PLANT);
    const params = {
      plantId,
    };
    const searchResults = await HttpGet(apiURL, params);
    return await prepareWorkCenterItems(searchResults.data.message.data);
  } catch (e) {
    console.log('e1', JSON.stringify(e));
    throw e;
  }
};

export const getFormsData = async (global, token) => {
  try {
    let funcLocDetails;
    let workCenterDetails;
    if(global.selectedPage == "Work Order From Spares") {
      funcLocDetails = await getFuncLocData(global.dataFromSpares?.plantId, token);
      workCenterDetails = await getMainWorkCenterData(global.dataFromSpares?.plantId);
    }
    else {
      funcLocDetails = await getFuncLocData(global.plantId, token);
      workCenterDetails = await getMainWorkCenterData(global.plantId);
    }
    return {
      funcLocDetails,
      workCenterDetails,
    };
  } catch (e) {
    throw new Error(e);
  }
};

const createPostRequestObject = async (
  orderState,
  plantID,
  sapUserId,
  confirmationDetails,
  orderDetails,
) => {
  const reqObj = {
    finalConf: confirmationDetails.finalConf,
    confText: confirmationDetails.confText,
    personalDetails: confirmationDetails.personnelDetails,
    // confExecution:true,
    confExecution:
      confirmationDetails.confText !== '' && confirmationDetails.personnelDetails.length > 0,
    plantId: plantID.toString(),
    userName: sapUserId,
    shortText: orderState[createWorkOrderStrings.SHORT_TEXT].value
      .slice(0, 40)
      .toUpperCase(),
    longText: orderState[createWorkOrderStrings.SHORT_TEXT].value.toUpperCase(),
    priority: orderState[createWorkOrderStrings.PRIORITY].selectedObject.value,
    orderType:
      orderState[createWorkOrderStrings.ORDER_TYPE].selectedObject.value,
    activityType:
      orderState[createWorkOrderStrings.ACTIVITY_TYPE].selectedObject.value,
    mainWorkCenter:
      orderState[
        createWorkOrderStrings.MAIN_WORK_CENTER
      ].selectedObject.value.toUpperCase(),
    fuctionalLoc: orderState.funcLocValue.value,
    sysCondition:
      orderState[createWorkOrderStrings.SYS_CONDITION].selectedObject.value,
    superiorOrderId:
      orderState.type === createWorkOrderStrings.FOLLOW_UP_TYPE
        ? `${orderState.parentOrderId}`
        : '',
    startDate: orderState[createWorkOrderStrings.BASIC_START_DATE].value,
    revision: orderState[createWorkOrderStrings.REVISION].value.toUpperCase(),
    equipment:
      orderState[createWorkOrderStrings.EQUIPMENT].selectedObject?.value,
    impact: orderState[createWorkOrderStrings.IMPACT].items
      .map((item) => {
        if (item.selected) {
          return item.value;
        }
        return undefined;
      })
      .filter((item) => item !== undefined),
    speciality: orderState[createWorkOrderStrings.SPECIALITY].items
      .map((item) => {
        if (item.selected) {
          return item.value;
        }
        return undefined;
      })
      .filter((item) => item !== undefined),
    kitting: orderState[createWorkOrderStrings.KITTING].value,
    immediateExecution:
      orderState[createWorkOrderStrings.IMMEDIATE_EXECUTION].value,
    operation: {
      mainWorkCenter:
        orderState[
          createWorkOrderStrings.OP_WK_CNTR
        ].selectedObject?.value.toUpperCase(),
      shortText: orderState[createWorkOrderStrings.OP_DESC].value
        .slice(0, 40)
        .toUpperCase(),
      duration: orderState[createWorkOrderStrings.OP_DURATION].value.toString(),
      numberCapacities: orderState[createWorkOrderStrings.NO_OF_RQD_RES].value,
      longText: orderState[createWorkOrderStrings.OP_DESC].value.toUpperCase(),
    },
    component: orderState.reserveMaterials
      .map((item) => {
        let obj = {};
        if (item.isMaterialFetched) {
          obj = {
            materialNo: item[createWorkOrderStrings.MATERIAL],
            itemNumber: item[createWorkOrderStrings.ITEM_NUMBER],
            reservationNumber: orderDetails?.reservationNumber,
            goodsRecipent: item[createWorkOrderStrings.RECIPIENT],
            unloadingPoint: item[createWorkOrderStrings.UNLOAD_POINT],
            reqmntQty: item[createWorkOrderStrings.QTY_RQD],
            itemCategory: 'L',
            reservationItem: item[createWorkOrderStrings.RESERVATION_ITEM],
            isMaterial: item.isMaterial || '',
          };
          return obj;
        }
        return undefined;
      })
      .filter((item) => item !== undefined),
    // component:[{
    //   "materialNo": "10928896",
    //   "itemNumber": "0150",
    //   "reservationItem": "0023",
    //   "reservationNumber": "0230360826",
    //   "isMaterial": "D"
    // }]
  };
  return reqObj;
};

const prepareWorkOrderObject = (orderData, orderId, plantID) => {
  const orderObj = {
    orderId,
    // personalNumber,
    plant: plantID,
    mnWrkCtrId:
      orderData[createWorkOrderStrings.MAIN_WORK_CENTER].value.toUpperCase(),
    shortDescription: orderData[createWorkOrderStrings.SHORT_TEXT].value,
    funLoc: orderData.funcLocValue,
    activityType:
      orderData[createWorkOrderStrings.ACTIVITY_TYPE].selectedObject.value,
    orderType:
      orderData[createWorkOrderStrings.ORDER_TYPE].selectedObject.value,
    priority: orderData[createWorkOrderStrings.PRIORITY].selectedObject.value,
    operations: [{ activity: '0010' }],
  };
  return orderObj;
};

export const uploadFileToS3 = async (imgList, token) => {
  try {
    let header = {
      Authorization: `Bearer ${token}`,
    };
    let keys = await HttpGet(config.INS_SCH_BASE_URL + GET_AWS_CREDS, {}, header);
    AWS.config.update({
      accessKeyId: keys.data.body.accessKey,
      secretAccessKey: keys.data.body.secretKey,
    });
    let s3ImageUrl = [];
    const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
    await Promise.all(imgList.map(async (imgData) => {
      if (!imgData.isUploaded) {
        let uploadParams = {
          Bucket: 'gln-maintenance-portal',
          Key: '',
          Body: null,
        };
        const base64Data = atob(imgData.base64Code);
        const uint8Array = new Uint8Array(base64Data.length);
        for (let i = 0; i < base64Data.length; i++) {
            uint8Array[i] = base64Data.charCodeAt(i);
        }
        uploadParams.Body = uint8Array;
        // uploadParams.Body = Buffer.from((imgData.base64Code, 'base64'));
        uploadParams.Key = `${new Date().getTime()}_${imgData.name}`;
 
        await s3.upload(uploadParams).promise();
        s3ImageUrl.push(config.INS_SCH_BASE_URL + CWO_IMAGES + uploadParams.Key);
      }
      // s3ImageUrl.push(INSP_SCHEDULE_URL + CWO_IMAGES + uploadParams.Key);
    }));
    return s3ImageUrl;
  } catch (e) {
    // eslint-disable-next-line
    throw "Failure in uploading images."
  }
};

export const onCreateOrdersPost = async (
  orderState,
  plantID,
  imgList,
  token,
  sapUserId,
  orderDetails,
  confirmationDetails,
) => {
  try {
    let imageUrls = [];
    if (imgList.length > 0) {
      imageUrls = await uploadFileToS3(imgList, token);
    }
    const reqObj = await createPostRequestObject(
      orderState,
      plantID,
      sapUserId,
      confirmationDetails,
      orderDetails,
    );
    reqObj.imageUrl = imageUrls;
    const apiURL = getURL(POST_ORDERS);
    let result;
    if (orderDetails != null) {
      reqObj.orderId = orderDetails.orderId;
      reqObj.activity = orderDetails.selectedOperation.activity;
      reqObj.operation.controlKey = orderDetails.selectedOperation.controlKey;
      reqObj.reservationNumber = orderDetails.reservationNumber;
      reqObj.component.forEach(
        (element) => element.reservationNumber === orderDetails.reservationNumber,
      );
      result = await HttpPut(apiURL, reqObj);
    } else {
      result = await HttpPost(apiURL, reqObj);
    }
    // let length = result.data.message.data.orders[0].message.split(' ');
    // getLastWordInString(result.data.message.data.order.message) |||| result.data.message.data.orders[0].message;
    const orderID = confirmationDetails.personnelDetails.length === 0
      ? result.data.message.data.order.message
      : result.data.message.data.orders[0].message;
    const orderObj = prepareWorkOrderObject(orderState, orderID, plantID);
    return orderObj;
  } catch (err) {
    let errorMessage = 'Something went wrong';
    if (typeof err === 'object') {
      try {
        // console.log(err.response)
        errorMessage = err?.response?.data?.message?.data;
        if (err.response.status === 404) {
          errorMessage = err?.response?.data?.message?.data.error?.description;
        }
      } catch {
        errorMessage = 'Something went wrong';
      }
    } else if (typeof err === 'string') {
      errorMessage = err;
    }
    throw errorMessage;
  }
};

export const getMaterialDetails = async (materialValue, plantId) => {
  try {
    const params = {
      plant: plantId,
    };
    const apiURL = getURL(`${MATERIAL_SEARCH}/${materialValue}`);
    const searchResults = await HttpGet(apiURL, params);
    if (searchResults.data.message.data.material) {
      return searchResults.data.message.data.material;
    }
    throw searchResults;
  } catch (e) {
    console.log(e);
    throw e.data.message.data.error.description || "No Material Found";
  }
};

export const stringConvertor = (str) => {
  const result = str.match(/.{1,2}/g) ?? [];
  return result.join(':');
};
