import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import imgDownArrow from '../../../assets/images/dropdown-arrow-blue.png';
import styles from './dropdown.module.scss';

export default function Dropdown({
  items,
  onItemClick,
  style = {},
  value,
  allowNewValues = false,
  pickerClass = '',
  withSmartTyping = true,
}) {
  const [dropdownPicker, setDropdownPicker] = React.useState(false);
  const [textValue, setTextValue] = React.useState('');
  const toggleDropdown = () => setDropdownPicker(!dropdownPicker);
  const onChangeText = (e) => {
    setTextValue(e.target.value);
    setDropdownPicker(true);
    if (allowNewValues) {
      onItemClick({ name: e.target.value });
    }
  };

  useEffect(() => {
    if (withSmartTyping) {
      setTextValue(value);
    }
    // eslint-disable-next-line
  }, [value])

  return (
    <OutsideClickHandler onOutsideClick={() => setDropdownPicker(false)}>
      <div style={{ ...style, backgroundColor: style.inputBgColor }} className={`${styles.dataDropdownContainer} ${dropdownPicker && styles.borderContainer}`}>
        <div
          style={{
            width: '--webkit-fill-available',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: style.inputBgColor,
          }}
          onClick={toggleDropdown}
          role="presentation"
        >
          {
            withSmartTyping
              ? (
                <input value={textValue} onChange={onChangeText} className={`${styles.dataDropDownInput}`} style={{ backgroundColor: style.inputBgColor }} />
              )
              : (
                <p className={`${styles.dataDropDownInput} m-0`} style={{ backgroundColor: style.inputBgColor }}>{value}</p>
              )
          }
          <img src={imgDownArrow} alt="dropdown-arrow" height={10} width={20} style={{ marginRight: 10 }} />
        </div>
      </div>
      {
        dropdownPicker && (items.filter((item) => item.name.includes(withSmartTyping ? textValue : '')).length > 0)
        && (
        <div className={`${styles.dataDropdownContent} ${pickerClass}`}>
          {items.filter((item) => item.name.includes(withSmartTyping ? textValue : '')).map((item) => {
            const onClick = () => { onItemClick(item); setDropdownPicker(false); };
            return (
              <div onClick={onClick} className={`${styles.dataDropdownItem}`} role="presentation">
                {item.name}
              </div>
            );
          })}
        </div>
        )
      }
    </OutsideClickHandler>
  );
}

Dropdown.propTypes = {
  allowNewValues: PropTypes.bool,
  items: PropTypes.shape({
    filter: PropTypes.func,
  }),
  onItemClick: PropTypes.func,
  pickerClass: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.shape()),
  value: PropTypes.string,
  withSmartTyping: PropTypes.bool,
};
Dropdown.defaultProps = {
  allowNewValues: false,
  items: PropTypes.shape({
    filter: () => { },
  }),
  onItemClick: () => { },
  pickerClass: '',
  style: { },
  value: '',
  withSmartTyping: false,
};
