import { combineReducers } from 'redux';

import globalReducer from './global.reducer';
import createWorkOrderReducer from '../../components/pages/work-order/work-order.reducer';
import workConfirmationReducer from '../../components/pages/work-confirm/work-confirm.reducer';
import singleConfirmationReducer from '../../components/pages/single-confirmation/single-confirmation.reducer';
import weekScheduleReducer from '../../components/pages/week-schedule/week-schedule.reducer';
import inspectionScheduleReducer from '../../components/pages/inspection-schedule/inspection-schedule.reducer';
import eStoresReducer from '../../components/pages/e-stores/e-stores.reducer';
import adminSetupReducer from '../../components/pages/admin-setup/admin-setup.reducer';
import sparesMgtReducer from '../../components/pages/spares-management-external-repairs/spares-management-external-repairs.reducer';

const reducers = combineReducers({
  globalReducer,
  createWorkOrderReducer,
  workConfirmationReducer,
  weekScheduleReducer,
  singleConfirmationReducer,
  inspectionScheduleReducer,
  eStoresReducer,
  adminSetupReducer,
  sparesMgtReducer,
});

export default reducers;
