import { format } from 'date-fns';
import { HttpGet, HttpPost } from '../../../services/api-services';
import { getURL, getOrderValue } from '../../../Helpers/common-helpers';
// import {updatePlantID, updateUserData} from './work-orders.actions';
import {
  GET_ORDERS,
  COST_CENTER,
  POST_WORK_CONFIRMATION,
  PERSONNEL_DATA,
  GET_CONFIRM_RECENT,
} from '../../../constants/endpoints';

export const getHourDecimal = ([hour, minute]) => {
  const dec = parseInt((minute / 6) * 10, 10);
  return {
    decimal: parseFloat(
      `${parseInt(hour, 10)}.${dec < 10 ? '0' : ''}${dec}`,
    ).toFixed('1'),
    minutes: hour * 60 + Number(minute),
  };
};

export const getDateViews = (date) => ({
  iso: date,
  localized: format(new Date(date), 'MM/dd/yyyy HH:mm'),
});

export const getStartDate = (date, duration) => {
  const ctx = new Date(date);
  return new Date(ctx.getTime() - duration * 60000).toISOString();
};

export const stringToDate = (string) => {
  const splitDate = string.split(' ');
  const date = splitDate[0].split('/');
  const time = splitDate[1].split(':');
  return new Date(
    date[2],
    date[0] - 1,
    date[1],
    time[0],
    time[1],
  ).toISOString();
};

export const getWorkOrderResults = async (plantId, searchValue) => {
  const apiURL = `${getURL(GET_ORDERS)}/${getOrderValue(searchValue)}`;
  const params = { plant: plantId };
  const response = await HttpGet(apiURL, params);
  if (response.data.message.data.error) {
    throw response.data.message.data.error.message;
  }
  return response.data.message.data.order;
};

export const getCCResults = async (plantId, searchValue) => {
  const apiURL = `${getURL(COST_CENTER)}/${searchValue}`;
  const params = { plant: plantId };
  const response = await HttpGet(apiURL, params);
  return response.data.message.data.costCenterDetails;
};

export const listPersonnelData = async (globalData) => {
  const apiURL = getURL(PERSONNEL_DATA);
  try {
    const params = {
      plantId: Number(globalData.plantId),
      email: globalData.userData.mail,
    };
    const response = await HttpGet(apiURL, params);
    return response.data.message.data;
  } catch (err) {
    console.log('ERR', JSON.stringify(err));
  }
  throw new Error('Unable to process request.');
};

export const submitWorkConfirmation = async (body) => {
  const apiURL = getURL(POST_WORK_CONFIRMATION);
  try {
    const response = await HttpPost(apiURL, body);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const personnelTemplate = (name, personnelNumber) => ({
  label: `${name} - ${personnelNumber}`,
  value: personnelNumber,
  actualDuration: {
    decimal: 0,
    minutes: 0,
  },
  startDate: undefined,
  endDate: undefined,
});

export const getLastConfirmatinosResults = async (plantId, pernr) => {
  const apiURL = getURL(GET_CONFIRM_RECENT);
  // let params = {plant: 1323, pernr: 10029329};
  const params = { plant: parseInt(plantId, 10), pernr: parseInt(pernr, 10) };
  const response = await HttpGet(apiURL, params);
  return response;
};
