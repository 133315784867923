import PropTypes from 'prop-types';
import React from 'react';
import Drawer from '../../../assets/svgs/icon_drawer';
import styles from './empty-list.module.scss';

export default function EmptyList({ message }) {
  return (
    <div className={styles.emptyScreen}>
      <Drawer />
      <span>{message}</span>
    </div>
  );
}

EmptyList.propTypes = {
  message: PropTypes.string,
};

EmptyList.defaultProps = {
  message: '',
};
