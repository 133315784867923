import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';
import styles from './page-loader.module.scss';

export default function PageLoader({ message, style = {} }) {
  return (
    <div
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderWidth: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        ...style,
      }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Loader
          type="Oval"
          color="#0578BE"
          height={40}
          width={40}
          visible
        />
        <p
          className={`${styles.messageStyle} d-flex align-items-center justify-content-center`}
        >
          {message}
        </p>
      </div>
    </div>
  );
}

PageLoader.propTypes = {
  message: PropTypes.string,
  style: PropTypes.shape({}),
};

PageLoader.defaultProps = {
  message: '',
  style: {},
};
