const SCHEDULE = {
  emptyScreen: {
    text: 'No scheduled work found for the time frame (+/- 7 days)',
    filterText: 'No results found for filter criteria.',
  },
  operationFields: [
    { label: 'Entered By', size: '3', key: 'displayName' },
    { label: 'Order Type', size: '3', key: 'orderType' },
    { label: 'Activity Type', size: '3', key: 'activityType' },
    { label: 'Main Work Center', size: '3', key: 'mnWrkCtrId' },
    { label: 'Basic Start Date', size: '3', key: 'basicStartDate' },
    { label: 'Basic Finish Date', size: '3', key: 'basicFinishDate' },
    { label: 'Priority', size: '3', key: 'priority' },
    { label: '# of People Assigned', size: '3', key: 'peopleAssigned' },
    { label: 'System Status', size: '3', key: 'systemStatus' },
    { label: 'User Status', size: '3', key: 'userStatus' },
    { label: 'Functional Location ID', size: '3', key: 'funLoc' },
    {
      label: 'Functional Location Description',
      size: '3',
      key: 'funLocDescription',
    },
    { label: 'Work Order Long Text', size: '12', key: 'wlongText' },
    { label: 'Operational Work Center', size: '6', key: 'workCenter' },
    { label: 'Operational Short Text', size: '6', key: 'description' },
    { label: 'Operational Long Text', size: '12', key: 'longText' },
  ],
  buttons: {
    confirmation: 'Create Confirmation',
    followup: 'Create Follow-Up',
    changeOrder: 'Edit Order',
  },
};

export default SCHEDULE;
