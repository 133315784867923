import PropTypes from 'prop-types';
import React from 'react';
// import './content-field.styles.css';
import styles from './content-field.module.scss';

export default function ContentField({ children, bootStrapClass = ' ' }) {
  return (
    <div className={`${styles.contentFieldContainer} ${bootStrapClass}`}>
      {children}
    </div>
  );
}

ContentField.propTypes = {
  bootStrapClass: PropTypes.string,
  children: PropTypes.element,
};
ContentField.defaultProps = {
  bootStrapClass: '',
  children: '',
};
